/**
 * Manages the visualization of audio data.
 */
export class AudioVisualizer {
  private readonly audioContext: AudioContext;
  private analyser?: AnalyserNode;
  private svgElement?: SVGElement;
  private animationFrameId?: number;
  private originalRx?: number;
  private readonly fftSize: number = 2048; // FFT size for the analyser
  private readonly maxFrequencyValue: number = 255; // Max value for Uint8Array
  private readonly amplitudeScale: number = 25; // Scale factor for radius change
  private readonly brightnessBoost: number = 50; // Percentage to boost brightness

  /**
   * Initializes the audio visualizer with a specific audio context.
   * @param {AudioContext} audioContext - The audio context to be used for audio operations.
   */
  constructor(audioContext: AudioContext) {
    this.audioContext = audioContext;
    this.setupAnalyzer();
  }

  /**
   * Sets up the analyzer node used in the audio visualization.
   */
  private setupAnalyzer(): void {
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = this.fftSize;
    this.analyser.connect(this.audioContext.destination);
  }

  /**
   * Sets the SVG element to be manipulated based on the audio data.
   * @param {SVGElement} svgElement - The SVG element used for visualization.
   */
  public setSvgElement(svgElement: SVGElement): void {
    this.svgElement = svgElement;

    const rxValue = svgElement.getAttribute('rx');
    if (rxValue) {
      this.originalRx = parseFloat(rxValue);
    }
  }

  /**
   * Connects a source node to the analyzer and starts visualization.
   * @param {AudioNode} sourceNode - The audio source node to connect to the analyzer.
   */
  public connectSource(sourceNode: AudioNode): void {
    if (!this.analyser) {
      console.error('Analyser node is not initialized.');
      return;
    }

    sourceNode.connect(this.analyser);
    this.startVisualization();
  }

  /**
   * Starts the visualization process by requesting animation frames.
   */
  private startVisualization(): void {
    if (!this.analyser) {
      return;
    }

    const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
    this.animationFrameId = requestAnimationFrame(() => this.updateVisualization(frequencyData));
  }

  /**
   * Updates the visualization with new audio data.
   * @param {Uint8Array} frequencyData - The frequency data array from the analyzer.
   */
  private updateVisualization(frequencyData: Uint8Array): void {
    if (!this.analyser) {
      return;
    }

    this.analyser.getByteFrequencyData(frequencyData);
    this.updateSvgAttributes(frequencyData);
    this.animationFrameId = requestAnimationFrame(() => this.updateVisualization(frequencyData));
  }

  /**
   * Updates the SVG element's attributes based on the calculated average frequency.
   * @param {Uint8Array} frequencyData - The frequency data used to adjust the SVG attributes.
   */
  private updateSvgAttributes(frequencyData: Uint8Array): void {
    const average = frequencyData.reduce((sum, value) => sum + value, 0) / frequencyData.length;
    this.updateRadius(average);
    this.updateBrightness(average);
  }

  /**
   * Updates the radius of the SVG element based on the frequency data.
   * @param {number} average - The average frequency value.
   */
  private updateRadius(average: number): void {
    if (!this.svgElement || !this.originalRx) {
      return;
    }

    const newRadius = this.originalRx + (average / this.maxFrequencyValue) * this.amplitudeScale;
    this.svgElement.setAttribute('rx', newRadius.toString());
    this.svgElement.setAttribute('ry', newRadius.toString());
  }

  /**
   * Updates the brightness of the SVG element based on the frequency data.
   * @param {number} average - The average frequency value.
   */
  private updateBrightness(average: number): void {
    if (!this.svgElement) {
      return;
    }

    const newBrightness = 100 + (average / this.maxFrequencyValue) * this.brightnessBoost;
    this.svgElement.style.filter = `brightness(${newBrightness}%)`;
  }
}
