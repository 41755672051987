import { FeatureFlagsProviderProps } from '@aily/feature-flags-service';

import envConfig from '@/config/env';

export const featureFlagsConfig: FeatureFlagsProviderProps = {
  apiKey: envConfig.FEATURE_FLAGS_API_KEY,
  config: {
    serverUrl: envConfig.FEATURE_FLAGS_API_URL,
    flagsServerUrl: envConfig.FEATURE_FLAGS_API_URL,
  },
};
