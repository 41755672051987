import { useMarkStoryAsReadMutation } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { AiInspired, AiXplained, ChevronRightIcon, config } from '@aily-labs/ui';
import { Refresh } from '@mui/icons-material';
import { Box, BoxProps, Button, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import mime from 'mime/lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  GroupedChartDataView,
  HeaderRowComponent,
  LoadingSkeleton,
  MarkdownRenderer,
} from '../../components';
import { useHandleLink } from '../../hooks';
import { AiSmall } from '../../icons';
import { useEnvironment, useStoriesAdapter } from '../../providers';
import theme from '../../theme/default';
import { colors } from '../../theme/default/colors';
import { lineClamp } from '../../theme/utils';
import { getFullUrl, mapSentimentToColor } from '../../utils';
import { Props as PageContentProps } from '../PageContent/PageContent';
import Stamp from '../Stamp';
import TableDataViewWithPageContent from '../TableDataView/TableDataViewWithPageContent';
import { VideoPlayer } from '../VideoPlayer';
import AddBrainButton from './AddBrainButton';
import MediaStory from './MediaStory';

export interface StoryViewProps {
  story: T.Story;
  className?: string;
  loading?: boolean;
  distanceFromCurrentItem?: number;
  onRead?: (story: T.Story) => void;
  onSeeMoreButtonClick?: (story: T.Story) => void;
  onSeeMoreButtonHoverChange?: (isHovered: boolean) => void;
  onResetButtonClick?: (story: T.Story) => void;
  onReferenceClick?: (story: T.Story, reference: T.ExternalLink) => void;
  fullWidth?: boolean;
}

const baseFontSize = 15;

const ShareIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path d="M26.5258 3.42768C28.0099 3.43479 28.9361 5.15352 28.1998 6.53418L16.3917 28.6755C15.5034 30.3411 13.1626 29.9358 12.8092 28.0552L10.4402 15.4491L1.43528 6.94781C0.0919378 5.67961 0.934747 3.30495 2.72515 3.31354L26.5258 3.42768ZM12.413 15.4273L14.7091 27.6454L25.568 7.28372L12.413 15.4273ZM24.5841 5.49478L2.71648 5.38991L11.4439 13.6291L24.5841 5.49478Z" />
  </SvgIcon>
);

function isAiExplainedOrAiInspiredStory(storyType: T.StoryDisplayType) {
  return storyType === T.StoryDisplayType.AiXplained || storyType === T.StoryDisplayType.AiInspired;
}

function isWhisperStory(storyType: T.StoryDisplayType) {
  return storyType === T.StoryDisplayType.Whisper || storyType === T.StoryDisplayType.WhisperReal;
}

function getStoryOuterGradient({ storyType, sentiment }: T.Story) {
  if (isAiExplainedOrAiInspiredStory(storyType)) {
    return `linear-gradient(0deg, ${colors.primary.aqua} 0, ${colors.neutrals.black} 100%)`;
  }

  if (isWhisperStory(storyType)) {
    return `linear-gradient(0deg, ${colors.primary.aqua} 0, #0C3A41 30%, #111521 70%, ${colors.neutrals.black} 100%)`;
  }

  const sentimentColor = mapSentimentToColor(sentiment ?? T.Sentiment.Neutral, {
    [T.Sentiment.Neutral]: colors.primary.aqua,
  });

  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return `linear-gradient(0deg, ${sentimentColor} 0, ${colors.neutrals.black} 100%)`;
  }

  return `linear-gradient(0deg, ${sentimentColor} 0, ${colors.neutrals.black} 100%)`;
}

function getGradientColors(sentiment: T.Sentiment) {
  switch (sentiment) {
    case T.Sentiment.Positive:
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.VeryPositive:
      return [colors.neutrals.black, '#06110B', '#14472B', colors.primary.green];
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.SlightlyNegative:
      return [colors.neutrals.black, '#11060F', '#5B1729', colors.primary.pink];
    case T.Sentiment.Neutral:
    default:
      return [colors.neutrals.black, '#111521', '#0C3A41', colors.primary.aqua];
  }
}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'story',
})<{ story: T.Story }>(({ story }) => ({
  position: 'relative',
  width: 454,
  height: 656,
  borderRadius: 24,
  padding: 3,
  backgroundImage: getStoryOuterGradient(story),
}));

const BodyBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'story',
})<{ story: T.Story }>(({ theme, story }) => ({
  position: 'relative',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: theme.spacing(1),
  borderRadius: 'inherit',
  backgroundColor: theme.palette.background.default,
  backgroundImage: (() => {
    if (isAiExplainedOrAiInspiredStory(story.storyType)) {
      return `linear-gradient(10deg, ${colors.neutrals.black} 70%, #111521 80%, #0C3A41 90%, ${colors.primary.aqua} 100%)`;
    }

    if (isWhisperStory(story.storyType)) {
      return `linear-gradient(to top right, ${colors.neutrals.black} 70%, #111521 80%, #0C3A41 90%, ${colors.primary.aqua} 100%)`;
    }

    const sentimentGradientColors = getGradientColors(story.sentiment ?? T.Sentiment.Neutral);
    return `linear-gradient(10deg, ${sentimentGradientColors[0]} 70%, ${sentimentGradientColors[1]} 80%, ${sentimentGradientColors[2]} 90%, ${sentimentGradientColors[3]} 100%)`;
  })(),
}));

const renderThumb = ({ style, ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  const thumbStyle: React.CSSProperties = {
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 'inherit',
  };

  return <div style={{ ...style, ...thumbStyle }} {...rest} />;
};

const ContentBox = styled(
  ({ children, ...rest }: BoxProps) => (
    <Scrollbars style={{ width: '100%', height: '100%' }} renderThumbVertical={renderThumb}>
      <Box {...rest}>{children}</Box>
    </Scrollbars>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'story' && prop !== 'hasEmbeddedContent',
  },
)<{ story: T.Story; hasEmbeddedContent?: boolean }>(({ theme, story, hasEmbeddedContent }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isAiExplainedOrAiInspiredStory(story.storyType) ? 'flex-start' : 'initial',
  justifyContent: isAiExplainedOrAiInspiredStory(story.storyType)
    ? 'flex-end'
    : story.content?.every(T.isMarkdownComponent)
      ? 'center'
      : 'initial',
  minHeight: '100%',
  height: hasEmbeddedContent ? '100%' : 'auto',
  padding: `calc(${50 / baseFontSize}em - ${theme.spacing(1)})`,
  paddingBottom:
    story.storyType === T.StoryDisplayType.AiXplained ||
      !story.content?.every(T.isMarkdownComponent)
      ? `${50 / baseFontSize}em`
      : story.storyType === T.StoryDisplayType.AiInspired
        ? `${150 / baseFontSize}em`
        : `${80 / baseFontSize}em`,
  textAlign:
    isAiExplainedOrAiInspiredStory(story.storyType) || !story.content?.every(T.isMarkdownComponent)
      ? 'left'
      : isWhisperStory(story.storyType)
        ? 'start'
        : 'center',
  '& .StatusIcon': {
    alignSelf: 'center',
    fontSize: `${80 / baseFontSize}em`,
  },
  '& > h1, h2, h3, h4, h5, & > [class*="MuiTypography-h"]': {
    fontSize:
      story.storyType === T.StoryDisplayType.PlailistStory ? `${40 / baseFontSize}em` : undefined,
    fontStyle: story.storyType === T.StoryDisplayType.PlailistStory ? 'normal' : undefined,
    fontWeight: story.storyType === T.StoryDisplayType.PlailistStory ? 700 : undefined,
    lineHeight: story.storyType === T.StoryDisplayType.PlailistStory ? `${48 / 32}` : undefined,
  },
  '& > h6 > [class*="MuiTypography-h"]': {
    fontWeight: story.storyType === T.StoryDisplayType.AiInspired ? 300 : 700,
  },
  '& p': {
    alignSelf: 'center',
    fontFamily: 'SF-Pro-Display',
    fontWeight: 600,
    fontSize: isAiExplainedOrAiInspiredStory(story.storyType)
      ? `${17 / baseFontSize}em`
      : `${24 / baseFontSize}em`,
    fontStyle: 'normal',
    lineHeight: isAiExplainedOrAiInspiredStory(story.storyType) ? `${27 / 17}` : `${28.8 / 24}`,

    ...(isWhisperStory(story.storyType) && {
      // bodyLarge
      fontSize: `${20 / baseFontSize}em`,
      lineHeight: 28 / 20,
      fontWeight: 300,
      alignSelf: 'start',
    }),
  },
  '& .Variance': {
    alignSelf: 'center',
    fontFamily: 'SF-Pro-Display',
    fontWeight: 700,
    fontSize: `${48 / baseFontSize}em`,
    lineHeight: 1,
  },
  '& .MuiTypography-h2': {
    fontSize: `${48 / baseFontSize}em`,
    lineHeight: `${56 / 48}`,
  },
  '& .MuiTypography-h4': {
    fontSize: `${36 / baseFontSize}em`,
    lineHeight: 1,
  },
  '& .MuiTypography-h6': {
    fontSize: `${32 / baseFontSize}em`,
    lineHeight: `${38 / 32}`,
  },
}));

const UpperBar = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'storyType',
})<{ storyType: T.StoryDisplayType }>(({ storyType }) => ({
  position: 'absolute',
  bottom: '100%',
  display: 'flex',
  alignItems: 'center',
  opacity: 0,
  width: '100%',
  height: 60,
  transition: 'opacity 0.5s',
  transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  willChange: 'opacity',

  ...(isWhisperStory(storyType) && {
    display: 'none',
  }),
}));

export const LowerBar = styled(Box)({
  position: 'absolute',
  top: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  width: '100%',
  height: 100,
  transition: 'opacity 0.5s',
  transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  willChange: 'opacity',
});

export const ActionButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle,
  height: 35,
  borderRadius: 35,
  padding: '0 24px',
  transition: 'opacity 0.15s',
  '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
    opacity: 0.7,
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    color: 'inherit',
  },
}));

const StyledStamp = styled(Stamp)({
  position: 'absolute',
  bottom: 30,
  left: '50%',
  transform: 'translateX(-50%)',
});

const StoryView: React.FC<StoryViewProps> = ({
  story,
  className,
  loading,
  distanceFromCurrentItem = 0,
  onRead,
  onSeeMoreButtonClick,
  onSeeMoreButtonHoverChange,
  onResetButtonClick,
  fullWidth,
}) => {
  const [key, setKey] = useState(0);
  const handleLink = useHandleLink();
  const [markStoryAsRead] = useMarkStoryAsReadMutation();
  const { API_URL } = useEnvironment();
  const { redirect, isSeeMoreButtonAvailable } = useStoriesAdapter();
  const isSeeMoreButton =
    isSeeMoreButtonAvailable === undefined
      ? (story: T.Story) => !!story.link
      : isSeeMoreButtonAvailable;

  useEffect(() => {
    if (!loading && distanceFromCurrentItem === 0 && !story.isRead) {
      markStoryAsRead({ variables: { input: { storyId: story.id } } });
      onRead?.(story);
    }
  }, [story, loading, distanceFromCurrentItem, onRead, markStoryAsRead]);

  const handleSeeMoreButtonClick = useCallback(
    (story: T.Story) => () => {
      onSeeMoreButtonClick?.(story);

      if (story.link && !redirect) {
        handleLink(story.link);
      }

      if (story.legacyLink && redirect) {
        redirect(story.legacyLink);
      }
    },
    [handleLink, onSeeMoreButtonClick],
  );

  const handleResetButtonClick = useCallback(
    (story: T.Story) => () => {
      setKey((prevState) => prevState + 1);
      onResetButtonClick?.(story);
    },
    [onResetButtonClick],
  );

  const handlePageContentComponentRender = useCallback<
    NonNullable<PageContentProps['onComponentRender']>
  >((component, componentIndex, components) => {
    const prevComponent = components[componentIndex - 1];

    if (T.isChartComponent(component) && !T.isChartComponent(prevComponent)) {
      return (
        <GroupedChartDataView
          key={componentIndex}
          component={component}
          componentIndex={componentIndex}
          components={components}
          ChartDataViewProps={{
            defaultChartOptions: { chart: { height: 320 } },
            delay: 500,
          }}
          IconTabsProps={{ sx: { mt: T.isHeaderRowComponent(prevComponent) ? -6 : 0 } }}
        />
      );
    }

    if (T.isChartComponent(component)) {
      return null;
    }

    return undefined;
  }, []);

  const hoverSeeMoreButton = useCallback(
    (isHovered: boolean) => () => {
      onSeeMoreButtonHoverChange?.(isHovered);
    },
    [onSeeMoreButtonHoverChange],
  );

  const hasEmbeddedContent = useMemo(
    () =>
      !!story.content?.find(
        (component) =>
          T.isTableComponent(component) ||
          T.isChartComponent(component) ||
          T.isHeaderRow(component),
      ),
    [story.content],
  );

  const showAddToBrain = useMemo(
    () =>
      !hasEmbeddedContent &&
      !!story.content?.some(
        (component) =>
          T.isTableComponent(component) ||
          T.isChartComponent(component) ||
          T.isHeaderRow(component) ||
          T.isMarkdownComponent(component),
      ),
    [story.content, hasEmbeddedContent],
  );

  const isChartOrTableComponent = useMemo(
    () => story.content?.some(T.isChartComponent) || story.content?.some(T.isTableComponent),
    [story.content],
  );

  const contentHeader = useMemo(
    () =>
      hasEmbeddedContent && (
        <Box
          data-testid="EmbeddedStoryHeader"
          sx={{
            borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.25)}`,
            mt: -3,
            mb: 2,
            pb: 2,
          }}
        >
          <Typography
            variant="h7"
            color="text.primary"
            sx={{ ...lineClamp(2) }}
            data-testid="headline"
          >
            {story.recommenderHeadline}
          </Typography>
        </Box>
      ),
    [hasEmbeddedContent, story],
  );

  const onComponentRender = useCallback(
    (component: T.Component, componentIndex: number, components: T.Component[]) => {
      const prevComponent = components[componentIndex - 1];

      if (T.isMarkdownComponent(component) && componentIndex === 0 && contentHeader) {
        return <React.Fragment key={componentIndex}>{contentHeader}</React.Fragment>;
      }

      if (T.isMarkdownComponent(component)) {
        if (story.storyType === T.StoryDisplayType.AiXplained) {
          return (
            <div key={componentIndex} style={{ flexGrow: 2 }}>
              <MarkdownRenderer>
                {(component.content ?? '').replace(/(\r\n|\r|\n)/g, '\n\n')}
              </MarkdownRenderer>
            </div>
          );
        }

        const markdownContent = (component.content ?? '').replace(/(\r\n|\r|\n)/g, '\n\n');
        const isLinkStory = markdownContent?.includes('https://');
        const linkStoryStyles = {
          h4: {
            component: Typography,
            props: { variant: 'h4', component: 'div', style: { textAlign: 'left' } },
          },
          p: {
            component: Typography,
            props: { variant: 'h7', component: 'p', style: { textAlign: 'left', fontWeight: 400 } },
          },
        };

        return (
          <MarkdownRenderer
            key={componentIndex}
            options={isLinkStory ? { overrides: linkStoryStyles } : undefined}
          >
            {markdownContent}
          </MarkdownRenderer>
        );
      }

      if (T.isTableComponent(component)) {
        return (
          <React.Fragment key={componentIndex}>
            {componentIndex === 0 && contentHeader}
            <Box sx={{ '& + &': { mt: 2 }, minHeight: 0 }} data-testid="EmbeddedStoryBody">
              {distanceFromCurrentItem === 0 ? (
                <TableDataViewWithPageContent
                  TableDataViewProps={{ component, local: true }}
                  onComponentRender={handlePageContentComponentRender}
                />
              ) : (
                <LoadingSkeleton
                  SkeletonProps={{
                    animation: false,
                    sx: { backgroundColor: (theme) => theme.palette.grey[100] },
                  }}
                />
              )}
            </Box>
          </React.Fragment>
        );
      }

      if (T.isChartComponent(component) && !T.isChartComponent(prevComponent)) {
        return (
          <React.Fragment key={componentIndex}>
            {componentIndex === 0 && contentHeader}
            <Box data-testid="EmbeddedStoryBody">
              {distanceFromCurrentItem === 0 ? (
                <GroupedChartDataView
                  key={componentIndex}
                  component={component}
                  componentIndex={componentIndex}
                  components={components}
                  ChartDataViewProps={{
                    defaultChartOptions: { chart: { height: 300 } },
                    delay: 500,
                  }}
                />
              ) : (
                <LoadingSkeleton
                  SkeletonProps={{
                    animation: false,
                    sx: { backgroundColor: (theme) => theme.palette.grey[100] },
                  }}
                />
              )}
            </Box>
          </React.Fragment>
        );
      }

      if (T.isHeaderRowComponent(component)) {
        return (
          <React.Fragment key={componentIndex}>
            {componentIndex === 0 && contentHeader}
            <HeaderRowComponent key={component.id} component={component} />
          </React.Fragment>
        );
      }

      if (
        T.isVideoComponent(component) &&
        T.isVideoContent(component.videoContent) &&
        component.videoContent?.sourceUrl &&
        mime.getType(component.videoContent.sourceUrl)?.startsWith('video/')
      ) {
        const src = getFullUrl(component.videoContent.sourceUrl, API_URL);
        const disabled = distanceFromCurrentItem !== 0;

        return (
          <Box
            key={componentIndex}
            sx={{
              '&&': {
                width: (theme) => `calc(100% + ${theme.spacing(11)})`,
                ml: -5.5,
                mt: fullWidth ? -8 : undefined,
              },
            }}
          >
            <VideoPlayer
              src={src}
              autoPlay
              canvasBackdrop
              controls={!disabled}
              disabled={disabled}
              preload="auto"
              ContainerProps={{ style: { position: fullWidth ? 'absolute' : undefined } }}
            />
          </Box>
        );
      }

      return null;
    },
    [story, distanceFromCurrentItem, contentHeader, fullWidth, handlePageContentComponentRender],
  );

  if (loading) {
    return (
      <StyledBox key={key} story={story} className={className}>
        <BodyBox story={story}>
          <ContentBox story={story} hasEmbeddedContent={hasEmbeddedContent}>
            {loading ? (
              <LoadingSkeleton
                SkeletonProps={{
                  animation: false,
                  sx: { backgroundColor: (theme) => theme.palette.grey[100] },
                }}
              />
            ) : (
              <LoadingSkeleton />
            )}
          </ContentBox>
        </BodyBox>
      </StyledBox>
    );
  }

  if (story.storyType === T.StoryDisplayType.Media) {
    return (
      <Box key={key} sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <UpperBar
          storyType={story.storyType}
          sx={{ opacity: distanceFromCurrentItem === 0 ? 1 : 0 }}
        >
          <Typography variant="h9">{story.caption ?? story.tag}</Typography>
        </UpperBar>
        <MediaStory>{((story.content ?? []) as T.Component[]).map(onComponentRender)}</MediaStory>
        <LowerBar sx={{ opacity: distanceFromCurrentItem === 0 ? 1 : 0 }}>
          {isSeeMoreButton(story) && (
            <ActionButton
              variant="secondary"
              data-testid="SeeMoreButton"
              onClick={handleSeeMoreButtonClick(story)}
              onMouseOver={hoverSeeMoreButton(true)}
              onMouseOut={hoverSeeMoreButton(false)}
              endIcon={
                <ChevronRightIcon
                  width={12}
                  height={12}
                  color={config.tokens.colors.colorNeutralWhite}
                />
              }
            >
              See More
            </ActionButton>
          )}
        </LowerBar>
      </Box>
    );
  }

  return (
    <StyledBox key={key} story={story} className={className}>
      <UpperBar
        storyType={story.storyType}
        sx={{ opacity: distanceFromCurrentItem === 0 ? 1 : 0 }}
        data-testid="UpperBar"
      >
        <Typography variant="h9" data-testid="Caption" sx={{ ...lineClamp(2) }}>
          {story.caption ?? story.tag}
        </Typography>
      </UpperBar>
      <BodyBox story={story}>
        <ContentBox story={story} hasEmbeddedContent={hasEmbeddedContent}>
          {story.storyType === T.StoryDisplayType.AiXplained && (
            <div style={{ flexGrow: 2, width: `${170 / baseFontSize}em` }}>
              <AiXplained style={{ width: '100%' }} />
            </div>
          )}

          {story.storyType === T.StoryDisplayType.AiInspired && (
            <div style={{ marginBottom: 'auto' }}>
              <AiInspired style={{ width: '100%' }} />
            </div>
          )}

          {isWhisperStory(story.storyType) ? (
            <Stack spacing={1.5}>
              <div style={{ flexGrow: 1 }}>
                <Typography variant="h9" data-testid="Caption">
                  {story.caption ?? story.tag}
                </Typography>
              </div>
              {((story.content ?? []) as T.Component[]).map(onComponentRender)}
            </Stack>
          ) : (
            ((story.content ?? []) as T.Component[]).map(onComponentRender)
          )}

          {(story.storyType === T.StoryDisplayType.AiDriveFocus ||
            isWhisperStory(story.storyType)) &&
            !!story.stamp && (
              <StyledStamp
                icon={<AiSmall />}
                label={story.stamp}
                color="warning"
                data-testid="Stamp"
              />
            )}
          {isSeeMoreButton(story) && (
            <ActionButton
              variant="tertiary"
              data-testid="SeeMoreButton"
              onClick={handleSeeMoreButtonClick(story)}
              onMouseOver={hoverSeeMoreButton(true)}
              onMouseOut={hoverSeeMoreButton(false)}
              style={{ marginBottom: isChartOrTableComponent ? -30 : 0 }}
              endIcon={
                <ChevronRightIcon
                  width={12}
                  height={12}
                  color={config.tokens.colors.colorNeutralWhite}
                />
              }
            >
              See More
            </ActionButton>
          )}
        </ContentBox>
      </BodyBox>
      <LowerBar sx={{ opacity: distanceFromCurrentItem === 0 ? 1 : 0 }} data-testid="LowerBar">
        <Stack direction="row" spacing={2}>
          {isChartOrTableComponent && (
            <ActionButton
              variant="secondary"
              startIcon={<Refresh />}
              data-testid="ResetButton"
              onClick={handleResetButtonClick(story)}
            >
              Reset
            </ActionButton>
          )}
          {showAddToBrain && <AddBrainButton story={story} />}
          {showAddToBrain && (
            <ActionButton
              variant="secondary"
              startIcon={<ShareIcon />}
              data-testid="ShareButton"
              disabled
            >
              Share
            </ActionButton>
          )}
        </Stack>
      </LowerBar>
    </StyledBox>
  );
};

export default StoryView;
