import { styled, Tab, Tabs as MuiTabs, TabsProps } from '@mui/material';
import { motion } from 'framer-motion';
import React, { PropsWithChildren, useCallback, useState } from 'react';

const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginBottom: 0,
  boxShadow: 'none',
  pointerEvents: 'none',
  '& .MuiTab-root': {
    pointerEvents: 'all',
    marginRight: theme.spacing(3),
  },
  '& .MuiTab-root:last-child': {
    marginRight: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'flex-end',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

export interface IconTabsProps extends TabsProps {
  icons: React.ReactElement[];
  defaultValue?: number;
}

export const IconTabs: React.FC<PropsWithChildren<IconTabsProps>> = ({
  icons,
  defaultValue = 0,
  children,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  return (
    <>
      <Tabs value={value} onChange={handleChange} {...rest}>
        {icons?.map((icon, index) => (
          <Tab key={index} value={index} icon={icon} data-testid="TabButton" />
        ))}
      </Tabs>
      {React.Children.map(children, (child, index) => (
        <motion.div
          key={index}
          initial="hidden"
          variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
          transition={{ duration: 0.15 }}
          animate={value === index ? 'visible' : undefined}
          role="tabpanel"
          hidden={value !== index}
        >
          {value === index && child}
        </motion.div>
      ))}
    </>
  );
};
