import { IconButton, styled } from '@mui/material';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
  '&[disabled]': {
    color: theme.palette.text.secondary,
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

export default StyledIconButton;
