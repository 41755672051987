import { Box, BoxProps, Container, IconButton, Stack, styled, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useWindowScroll } from 'react-use';

interface Props extends BoxProps {
  title: string;
  sticky?: boolean;
  onClose?: () => void;
  closeButton?: React.ReactNode;
}

const CloseIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor">
    <path d="M15.4166 14L22.5943 5.44414C22.7147 5.30195 22.6135 5.08594 22.4275 5.08594H20.2455C20.117 5.08594 19.994 5.14336 19.9092 5.2418L13.9893 12.2992L8.06936 5.2418C7.98733 5.14336 7.86428 5.08594 7.73303 5.08594H5.551C5.36506 5.08594 5.26389 5.30195 5.3842 5.44414L12.5619 14L5.3842 22.5559C5.35725 22.5876 5.33996 22.6263 5.33439 22.6675C5.32881 22.7088 5.33518 22.7507 5.35274 22.7884C5.3703 22.8262 5.39832 22.858 5.43346 22.8803C5.4686 22.9026 5.5094 22.9143 5.551 22.9141H7.73303C7.86155 22.9141 7.98459 22.8566 8.06936 22.7582L13.9893 15.7008L19.9092 22.7582C19.9912 22.8566 20.1143 22.9141 20.2455 22.9141H22.4275C22.6135 22.9141 22.7147 22.698 22.5943 22.5559L15.4166 14Z" />
  </svg>
);

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sticky',
})<Pick<Props, 'sticky'>>(({ theme, sticky }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex['appBar'] + 1,
  boxShadow: sticky ? theme.shadows[5] : undefined,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(7.5, 0, 5),
  marginBottom: theme.spacing(5),
  transition: theme.transitions.create('box-shadow', {
    duration: theme.transitions.duration.shortest,
    easing: 'linear',
  }),
}));

const StickyPageHeader: React.FC<PropsWithChildren<Props>> = ({
  title,
  sticky,
  onClose,
  closeButton,
  children,
}) => {
  const { y } = useWindowScroll();
  return (
    <StyledBox sticky={sticky && y > 0}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center">
          <Typography variant="h4" color="text.primary" sx={{ flex: 1 }}>
            {title}
          </Typography>
          {closeButton
            ? closeButton
            : !!onClose && (
                <IconButton
                  disableRipple
                  sx={{ m: -1, color: 'text.secondary', '&:hover': { color: 'text.primary' } }}
                  onClick={onClose}
                  data-testid="CloseButton"
                >
                  <CloseIcon />
                </IconButton>
              )}
        </Stack>
        {children}
      </Container>
    </StyledBox>
  );
};

export default StickyPageHeader;
