import { Environment } from '@aily/saas-core';

// We augment the global Window interface to inform TypeScript about the `_env_` property
declare global {
  interface Window {
    _env_?: Partial<Environment>;
  }
}

// Use .env values as defaults (build-time config)
const buildTimeConfig: Environment = {
  SITE_NAME: import.meta.env.REACT_APP_SITE_NAME || '',
  SITE_DESCRIPTION: import.meta.env.REACT_APP_SITE_DESCRIPTION || '',
  BUILD_VERSION: import.meta.env.REACT_APP_BUILD_VERSION || '',

  TENANT: import.meta.env.REACT_APP_TENANT || '',
  ENV: import.meta.env.REACT_APP_ENV || '',

  OIDC_AUTH_URL: import.meta.env.REACT_APP_OIDC_AUTH_URL || '',
  OIDC_CLIENT_ID: import.meta.env.REACT_APP_OIDC_CLIENT_ID || '',
  OIDC_REDIRECT_URL: import.meta.env.REACT_APP_OIDC_REDIRECT_URL || '',
  OIDC_SCOPE: import.meta.env.REACT_APP_OIDC_SCOPE || '',
  OIDC_DEBUG: import.meta.env.REACT_APP_OIDC_DEBUG || '',

  API_URL: import.meta.env.REACT_APP_API_URL || '',
  API_KEY: import.meta.env.REACT_APP_API_KEY || '',

  ANALYTICS_WRITE_KEY: import.meta.env.REACT_APP_ANALYTICS_WRITE_KEY || '',
  ANALYTICS_CDN_URL: import.meta.env.REACT_APP_ANALYTICS_CDN_URL || '',

  SENTRY_DSN: import.meta.env.REACT_APP_SENTRY_DSN || '',
  SENTRY_RELEASE: import.meta.env.REACT_APP_SENTRY_RELEASE || '',
  SENTRY_ENVIRONMENT: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT || '',

  USER_DISCLAIMER_TITLE: import.meta.env.REACT_APP_USER_DISCLAIMER_TITLE || '',
  USER_DISCLAIMER_MESSAGE: import.meta.env.REACT_APP_USER_DISCLAIMER_MESSAGE || '',
  USER_DISCLAIMER_LOCAL_STORAGE: import.meta.env.REACT_APP_USER_DISCLAIMER_LOCAL_STORAGE || '',

  FEATURE_FLAGS_API_URL: import.meta.env.REACT_APP_FEATURE_FLAGS_API_URL || '',
  FEATURE_FLAGS_API_KEY: import.meta.env.REACT_APP_FEATURE_FLAGS_TENANT_KEY || '',
};

// Get runtime config values from window object, if they exist
const runtimeConfig: Partial<Environment> = window._env_ || {};

// Merge build-time and runtime configurations
const mergedConfig: Environment = { ...buildTimeConfig, ...runtimeConfig };

export default mergedConfig;
