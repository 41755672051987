import * as T from '@aily/graphql-sdk/schema';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface StoryContextState {
  story: T.Story;
  onStoryReferenceClick?: (story: T.Story, reference: T.ExternalLink) => void;
}

const StoryContext = createContext<StoryContextState | null>(null);

export const StoryContextProvider: React.FC<PropsWithChildren<StoryContextState>> = ({
  children,
  story,
  onStoryReferenceClick,
}) => {
  const value = useMemo<StoryContextState>(
    () => ({ story, onStoryReferenceClick }),
    [story, onStoryReferenceClick],
  );

  return <StoryContext.Provider value={value}>{children}</StoryContext.Provider>;
};

export function useStoryContext() {
  return useContext(StoryContext);
}

export default StoryContext;
