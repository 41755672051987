import * as T from '@aily/graphql-sdk/schema';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import { colors } from '../../theme/default/colors';
import { mapSentimentToColor } from '../../utils';
import { RiskIndicatorIcon } from '../RiskIndicator/RiskIndicatorIcon';

export interface TableCellComparatorTrendProps {
  value: T.ComparatorTrendResult;
}

export const TableCellComparatorTrend: FC<TableCellComparatorTrendProps> = ({ value }) => {
  const { leftValue, rightValue, trendArrow } = value;

  const renderRiskIndicatorIcon = () => {
    if (!trendArrow) return null;

    return <RiskIndicatorIcon sentiment={trendArrow.sentiment} />;
  };

  if (rightValue) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing="5px">
        <Stack direction="row" alignItems="center">
          <Typography
            variant="h9"
            color={mapSentimentToColor(leftValue?.sentiment ?? T.Sentiment.Neutral)}
          >
            {leftValue?.value}
          </Typography>

          <Stack direction="row" alignItems="center" spacing="5px" marginTop="4px" marginLeft="5px">
            <Typography variant="small" color={colors.neutrals.grey4}>
              /
            </Typography>
            <Typography variant="small" color={colors.neutrals.grey4}>{`${rightValue}`}</Typography>
          </Stack>
        </Stack>
        {renderRiskIndicatorIcon()}
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
      <Typography variant="body">{leftValue?.value}</Typography>
      {renderRiskIndicatorIcon()}
    </Stack>
  );
};

export default TableCellComparatorTrend;
