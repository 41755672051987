import * as T from '@aily/graphql-sdk/schema';

import { findDeep } from './traversal';

export function findPlailistFilterOption(
  options: readonly T.PlailistFilterOption[],
  predicate: (option: T.PlailistFilterOption) => boolean,
) {
  return findDeep<T.PlailistFilterOption>(options, predicate)?.value;
}
