import { Grid2 as Grid, Grid2Props as GridProps } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import PlailistListItem, { Props as ListItemProps } from './PlailistListItem';

interface Props extends GridProps {
  children: React.ReactElement<ListItemProps> | React.ReactElement<ListItemProps>[];
  GridItemProps?: Omit<GridProps, 'container' | 'item'>;
}

const PlailistList: React.FC<Props> & { Item: typeof PlailistListItem } = ({
  children,
  ...rest
}) => (
  <Grid container spacing={2} {...rest}>
    {React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return (
          <Grid
            key={index}
            size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 12 / 5 }}
            component={motion.div}
            custom={index}
            animate="visible"
            initial={{ opacity: 0 }}
            variants={{
              visible: (index: number) => ({
                opacity: 1,
                transition: { delay: index * 0.02, duration: 0.1 },
              }),
            }}
          >
            {child}
          </Grid>
        );
      }

      return child;
    })}
  </Grid>
);

PlailistList.Item = PlailistListItem;

export default PlailistList;
