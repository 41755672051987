import * as T from '@aily/graphql-sdk/schema';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

import { BarChart, LineChart, WaterfallChartIcon } from '../../icons';

export interface ComponentIconProps extends SvgIconProps {
  componentIcon: T.ComponentIcon;
}

// prettier-ignore
export const componentMap: Record<T.ComponentIcon, React.ComponentType<SvgIconProps> | null> = {
  [T.ComponentIcon.BarChart]:       BarChart,
  [T.ComponentIcon.LineChart]:      LineChart,
  [T.ComponentIcon.None]:           null,
  [T.ComponentIcon.WaterfallChart]: WaterfallChartIcon,
};

export const ComponentIcon: React.FC<ComponentIconProps> = ({ componentIcon, ...rest }) => {
  const Component = componentMap[componentIcon];
  return Component ? <Component {...rest} /> : null;
};
