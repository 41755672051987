import * as T from '@aily/graphql-sdk/schema';
import { ChipProps } from '@mui/material/Chip/Chip';

import { NeonDividerProps } from '../components';
import { colors } from '../theme/default/colors';
import { mapEnumToValue } from './mapping';

type RecommendationSentimentType = 'POSITIVE' | 'NEGATIVE';

export function mapColorEnumToColor(
  color: T.Color,
  customMap?: Partial<Record<T.Color, string>>,
): string {
  // prettier-ignore
  const defaultMap: Record<T.Color, string> = {
    [T.Color.AilyBlue]: colors.secondary.ailyBlue,
    [T.Color.Aqua]:     colors.primary.aqua,
    [T.Color.Black]:    colors.neutrals.black,
    [T.Color.Blue]:     colors.secondary.blue,
    [T.Color.DarkBlue]: colors.secondary.darkBlue,
    [T.Color.Default]:  colors.neutrals.white,
    [T.Color.Failure]:  colors.primary.pink,
    [T.Color.Green]:    colors.primary.green,
    [T.Color.Grey_1]:   colors.neutrals.grey1,
    [T.Color.Grey_2]:   colors.neutrals.grey2,
    [T.Color.Grey_3]:   colors.neutrals.grey3,
    [T.Color.Orange]:   colors.primary.orange,
    [T.Color.Pink]:     colors.primary.pink,
    [T.Color.Success]:  colors.primary.green,
    [T.Color.Warning]:  colors.primary.orange,
    [T.Color.White]:    colors.neutrals.white,
  };

  return mapEnumToValue(color, defaultMap, customMap);
}

export function mapColorEnumToStatus(
  color: T.Color,
  customMap?: Partial<Record<T.Color, 'error' | 'success' | 'warning' | 'info'>>,
): string {
  // prettier-ignore
  const defaultMap: Record<T.Color, 'error' | 'success' | 'warning' | 'info'> = {
    [T.Color.AilyBlue]: 'info',
    [T.Color.Aqua]:     'info',
    [T.Color.Black]:    'info',
    [T.Color.Blue]:     'info',
    [T.Color.DarkBlue]: 'info',
    [T.Color.Default]:  'info',
    [T.Color.Failure]:  'error',
    [T.Color.Green]:    'success',
    [T.Color.Grey_1]:   'info',
    [T.Color.Grey_2]:   'info',
    [T.Color.Grey_3]:   'info',
    [T.Color.Orange]:   'warning',
    [T.Color.Pink]:     'error',
    [T.Color.Success]:  'success',
    [T.Color.Warning]:  'warning',
    [T.Color.White]:    'info',
  };

  return mapEnumToValue(color, defaultMap, customMap);
}

export function mapColorUnionToColor(
  colorUnion: T.ColorUnion,
  defaultColor: string = colors.primary.aqua,
): string {
  if (T.isColorString(colorUnion)) {
    return colorUnion.color ?? defaultColor;
  }

  if (T.isThemeColor(colorUnion)) {
    return colorUnion.color ? mapColorEnumToColor(colorUnion.color) : defaultColor;
  }

  return defaultColor;
}

export function mapSentimentToColor(
  sentiment: T.Sentiment,
  customMap?: Partial<Record<T.Sentiment, string>>,
): string {
  // prettier-ignore
  const defaultMap: Record<T.Sentiment, string> = {
    [T.Sentiment.SlightlyPositive]: colors.primary.green,
    [T.Sentiment.Positive]:         colors.primary.green,
    [T.Sentiment.VeryPositive]:     colors.primary.green,
    [T.Sentiment.Neutral]:          colors.neutrals.white,
    [T.Sentiment.SlightlyNegative]: colors.primary.orange,
    [T.Sentiment.VeryNegative]:     colors.primary.pink,
    [T.Sentiment.Negative]:         colors.primary.pink,
    [T.Sentiment.Critical]:         colors.primary.pink,
    [T.Sentiment.Warning]:          colors.primary.orange,
  };

  return mapEnumToValue(sentiment, defaultMap, customMap);
}

export function mapSentimentToChipColor(
  sentiment: T.Sentiment,
  customMap?: Partial<Record<T.Sentiment, ChipProps['color']>>,
): ChipProps['color'] {
  // prettier-ignore
  const defaultMap: Record<T.Sentiment, ChipProps['color']> = {
    [T.Sentiment.SlightlyPositive]: 'success',
    [T.Sentiment.Positive]:         'success',
    [T.Sentiment.VeryPositive]:     'success',
    [T.Sentiment.Neutral]:          'default',
    [T.Sentiment.SlightlyNegative]: 'warning',
    [T.Sentiment.VeryNegative]:     'error',
    [T.Sentiment.Negative]:         'error',
    [T.Sentiment.Critical]:         'error',
    [T.Sentiment.Warning]:         'warning',
  };

  return mapEnumToValue(sentiment, defaultMap, customMap);
}

export function mapSentimentToGradientColors(
  sentiment: T.Sentiment,
  customMap?: Partial<Record<T.Sentiment, [string, string]>>,
): [string, string] {
  // prettier-ignore
  const defaultMap: Record<T.Sentiment, [string, string]> = {
    [T.Sentiment.SlightlyPositive]: colors.gradients.green,
    [T.Sentiment.Positive]:         colors.gradients.green,
    [T.Sentiment.VeryPositive]:     colors.gradients.green,
    [T.Sentiment.Neutral]:          colors.gradients.aqua,
    [T.Sentiment.SlightlyNegative]: colors.gradients.orange,
    [T.Sentiment.VeryNegative]:     colors.gradients.pink,
    [T.Sentiment.Negative]:         colors.gradients.pink,
    [T.Sentiment.Critical]:         colors.gradients.pink,
    [T.Sentiment.Warning]:          colors.gradients.orange,
  };

  return mapEnumToValue(sentiment, defaultMap, customMap);
}

export function mapSentimentToTextGradient(
  sentiment: T.Sentiment,
  customMap?: Partial<Record<T.Sentiment, [string, string]>>,
): string {
  const gradientColors = mapSentimentToGradientColors(sentiment, customMap);

  return `linear-gradient(to left, ${gradientColors.join(', ')})`;
}

export function mapSentimentToRecommendationColor(
  sentiment: T.Sentiment,
  customMap?: Partial<Record<T.Sentiment, RecommendationSentimentType>>,
): RecommendationSentimentType {
  const defaultMap: Record<T.Sentiment, RecommendationSentimentType> = {
    [T.Sentiment.SlightlyPositive]: 'POSITIVE',
    [T.Sentiment.Positive]: 'POSITIVE',
    [T.Sentiment.VeryPositive]: 'POSITIVE',
    [T.Sentiment.Neutral]: 'POSITIVE',
    [T.Sentiment.SlightlyNegative]: 'NEGATIVE',
    [T.Sentiment.VeryNegative]: 'NEGATIVE',
    [T.Sentiment.Negative]: 'NEGATIVE',
    [T.Sentiment.Critical]: 'NEGATIVE',
    [T.Sentiment.Warning]: 'NEGATIVE',
  };

  return mapEnumToValue(sentiment, defaultMap, customMap);
}

export function mapSentimentToNeonDividerColor(
  sentiment: T.Sentiment,
  customMap?: Partial<Record<T.Sentiment, NeonDividerProps['color']>>,
): NeonDividerProps['color'] {
  // prettier-ignore
  const defaultMap: Record<T.Sentiment, NeonDividerProps['color']> = {
    [T.Sentiment.SlightlyPositive]: 'success',
    [T.Sentiment.Positive]:         'success',
    [T.Sentiment.VeryPositive]:     'success',
    [T.Sentiment.Neutral]:          'info',
    [T.Sentiment.SlightlyNegative]: 'warning',
    [T.Sentiment.VeryNegative]:     'error',
    [T.Sentiment.Negative]:         'error',
    [T.Sentiment.Critical]:         'error',
    [T.Sentiment.Warning]:         'warning',
  };

  return mapEnumToValue(sentiment, defaultMap, customMap);
}
