import { useMemo } from 'react';

/**
 * Custom hook to deserialize a string into a number.
 *
 * @param {string | null | undefined} param - The input parameter to be deserialized.
 * @returns {number | null} - Returns the deserialized number or null if the input is not a valid number.
 */
export function useSerializedNumber(param?: string | null): number | null {
  return useMemo(() => {
    if (!param || param.trim() === '') {
      return null;
    }

    const number = Number(param);
    return isNaN(number) ? null : number;
  }, [param]);
}
