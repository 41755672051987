import * as T from '@aily/graphql-sdk/schema';
import { find } from 'lodash-es';
import { useCallback } from 'react';

import { SelectFilterDisplayMode } from '../SelectFilter';

export interface RenderFilterProps {
  selectedFilterOptions?: T.FilterValue[];
  onSelectFilterChange: (
    filterId: T.Filter['id'],
    filterCode?: string,
  ) => (filterOption: T.FilterOptionResult | T.TreeFilterOptionResult) => void;
  onFilterDisplayMode?: (filterId: T.Filter['id']) => T.DisplayMode;
}

export const useRenderFilter = ({
  selectedFilterOptions,
  onSelectFilterChange,
  onFilterDisplayMode,
}: RenderFilterProps) => {
  return {
    renderFilter: useCallback(
      (filter: T.Filter) => {
        if (T.isSelectFilter(filter) || T.isTreeSelectFilter(filter)) {
          const displayMode = onFilterDisplayMode?.(filter.id) ?? T.DisplayMode.Default;
          const filterOptions = (filter.options ?? []) as T.FilterOptionResult[];
          const value =
            (find(selectedFilterOptions, { id: filter.id }) as T.SelectFilterValue)?.value ?? -1;

          return (
            <SelectFilterDisplayMode
              key={filter.id}
              displayMode={displayMode}
              SelectFilterProps={{
                filterOptions,
                value,
                onChange: onSelectFilterChange(filter.id, filter.filterCode ?? undefined),
                filterCode: filter.filterCode ?? undefined,
                sx: { '&:last-child': { mr: -0.5 } },
              }}
              SelectFilterRadioGroupProps={{
                filterOptions,
                value: String(value),
                onChange: onSelectFilterChange(filter.id),
              }}
            />
          );
        }

        return null;
      },
      [selectedFilterOptions, onSelectFilterChange, onFilterDisplayMode],
    ),
  };
};
