import * as T from '@aily/graphql-sdk/schema';
import {
  styled,
  tableCellClasses,
  tableContainerClasses,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { scaleLinear } from 'd3';
import React, { useCallback, useMemo, useState } from 'react';

import { convertDateFormat, mapColorEnumToColor } from '../../utils';
import { TableColumnProps } from '../Table';
import { TimelineContextProvider, useTimelineContext } from './TimelineContext';
import { TimelineInterval, TimelineIntervalProps } from './TimelineInterval';
import { SymbolType, TimelineSymbol } from './TimelineSymbol';
import { TimelineTooltipContent } from './TimelineTooltipContent';
import { useTimelineGroupedLabels } from './useTimelineGroupedLabels';
import { translatePixelToValue } from './utils';

export function mapTimelineMarkerSymbolToSymbolType(symbol: T.TimelineMarkerSymbol): SymbolType {
  const map: Record<T.TimelineMarkerSymbol, SymbolType> = {
    [T.TimelineMarkerSymbol.Circle]: SymbolType.Circle,
    [T.TimelineMarkerSymbol.Rhomb]: SymbolType.Rhombus,
    [T.TimelineMarkerSymbol.Square]: SymbolType.Square,
    [T.TimelineMarkerSymbol.Triangle]: SymbolType.Triangle,
  };

  return map[symbol];
}

export function translateTimelineMarker(marker: T.TimelineMarker): TimelineMarkerProps {
  return {
    symbol: mapTimelineMarkerSymbolToSymbolType(marker.symbol),
    size: 16,
    stroke: marker?.color.color ? mapColorEnumToColor(marker.color.color) : undefined,
    fill: marker.isFilled,
  };
}

export function translateTimelineInterval(interval?: T.TimelineInterval | null) {
  return {
    x1: interval?.x1 ?? undefined,
    x2: interval?.x2 ?? undefined,
    stroke: interval?.color.color ? mapColorEnumToColor(interval.color.color) : undefined,
    strokeWidth: 3,
  } as TimelineIntervalProps;
}

export function translateTimelineDataPoint(dataPoint?: T.TimelineDataPoint | null) {
  return {
    x: dataPoint?.x,
    formattedX: dataPoint?.formattedX,
    label: dataPoint?.label,
    marker: dataPoint?.marker ? translateTimelineMarker(dataPoint.marker) : undefined,
    tooltip: dataPoint?.tooltip,
  } as TimelineDataPointProps;
}

export function translateTimelineSeries(series?: T.TimelineSeries | null) {
  return {
    id: series?.id,
    name: series?.name,
    data: series?.data?.map(translateTimelineDataPoint),
    marker: series?.marker ? translateTimelineMarker(series.marker) : undefined,
  } as TimelineSeriesProps;
}

export interface TimelineMarkerProps {
  symbol?: SymbolType;
  size?: number;
  strokeWidth?: number;
  stroke?: string;
  fill?: string | boolean;
}

export interface TimelineSeriesProps {
  id?: string;
  name?: string;
  marker?: TimelineMarkerProps;
  data?: TimelineDataPointProps[];
}

export interface TimelineDataPointProps {
  /**
   * The x value of the point
   */
  x: number;
  label?: string;
  marker?: TimelineMarkerProps;
  nearbyDataPoints?: TimelineDataPointProps[];
  tooltip?: T.TableContent;
}

export interface TimelineAxisProps {
  /**
   * The minimum x value of the axis
   */
  minX: number;
  /**
   * The maximum x value of the axis
   */
  maxX: number;
  /**
   * The y value of the axis
   */
  y: string | number;
}

interface TimelineProps {
  /**
   *  The timeline axis
   */
  axis: TimelineAxisProps;
  /**
   *  The timeline intervals
   */
  intervals: TimelineIntervalProps[];
  /**
   *  The timeline series
   */
  series: TimelineSeriesProps[];
  /**
   *  The timeline name
   */
  name?: string;
}

export interface TooltipState {
  open: boolean;
  content: React.ReactNode;
  anchorEl: HTMLElement | null;
}

const SvgContainer = styled('svg')(({ theme }) => ({
  overflow: 'visible',
  '& text.label': {
    ...theme.typography.xSmall,
    fill: theme.palette.text.primary,
    cursor: 'default',
  },
}));

const TimelineMarkerTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    maxWidth: 346,
    maxHeight: 400,
    borderRadius: 4,
    padding: 0,
    [`& .${tableContainerClasses.root}`]: {
      overflow: 'auto',
    },
    [`& .${tableCellClasses.root}`]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      height: 30,
      borderBottom: `1px solid ${theme.palette.grey['200']}`,
      padding: theme.spacing(0, 1),
      '&:first-of-type': { width: '45%', paddingLeft: 0 },
      '&:last-of-type': { paddingRight: 0 },
    },
    [`& .${tableCellClasses.head}`]: {
      ...theme.typography.small,
      color: theme.palette.text.secondary,
    },
  },
}));

const tooltipTableColumns = [
  { dataKey: '', label: '', align: 'left' },
  { dataKey: 'Planned', label: 'Planned', align: 'right' },
  { dataKey: 'Completed', label: 'Completed', align: 'right' },
] as TableColumnProps[];

const TimelineSeries: React.FC<TimelineSeriesProps> = ({ data, marker: seriesMarker }) => {
  const { axis, xScale, setTooltip, name } = useTimelineContext();
  const { y } = axis;
  const theme = useTheme();

  const handleCellRender = useCallback(
    (dataPoint: TimelineDataPointProps, column: TableColumnProps, columnIndex: number) => {
      if (columnIndex === 0) {
        return (
          <Typography
            variant="small"
            sx={{ '& > svg': { verticalAlign: 'middle', marginRight: 0.625 } }}
          >
            {!!dataPoint.marker?.symbol && (
              <TimelineSymbol
                type={dataPoint.marker?.symbol}
                size={15}
                stroke={dataPoint.marker?.stroke}
                fill={
                  dataPoint.marker?.fill
                    ? dataPoint.marker?.stroke
                    : theme.palette.background.default
                }
              />
            )}
            {dataPoint.label}
          </Typography>
        );
      }

      const row = dataPoint.tooltip?.rows?.find(
        (row) =>
          T.isTextResult(row.cells?.[0]?.cellContent) &&
          row.cells?.[0]?.cellContent.value === column.dataKey,
      );

      if (T.isTextResult(row?.cells?.[1]?.cellContent)) {
        return (
          <Typography variant="small" noWrap>
            {convertDateFormat(row?.cells?.[1]?.cellContent.value ?? '')}
          </Typography>
        );
      }

      return '‐';
    },
    [],
  );

  const handleMarkerMouseEnter = useCallback(
    (dataPoint: TimelineDataPointProps) => (e: React.MouseEvent) => {
      setTooltip({
        open: true,
        content: (
          <TimelineTooltipContent
            dataPoint={dataPoint}
            columns={tooltipTableColumns}
            onCellRender={handleCellRender}
            title={name}
          />
        ),
        anchorEl: e.currentTarget as HTMLElement,
      });
    },
    [name, tooltipTableColumns, handleCellRender],
  );

  const handleMarkerMouseLeave = useCallback(() => {
    setTooltip((prevState) => ({ ...prevState, open: false }));
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      {data.map((dataPoint, index) => {
        const { x, marker, label } = dataPoint;
        const symbolSize = 16;
        // Marker props are inherited from the parent series marker
        const { symbol, ...markerProps } = {
          ...seriesMarker,
          ...marker,
          size: symbolSize,
        } as TimelineMarkerProps & {
          size: number;
        };

        // If not set on the marker, inherit the stroke color from the interval
        const stroke = markerProps.stroke;

        return (
          <g
            key={index}
            className="marker"
            onMouseEnter={handleMarkerMouseEnter(dataPoint)}
            onMouseLeave={handleMarkerMouseLeave}
          >
            <g transform={`translate(${(-symbolSize * 1.5) / 2},${(-symbolSize * 1.5) / 2})`}>
              <rect
                x={`${xScale(x)}%`}
                y={y}
                width={symbolSize * 1.5}
                height={symbolSize * 1.5}
                opacity="0"
              />
            </g>
            <g transform={`translate(${-symbolSize / 2},${-symbolSize / 2})`}>
              <TimelineSymbol
                type={symbol ?? SymbolType.Dot}
                size={symbolSize}
                stroke={stroke}
                fill={markerProps.fill ? stroke : theme.palette.background.default}
                x={`${xScale(x)}%`}
                y={y}
              />
            </g>
            {!!label && (
              <text
                className="label"
                x={`${xScale(x)}%`}
                y={y}
                dy={20}
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {label}
              </text>
            )}
          </g>
        );
      })}
    </>
  );
};

export const Timeline: React.FC<TimelineProps> = ({ axis, intervals = [], series = [], name }) => {
  const { minX, maxX } = axis;
  const xScale = useMemo(() => scaleLinear().domain([minX, maxX]).range([0, 100]), [minX, maxX]);
  const [svg, setSvg] = useState<SVGSVGElement | null>(null);

  const enhancedSeries = useTimelineNearbyDataPoints(series, minX, maxX, 16, svg);

  const [tooltip, setTooltip] = useState<TooltipState>({
    open: false,
    content: '',
    anchorEl: null,
  });

  useTimelineGroupedLabels(svg);

  return (
    <TimelineContextProvider axis={axis} xScale={xScale} setTooltip={setTooltip} name={name}>
      <TimelineMarkerTooltip
        open={tooltip.open}
        title={tooltip.content}
        placement="bottom"
        arrow
        disableFocusListener
        disableTouchListener
        PopperProps={{ anchorEl: tooltip.anchorEl, disablePortal: true }}
      >
        <div></div>
      </TimelineMarkerTooltip>
      <SvgContainer width="100%" height="100%" ref={setSvg} data-testid="Timeline">
        {!!intervals?.length && (
          <g className="timeline-intervals">
            {intervals.map((intervalProps: TimelineIntervalProps, index: number) => (
              <TimelineInterval key={index} {...intervalProps} />
            ))}
          </g>
        )}
        {!!enhancedSeries?.length && (
          <g className="timeline-series">
            {enhancedSeries.map((seriesProps: TimelineSeriesProps, index: number) => (
              <TimelineSeries key={index} {...seriesProps} />
            ))}
          </g>
        )}
      </SvgContainer>
    </TimelineContextProvider>
  );
};

// Hook to enhance series data with nearbyDataPoints
function useTimelineNearbyDataPoints(
  series: TimelineSeriesProps[],
  min: number,
  max: number,
  pixelRange: number,
  svgContainer: SVGSVGElement | null,
) {
  const { isWithinRange } = useTimelineRange(pixelRange, min, max, svgContainer);

  return useMemo(
    () =>
      series.map((item: TimelineSeriesProps) => ({
        ...item,
        data: item.data?.map((dataPoint: TimelineDataPointProps) => ({
          ...dataPoint,
          // Enhance the data point with nearbyDataPoints
          nearbyDataPoints: series.reduce(
            (nearbyDataPoints: TimelineDataPointProps[], s: TimelineSeriesProps) => {
              s.data?.forEach((p: TimelineDataPointProps) => {
                if (isWithinRange(p, dataPoint)) {
                  nearbyDataPoints.push(p); // Add nearby points to the array
                }
              });

              return nearbyDataPoints; // Return accumulated nearby points
            },
            [],
          ),
        })),
      })),
    [series, isWithinRange],
  );
}

// Hook to calculate the range based on the SVG container width
function useTimelineRange(
  pixelRange: number,
  min: number,
  max: number,
  svgContainer: SVGSVGElement | null,
) {
  const range = useMemo(() => {
    const clientRect = svgContainer?.getBoundingClientRect();
    if (clientRect) {
      return translatePixelToValue(pixelRange, min, max, clientRect.width);
    }

    return 0;
  }, [svgContainer, min, max, pixelRange]);

  // Function to check whether the distance between two data points is within a specified range
  const isWithinRange = useCallback(
    ({ x: x1 }: TimelineDataPointProps, { x: x2 }: TimelineDataPointProps) => {
      return Math.abs(x1 - x2) <= range;
    },
    [range],
  );

  return { range, isWithinRange };
}
