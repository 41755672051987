import { Components, Theme } from '@mui/material';
import React from 'react';

import { colors } from '../colors';

const commonStyle: (theme: Theme) => React.CSSProperties = (theme) => ({
  minWidth: 136,
  height: 36,
  borderRadius: 24,
  padding: theme.spacing(1, 3),
  ...theme.typography.subtitle,
});

export const MuiButton: Components<Theme>['MuiButton'] = {
  variants: [
    {
      props: { variant: 'primary' },
      style: ({ theme }) => ({
        ...commonStyle(theme),
        backgroundColor: colors.neutrals.white,
        color: colors.neutrals.black,
        '&:hover': {
          backgroundColor: colors.neutrals.white,
          color: colors.neutrals.black,
        },
      }),
    },
    {
      props: { variant: 'secondary' },
      style: ({ theme }) => ({
        ...commonStyle(theme),
        backgroundColor: 'transparent',
        color: colors.neutrals.white,
        border: '1px solid',
        borderColor: 'inherit',
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.neutrals.white,
        },
      }),
    },
    {
      props: { variant: 'tertiary' },
      style: ({ theme }) => ({
        ...commonStyle(theme),
        backgroundColor: 'transparent',
        color: colors.neutrals.white,
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.neutrals.white,
        },
      }),
    },
  ],
};
