import React, { createContext, useContext } from 'react';

import { SlideshowAction, SlideshowState, useSlideshowReducer } from './slideshowState';

export interface SlideshowContextType {
  state: SlideshowState;
  dispatch: React.Dispatch<SlideshowAction>;
}

const SlideshowContext = createContext<SlideshowContextType | undefined>(undefined);

export const SlideshowProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useSlideshowReducer();
  return (
    <SlideshowContext.Provider value={{ state, dispatch }}>{children}</SlideshowContext.Provider>
  );
};

export const useSlideshow = () => {
  const context = useContext(SlideshowContext);

  if (!context) {
    throw new Error('useSlideshow must be used within a SlideshowProvider');
  }

  return context;
};
