import React, { useMemo } from 'react';

import { colors } from '../../theme/default/colors';
import { DataPoint, LineStyle, MicroChart, MicroChartProps, SeriesType } from '../MicroChart';
import { duplicateSingleNumericDataPoint } from './utils';

export interface MicroLineChartProps extends MicroChartProps {
  data: DataPoint[];
  trendlineData?: DataPoint[] | null;
  color?: string;
  lineWidth?: number;
  lineOpacity?: number;
  trendlineWidth?: number;
  trendlineOpacity?: number;
  fillOpacity?: number;
  width?: number;
  height?: number;
  offsetTop?: number;
  offsetBottom?: number;
  animated?: boolean;
}

export const MicroLineChart = React.memo(
  ({
    data: dataProp,
    trendlineData: trendlineDataProp,
    color = colors.primary.aqua,
    lineWidth = 1,
    lineOpacity = 1,
    trendlineWidth = lineWidth,
    trendlineOpacity = 1,
    fillOpacity = 0.3,
    width = 70,
    height = 24,
    offsetTop = 0.1,
    offsetBottom = 0.1,
    animated,
    ...rest
  }: MicroLineChartProps) => {
    const data = useMemo(() => duplicateSingleNumericDataPoint(dataProp), [dataProp]);

    const trendlineData = useMemo(
      () => (trendlineDataProp ? duplicateSingleNumericDataPoint(trendlineDataProp) : undefined),
      [trendlineDataProp],
    );

    return (
      <MicroChart
        width={width}
        height={height}
        maxPadding={offsetTop}
        minPadding={offsetBottom}
        {...rest}
      >
        <MicroChart.Series
          type={SeriesType.Area}
          data={data}
          color={color}
          lineWidth={lineWidth}
          lineOpacity={lineOpacity}
          fillOpacity={fillOpacity}
          animationDuration={animated ? 300 : 0}
        />
        {!!trendlineData && (
          <MicroChart.Series
            type={SeriesType.Line}
            data={trendlineData}
            color={color}
            lineWidth={trendlineWidth}
            lineOpacity={trendlineOpacity}
            lineStyle={LineStyle.Dashed}
            fillOpacity={fillOpacity}
          />
        )}
      </MicroChart>
    );
  },
);
