import { eventChannel } from 'redux-saga';
import { call, put, take, takeLatest } from 'redux-saga/effects';

import { getLocalUserSettings } from '@/core/services/localUserSettings';
import { fetchModulesRequest, fetchModulesSuccess } from '@/store/modules/slices';
import {
  verifyUserDisclaimerRequest,
  verifyUserDisclaimerSuccess,
} from '@/store/userDisclaimer/slices';

import { appInitialized, appStarted, setIsUpdateAvailable } from './slices';

// Watchers
function* onAppStarted() {
  yield takeLatest(appStarted, handleAppStarted);
}

function* watchForUpdates() {
  const updateChannel = (yield call(createUpdateChannel)) as ReturnType<typeof createUpdateChannel>;

  while (true) {
    yield take(updateChannel);
    yield put(setIsUpdateAvailable(true));
  }
}

// Workers
export function* handleAppStarted() {
  const settings = getLocalUserSettings();

  if (!settings?.userDisclaimerConfirmedOn) {
    yield put(verifyUserDisclaimerRequest());
    yield take(verifyUserDisclaimerSuccess);
  }

  yield put(fetchModulesRequest());
  yield take(fetchModulesSuccess);

  yield put(appInitialized());
}

function createUpdateChannel() {
  return eventChannel((emitter) => {
    const updateHandler = () => {
      emitter(true);
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.addEventListener('updatefound', updateHandler);
      });
    }

    // Cleanup function
    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.removeEventListener('updatefound', updateHandler);
        });
      }
    };
  });
}

export default [onAppStarted, watchForUpdates];
