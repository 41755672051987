import { extent } from 'd3';
import React from 'react';

import { MicroChartSeries, MicroChartSeriesProps } from './MicroChartSeries';

export function calculateDataExtent(
  children: React.ReactNode,
): [number, number] | [undefined, undefined] {
  const numericData: number[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement<MicroChartSeriesProps>(child) && child.type === MicroChartSeries) {
      const childData = child.props.data.filter((value) => typeof value === 'number') as number[];
      numericData.push(...childData);
    }
  });

  return extent(numericData);
}

export function calculateMaxDataLength(children: React.ReactNode): number {
  let maxDataLength = 0;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement<MicroChartSeriesProps>(child) && child.type === MicroChartSeries) {
      maxDataLength = Math.max(maxDataLength, child.props.data.length);
    }
  });

  return maxDataLength;
}
