import { GetPlailistsQuery, useGetPlailistsQuery } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { ApolloError } from '@apollo/client';
import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { useCallback, useMemo, useReducer } from 'react';

import { useModule } from '../../providers';
import plailistsSlice from './plailistsSlice';

const { queryCompleted, queryError, storyRead } = plailistsSlice.actions;

/**
 * Custom hook to encapsulate the logic related to managing and interacting with plailists data.
 */
export const usePlailists = () => {
  const moduleId = useModule()?.id ?? '';
  const [state, dispatch] = useReducer(plailistsSlice.reducer, plailistsSlice.getInitialState());

  // Bind action creators to the dispatch function
  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        { queryCompleted, queryError, storyRead },
        // This type alias it totally fine
        dispatch as Dispatch,
      ),
    [],
  );

  const onCompleted = useCallback(
    (data: GetPlailistsQuery) => {
      if (data.plailists) {
        boundActionCreators.queryCompleted({ plailists: data.plailists as T.PlailistsResult });
      }
    },
    [boundActionCreators],
  );

  const onError = useCallback(
    (error: ApolloError) => {
      boundActionCreators.queryError({ error });
    },
    [boundActionCreators],
  );

  useGetPlailistsQuery({ variables: { input: { moduleId } }, onCompleted, onError });

  return { state, actions: boundActionCreators } as const;
};
