import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Shield: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path
      d="M6 13.0364V7.05255L14.5 3.10269L23 7.05255V13.0364C23 18.6778 19.3192 23.8729 14.5 25.2443C9.68084 23.8729 6 18.6778 6 13.0364Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
  </SvgIcon>
);
