import { snakeCase, trim } from 'lodash-es';

export const getPathDetails = (
  path: string,
): {
  moduleName?: string;
  pageName?: string;
  focusArea?: string;
} => {
  // Remove leading and trailing slashes
  let cleanPath = trim(path, '/');

  // Check if the path starts with "web/" and remove it if present
  cleanPath = cleanPath.startsWith('web/') ? cleanPath.slice(4) : cleanPath;

  const pathParts = cleanPath.split('/').filter(Boolean); // Split the path and remove empty parts

  // Destructure to get moduleName, pageName, and focusArea (if available)
  const [moduleName, pageName, focusArea] = pathParts;

  return {
    moduleName: moduleName || undefined,
    pageName: pageName || undefined,
    focusArea: focusArea ? snakeCase(focusArea) : undefined,
  };
};
