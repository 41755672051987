import { InputAdornment } from '@mui/material';
import React from 'react';

import { SearchOutlined } from '../../icons';
import { InputBox, InputBoxProps } from '../InputBox';

export interface SearchBarProps extends Omit<InputBoxProps, 'ref'> {}

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder = 'Search', ...rest }) => (
  <InputBox
    placeholder={placeholder}
    startAdornment={
      <InputAdornment position="start">
        <SearchOutlined />
      </InputAdornment>
    }
    data-testid="SearchBar"
    {...rest}
  />
);
