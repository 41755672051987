import { alpha, useTheme } from '@mui/material';
import { motion, Transition } from 'framer-motion';
import React, { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size: number;
  lineWidth: number;
}

const transition: Transition = {
  repeat: Infinity,
  repeatType: 'loop',
  duration: 1.4,
  ease: 'linear',
};

const LoadingCircle: React.FC<Props> = ({ size, lineWidth, ...rest }) => {
  const theme = useTheme();
  const stopColors = [alpha(theme.palette.background.paper, 0.4), theme.palette.background.paper];

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} {...rest}>
      <g style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
        <motion.circle
          cx="50%"
          cy="50%"
          r={size / 2 - lineWidth / 2}
          stroke={stopColors[0]}
          strokeWidth={lineWidth}
          fill="none"
          animate={{ stroke: [stopColors[0], stopColors[1], stopColors[0]] }}
          transition={transition}
        />
      </g>
    </svg>
  );
};

export default LoadingCircle;
