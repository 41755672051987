import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Search: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" data-testid="SearchIcon" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7811 22.6877C25.8457 20.5996 27.1204 17.7288 27.1204 14.5603C27.1204 8.17571 21.9447 3 15.5602 3C9.17559 3 3.99988 8.17571 3.99988 14.5603C3.99988 20.9448 9.17559 26.1205 15.5602 26.1205C17.9405 26.1205 20.1528 25.4011 21.9915 24.1679L28.615 30.7914C29.0665 31.2429 29.7985 31.2429 30.2499 30.7914C30.7014 30.3399 30.7014 29.608 30.2499 29.1565L23.7811 22.6877ZM24.8084 14.5603C24.8084 19.6679 20.6678 23.8085 15.5602 23.8085C10.4525 23.8085 6.31193 19.6679 6.31193 14.5603C6.31193 9.45262 10.4525 5.31205 15.5602 5.31205C20.6678 5.31205 24.8084 9.45262 24.8084 14.5603Z"
    />
  </SvgIcon>
);
