import { LocalUserSettings, STORAGE_KEY } from '@/core/services/localUserSettings';

let performMigration = true;

/**
 * Retrieves the current local user settings from storage.
 * @returns {LocalUserSettings|undefined} The retrieved user settings, or undefined if not found or error occurred.
 */
export function getLocalUserSettings(): LocalUserSettings | undefined {
  // Check if migration has already been done
  if (performMigration) {
    performMigration = false;
    migrateOldSettings();
  }

  try {
    const data = localStorage.getItem(STORAGE_KEY);
    return data ? JSON.parse(data) : undefined;
  } catch (error) {
    console.error('Failed to parse local user settings from local storage:', error);
    return undefined;
  }
}

/**
 * Saves the provided settings to local storage.
 * @param {LocalUserSettings} settings - The settings to save.
 */
export function setLocalUserSettings(settings: LocalUserSettings) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
}

/**
 * Updates the local user settings with partial updates.
 * @param {Partial<LocalUserSettings>} newSettings - The partial settings to update.
 */
export function updateLocalUserSettings(newSettings: Partial<LocalUserSettings>) {
  const currentSettings = getLocalUserSettings();
  const updatedSettings = { ...currentSettings, ...newSettings } as LocalUserSettings;
  setLocalUserSettings(updatedSettings);
}

/**
 * Clears all local user settings from storage.
 */
export function clearLocalUserSettings() {
  localStorage.removeItem(STORAGE_KEY);
}

/**
 * Migrates old local user settings to the current format.
 */
export function migrateOldSettings() {
  // Retrieve old settings
  const defaultModuleId = localStorage.getItem('aily.defaultModuleId');
  const userDisclaimer = localStorage.getItem('aily.userDisclaimer');

  const newSettings: Partial<LocalUserSettings> = {};

  if (defaultModuleId) {
    newSettings.defaultModuleId = defaultModuleId;
  }

  if (userDisclaimer) {
    const parsedTimestamp = parseInt(userDisclaimer);
    if (!isNaN(parsedTimestamp)) {
      newSettings.userDisclaimerConfirmedOn = parsedTimestamp;
    }
  }

  if (Object.keys(newSettings).length > 0) {
    updateLocalUserSettings(newSettings);

    // Clean up old settings
    localStorage.removeItem('aily.defaultModuleId');
    localStorage.removeItem('aily.userDisclaimer');
  }
}
