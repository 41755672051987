import * as T from '@aily/graphql-sdk/schema';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash-es';
import React, { useCallback, useState } from 'react';

import NestedList from '../NestedList';
import { sortFilterOptionsByDefaultOrder } from './utils';

export interface MultiDimensionFilterProps {
  selectedFilterOptions?: T.TreeFilterOptionResult[];
  defaultSelectedFilterOptions?: T.TreeFilterOptionResult[];
  filterOptions?: T.TreeFilterOptionResult[];
  onChange?: (newSelectedFilterOptions: T.TreeFilterOptionResult[]) => void;
  search?: string;
}

const itemKey = ({ id }: T.TreeFilterOptionResult): string => id;
const itemLabel = ({ label }: T.TreeFilterOptionResult): string => label;
const itemSubLabel = (item: T.TreeFilterOptionResult): string => item.additionalLabels?.[0] ?? '';
const itemChildren = ({ children }: T.TreeFilterOptionResult): T.TreeFilterOptionResult[] =>
  children ?? [];
const itemSelectable = ({ value }: T.TreeFilterOptionResult): boolean => !isNil(value);

const SelectedListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main} !important`,
  borderRadius: '10px',
  marginBottom: theme.spacing(1.2),
  '& .MuiListItemButton-root': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '& .MuiListItemButton-root > svg': {
    color: theme.palette.text.secondary,
  },
  '& .MuiListItemButton-root .MuiTypography-body': {
    fontWeight: 600,
  },
}));

export const MultiDimensionFilter: React.FC<MultiDimensionFilterProps> = ({
  selectedFilterOptions,
  defaultSelectedFilterOptions,
  filterOptions,
  search,
  onChange,
}) => {
  const [localSelectedFilterOptions, setLocalSelectedFilterOptions] = useState(
    defaultSelectedFilterOptions ?? [],
  );

  const currentSelectedFilterOptions = selectedFilterOptions ?? localSelectedFilterOptions;

  const handleSelect = useCallback(
    (filterOption: T.TreeFilterOptionResult) => {
      // Replace the existing option with the same `optionGroupCode` with the new selection
      const newSelectedFilterOptions = [
        ...currentSelectedFilterOptions.filter(
          ({ optionGroupCode }) => optionGroupCode !== filterOption.optionGroupCode,
        ),
        filterOption, // Add the newly selected option
      ];

      // Sort the options based on the default filter order
      const sortedSelectedFilterOptions = sortFilterOptionsByDefaultOrder(
        newSelectedFilterOptions,
        filterOptions ?? [],
      );

      setLocalSelectedFilterOptions(sortedSelectedFilterOptions);
      onChange?.(sortedSelectedFilterOptions);
    },
    [currentSelectedFilterOptions, filterOptions, onChange],
  );

  const handleRemove = useCallback(
    (filterOption: T.TreeFilterOptionResult) => {
      // Find the default option for the same optionGroupCode as the removed item
      const defaultOption = filterOptions?.find(
        ({ optionGroupCode }) => optionGroupCode === filterOption.optionGroupCode,
      );

      // Remove the selected item and substitute with the default option if available
      const newSelectedFilterOptions = currentSelectedFilterOptions
        .filter((option) => option.id !== filterOption.id) // Remove the selected option
        .concat(defaultOption ? [defaultOption] : []); // Add the default if found

      // Sort the options based on the default filter order
      const sortedSelectedFilterOptions = sortFilterOptionsByDefaultOrder(
        newSelectedFilterOptions,
        filterOptions ?? [],
      );

      setLocalSelectedFilterOptions(sortedSelectedFilterOptions);
      onChange?.(sortedSelectedFilterOptions);
    },
    [currentSelectedFilterOptions, filterOptions, onChange],
  );

  return (
    <Stack
      direction="column"
      divider={<div className="divider" />}
      sx={{
        '& .divider': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        },
      }}
    >
      {filterOptions?.map((filterOption) => {
        // Finds a filter option by optionGroupCode that is not the root item
        const selectedFilterOption = currentSelectedFilterOptions?.find(
          ({ value, optionGroupCode }) =>
            optionGroupCode === filterOption.optionGroupCode && value !== filterOption.value,
        );

        const filterOptions: T.TreeFilterOptionResult[] = [
          // Make the root item unselectable by nullifying its value
          { ...filterOption, value: null },
        ];

        return (
          <Box key={filterOption.id}>
            {selectedFilterOption ? (
              <SelectedListItem disablePadding data-testid="ListItem">
                <ListItemButton
                  onClick={() => handleRemove(selectedFilterOption)}
                  data-testid="ListItemButton"
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography variant="body">{selectedFilterOption.label}</Typography>}
                    data-testid="ListItemText"
                  />
                  <CloseIcon />
                </ListItemButton>
              </SelectedListItem>
            ) : (
              <NestedList
                containerMaxHeight={414}
                itemHeight={46}
                items={filterOptions}
                currentItem={selectedFilterOption}
                itemKey={itemKey}
                itemLabel={itemLabel}
                itemSubLabel={itemSubLabel}
                itemChildren={itemChildren}
                selectable={itemSelectable}
                onSelect={handleSelect}
                search={search}
              />
            )}
          </Box>
        );
      })}
    </Stack>
  );
};
