import { ChevronRight } from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';
import React, { useCallback } from 'react';

import CarouselButtonBase from './CarouselButtonBase';
import useCarouselState from './useCarouselState';

export interface CarouselButtonNextProps extends IconButtonProps {}

const CarouselButtonNext: React.FC<CarouselButtonNextProps> = (props) => {
  const { currentPage, totalPages, visibleSlides, carouselContext } = useCarouselState();
  const disabled = currentPage >= totalPages - 1;

  const handleClick = useCallback(() => {
    if (currentPage < totalPages - 1) {
      const newCurrentPage = currentPage + 1;
      const newCurrentSlide = newCurrentPage * visibleSlides;
      carouselContext.setStoreState({ currentSlide: newCurrentSlide });
    }
  }, [currentPage, totalPages, visibleSlides, carouselContext]);

  return (
    <CarouselButtonBase
      onClick={handleClick}
      disabled={currentPage >= totalPages - 1}
      sx={{ opacity: disabled ? '0.8 !important' : 1 }}
      {...props}
    >
      <ChevronRight />
    </CarouselButtonBase>
  );
};

export default CarouselButtonNext;
