import { downloadMedia, useEnvironment } from '@aily/saas-core';
import { useCallback, useEffect, useState } from 'react';
import { useAudio } from 'react-use';

export const formatAudioUrl = (audioSrc: string, url: string) => {
  if (!audioSrc) {
    return '';
  }

  const baseUrl = url.split('/graphql/')[0];
  const encodedAudioNameUrl = encodeURIComponent(audioSrc);

  return `${baseUrl}/file/get-image-file/${encodedAudioNameUrl}`;
};

const createTimeout = (callback: () => void, delay: number) => {
  const timeoutId = setTimeout(callback, delay);
  return () => clearTimeout(timeoutId);
};

export const useRealAudio = (trackName: string, pauseAudio: boolean, onAudioEnd: () => void) => {
  const { API_URL } = useEnvironment();
  const [audioSrc, setAudioSrc] = useState('');

  const [audio, state, controls] = useAudio({
    src: audioSrc,
    autoPlay: false,
  });

  const fetchMediaBlob = useCallback(async () => {
    if (trackName) {
      const formattedAudioUrl = formatAudioUrl(trackName, API_URL);

      const blob = await downloadMedia(formattedAudioUrl);
      setAudioSrc(blob ?? '');
    }
  }, [trackName, API_URL]);

  useEffect(() => {
    fetchMediaBlob();
  }, [fetchMediaBlob]);

  useEffect(() => {
    if (!trackName || pauseAudio) {
      return;
    }

    return createTimeout(() => {
      controls.play();
    }, 500);
  }, [trackName, controls, pauseAudio, audioSrc]);

  useEffect(() => {
    if (state.duration > 0 && state.time === state.duration) {
      return createTimeout(() => {
        onAudioEnd();
        setAudioSrc('');
      }, 100);
    }
  }, [state.time, state.duration, onAudioEnd]);

  useEffect(() => {
    if (pauseAudio) {
      controls.pause();
    }
  }, [pauseAudio, controls]);

  return { audio, state, controls };
};
