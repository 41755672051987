import * as T from '@aily/graphql-sdk/schema';
import { useMemo } from 'react';

type TableDataViewAbstractType = {
  rows?: T.TableRowResult[] | null;
};

export function useLocationMarkers(dataView?: TableDataViewAbstractType | null) {
  const locationMarkers = useMemo(
    () =>
      dataView?.rows?.reduce((markers: T.LocationMarkerResult[], row) => {
        row?.cells?.forEach((cell) => {
          if (cell && T.isLocationMarkerResult(cell?.cellContent)) {
            markers.push(cell.cellContent);
          }
        });

        return markers;
      }, []) ?? [],
    [dataView?.rows],
  );

  const hasLocationMarkers = useMemo(() => locationMarkers.length > 0, [locationMarkers]);

  return { locationMarkers, hasLocationMarkers } as const;
}
