import * as T from '@aily/graphql-sdk/schema';
import { CircleIconUnderlined, CircleIconUnderlinedIcon } from '@aily-labs/ui/beta';
import { Stack, styled, Typography } from '@mui/material';
import React, { FC, useCallback, useMemo, useRef } from 'react';

import { NavLink } from '../NavLink';
import { OptimallyWideMultilineText } from '../OptimallyWideMultilineText';

export interface TableCellIconsLinkProps {
  iconsLink: T.IconsLinkResult;
  onClick?: (icon: T.IconsLinkResult) => void;
}

const TitleAndIcon = styled('div')(() => ({
  display: 'flex',
}));

const IconWrapper = styled('div')(() => ({
  display: 'flex',
  marginLeft: 10,
  marginTop: 3,
  height: 20,
}));

type IconType = 'customer' | 'procurement' | 'distribution' | 'manufacturing';

const iconTypeMap: Record<IconType, CircleIconUnderlinedIcon> = {
  customer: 'customerIcon',
  procurement: 'rawMaterialsIcon',
  distribution: 'distributionIcon',
  manufacturing: 'manufacturingIcon',
};

export const TableCellIconsLink: FC<TableCellIconsLinkProps> = ({ iconsLink, onClick }) => {
  const { value, subLabels, linkResult, icons } = iconsLink;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    onClick?.(iconsLink);
  }, [onClick, iconsLink]);

  const cellIcon = useMemo(() => {
    if (!linkResult || !icons?.length) return null;

    const iconType = iconTypeMap[icons[0] as IconType];
    if (!iconType) return null;

    return (
      <CircleIconUnderlined
        iconType={iconType}
        size="small"
        sentiment="warning"
        hasDoubleIcon={icons.length > 1}
      />
    );
  }, [linkResult, icons]);

  const titleAndIcon = (
    <TitleAndIcon>
      <Typography variant="inherit" color="text.primary" component="span">
        {value}
      </Typography>
      {!!cellIcon && <IconWrapper>{cellIcon}</IconWrapper>}
    </TitleAndIcon>
  );

  let cellContent = subLabels?.length ? (
    <Stack>
      <OptimallyWideMultilineText containerRef={containerRef}>
        {titleAndIcon}
      </OptimallyWideMultilineText>
      <Typography variant="small" color="text.secondary" component="span">
        {subLabels[0]}
      </Typography>
    </Stack>
  ) : (
    <OptimallyWideMultilineText containerRef={containerRef}>
      {titleAndIcon}
    </OptimallyWideMultilineText>
  );

  if (icons?.length) {
    cellContent = (
      <NavLink forward onClick={handleClick}>
        {cellContent}
      </NavLink>
    );
  }

  return (
    <div ref={containerRef} data-testid="DrillDownCell">
      {cellContent}
    </div>
  );
};

export default TableCellIconsLink;
