import * as T from '@aily/graphql-sdk/schema';
import { useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { mapSentimentToGradientColors } from '../../utils';
import { ProgressBar, ProgressBarProps } from '../ProgressBar';

export interface TableCellProgressBarProps {
  progressBar: T.ProgressBarResult;
}

const height = 15;

const defaultProgressBarProps: ProgressBarProps = {
  barStartBorderRadius: 0,
  barEndBorderRadius: 0,
};

const tornadoProgressBarProps: ProgressBarProps = {
  trackBorderRadius: 0,
  barStartBorderRadius: '2px',
  barEndBorderRadius: `${height}px`,
};

export const TableCellProgressBar: React.FC<TableCellProgressBarProps> = ({ progressBar }) => {
  const { progressValue, baseValue, min, max, inverted, sentiment, variant } = progressBar;
  const sentimentColors = mapSentimentToGradientColors(sentiment ?? T.Sentiment.Neutral);
  const gradientDirection = inverted ? 'to left' : 'to right';
  const barBackground = `linear-gradient(${gradientDirection}, ${sentimentColors.join(',')})`;
  const { ref, inView } = useInView();
  const theme = useTheme();

  const trackBackground =
    variant === T.ProgressBarVariant.Tornado ? 'transparent' : theme.palette.background.paper;

  const progressBarProps = useMemo(
    () =>
      variant === T.ProgressBarVariant.Tornado ? tornadoProgressBarProps : defaultProgressBarProps,
    [variant],
  );

  return (
    <div ref={ref} data-testid="ProgressBarCell">
      {inView && (
        <ProgressBar
          width="100%"
          height={height}
          min={min}
          max={max}
          baseValue={baseValue}
          value={progressValue}
          inverted={inverted}
          barBackground={barBackground}
          trackBackground={trackBackground}
          {...progressBarProps}
        />
      )}
    </div>
  );
};

export default TableCellProgressBar;
