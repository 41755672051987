import { Box, Stack, StackProps, styled, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import React from 'react';

import { AiActive } from '../../../../icons';

export interface AgentHeaderProps extends StackProps {
  title?: string;
  subTitle?: string;
}

const StyledStack = styled(Stack)(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  '& .MuiTypography-root': {
    lineHeight: 1.2, // Compatibility fix
  },
  // Typography style inconsistencies
  '& .MuiTypography-h5': { fontWeight: 400 },
  '& .MuiTypography-h7': { lineHeight: '29px' },
}));

export const AgentHeader: React.FC<AgentHeaderProps> = ({ title, subTitle }) => {
  return (
    <StyledStack>
      <Box sx={{ display: 'flex' }}>
        <AiActive sx={{ fontSize: 40, paddingRight: 1 }} />
        <Typography variant="h5">{title}</Typography>
      </Box>
      {!!subTitle && (
        <Typography variant="h7">
          <Markdown options={{ wrapper: React.Fragment }}>{subTitle}</Markdown>
        </Typography>
      )}
    </StyledStack>
  );
};
