import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type * as T from '../../../../schema/__generated__/types';
const defaultOptions = {} as const;
export type SaveUserDailyAgentPreselectedFilterMutationVariables = T.Exact<{
  preselectedFilterId: T.Scalars['String']['input'];
  goalId: T.Scalars['Int']['input'];
}>;

export type SaveUserDailyAgentPreselectedFilterMutation = {
  __typename?: 'Mutation';
  saveUserDailyAgentPreselectedFilter: boolean;
};

export type DeleteUserDailyAgentPreselectedFilterMutationVariables = T.Exact<{
  goalId: T.Scalars['Int']['input'];
  filterCode: T.Scalars['String']['input'];
}>;

export type DeleteUserDailyAgentPreselectedFilterMutation = {
  __typename?: 'Mutation';
  deleteUserDailyAgentPreselectedFilter: boolean;
};

export const SaveUserDailyAgentPreselectedFilterDocument = gql`
  mutation SaveUserDailyAgentPreselectedFilter($preselectedFilterId: String!, $goalId: Int!) {
    saveUserDailyAgentPreselectedFilter(preselectedFilterId: $preselectedFilterId, goalId: $goalId)
  }
`;
export type SaveUserDailyAgentPreselectedFilterMutationFn = Apollo.MutationFunction<
  SaveUserDailyAgentPreselectedFilterMutation,
  SaveUserDailyAgentPreselectedFilterMutationVariables
>;

/**
 * __useSaveUserDailyAgentPreselectedFilterMutation__
 *
 * To run a mutation, you first call `useSaveUserDailyAgentPreselectedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserDailyAgentPreselectedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserDailyAgentPreselectedFilterMutation, { data, loading, error }] = useSaveUserDailyAgentPreselectedFilterMutation({
 *   variables: {
 *      preselectedFilterId: // value for 'preselectedFilterId'
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useSaveUserDailyAgentPreselectedFilterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveUserDailyAgentPreselectedFilterMutation,
    SaveUserDailyAgentPreselectedFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveUserDailyAgentPreselectedFilterMutation,
    SaveUserDailyAgentPreselectedFilterMutationVariables
  >(SaveUserDailyAgentPreselectedFilterDocument, options);
}
export type SaveUserDailyAgentPreselectedFilterMutationHookResult = ReturnType<
  typeof useSaveUserDailyAgentPreselectedFilterMutation
>;
export type SaveUserDailyAgentPreselectedFilterMutationResult =
  Apollo.MutationResult<SaveUserDailyAgentPreselectedFilterMutation>;
export type SaveUserDailyAgentPreselectedFilterMutationOptions = Apollo.BaseMutationOptions<
  SaveUserDailyAgentPreselectedFilterMutation,
  SaveUserDailyAgentPreselectedFilterMutationVariables
>;
export const DeleteUserDailyAgentPreselectedFilterDocument = gql`
  mutation DeleteUserDailyAgentPreselectedFilter($goalId: Int!, $filterCode: String!) {
    deleteUserDailyAgentPreselectedFilter(goalId: $goalId, filterCode: $filterCode)
  }
`;
export type DeleteUserDailyAgentPreselectedFilterMutationFn = Apollo.MutationFunction<
  DeleteUserDailyAgentPreselectedFilterMutation,
  DeleteUserDailyAgentPreselectedFilterMutationVariables
>;

/**
 * __useDeleteUserDailyAgentPreselectedFilterMutation__
 *
 * To run a mutation, you first call `useDeleteUserDailyAgentPreselectedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDailyAgentPreselectedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDailyAgentPreselectedFilterMutation, { data, loading, error }] = useDeleteUserDailyAgentPreselectedFilterMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      filterCode: // value for 'filterCode'
 *   },
 * });
 */
export function useDeleteUserDailyAgentPreselectedFilterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserDailyAgentPreselectedFilterMutation,
    DeleteUserDailyAgentPreselectedFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteUserDailyAgentPreselectedFilterMutation,
    DeleteUserDailyAgentPreselectedFilterMutationVariables
  >(DeleteUserDailyAgentPreselectedFilterDocument, options);
}
export type DeleteUserDailyAgentPreselectedFilterMutationHookResult = ReturnType<
  typeof useDeleteUserDailyAgentPreselectedFilterMutation
>;
export type DeleteUserDailyAgentPreselectedFilterMutationResult =
  Apollo.MutationResult<DeleteUserDailyAgentPreselectedFilterMutation>;
export type DeleteUserDailyAgentPreselectedFilterMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserDailyAgentPreselectedFilterMutation,
  DeleteUserDailyAgentPreselectedFilterMutationVariables
>;
