import * as T from '@aily/graphql-sdk/schema';
import { colors } from '@aily/saas-core/theme/default/colors';
import {
  AccelerateIcon,
  AiIcon,
  ArrowLeft,
  Bank,
  Box,
  CheckeredFlagIcon,
  FailureIcon,
  FlashIcon,
  Heading,
  HStack,
  MaskedText,
  MinusCircle,
  OutlinedAddIcon,
  PipelineIcon,
  ReprioritizeIcon,
  StopIcon,
  VStack,
} from '@aily-labs/ui';
import type { ReactNode } from 'react';

export interface RealText extends T.FormattedTextResult {
  fullWidth?: boolean;
  boldText?: boolean;
  isHeader?: boolean;
}

interface RealsTextBlockProps {
  data?: T.RealTextListItem[];
  title?: ReactNode;
}

const SENTIMENT_COLORS: Record<string, 'mint-chart' | 'pink-chart'> = {
  POSITIVE: 'mint-chart',
  NEGATIVE: 'pink-chart',
};

const iconCommonProps = {
  width: 28,
  height: 28,
};

const ICONS: Record<T.RealIcon, ReactNode> = {
  AI: <AiIcon {...iconCommonProps} />,
  PLUS: <OutlinedAddIcon {...iconCommonProps} />,
  MINUS: <MinusCircle color={colors.primary.pink} {...iconCommonProps} />,
  FLASH: <FlashIcon {...iconCommonProps} />,
  LEFT_ARROW: <ArrowLeft {...iconCommonProps} />,
  ACCELERATE: <AccelerateIcon {...iconCommonProps} />,
  FLAG: <CheckeredFlagIcon color={colors.neutrals.white} {...iconCommonProps} />,
  FAILURE: <FailureIcon color={colors.neutrals.white} {...iconCommonProps} />,
  STOP: <StopIcon {...iconCommonProps} />,
  BANK: <Bank {...iconCommonProps} />,
  PIPELINE: <PipelineIcon color={colors.neutrals.white} {...iconCommonProps} />,
  RE_PRIORITIZE: <ReprioritizeIcon {...iconCommonProps} />,
};

export const renderText = (
  { sentiment, content, fullWidth, boldText, isHeader }: RealText,
  textIndex: number,
) => {
  const fill = SENTIMENT_COLORS[sentiment ?? T.Sentiment.Neutral];
  if (sentiment === T.Sentiment.Neutral) {
    return (
      <Heading
        key={textIndex}
        color="$white"
        type={boldText ? 'H7' : 'H8'}
        fontWeight={boldText ? '$fontWeightBold' : '$fontWeightRegular'}
        alignSelf={isHeader ? 'center' : 'flex-start'}
        lineHeight={'$lineHeightHeading'}
        width={fullWidth ? undefined : 245}
      >
        {content}
      </Heading>
    );
  }

  return (
    <MaskedText
      fontSize={boldText ? 'fontSize24' : 'fontSize20'}
      fontWeight={boldText ? 'fontWeightBold' : 'fontWeightRegular'}
      gradient={fill}
      fullWidth={false}
      horizontal
      text={content}
    />
  );
};

export const RealsTextBlockBuilder = ({ data = [], title }: RealsTextBlockProps) => (
  <VStack height="100%" flexWrap="wrap" justifyContent="flex-start">
    {title}
    <VStack
      justifyContent={data?.length > 2 ? 'space-between' : 'flex-start'}
      flex={1}
      gap={data?.length > 2 ? 20 : 30}
    >
      {data?.map(({ text, icon }, index) => (
        <HStack key={index} alignItems="center" maxWidth={290}>
          {icon && <Box width={45}>{ICONS[icon]}</Box>}
          <VStack width={icon ? 245 : 290} gap={5}>
            {(text as RealText[]).map(renderText)}
          </VStack>
        </HStack>
      ))}
    </VStack>
  </VStack>
);
