import { Close } from '@aily/saas-core';
import { colors } from '@aily/saas-core/theme/default/colors';
import {
  Backdrop,
  BackdropProps,
  Box,
  Container,
  Grid2 as Grid,
  Grow,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

import { useInterceptComponents } from '@/components/Intercept/useInterceptComponents';

export interface InterceptProps extends Omit<BackdropProps, 'open'> {
  onClose?: () => void;
}

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  backgroundColor: colors.overlays.black,
}));

const TransitionComponent = ({
  children,
  ...rest
}: TransitionProps & { children: React.ReactElement }) => <Grow {...rest}>{children}</Grow>;

export const Intercept: React.FC<InterceptProps> = ({ onClose, children, ...rest }) => {
  const { InterceptMediaComponent, InterceptContentComponent, InterceptActionsComponent } =
    useInterceptComponents(children);

  return (
    <StyledBackdrop
      open
      TransitionComponent={TransitionComponent}
      transitionDuration={150}
      {...rest}
    >
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Box sx={{ height: '100%', py: 10 }}>
          <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', height: '100%' }}>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
              <Close />
            </IconButton>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              columnSpacing={12.5}
            >
              <Grid>
                <Box sx={{ my: 5 }}>{InterceptMediaComponent}</Box>
              </Grid>
              <Grid>
                <Stack direction="column" spacing={3}>
                  {InterceptContentComponent}
                  {InterceptActionsComponent}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </StyledBackdrop>
  );
};
