import { Box, Button, Skeleton, styled } from '@mui/material';
import React from 'react';

import { ChevronLeft } from '../../icons';

interface BackButtonWithLabelProps {
  label?: string;
  loading?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  // For the ScaleBox to work properly
  overflow: 'hidden',
  margin: '0 auto',
  width: '100%',
  minHeight: '100vh',
  [theme.breakpoints.up('xs')]: {
    padding: theme.spacing(0, 5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 7.5),
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 986,
    padding: 0,
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1100,
  },
  [theme.breakpoints.up('xxl')]: {
    maxWidth: 1400,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  justifyContent: 'flex-start',
  paddingLeft: 0,
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
  // If it's the first child in the toolbar, align it to the left edge by collapsing the left padding
  '.MuiToolbar-root > &:first-of-type': {
    marginLeft: theme.spacing(-1),
  },
}));

export const BackButtonWithLabel: React.FC<BackButtonWithLabelProps> = ({
  label,
  loading,
  children = 'Back',
  ...rest
}) => {
  if (loading) {
    return (
      <StyledBox {...rest}>
        <Skeleton width="38%" />
      </StyledBox>
    );
  }

  return (
    <StyledButton
      variant="text"
      startIcon={<ChevronLeft />}
      sx={{ typography: 'subtitle' }}
      {...rest}
    >
      {label ?? children}
    </StyledButton>
  );
};
