import * as T from '@aily/graphql-sdk/schema';
import { SvgIconProps } from '@mui/material';
import React from 'react';

import {
  AiCircle,
  ArrowCircleDownThick,
  ArrowCircleUpThick,
  Correlator,
  PlayActive as PlayActive,
  Reals,
  Stars,
} from '../../icons';

export interface StoryIconProps extends SvgIconProps {
  story: T.Story;
}

type IconType =
  | 'AiCircle'
  | 'ArrowCircleDown'
  | 'ArrowCircleUp'
  | 'Correlator'
  | 'Play'
  | 'Reals'
  | 'Stars';

type IconComponentMap = Record<IconType, React.ComponentType<SvgIconProps>>;

const componentMap: IconComponentMap = {
  AiCircle,
  ArrowCircleDown: ArrowCircleDownThick,
  ArrowCircleUp: ArrowCircleUpThick,
  Correlator,
  Play: PlayActive,
  Reals,
  Stars,
};

const StoryIcon: React.FC<StoryIconProps> = ({ story, ...rest }) => {
  let Component: React.ComponentType<SvgIconProps> | undefined = undefined;

  switch (story.storyType) {
    case T.StoryDisplayType.AiInspired:
      Component = componentMap.Stars;
      break;
    case T.StoryDisplayType.AiXplained:
    case T.StoryDisplayType.Whisper:
      Component = componentMap.AiCircle;
      break;
    case T.StoryDisplayType.WhisperReal:
      Component = componentMap.Correlator;
      break;
    case T.StoryDisplayType.Media:
      Component = componentMap.Play;
      break;
    case T.StoryDisplayType.Real:
      Component = componentMap.Reals;
      break;
  }

  if (Component) {
    return <Component {...rest} />;
  }

  switch (story.sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      Component = componentMap.ArrowCircleUp;
      break;
    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
    case T.Sentiment.Critical:
      Component = componentMap.ArrowCircleDown;
      break;
  }

  if (story.insightsType === T.InsightsType.Real) {
    Component = componentMap.Reals;
  }

  if (!Component) {
    Component = componentMap.AiCircle;
  }

  return <Component {...rest} />;
};

export default React.memo(StoryIcon);
