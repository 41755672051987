import { CarouselContext } from 'pure-react-carousel';
import { useCallback, useContext, useEffect, useState } from 'react';

function useCarouselState() {
  const carouselContext = useContext(CarouselContext);

  // States derived from the carousel context
  const [totalSlides, setTotalSlides] = useState(carouselContext.state.totalSlides);
  const [visibleSlides, setVisibleSlides] = useState(carouselContext.state.visibleSlides);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  const [currentPage, setCurrentPage] = useState(Math.floor(currentSlide / visibleSlides));

  // Calculate the total number of pages
  const totalPages = Math.ceil(totalSlides / visibleSlides);

  const handleCarouselStateChange = useCallback(() => {
    setTotalSlides(carouselContext.state.totalSlides);
    setVisibleSlides(carouselContext.state.visibleSlides);
    setCurrentSlide(carouselContext.state.currentSlide);
    setCurrentPage(
      Math.floor(carouselContext.state.currentSlide / carouselContext.state.visibleSlides),
    );
  }, [carouselContext]);

  useEffect(() => {
    // Subscribe to carousel state changes
    carouselContext.subscribe(handleCarouselStateChange);
    return () => carouselContext.unsubscribe(handleCarouselStateChange);
  }, [carouselContext, handleCarouselStateChange]);

  return {
    totalSlides,
    visibleSlides,
    currentSlide,
    currentPage,
    totalPages,
    carouselContext,
  };
}

export default useCarouselState;
