import { MarkdownRenderer } from '@aily/saas-core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getModals, ModalType, onModalCancel, onModalClose, onModalOk } from '@/store/modals';

const ModalRoot = () => {
  const modals = useSelector(getModals);
  const dispatch = useDispatch();

  const handleCancel = useCallback(
    (key: string) => () => {
      dispatch(onModalCancel(key));
      dispatch(onModalClose(key));
    },
    [],
  );

  const handleOk = useCallback(
    (key: string) => () => {
      dispatch(onModalOk(key));
      dispatch(onModalClose(key));
    },
    [],
  );

  return (
    <>
      {modals.map(({ key, title, content, okText, cancelText, type }) => (
        <Dialog
          key={key}
          open
          PaperProps={{
            sx: {
              width: '100%',
              minWidth: 300,
            },
          }}
        >
          {!!title && <DialogTitle data-testid="DialogTitle">{title}</DialogTitle>}
          {!!content && (
            <DialogContent data-testid="DialogContent">
              <MarkdownRenderer>{content}</MarkdownRenderer>
            </DialogContent>
          )}
          <DialogActions>
            {type === ModalType.CONFIRM && (
              <Button onClick={handleCancel(key)} data-testid="CancelButton">
                {cancelText ?? 'Cancel'}
              </Button>
            )}
            <Button onClick={handleOk(key)} variant={'contained'} data-testid="OkButton">
              {okText ?? 'OK'}
            </Button>
          </DialogActions>
        </Dialog>
      ))}
    </>
  );
};

export default ModalRoot;
