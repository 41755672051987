import * as T from '@aily/graphql-sdk/schema';

import H from '../../config/highchartsConfig';
import { mapColorEnumToColor, mapEnumToValue } from '../../utils';
import { SeriesOptionsType } from './types';

export function mapLineStyleToDashStyle(
  lineStyle: T.LineStyle,
  customMap?: Record<T.LineStyle, H.DashStyleValue | null>,
): H.DashStyleValue | null {
  // prettier-ignore
  const defaultMap: Record<T.LineStyle, H.DashStyleValue | null> = {
    [T.LineStyle.Dashed]:       'Dash',
    [T.LineStyle.Dasheddotted]: 'DashDot',
    [T.LineStyle.Dotted]:       'Dot',
    [T.LineStyle.None]:         null,
    [T.LineStyle.Solid]:        'Solid'
  };

  return mapEnumToValue(lineStyle, defaultMap, customMap);
}

export function mapSeriesType(
  seriesType: T.SeriesType,
  customMap?: Record<T.SeriesType, string>,
): string {
  // prettier-ignore
  const defaultMap: Record<T.SeriesType, string> = {
    [T.SeriesType.Area]:        'area',
    [T.SeriesType.AreaRange]:   'arearange',
    [T.SeriesType.Bar]:         'column',
    [T.SeriesType.Column]:      'column',
    [T.SeriesType.ColumnRange]: 'columnrange',
    [T.SeriesType.Line]:        'line',
    [T.SeriesType.Lowmedhigh]:  'lowmedhigh',
    [T.SeriesType.Waterfall]:   'waterfall',
    [T.SeriesType.Spline]:      'spline',
  };

  return mapEnumToValue(seriesType, defaultMap, customMap);
}

export function resolveSeriesColor(series: T.ChartSeries, options?: SeriesOptionsType) {
  return options?.color ?? (series.color ? mapColorEnumToColor(series.color) : undefined);
}
