import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useTimelineGroupedLabels(svgContainer: SVGSVGElement | null) {
  let prevWidth: number = 0;

  const [ro] = useState(
    new ResizeObserver((entries) => {
      const [entry] = entries;
      if (prevWidth !== entry.contentRect.width) {
        const svgContainer = entry.target as SVGSVGElement;

        const labels = extractLabelsFromSVG(svgContainer);
        const groupedLabels = groupOverlappingLabels(labels);

        hideOriginalLabels(svgContainer);
        appendGroupedLabelsToSVG(svgContainer, groupedLabels);

        prevWidth = entry.contentRect.width;
      }
    }),
  );

  useEffect(() => {
    if (svgContainer) {
      ro.observe(svgContainer);
    }

    return () => ro.disconnect();
  }, [svgContainer]);
}

type Label = { text: string; x: number; width: number; y: string; dy: string };
type GroupedLabel = { text: string; x: number; y: string; dy: string };

function isOverlap(
  x1: number,
  width1: number,
  x2: number,
  width2: number,
  buffer: number,
): boolean {
  return !(x2 > x1 + width1 + buffer || x2 + width2 + buffer < x1);
}

/**
 * Creates a single text string by grouping labels based on available width.
 * This function concatenates the text of each label in a group and ensures that the concatenated text fits within the available width.
 * Labels are concatenated with a comma and space, and an ellipsis is added if there is not enough space to display the next label completely.
 *
 * @param {Label[]} group - An array of label objects that should be grouped together.
 * @param {number} availableWidth - The maximum width available for displaying the grouped label text.
 *
 * @returns {string} Returns a single string representing the grouped labels' text, considering the available width.
 */
function createGroupedLabelText(group: Label[], availableWidth: number): string {
  const spacing = 10; // Space considered for comma and space between labels
  let labelText = ''; // Initialize the resulting label text
  let remainingWidth = availableWidth; // Initialize the remaining width available for labels

  // Iterate over each label in the group
  for (let i = 0; i < group.length; i++) {
    const label = group[i].text;
    const labelWidth = group[i].width;

    // If the label fits within the remaining width, add it to the resulting text
    if (i === 0 || labelWidth + spacing <= remainingWidth) {
      labelText += (i !== 0 ? ', ' : '') + label; // Concatenate labels with comma and space
      remainingWidth -= labelWidth + (i !== 0 ? spacing : 0); // Adjust the remaining width
    } else {
      labelText += ', ...'; // If the label doesn't fit, append ellipsis and exit the loop
      break;
    }
  }

  // Return the concatenated and possibly truncated label text
  return labelText;
}

/**
 * This function identifies and groups overlapping labels based on their positions and dimensions.
 * Each label is checked against others to find overlaps, and groups are formed with overlapping labels.
 * For groups with multiple labels, a new label text is created by concatenating the texts of the overlapping labels.
 * Additionally, the function calculates the position where each group of labels (or individual label) should be displayed,
 * ensuring that the labels are placed at a central position relative to the grouped labels.
 *
 * @param {Label[]} labels - An array of label objects, each containing text and position information.
 * @param {number} [buffer=5] - An optional buffer space to consider when grouping labels to avoid crowding.
 *
 * @returns {GroupedLabel[]} Returns an array of grouped label objects, each with text and position information.
 */
function groupOverlappingLabels(labels: Label[], buffer: number = 5): GroupedLabel[] {
  const groupedLabels: GroupedLabel[] = [];

  // Sort the labels based on their x positions to process them in order
  const sortedLabels = labels.sort(({ x: x1 }, { x: x2 }) => x1 - x2);

  for (let i = 0; i < sortedLabels.length; i++) {
    // Start a new group with the current label
    const group: Label[] = [sortedLabels[i]];

    // Try adding the next labels to the group if they overlap with the last label in the group
    for (let j = i + 1; j < sortedLabels.length; j++) {
      if (
        isOverlap(
          group[group.length - 1].x,
          group[group.length - 1].width,
          sortedLabels[j].x,
          sortedLabels[j].width,
          buffer,
        )
      ) {
        // If overlapping, add the label to the group and update the index
        group.push(sortedLabels[j]);
        i = j;
      }
    }

    // Calculate the middle x position for the grouped labels
    const middleX = (group[0].x + group[group.length - 1].x + group[group.length - 1].width) / 2;
    // The y position and dy (vertical shift) are taken from the first label in the group
    const y = group[0].y;
    const dy = group[0].dy;

    // If the group contains multiple labels, create a concatenated label text
    if (group.length > 1) {
      const text = createGroupedLabelText(group, getAvailableWidth(group, buffer));
      groupedLabels.push({ text, x: middleX, y, dy });
    } else {
      // For single-label groups, keep the original text
      groupedLabels.push({ text: group[0].text, x: middleX, y, dy });
    }
  }

  // Return the array of grouped labels with their positioning information
  return groupedLabels;
}

function getAvailableWidth(group: Label[], buffer: number): number {
  // Start position of the first label in the group
  const startX = group[0].x;

  // End position of the last label in the group
  const endX = group[group.length - 1].x + group[group.length - 1].width;

  // Calculate available width
  return endX - startX - buffer;
}

function extractLabelsFromSVG(svgContainer: SVGSVGElement): Label[] {
  const textElements = svgContainer.querySelectorAll<SVGTextElement>(
    'text.label:not([data-group])',
  );

  const labels: Label[] = [];

  textElements.forEach((element) => {
    const text = element.textContent ?? '';
    const { x, width } = element.getBBox();
    const y = element.getAttribute('y') ?? '0';
    const dy = element.getAttribute('dy') ?? '0';

    labels.push({ text, x, y, width, dy });
  });

  return labels;
}

function hideOriginalLabels(svgContainer: SVGSVGElement): void {
  svgContainer
    .querySelectorAll('text.label')
    .forEach((element) => element.setAttribute('visibility', 'hidden'));
}

function createGroupedLabelElement(group: GroupedLabel): SVGTextElement {
  const newTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  newTextElement.classList.add('label');
  newTextElement.setAttribute('x', group.x.toString());
  newTextElement.setAttribute('y', group.y);
  newTextElement.setAttribute('dy', group.dy);
  newTextElement.setAttribute('dominant-baseline', 'middle');
  newTextElement.setAttribute('text-anchor', 'middle');
  newTextElement.dataset.group = '';
  newTextElement.textContent = group.text;

  return newTextElement;
}

function appendGroupedLabelsToSVG(
  svgContainer: SVGSVGElement,
  groupedLabels: GroupedLabel[],
): void {
  // Remove existing grouped labels
  svgContainer.querySelectorAll('text.label[data-group]').forEach((element) => element.remove());
  groupedLabels.forEach((group) => {
    svgContainer.appendChild(createGroupedLabelElement(group));
  });
}
