import * as T from '@aily/graphql-sdk/schema';
import {
  Stack,
  StackProps as MuiStackProps,
  styled,
  Toolbar as MuiToolbar,
  ToolbarProps,
} from '@mui/material';
import React, { useCallback } from 'react';

import { useAuxFilters } from '../../contexts';
import { useAlignFilters, useRenderFilter } from '../Filters';
import { SelectFilterProps } from '../SelectFilter';

export type FilterChangeFunction = (
  filterId: string,
  filterOption: T.FilterOptionResult | T.TreeFilterOptionResult,
  filterCode?: string,
) => void;
export type FilterAlignFunction = (filterId: string) => T.Alignment | undefined;
export type FilterDisplayModeFunction = (filterId: string) => T.DisplayMode;

export interface FilterToolbarProps extends ToolbarProps {
  filters: T.Filter[];
  selectedFilterOptions?: T.FilterValue[];
  onFilterChange?: FilterChangeFunction;
  onFilterAlign?: FilterAlignFunction;
  onFilterDisplayMode?: FilterDisplayModeFunction;
  StackProps?: MuiStackProps;
  SelectFilterProps?: Partial<SelectFilterProps>;
  showAuxFilters?: boolean;
}

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  paddingRight: theme.spacing(2),
  pointerEvents: 'none',
  '& .MuiInputBase-root, & .MuiFormGroup-root, .MuiButton-root': {
    pointerEvents: 'all',
  },
  '& .MuiFormGroup-root + .MuiFormGroup-root::before': {
    content: '""',
    borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
    marginLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(3.75),
  },
}));

export const FilterToolbar: React.FC<FilterToolbarProps> = ({
  filters,
  selectedFilterOptions,
  onFilterChange,
  onFilterAlign,
  onFilterDisplayMode,
  StackProps,
  showAuxFilters,
  ...rest
}) => {
  const auxFiltersContext = useAuxFilters();
  const alignFilters = useAlignFilters(filters, onFilterAlign);

  const handleSelectFilterChange = useCallback(
    (filterId: T.Filter['id'], filterCode?: string) =>
      (filterOption: T.FilterOptionResult | T.TreeFilterOptionResult) => {
        onFilterChange?.(filterId, filterOption, filterCode);
      },
    [onFilterChange],
  );

  const { renderFilter } = useRenderFilter({
    selectedFilterOptions,
    onSelectFilterChange: handleSelectFilterChange,
    onFilterDisplayMode,
  });

  return (
    <Toolbar data-testid="FilterToolbar" {...rest}>
      <Stack direction="row" spacing={0} style={{ width: '100%' }} {...StackProps}>
        {(!!alignFilters[T.Alignment.Left].length ||
          (showAuxFilters && !!auxFiltersContext?.auxFilters.length)) && (
          <Stack direction="row" spacing={2}>
            {alignFilters[T.Alignment.Left].map(renderFilter)}
            {showAuxFilters && auxFiltersContext!.auxFilters}
          </Stack>
        )}
        {!!alignFilters[T.Alignment.Right].length && (
          <Stack direction="row" spacing={2} style={{ marginLeft: 'auto' }}>
            {alignFilters[T.Alignment.Right].map(renderFilter)}
          </Stack>
        )}
      </Stack>
    </Toolbar>
  );
};
