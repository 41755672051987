import { all, fork } from 'redux-saga/effects';

import appSagas from './app/sagas';
import dimensionFilterSagas from './dimensionFilter/sagas';
import modulesSagas from './modules/sagas';
import priorityFilterSagas from './priorityFilter/sagas';
import userDisclaimerSagas from './userDisclaimer/sagas';

export default function* rootSaga() {
  yield all(
    [
      ...appSagas,
      ...modulesSagas,
      ...priorityFilterSagas,
      ...dimensionFilterSagas,
      ...userDisclaimerSagas,
    ].map(fork),
  );
}
