import { ComponentType, FC, useMemo } from 'react';

import { useAnalyticsTrackEvent } from '../hooks';
import { EventTrackingConfig } from '../types';

/**
 * HOC to enhance a component with analytics event tracking for specified event handlers.
 *
 * @note
 * `useCallback` is not used for individual handlers as it cannot be applied inside loops
 * or conditional statements, as per the Rules of Hooks, instead all handlers are memoized.
 */
export function withEventTracking<T>(
  WrappedComponent: ComponentType<T>,
  config: EventTrackingConfig<T>,
): FC<T> {
  return (props) => {
    const trackEvent = useAnalyticsTrackEvent();

    // Generate handlers for each event specified in the config
    const handlers = useMemo(
      () =>
        Object.keys(config).reduce(
          (acc, key) => {
            const eventConfig = config[key as keyof T];

            if (eventConfig) {
              acc[key as keyof T] = (...args: unknown[]) => {
                const eventProps = eventConfig.getEventProps(...args);
                if (eventProps) {
                  trackEvent(eventConfig.eventName, eventProps);
                }

                // Call the original prop, if it exists
                const originalProp = props[key as keyof T];
                if (typeof originalProp === 'function') {
                  originalProp(...args);
                }
              };
            }

            return acc;
          },
          {} as Record<keyof T, (...args: unknown[]) => void>,
        ),
      [props, config],
    );

    return <WrappedComponent {...props} {...handlers} />;
  };
}
