import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ChevronLeft: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" data-testid="chevron-left" {...props}>
    <path
      d="M16.7611 7.83984L10.3311 14.2698L16.7611 20.6998"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
