import * as T from '@aily/graphql-sdk/schema';
import { Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { OpenInNewLink, StoryMetaBar, StoryMetaBarProps } from '../StoryMetaBar';

export interface DefaultStoryMetaBarProps extends StoryMetaBarProps {
  story: T.Story;
}

const DefaultStoryMetaBar: React.FC<DefaultStoryMetaBarProps> = ({
  story,
  onReferenceClick,
  ...rest
}) => {
  const handleReferenceClick = useCallback(() => {
    if (story.reference) {
      onReferenceClick?.(story, story.reference);
    }
  }, [story, onReferenceClick]);

  // Prevent displaying empty component
  if (![story.timeStamp, story.reference?.url].some(Boolean)) {
    return null;
  }

  return (
    <StoryMetaBar {...rest}>
      {!!story.timeStamp && (
        <Typography variant="small" data-testid="Timestamp">
          {story.timeStamp}
        </Typography>
      )}
      {!!story.reference?.url && (
        <OpenInNewLink
          onClick={handleReferenceClick}
          href={story.reference.url}
          data-testid="Reference"
        >
          {story.reference.displayText}
        </OpenInNewLink>
      )}
    </StoryMetaBar>
  );
};

export default DefaultStoryMetaBar;
