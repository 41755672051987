import React from 'react';

import { AchievementBadgeProps } from './AchievementBadge';

export const AchievementBadgeBronze: React.FC<AchievementBadgeProps> = ({
  width = 212,
  height = 220,
  backgroundColor = '#000',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 212 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="achievement-badge__bronze"
  >
    <g clipPath="url(#clip0_5951_27612)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0875 152.678C64.9637 152.678 65.6741 153.414 65.6741 154.322V212.17C65.6741 213.636 67.383 214.37 68.3824 213.333L101.972 178.512C103.831 176.585 106.844 176.585 108.703 178.512L142.293 213.333C143.292 214.37 145.001 213.636 145.001 212.17V154.322C145.001 153.414 145.711 152.678 146.588 152.678C147.464 152.678 148.174 153.414 148.174 154.322V212.17C148.174 216.566 143.047 218.768 140.049 215.659L106.459 180.838C105.84 180.195 104.835 180.195 104.216 180.838L70.6261 215.659C67.6278 218.768 62.501 216.566 62.501 212.17V154.322C62.501 153.414 63.2113 152.678 64.0875 152.678Z"
        fill="url(#paint0_linear_5951_27612)"
      />
      <path
        d="M165.613 85.2476C165.613 119.765 138.621 147.748 105.325 147.748C72.0282 147.748 45.0361 119.765 45.0361 85.2476C45.0361 50.7298 72.0282 22.7476 105.325 22.7476C138.621 22.7476 165.613 50.7298 165.613 85.2476Z"
        fill="url(#paint1_linear_5951_27612)"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.325 144.458C136.869 144.458 162.44 117.949 162.44 85.2476C162.44 52.5465 136.869 26.037 105.325 26.037C73.7806 26.037 48.2092 52.5465 48.2092 85.2476C48.2092 117.949 73.7806 144.458 105.325 144.458ZM105.325 147.748C138.621 147.748 165.613 119.765 165.613 85.2476C165.613 50.7298 138.621 22.7476 105.325 22.7476C72.0282 22.7476 45.0361 50.7298 45.0361 85.2476C45.0361 119.765 72.0282 147.748 105.325 147.748Z"
        fill="url(#paint2_linear_5951_27612)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.327 164.184C147.386 164.184 181.481 128.838 181.481 85.2368C181.481 41.6354 147.386 6.28947 105.327 6.28947C63.2683 6.28947 29.1731 41.6354 29.1731 85.2368C29.1731 128.838 63.2683 164.184 105.327 164.184ZM105.327 167.474C149.138 167.474 184.654 130.655 184.654 85.2368C184.654 39.8187 149.138 3 105.327 3C61.5159 3 26 39.8187 26 85.2368C26 130.655 61.5159 167.474 105.327 167.474Z"
        fill="url(#paint3_linear_5951_27612)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.897 60.0218L108.58 115.45C104.173 122.176 94.6128 122.176 90.2062 115.45L65.7432 78.1139L78.8673 68.8724L99.3931 100.199L131.773 50.7803L144.897 60.0218Z"
        fill="url(#paint4_linear_5951_27612)"
      />
      <path
        d="M186.701 84.3742C186.701 129.881 149.81 166.772 104.303 166.772C58.796 166.772 21.9053 129.881 21.9053 84.3742C21.9053 38.8672 58.796 1.97656 104.303 1.97656C149.81 1.97656 186.701 38.8672 186.701 84.3742Z"
        fill={backgroundColor}
      />
      <path
        d="M61.3128 223.58V153.977H148.317V223.58L103.791 187.243L61.3128 223.58Z"
        fill={backgroundColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.1975 153.152C66.0535 153.152 66.7475 153.871 66.7475 154.758V211.246C66.7475 212.677 68.417 213.393 69.3935 212.382L102.209 178.379C104.025 176.497 106.97 176.497 108.786 178.379L141.601 212.382C142.578 213.393 144.247 212.677 144.247 211.246V154.758C144.247 153.871 144.941 153.152 145.797 153.152C146.653 153.152 147.347 153.871 147.347 154.758V211.246C147.347 215.539 142.339 217.688 139.409 214.653L106.593 180.65C105.988 180.023 105.007 180.023 104.401 180.65L71.5855 214.653C68.6562 217.688 63.6475 215.539 63.6475 211.246V154.758C63.6475 153.871 64.3414 153.152 65.1975 153.152Z"
        fill="url(#paint5_linear_5951_27612)"
      />
      <path
        d="M164.398 87.3028C164.398 121.009 138.027 148.333 105.498 148.333C72.9681 148.333 46.5977 121.009 46.5977 87.3028C46.5977 53.5967 72.9681 26.2725 105.498 26.2725C138.027 26.2725 164.398 53.5967 164.398 87.3028Z"
        fill="url(#paint6_linear_5951_27612)"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.498 145.121C136.315 145.121 161.298 119.235 161.298 87.3028C161.298 55.3707 136.315 29.4846 105.498 29.4846C74.6802 29.4846 49.6977 55.3707 49.6977 87.3028C49.6977 119.235 74.6802 145.121 105.498 145.121ZM105.498 148.333C138.027 148.333 164.398 121.009 164.398 87.3028C164.398 53.5967 138.027 26.2725 105.498 26.2725C72.9681 26.2725 46.5977 53.5967 46.5977 87.3028C46.5977 121.009 72.9681 148.333 105.498 148.333Z"
        fill="url(#paint7_linear_5951_27612)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.5 164.394C146.59 164.394 179.9 129.879 179.9 87.303C179.9 44.7269 146.59 10.2121 105.5 10.2121C64.41 10.2121 31.1 44.7269 31.1 87.303C31.1 129.879 64.41 164.394 105.5 164.394ZM105.5 167.606C148.302 167.606 183 131.653 183 87.303C183 42.9529 148.302 7 105.5 7C62.6979 7 28 42.9529 28 87.303C28 131.653 62.6979 167.606 105.5 167.606Z"
        fill="url(#paint8_linear_5951_27612)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.167 62.6873L108.686 116.812C104.381 123.379 95.0407 123.379 90.7356 116.812L66.8359 80.3539L79.6578 71.3298L99.7109 101.92L131.345 53.6631L144.167 62.6873Z"
        fill="url(#paint9_linear_5951_27612)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5951_27612"
        x1="105.338"
        y1="154.322"
        x2="105.338"
        y2="220.112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.1" />
        <stop offset="0.494792" stopColor="#F18D1A" />
        <stop offset="1" stopColor="#F39200" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5951_27612"
        x1="105.325"
        y1="22.7476"
        x2="105.325"
        y2="147.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.57" />
        <stop offset="0.541667" stopColor="#F06D00" />
        <stop offset="1" stopColor="#EF8833" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.61" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5951_27612"
        x1="105.325"
        y1="22.7476"
        x2="105.325"
        y2="147.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.54" />
        <stop offset="0.479167" stopColor="#F39200" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.58" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5951_27612"
        x1="105.327"
        y1="3"
        x2="105.327"
        y2="167.474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.54" />
        <stop offset="0.479167" stopColor="#F39200" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.58" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5951_27612"
        x1="105.32"
        y1="62.2038"
        x2="105.32"
        y2="114.835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.5" />
        <stop offset="0.458333" stopColor="#F39200" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.79" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5951_27612"
        x1="105.497"
        y1="154.758"
        x2="105.497"
        y2="219.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.1" />
        <stop offset="0.494792" stopColor="#F18D1A" />
        <stop offset="1" stopColor="#F39200" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5951_27612"
        x1="105.498"
        y1="26.2725"
        x2="105.498"
        y2="148.333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.57" />
        <stop offset="0.541667" stopColor="#F06D00" />
        <stop offset="1" stopColor="#EF8833" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.61" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_5951_27612"
        x1="105.498"
        y1="26.2725"
        x2="105.498"
        y2="148.333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.54" />
        <stop offset="0.479167" stopColor="#F39200" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.58" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_5951_27612"
        x1="105.5"
        y1="7"
        x2="105.5"
        y2="167.606"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.54" />
        <stop offset="0.479167" stopColor="#F39200" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.58" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_5951_27612"
        x1="105.501"
        y1="64.818"
        x2="105.501"
        y2="116.212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EF8833" stopOpacity="0.5" />
        <stop offset="0.458333" stopColor="#F39200" />
        <stop offset="1" stopColor="#EF8833" stopOpacity="0.79" />
      </linearGradient>
      <clipPath id="clip0_5951_27612">
        <rect width="211.354" height="220" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
