import { Module, Page } from '@aily/graphql-sdk/schema';
import { normalizeAbsolutePath } from '@aily/saas-core';

export type ModuleWithAtLeastOnePage = Module & { pages: [Page, ...Page[]] };

export function normalizeModules(modules: Module[]): Module[] {
  // Filter out modules with no pages because BE can't do it due to some limitation
  // @see https://ailylabs.atlassian.net/browse/CORE-2717
  const filteredModules = modules.filter(
    ({ pages }) => !!pages?.length,
  ) as ModuleWithAtLeastOnePage[];

  return filteredModules.map((module) => ({ ...module, pages: module.pages.map(normalizePage) }));
}

export function normalizePage(page: Page): Page {
  return {
    ...page,
    // Add a slash before the absolute path because BE sends it without it (which is essentially a relative path)
    absolutePath: normalizeAbsolutePath(page.absolutePath),
    children: page.children?.map(normalizePage),
  };
}
