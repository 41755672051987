import 'dayjs/locale/en-gb';

import { Box, Dialog, styled, TextField, TextFieldProps, Typography } from '@mui/material';
import {
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
  MobileDatePicker,
  PickerValidDate,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';

dayjs.locale('en-gb');

export interface DatePickerProps extends MuiDatePickerProps<PickerValidDate> {
  label?: string;
  error?: boolean;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDateCalendar-root': {
    width: 343,
    minHeight: 218,
  },
  '& .MuiPickersSlideTransition-root': {
    minHeight: 218,
  },
  '& .MuiPickersToolbar-root': {
    display: 'none',
  },
  '& .MuiYearCalendar-root': {
    width: 'auto',
  },
  '& .MuiDayCalendar-root': {
    padding: theme.spacing(1.5),
  },
  '& .MuiDayCalendar-header': {
    justifyContent: 'space-between',
  },
  '& .MuiDayCalendar-weekContainer': {
    justifyContent: 'space-between',
  },
  '& .MuiDayCalendar-weekDayLabel': {
    width: 34,
    ...theme.typography.xSmall,
  },
  '& .MuiPickersDay-root': {
    width: 34,
    height: 34,
    ...theme.typography.body,
  },
  '& .MuiPickersDay-root, & .MuiPickersYear-yearButton': {
    '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
      background: `${theme.palette.text.primary} !important`,
      color: '#000',
      ...theme.typography.bodyBold,
    },
    '&.Mui-disabled:not(.Mui-selected)': {
      background: 'transparent',
      color: theme.palette.grey[200],
    },
  },
  '& .MuiPickersCalendarHeader-root .MuiIconButton-root': {
    color: theme.palette.text.secondary,
    '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
  },
  '& .MuiDialogActions-root .MuiButtonBase-root': {
    minWidth: 'auto',
    ...theme.typography.subtitle,
    '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
  },
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 48,
  borderRadius: 6,
  background: theme.palette.background.paper,
  padding: theme.spacing(0, 2),
  userSelect: 'none',
  ...(error && { boxShadow: `0 0 0 1px ${theme.palette.error.main}` }),
}));

const StyledTextField = styled(({ ...rest }: TextFieldProps) => (
  <TextField
    variant="standard"
    slotProps={{ input: { disableUnderline: true, placeholder: 'DD MMM YYYY' } }}
    {...rest}
  />
))(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: 100,
    height: 39,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 6,
    marginRight: theme.spacing(-0.75),
    cursor: 'pointer',
  },
  '& .MuiInputBase-input': {
    ...theme.typography.body,
    padding: theme.spacing(0.75),
    textAlign: 'center',
    userSelect: 'none',
    pointerEvents: 'none',
    caretColor: 'transparent',
  },
}));

const minDate = dayjs().subtract(13, 'year');
const maxDate = dayjs().add(6, 'year');
const dayOfYearFormatter = (date: Dayjs) => dayjs(date).format('dd');

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  label = 'Pick a date',
  error,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState<Dayjs | null>(dayjs());
  const currentValue = value ?? localValue;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledBox error={error}>
        <Typography variant="body" component="div">
          {label}
        </Typography>
        <MobileDatePicker
          value={currentValue}
          onChange={setLocalValue}
          minDate={minDate}
          maxDate={maxDate}
          format="DD MMM YYYY"
          yearsPerRow={4}
          dayOfWeekFormatter={dayOfYearFormatter}
          slots={{ dialog: StyledDialog, textField: StyledTextField }}
          {...rest}
        />
      </StyledBox>
    </LocalizationProvider>
  );
};
