import * as T from '@aily/graphql-sdk/schema';

import envConfig from '@/config/env';

// Helper functions to improve readability
const isProModule = (module: T.Module) => module.moduleCode === T.ModuleCode.Pro;

const isCoreProductEnv = () => envConfig.TENANT === 'coreproduct';

const isSanofiAiEnv = () => envConfig.TENANT === 'sanofi' && envConfig.ENV === 'ai';

const isDevEnv = () => envConfig.ENV === 'dev';

// So far only enabled for Pro module in Core and Sanofi AI environments, soon driven by feature flag
export const isProfilePageEnabled = (module: T.Module) =>
  isProModule(module) && (isDevEnv() || isCoreProductEnv() || isSanofiAiEnv());
