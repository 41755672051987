import * as T from '@aily/graphql-sdk/schema';
import { Box, Stack, Typography } from '@mui/material';
import { chunk } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { NeonDivider, useSlideshow } from '../../../../components';
import { useDeepCompareMemoize } from '../../../../hooks';
import { AilyAgentOperation } from '../../classes';
import { useAilyAgent } from '../../providers';
import { AgentJsonData, Screen } from '../../types/agentJsonData';
import { getAgentAudioPath } from '../../utils/getAgentAudioPath';
import { AgentElementRenderer } from '../AgentElementRenderer/AgentElementRenderer';
import { Popup, PopupProps } from '../Popup';
import { PopupNextButton } from './PopupNextButton';
import { PopupPrevButton } from './PopupPrevButton';

enum PopupControlOptions {
  NEXT = 'NEXT',
  PREV = 'PREV',
}

// Popup content component
const PopupContent: React.FC<{
  screen: Screen;
  titleRef?: React.Ref<HTMLElement>;
  agentJsonData: AgentJsonData;
  decision: T.StoryDecision | null;
  textElementStyle?: React.CSSProperties;
  onModalClose: () => void;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}> = ({
  screen,
  titleRef,
  agentJsonData,
  decision,
  textElementStyle,
  onModalClose,
  onAgentClose,
  onBackdropClose,
}) => {
  return (
    <Box ref={titleRef} flex={2}>
      <Stack flex={1}>
        {screen.content?.map(({ elements: row }, rowIndex) => (
          <Stack key={`row-${rowIndex}`} spacing={2} flex={1}>
            {row?.map((col, colIndex) => (
              <Stack key={`col-${rowIndex}-${colIndex}`} flexDirection="row">
                {col.map((elements, elementsIndex) => (
                  <Stack key={`elements-${elementsIndex}`} flex={1}>
                    {elements.map((element, elementKey) => (
                      <AgentElementRenderer
                        key={elementKey}
                        element={element}
                        screen={screen}
                        titleRef={titleRef}
                        agentJsonData={agentJsonData}
                        decision={decision}
                        textElementStyle={textElementStyle}
                        onModalClose={onModalClose}
                        onAgentClose={onAgentClose}
                        onBackdropClose={onBackdropClose}
                      />
                    ))}
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

// Popup component that includes the PopupContent
export const AgentDecisionLeversPopup: React.FC<
  PopupProps & {
    screens: Screen[];
    baseAudioURL: string;
    agentJsonData: AgentJsonData;
    decision: T.StoryDecision | null;
    textElementStyle?: React.CSSProperties;
    onAgentClose?: () => void;
    onBackdropClose?: (forceRefetch: boolean) => void;
  }
> = ({
  screens,
  title,
  open,
  onClose,
  baseAudioURL,
  agentJsonData,
  decision,
  textElementStyle,
  onAgentClose,
  onBackdropClose,
  ...rest
}) => {
  const [popupPage, setPopupPage] = useState(0);
  const { startOperations, exit } = useAilyAgent();
  const refs = useRef(new Array(screens?.length).fill(null));
  const [refsReady, setRefsReady] = useState(false);
  const memoizedScreens = useDeepCompareMemoize(screens);

  const { state } = useSlideshow();
  const { audioEnabled } = state;

  const ITEMS_PER_POPUP_PAGE = 2;

  const handleClose = useCallback(() => {
    exit();
    onClose?.();
    setPopupPage(0);
  }, [onClose, exit]);

  const popupChunkedPages = useMemo(() => {
    const slides = (screens || []).reduce(
      (carry, { is_default, ...screen }) =>
        is_default ? [...carry, { is_default, ...screen }] : carry,
      new Array<Screen>(),
    );
    return chunk(slides, ITEMS_PER_POPUP_PAGE);
  }, [screens]);

  const handleSetPopupPage = useCallback(
    (control: PopupControlOptions) => {
      if (control === PopupControlOptions.NEXT && popupPage < popupChunkedPages.length - 1) {
        setPopupPage(popupPage + 1);
      }

      if (control === PopupControlOptions.PREV && popupPage > 0) {
        setPopupPage(popupPage - 1);
      }

      exit();
    },
    [popupPage, popupChunkedPages, exit],
  );

  // Function to handle setting of refs
  const setRef = (node: HTMLElement | null, index: number) => {
    refs.current[index] = node;
    // Check if all refs are set
    if (refs.current.every((ref) => ref !== null) && !refsReady) {
      setRefsReady(true); // Set flag to prevent re-triggering
    }
  };

  useEffect(() => {
    if (!refsReady || !audioEnabled) {
      return;
    }

    const operations = refs.current.reduce((acc, ref, index) => {
      // this calculation is required because screens are grouped by 2 and index is iterating only 0 and 1
      const screen = memoizedScreens[index + popupPage * 2];

      if (open && screen?.audio_track) {
        acc.push({
          audioPath: getAgentAudioPath(baseAudioURL, screen.audio_track),
          elementRef: ref,
          delayAfterEnd: 1000,
          corner: 'top-right',
        });
      }

      return acc;
    }, [] as AilyAgentOperation[]);

    if (operations.length) {
      startOperations(operations);
    }

    return () => {
      exit();
    };
  }, [
    refsReady,
    memoizedScreens,
    audioEnabled,
    exit,
    startOperations,
    popupPage,
    open,
    baseAudioURL,
  ]);

  if (!screens) return null;

  return (
    <Popup
      open={open}
      onClose={handleClose}
      disablePortal
      title={<Typography variant="h5">{title}</Typography>}
      // we control the size of the popup because currently we have a lot of fixed images
      // when images are removed, this could be changed if necessary
      width={popupChunkedPages[popupPage].length * 490}
      {...rest}
    >
      <PopupPrevButton
        sx={{ marginLeft: '-30px' }}
        onClick={() => handleSetPopupPage(PopupControlOptions.PREV)}
        isDisabled={popupPage === 0}
      />
      <Stack
        direction="row"
        divider={<NeonDivider color="success" orientation="vertical" flexItem />}
        spacing={5}
        sx={{ width: '100%' }}
        flex={2}
      >
        {popupChunkedPages[popupPage].map((screen, screenIdx) => {
          return (
            <PopupContent
              key={screenIdx}
              screen={screen}
              titleRef={(node) => setRef(node, screenIdx)}
              agentJsonData={agentJsonData}
              decision={decision}
              textElementStyle={textElementStyle}
              onModalClose={handleClose}
              onAgentClose={onAgentClose}
              onBackdropClose={onBackdropClose}
            />
          );
        })}
      </Stack>
      <PopupNextButton
        sx={{ marginRight: '-30px' }}
        onClick={() => handleSetPopupPage(PopupControlOptions.NEXT)}
        isDisabled={popupPage === popupChunkedPages.length - 1}
      />
    </Popup>
  );
};
