import React from 'react';

export function lineClamp(
  lineCount: number,
  additionalProps?: React.CSSProperties,
): React.CSSProperties {
  return {
    display: '-webkit-box',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    WebkitBoxPack: 'start',
    WebkitBoxAlign: 'start',
    WebkitLineClamp: lineCount,
    ...additionalProps,
  };
}

export function ellipsis(additionalProps?: React.CSSProperties): React.CSSProperties {
  return {
    display: 'inline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...additionalProps,
  };
}
