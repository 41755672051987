import { Box, BoxProps } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';

export interface DotsProps extends BoxProps {
  count: number;
  index: number;
  style?: React.CSSProperties;
  onDotClick?: (index: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  dotSize?: number;
  spacing?: number;
  transitionDuration?: number;
}

const transition = 'all 200ms cubic-bezier(0.645, 0.045, 0.355, 1)';

const defaultConfig = {
  dotSize: 8,
  spacing: 8,
  transitionDuration: 200,
};

const DotContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'count' && prop !== 'dotSize' && prop !== 'spacing',
})<{ count: number; dotSize: number; spacing: number }>(({ theme, count, dotSize, spacing }) => ({
  position: 'relative',
  padding: theme.spacing(2, 0, 3),
  width: count * dotSize + (count + 1) * spacing,
}));

const DotOuter = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dotSize',
})<{ dotSize: number }>(({ dotSize }) => ({
  position: 'absolute',
  width: dotSize,
  height: dotSize,
}));

const Dot = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'dotSize',
})<{ dotSize: number }>(({ theme, dotSize }) => ({
  width: dotSize,
  height: dotSize,
  background: theme.palette.text.primary,
  transition,
  borderRadius: '50%',
  opacity: 0.5,
}));

const DotSlider = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'dotSize',
})<{ dotSize: number }>(({ theme, dotSize }) => ({
  position: 'absolute',
  borderRadius: dotSize,
  height: dotSize,
  background: theme.palette.text.primary,
  transition,
}));

const Dots: React.FC<DotsProps> = ({
  count,
  index,
  style = {},
  onDotClick,
  dotSize = defaultConfig.dotSize,
  spacing = defaultConfig.spacing,
  transitionDuration = defaultConfig.transitionDuration,
  ...rest
}) => {
  const [previousIndex, setPreviousIndex] = useState(index);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (index !== previousIndex) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setPreviousIndex(index);
        timeoutRef.current = null;
      }, transitionDuration);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [index, previousIndex, transitionDuration]);

  const handleDotClick = (i: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onDotClick?.(i, event);
  };

  const isActive = (i: number) =>
    i >= Math.min(previousIndex, index) && i <= Math.max(previousIndex, index);

  return (
    <DotContainer style={style} count={count} dotSize={dotSize} spacing={spacing} {...rest}>
      {[...Array(count).keys()].map((i) => (
        <DotOuter
          key={i}
          dotSize={dotSize}
          style={{
            left: i * (dotSize + spacing) + spacing,
            cursor: onDotClick ? 'pointer' : 'inherit',
          }}
          onClick={(event) => handleDotClick(i, event)}
        >
          <Dot dotSize={dotSize} elevation={0} style={{ opacity: isActive(i) ? 0 : 0.5 }} />
        </DotOuter>
      ))}
      <DotSlider
        dotSize={dotSize}
        elevation={0}
        style={{
          left: Math.min(previousIndex, index) * (dotSize + spacing) + spacing,
          width: Math.abs(previousIndex - index) * (dotSize + spacing) + dotSize,
        }}
      />
    </DotContainer>
  );
};

export default Dots;
