import { Box, styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';

export interface ResponsiveScaleContainerProps {
  children: React.ReactNode;
  containerWidth: number;
  containerHeight: number;
  minScale?: number;
  maxScale?: number;
}

const ContainerWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const StyledContainer = styled(Box)({
  transformOrigin: 'top center',
  // Dynamic scale adjustment using CSS variable
  transform: 'scale(var(--scale-ratio, 1))',
});

export const ResponsiveScaleContainer: React.FC<ResponsiveScaleContainerProps> = ({
  children,
  containerWidth,
  containerHeight,
  minScale = 0.5,
  maxScale = 1,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const scaleWidth = window.innerWidth / containerWidth;
        const scaleHeight = window.innerHeight / containerHeight;
        const scale = Math.min(Math.max(Math.min(scaleWidth, scaleHeight), minScale), maxScale);
        containerRef.current.style.setProperty('--scale-ratio', scale.toString());
      }
    };

    // Observe the document body for size changes
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.body);

    // Trigger initial resize handling and set up observer
    handleResize();

    // Clean up the observer on component unmount
    return () => resizeObserver.disconnect();
  }, [containerWidth, containerHeight, minScale, maxScale]);

  return (
    <ContainerWrapper>
      <StyledContainer ref={containerRef} width={containerWidth} height={containerHeight}>
        {children}
      </StyledContainer>
    </ContainerWrapper>
  );
};
