import React from 'react';

import { Checkbox } from '../Checkbox';
import { Radio } from '../Radio';

export interface SelectionControlProps {
  selected?: boolean;
  multiSelect?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const SelectionControl: React.FC<SelectionControlProps> = ({
  selected,
  multiSelect,
  onClick,
}) => {
  const Control = multiSelect ? Checkbox : Radio;
  return <Control checked={selected} onClick={onClick} />;
};
