import { Typography } from '@mui/material/styles/createTypography';

// Custom typographic styles taken from the Figma design
export const typography = {
  h1: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 72,
    lineHeight: '72px',
  },
  h2: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 64,
    lineHeight: '56px',
  },
  h3: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 48,
    lineHeight: '42px',
  },
  h4: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 36,
    lineHeight: '36px',
  },
  h5: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 32,
    lineHeight: '38px',
  },
  h6: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '38px',
  },
  h7: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  h8: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '28px',
  },
  h9: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '20px',
  },
  body: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '22px',
  },
  bodyLarge: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '28px',
  },
  bodyTight: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '18px',
  },
  bodyBold: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '22px',
  },
  bodyBoldTight: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '18px',
  },
  subtitle: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '14px',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
  small: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '14px',
  },
  smallBold: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '14px',
  },
  xSmall: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '12px',
  },
  xSmallBold: {
    fontFamily: 'SF-Pro-Display',
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 10,
    lineHeight: '12px',
  },
  moduleText: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '22px',
  },
  moduleSwitchMedium: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 28,
    lineHeight: '32px',
  },
  moduleSwitchRegular: {
    fontFamily: 'Ubuntu',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '32px',
  },
} as Typography;
