import { useCallback, useState } from 'react';

import {
  clearLocalUserSettings,
  getLocalUserSettings,
  LocalUserSettings,
  updateLocalUserSettings,
} from '@/core/services/localUserSettings';

/**
 * Custom hook for managing local user settings.
 * Provides functionality to retrieve, update, and clear user settings stored locally.
 *
 * @returns {object} An object containing:
 *   - `settings`: The current user settings or `undefined` if no settings are found.
 *   - `updateSettings`: Function to update settings with new partial values.
 *   - `clearSettings`: Function to clear all local user settings.
 */
export function useLocalUserSettings(): {
  settings: LocalUserSettings | undefined;
  updateSettings: (newSettings: Partial<LocalUserSettings>) => void;
  clearSettings: () => void;
} {
  const [settings, setSettings] = useState(getLocalUserSettings());

  const updateSettings = useCallback((newSettings: Partial<LocalUserSettings>) => {
    updateLocalUserSettings(newSettings);
    setSettings(getLocalUserSettings());
  }, []);

  const clearSettings = useCallback(() => {
    clearLocalUserSettings();
    setSettings(undefined);
  }, []);

  return { settings, updateSettings, clearSettings };
}
