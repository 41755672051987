import { Box, Stack, styled } from '@mui/material';
import React from 'react';

import { colors } from '../../theme/default/colors';
import { GradientBox } from '../GradientBox';
import { StoryViewProps } from '../StorySlider/StorySliderNew';

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '100%',
  padding: theme.spacing(8, 5.5),
}));

const MediaStory: React.FC<StoryViewProps> = ({ children }) => {
  return (
    <GradientBox
      width="100%"
      height="100%"
      borderWidth={1}
      borderRadius={0}
      borderColor={colors.primary.aqua}
      background={`linear-gradient(64.44deg, ${colors.primary.aqua} 0%, #0c3a41 6.13%, #111521 22.2%)`}
    >
      <ContentBox>
        <Stack direction="column" spacing={4} sx={{ width: '100%' }}>
          {children}
        </Stack>
      </ContentBox>
    </GradientBox>
  );
};

export default MediaStory;
