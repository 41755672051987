import * as T from '@aily/graphql-sdk/schema';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

export type DimensionFilterType = T.SelectFilter | T.TreeSelectFilter;

export interface DimensionFilterContextState {
  dimensionId?: number;
  dimensionFilter?: DimensionFilterType;
  setDimensionId?: (dimensionId?: number) => void;
}

export type DimensionFilterProviderProps = PropsWithChildren<DimensionFilterContextState>;

export const DimensionFilterContext = createContext<DimensionFilterProviderProps | undefined>(
  undefined,
);

export const DimensionFilterProvider = ({
  dimensionFilter,
  dimensionId,
  setDimensionId,
  children,
}: DimensionFilterProviderProps) => {
  const value = useMemo(
    () => ({
      dimensionFilter,
      dimensionId,
      setDimensionId,
    }),
    [dimensionFilter, dimensionId, setDimensionId],
  );

  return (
    <DimensionFilterContext.Provider value={value}>{children}</DimensionFilterContext.Provider>
  );
};

export function useRowDimensionFilter() {
  return useContext(DimensionFilterContext);
}
