import React from 'react';

/**
 * Checks if a given React node is a specific component type.
 */
export function isComponentType<T>(
  child: React.ReactNode,
  component: React.ComponentType<T>,
): child is React.ReactElement<T> {
  return React.isValidElement<T>(child) && child.type === component;
}
