import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

export interface MapGridLayoutProps extends BoxProps {}

const GridLayoutBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr 1.3fr',
  gap: theme.spacing(5),
  padding: theme.spacing(4, 0),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

export const MapContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: 300,
  },
}));

export const MapGridLayout: React.FC<MapGridLayoutProps> = ({ children, ...rest }) => (
  <GridLayoutBox {...rest}>{children}</GridLayoutBox>
);
