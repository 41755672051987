import { Box } from '@mui/material';
import React from 'react';

import { ElementType, Screen } from '../../types/agentJsonData';
import { AgentContentRenderer } from '../AgentContentRenderer/AgentContentRenderer';
import AgentTextElement from '../AgentScreenItems/AgentTextElement';

interface AgentDefaultProps {
  screen: Screen;
}

const AgentDefault: React.FC<AgentDefaultProps> = ({ screen }) => {
  return (
    <AgentContentRenderer content={screen.content}>
      {(elements) =>
        elements.map((element, elementKey) => (
          <Box key={elementKey}>
            {element.type === ElementType.Text && <AgentTextElement element={element} />}
          </Box>
        ))
      }
    </AgentContentRenderer>
  );
};

export default AgentDefault;
