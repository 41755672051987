import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowCircleDownThick: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="33" height="32" viewBox="0 0 33 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.98968 22.729C5.05216 21.247 4.44115 19.5689 4.20723 17.8103C3.90307 15.5236 4.24916 13.1962 5.20464 11.1029C6.16012 9.00961 7.68509 7.23782 9.5995 5.99673C11.5139 4.75562 13.7378 4.09704 16.0087 4.09872C18.2795 4.10041 20.5025 4.76229 22.4151 6.00623C24.3278 7.25017 25.8502 9.02422 26.8027 11.1189C27.7551 13.2136 28.0979 15.5415 27.7904 17.8278C27.554 19.5861 26.9406 21.2632 26.0009 22.7439C25.9076 22.8909 25.8111 23.036 25.7114 23.179C25.6807 23.2232 25.6531 23.2689 25.6289 23.3159C25.3887 23.7806 25.4664 24.3658 25.8677 24.7124C26.3095 25.094 26.9757 25.0415 27.3171 24.5652C27.3535 24.5144 27.3895 24.4634 27.4252 24.4123C27.5499 24.2333 27.6704 24.0516 27.7866 23.8674C28.8803 22.1335 29.5947 20.1722 29.8711 18.1167C30.2328 15.427 29.8296 12.6883 28.709 10.2239C27.5885 7.75955 25.7974 5.67243 23.5472 4.20897C21.2971 2.74552 18.6818 1.96683 16.0102 1.96484C13.3386 1.96286 10.7223 2.73767 8.47 4.19779C6.21775 5.65791 4.42367 7.74237 3.29958 10.2051C2.17548 12.6678 1.76832 15.4059 2.12616 18.0961C2.39962 20.152 3.11115 22.1144 4.20237 23.8499C4.31832 24.0343 4.43855 24.2162 4.56301 24.3953C4.59861 24.4465 4.63456 24.4976 4.67086 24.5484C5.01155 25.0252 5.67768 25.0787 6.1201 24.6977C6.52191 24.3517 6.60043 23.7667 6.36091 23.3016C6.33671 23.2546 6.30925 23.2088 6.27855 23.1646C6.17907 23.0214 6.08276 22.8762 5.98968 22.729Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5604 25.8281C22.7368 26.5435 21.8262 27.1407 20.8513 27.602C19.3231 28.3251 17.6708 28.6986 16 28.6986C14.3292 28.6986 12.6769 28.3251 11.1487 27.602C10.1738 27.1407 9.26316 26.5435 8.43959 25.8281C8.24549 25.6595 8.05622 25.4844 7.87209 25.3028C7.83261 25.2639 7.79113 25.2283 7.74803 25.1959C7.32161 24.8758 6.73575 24.8795 6.34301 25.2621C5.91057 25.6832 5.87639 26.4034 6.30117 26.8334C6.34643 26.8792 6.39195 26.9247 6.43775 26.9698C6.65627 27.1852 6.88094 27.393 7.11139 27.593C8.07872 28.4323 9.14801 29.1331 10.2926 29.6747C12.0904 30.5254 14.0344 30.9648 16 30.9648C17.9656 30.9648 19.9096 30.5254 21.7074 29.6747C22.852 29.1331 23.9213 28.4323 24.8886 27.593C25.1191 27.3931 25.3437 27.1852 25.5622 26.9698C25.608 26.9247 25.6536 26.8792 25.6988 26.8334C26.1236 26.4034 26.0894 25.6832 25.657 25.2621C25.2643 24.8796 24.6784 24.8758 24.252 25.1959C24.2089 25.2283 24.1674 25.2639 24.1279 25.3028C23.9438 25.4844 23.7545 25.6595 23.5604 25.8281Z"
      fill="#FF3469"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.437 23.0158C15.5889 23.1738 15.7971 23.2563 16.008 23.2518C16.2151 23.2562 16.4239 23.1772 16.5789 23.0158L18.994 20.5029C19.2882 20.1967 19.2786 19.7099 18.9724 19.4157C18.6662 19.1214 18.1794 19.1311 17.8852 19.4373L16.7603 20.6077L16.7603 11.1257C16.7603 10.7011 16.4161 10.3568 15.9914 10.3568C15.5667 10.3568 15.2225 10.7011 15.2225 11.1257L15.2225 20.5732L14.1308 19.4372C13.8365 19.131 13.3497 19.1214 13.0436 19.4156C12.7374 19.7099 12.7277 20.1967 13.022 20.5028L15.437 23.0158Z"
      fill="#FF3469"
    />
  </SvgIcon>
);
