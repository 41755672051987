import { Box, BoxProps, styled } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import React from 'react';

import { useSlideshow } from './SlideshowContext';

export interface SlideshowSlideProps extends BoxProps {
  index: number;
}

export const FadeIn = (props: MotionProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    {...props}
  />
);

const SlideContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

export const SlideshowSlide: React.FC<SlideshowSlideProps> = ({ index, children, ...rest }) => {
  const { state } = useSlideshow();
  const isActive = index === state.currentSlide;

  return (
    isActive && (
      <FadeIn>
        <SlideContainer data-testid="slideshow__slide" {...rest}>
          {children}
        </SlideContainer>
      </FadeIn>
    )
  );
};
