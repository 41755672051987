import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      console.error(`[GraphQL error]:`, graphQLError);

      Sentry.withScope((scope) => {
        scope.setTag('operationName', operation.operationName);
        scope.setExtra('query', operation.query.loc);
        scope.setExtra('variables', operation.variables);

        if (graphQLError.path) {
          scope.addBreadcrumb({
            category: 'queryPath',
            message: graphQLError.path.join('.'),
            level: 'debug',
          });
        }

        const error = new Error();
        error.name = '[GraphQL error]';
        error.message = (graphQLError.extensions?.message as string) ?? graphQLError.message;
        error.stack = graphQLError.extensions?.stackTrace as string;

        Sentry.captureException(error);
      });
    });
  }

  if (networkError) {
    console.error(`[Network error]:`, networkError);

    const error = new Error();
    error.name = '[Network error]:';
    error.message = networkError.message;

    Sentry.captureException(error);
  }
});
