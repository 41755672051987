import H from '../../config/highchartsConfig';
import { translateSeriesColor } from '..';

/**
 * Custom formatter function that takes into account the numeric symbols provided in the `lang` settings
 *
 * @param value
 * @param decimals
 */
export function customNumberFormat(value: number, decimals: number) {
  // Get the language options from the Highcharts library
  const langOptions = H.getOptions().lang;

  // Get the numeric symbols array from the language options, or use an empty array if it's not defined
  const symbols = langOptions?.numericSymbols ?? [];

  // Loop through the symbols array from largest to smallest
  for (let i = symbols.length - 1; i >= 0; i--) {
    // Get the absolute value of the input value
    const absValue = Math.abs(value);
    const symbol = symbols[i];
    // This formula calculates the value 1000 raised to the power of (i + 1), which gives us
    // the threshold for the current symbol. The threshold represents the largest value for
    // which the current symbol should be used in formatting the input value.
    const threshold = 1e3 ** (i + 1);

    // If the absolute value is greater than or equal to the threshold, and the symbol is defined,
    // format the value with the symbol and return it
    if (absValue >= threshold && symbol) {
      return `${H.numberFormat(value / threshold, decimals)}${symbol}`;
    }
  }

  // If no symbol is found that matches the value, use the default number formatting
  return H.numberFormat(value, decimals, langOptions?.decimalPoint, langOptions?.thousandsSep);
}

/**
 * This function draws a dashed line with a color gradient between the columns in the waterfall chart (according to Figma design)
 *
 * @param {H.Chart} chart
 */
export function renderCustomLineGroup(chart: H.Chart & { customLineGroup?: H.SVGElement }) {
  const waterfallSeries = chart.series.find((series) => series.options.type === 'waterfall');
  if (!waterfallSeries) {
    return;
  }

  // Remove previously drawn group and its contents
  chart.customLineGroup?.destroy();

  // Create a new group for custom lines with a high zIndex
  chart.customLineGroup = chart.renderer.g('custom-line-group').attr({ zIndex: 2 }).add();

  waterfallSeries.data.forEach((p, index) => {
    // Extend the Highcharts types with the missing fields used in this function
    const point = p as H.Point & { negative?: boolean };
    const nextPoint = point.series.data[index + 1] as typeof point | undefined;
    const seriesOptions = point.series.options as Exclude<
      H.SeriesOptionsType,
      H.SeriesTiledwebmapOptions
    > & {
      upColor?: H.ColorType;
    };

    // Draws a line only from the current point to the next point
    if (point.shapeArgs && nextPoint?.shapeArgs) {
      const pointColor = point.negative ? seriesOptions.color : seriesOptions.upColor;
      const nextPointColor = nextPoint?.negative ? seriesOptions.color : seriesOptions.upColor;

      const startColor = pointColor
        ? translateSeriesColor(
            pointColor,
            // The negative point line starts at the bottom of the current point,
            // so the end color of the current point's gradient is used
            point?.negative ? 1 : 0,
          )
        : undefined;

      const endColor = nextPointColor
        ? translateSeriesColor(
            nextPointColor,
            // The negative point line end at the top of the next point,
            // so the start color of the next point's gradient is used
            nextPoint?.negative ? 0 : 1,
          )
        : undefined;

      // This places the line right at the top of the column (measured by hand)
      const dy: number = 9;
      const y = point.negative
        ? // For negative data points, the line starts at the bottom
          point.shapeArgs.y + point.shapeArgs.height + dy - 1
        : point.shapeArgs.y + dy;

      const x1 = point.shapeArgs.x - point.shapeArgs.width * 0.15;
      const x2 =
        nextPoint.shapeArgs.x + nextPoint.shapeArgs.width + nextPoint.shapeArgs.width * 0.15;

      const gradientId = `${point.series.index}-${index}-gradient`;

      const defs = chart.renderer.createElement('defs');
      const linearGradient = chart.renderer
        .createElement('linearGradient')
        .attr('id', gradientId)
        .attr('x1', x1)
        .attr('y1', y)
        .attr('x2', x2)
        .attr('y2', y)
        .attr('gradientUnits', 'userSpaceOnUse')
        .add(defs);

      chart.renderer
        .createElement('stop')
        .attr('offset', 1 / 3)
        .attr('stop-color', startColor)
        .add(linearGradient);

      chart.renderer
        .createElement('stop')
        .attr('offset', 2 / 3)
        .attr('stop-color', endColor)
        .add(linearGradient);

      defs.add(chart.customLineGroup);

      chart.renderer
        .path(['M', x1, y, 'L', x2, y])
        .attr({
          stroke: `url(#${gradientId})`,
          'stroke-width': 2,
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
          'stroke-dasharray': '6 6',
        })
        .add(chart.customLineGroup);
    }
  });
}
