import * as H from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';

import theme from '../theme/default';
import { colors } from '../theme/default/colors';
import { typography } from '../theme/default/typography';

type ExtendedSeries = H.Series & {
  color: string | H.GradientColorObject | H.PatternObject;
};

type ExtendedSVGAttributes = H.SVGAttributes & {
  x: number;
  width: number;
};

type LowMedHighGraphicPoint = H.Point & {
  shapeArgs: Pick<H.Point, 'shapeArgs'> & ExtendedSVGAttributes;
  graphic: SVGElement;
  highPlot: number;
  medianPlot: number;
  lowPlot: number;
};

// Initialize HighchartsMore extension
HighchartsMore(H);

// @ts-expect-error: seriesTypes is not defined in the Highcharts types
// eslint-disable-next-line import/namespace
H.seriesTypes.line.prototype.drawLegendSymbol = H.seriesTypes.area.prototype.drawLegendSymbol;

H.SVGRenderer.prototype.symbols['triangle-down-top'] = (
  x: number,
  y: number,
  w: number,
  h: number,
) => {
  const newY = y - h / 2;
  return ['M', x, newY, 'L', x + w, newY, 'L', x + w / 2, newY + h, 'Z'];
};

/**
 * Define custom series type for displaying low/median/high values using boxplot as a base
 */
H.seriesType(
  'lowmedhigh',
  'boxplot',
  {
    keys: ['low', 'median', 'high'],
  },
  {
    /**
     * Reshape graphic points to a segment with three crossing lines for low/median/high
     */
    drawPoints() {
      const series = this as ExtendedSeries;
      const points = series.getValidPoints(series.points) as LowMedHighGraphicPoint[];

      points.forEach((point) => {
        const { shapeArgs } = point;

        if (!shapeArgs) {
          return;
        }

        if (!point.graphic) {
          // @ts-expect-error Type 'string' is not assignable to parameter of type 'SVGPathArray'.
          point.graphic = series.chart.renderer.path('point').add(series.group);
        }

        // Extract coordinates for rendering
        const { x, width } = shapeArgs;
        const left = Math.floor(x);
        const right = left + width;
        const crispX = left + Math.round(width / 2);
        const lowPlotDelta = point.low === 0 ? 1 : 0; // Ensure low marker drawing in cases where the low plot is 0

        const highPlot = Math.floor(point.highPlot);
        const medianPlot = Math.floor(point.medianPlot);
        const lowPlot = Math.floor(point.lowPlot) - lowPlotDelta;

        // Build up the path for the point (three horizontal lines and one vertical)
        const path: Highcharts.SVGPathArray = [
          ['M', left, highPlot],
          ['H', right], // High plot line
          ['M', left, medianPlot],
          ['H', right], // Median plot line
          ['M', left, lowPlot],
          ['H', right], // Low plot line
          ['M', crispX, highPlot],
          ['V', lowPlot], // Vertical line from high to low
        ];

        point.graphic.attr({
          d: path,
          stroke: point.color || series.color,
          'stroke-width': 1,
        });
      });
    },
  },
);

export const defaultOptions = {
  colors: colors.graphs,
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
    numericSymbols: ['K', 'M', 'B'],
  },
  title: {
    text: '',
  },
  chart: {
    colorCount: colors.graphs.length,
    spacing: [8, 0, 8, 0],
    width: null,
    height: null,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    plotBorderColor: 'transparent',
    style: {
      fontFamily: theme.typography.fontFamily,
    },
    animation: {
      duration: 350,
      easing: 'easeInOut',
    },
  },
  plotOptions: {
    series: {
      lineWidth: 2,
      borderRadius: {
        radius: 0,
      },
      marker: {
        symbol: 'circle',
        radius: 0.1,
        // Inherits the series color
        lineColor: undefined,
      },
      states: {
        hover: {
          enabled: true,
          lineWidthPlus: 0,
        },
        inactive: {
          opacity: 1,
        },
      },
      animation: {
        duration: 350,
        easing: 'easeInOut',
      },
    },
    column: {
      stacking: 'normal',
    },
  },
  xAxis: {
    lineWidth: 0,
    tickWidth: 0,
    labels: {
      style: {
        ...typography.small,
        color: theme.palette.grey[300],
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    opposite: true,
    allowDecimals: true,
    gridLineColor: theme.palette.grey[100],
    labels: {
      style: {
        ...typography.small,
        color: theme.palette.grey[300],
      },
    },
  },
  legend: {
    margin: 20,
    symbolWidth: 7,
    symbolHeight: 7,
    symbolPadding: 4,
    itemDistance: 20,
    itemStyle: {
      ...typography.small,
      color: theme.palette.text.primary,
      transform: 'translateY(2px)',
    },
    itemHoverStyle: {
      color: undefined,
    },
    itemHiddenStyle: {
      color: theme.palette.grey[200],
      textDecoration: 'none',
    },
  },
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
} as H.Options;

// Set global options
H.setOptions(defaultOptions);

export default H;
