import {
  GetDimensionFilterDocument,
  GetDimensionFilterQuery,
  GetDimensionFilterQueryResult,
} from '@aily/graphql-sdk/core';
import {
  isSelectFilter,
  isTreeSelectFilter,
  QueryDimensionFilterArgs,
} from '@aily/graphql-sdk/schema';
import { client } from '@aily/saas-graphql-client';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  fetchDimensionFilterFailure,
  fetchDimensionFilterSuccess,
  getDimensionFilter,
  priorityFilterChanged,
} from './slices';

function* onPriorityFilterChange() {
  yield takeLatest(priorityFilterChanged, handleFetchDimensionFilterRequest);
}

// Workers
function* handleFetchDimensionFilterRequest({
  payload: { moduleId, priorityId },
}: ReturnType<typeof priorityFilterChanged>) {
  const dimensionFilter = (yield select(getDimensionFilter(moduleId, priorityId))) as ReturnType<
    ReturnType<typeof getDimensionFilter>
  >;

  if (dimensionFilter) {
    return;
  }

  const query = client.query<GetDimensionFilterQuery, QueryDimensionFilterArgs>;
  try {
    const { data, error } = (yield call(query, {
      query: GetDimensionFilterDocument,
      variables: { moduleId, priorityId },
    })) as GetDimensionFilterQueryResult;

    if (error) {
      yield put(fetchDimensionFilterFailure(error));
    } else if (
      !data?.dimensionFilter ||
      (!isSelectFilter(data.dimensionFilter) && !isTreeSelectFilter(data.dimensionFilter))
    ) {
      yield put(fetchDimensionFilterFailure(new Error('Unable to fetch priority filter')));
    } else {
      yield put(
        fetchDimensionFilterSuccess({
          moduleId,
          priorityId,
          dimensionFilter: data.dimensionFilter,
        }),
      );
    }
  } catch (e) {
    yield put(fetchDimensionFilterFailure(e as Error));
  }
}

export default [onPriorityFilterChange];
