import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ChevronRight: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" data-testid="chevron-right" {...props}>
    <path
      d="M10.3311 20.6998L16.7611 14.2698L10.3311 7.83984"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
