import { ScaleLinear } from 'd3';
import React, { createContext, PropsWithChildren, useContext } from 'react';

import { TimelineAxisProps, TooltipState } from './Timeline';

interface TimelineContextState {
  axis: TimelineAxisProps;
  xScale: ScaleLinear<number, number>;
  setTooltip: React.Dispatch<React.SetStateAction<TooltipState>>;
  name?: string;
}

export const TimelineContext = createContext<TimelineContextState | null>(null);

export const TimelineContextProvider: React.FC<PropsWithChildren<TimelineContextState>> = ({
  children,
  ...rest
}) => <TimelineContext.Provider value={rest}>{children}</TimelineContext.Provider>;

export function useTimelineContext() {
  const context = useContext(TimelineContext);

  if (!context) {
    throw new Error('useTimelineContext must be called within TimelineContextProvider');
  }

  return context;
}
