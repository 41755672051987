import { GlobalStyles, styled } from '@mui/material';
import React from 'react';

const FullScreenSubPage = styled('div')(() => ({
  background: 'black',
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  paddingLeft: 61,
  paddingRight: 158,
  overflow: 'scroll',
}));

interface FullscreenSubPageWrapperProps {
  children: React.ReactElement | Array<React.ReactElement>;
}

export const FullscreenSubPageWrapper: React.FC<FullscreenSubPageWrapperProps> = ({ children }) => (
  <FullScreenSubPage>
    <GlobalStyles
      styles={{
        '.ant-tabs-content-holder': {
          zIndex: '10 !important', // Overwrite tabs z-index to display the full scrren page on top of the priority filter (in plai)
        },
      }}
    />
    {children}
  </FullScreenSubPage>
);
