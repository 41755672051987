import { Module } from '@aily/graphql-sdk/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/rootReducer';

import { ModulesState } from './types';
import { normalizeModules } from './utils';

const initialState: ModulesState = {
  modules: [],
  currentModule: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    fetchModulesRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchModulesFailure: (state, action: PayloadAction<Error>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchModulesSuccess: (state, action: PayloadAction<Module[]>) => {
      state.modules = normalizeModules(action.payload);
      state.loading = false;
    },
    moduleChanged: (state, action: PayloadAction<Module>) => {
      state.currentModule = action.payload;
    },
  },
});

export const getModules = (state: RootState) => state.modules.modules;
export const getCurrentModule = (state: RootState) => state.modules.currentModule;
export const getModulesLoading = (state: RootState) => state.modules.loading;
export const getModulesError = (state: RootState) => state.modules.error;

export const { fetchModulesRequest, fetchModulesFailure, fetchModulesSuccess, moduleChanged } =
  slice.actions;

export default slice.reducer;
