import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { MarkdownRenderer } from '../MarkdownRenderer';

export const FooterToolbarDialog = ({
  content,
  title,
  onOk,
  onInfoContentRender,
}: {
  content?: string;
  title?: string;
  onOk?: () => void;
  onInfoContentRender?: () => React.ReactElement;
}) => (
  <Dialog open PaperProps={{ sx: { width: '100%', minWidth: 300 } }}>
    {!!title && <DialogTitle data-testid="DialogTitle">{title}</DialogTitle>}
    {!!content && (
      <DialogContent data-testid="DialogContent">
        <MarkdownRenderer>{content}</MarkdownRenderer>
      </DialogContent>
    )}
    {!!onInfoContentRender && (
      <DialogContent data-testid="DialogContent">{onInfoContentRender()}</DialogContent>
    )}
    <DialogActions>
      <Button onClick={onOk} variant="contained" data-testid="OkButton">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
