import { useSearchParams } from '@aily/saas-core';
import { useMemo } from 'react';

export function usePersistentParams(persistentParams: string[]) {
  const searchParams = useSearchParams();
  // Construct a URLSearchParams object with only the persistent search parameters,
  // or the original parameters if none are specified
  const newSearchParams = useMemo(() => {
    if (persistentParams) {
      return new URLSearchParams(
        Array.from(searchParams).filter(([paramName]) => persistentParams.includes(paramName)),
      );
    }

    return searchParams;
  }, [searchParams, persistentParams]);

  return newSearchParams;
}
