import { Box, BoxProps, styled } from '@mui/material';
import React, { FC } from 'react';

export interface RecommendationsBoxProps extends BoxProps {}

const StyledBox = styled(Box)(() => ({
  paddingTop: 20,
  paddingBottom: 20,
  marginRight: 5,
}));

export const RecommendationsBox: FC<RecommendationsBoxProps> = ({ children, ...rest }) => (
  <StyledBox {...rest}>{children}</StyledBox>
);
