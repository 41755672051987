import { colors } from '../../theme/default/colors';

export const backgroundColor = 'background.default';
export const color = 'text.primary';
export const positiveTextColor: [string, string] = [colors.primary.green, colors.primary.aqua];
export const negativeTextColor: [string, string] = [colors.primary.pink, 'rgba(254, 44, 99, 0.8)'];
export const headTypography = 'body';
export const bodyTypography = 'h7';
export const gridColumnGap = 40;
export const gridRowGap = 0;
