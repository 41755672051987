import { Components, Theme } from '@mui/material/styles';

export const CircularProgress: Components<Theme>['CircularProgress'] = {
  defaultProps: {
    size: { xs: 154, xl: 170, xxl: 220 },
    thickness: { xs: 10, xl: 12, xxl: 14 },
    color: 'inherit',
    fillType: 'gradient',
    glow: true,
  },
  styleOverrides: {
    root: {},
  },
};
