// chart due to its complexity for now remains untyped. Hopefully its type will change to one that is being used everywhere else outside of agent
import { ChartDataViewAbstractType } from '@aily/saas-core';

import { colors } from '../../../../theme/default/colors';
import { ChartType, Series } from '../../types/agentJsonData';

const defaultLineChart = {
  chart: {
    backgroundColor: 'transparent',
    marginTop: 20,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    lineColor: '#FFFFFF',
    lineWidth: 1,
    labels: {
      style: {
        fontSize: '18px',
        fontWeight: 'light',
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    lineColor: 'rgba(255, 255, 255, 0.6)',
    lineWidth: 0,
    gridLineColor: '#333333',
    gridLineWidth: 2,
    labels: {
      align: 'center',
      style: {
        fontSize: '18px',
        fontWeight: 'light',
        color: 'rgba(255, 255, 255, 0.6)',
      },
    },
    tickAmount: 4,
    opposite: true,
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: false,
      },
      enableMouseTracking: true,
    },
  },
  tooltip: {
    enabled: false,
    backgroundColor: '#2A2A2A',
    borderColor: '#FFFFFF',
    style: {
      fontSize: '16px',
      fontWeight: 'light',
      color: 'rgba(255, 255, 255, 0.6)',
    },
  },
};

export const getYaxisMax = (series: Series[], chartType: string) => {
  if (chartType === 'line' || chartType === 'delta') {
    return null;
  }

  const paddingTop = 10;
  let yAxisMax = 0;
  series.forEach(({ data }) => {
    data.forEach(({ y }) => {
      const value = y.value.raw;

      if (value > yAxisMax) {
        yAxisMax = value;
      }
    });
  });
  return yAxisMax + paddingTop;
};

export const getChartConfig = (height: number) => {
  const chartConfig = {
    ...defaultLineChart,
    chart: {
      ...defaultLineChart.chart,
      type: ChartType.line,
      height: `${height}px`,
    },
  };

  return chartConfig;
};

const chartSeriesColors = [colors.primary.aqua, colors.primary.orange];

export const getChartSeries = (
  chartData: ChartDataViewAbstractType,
  chartType: ChartType,
  options: Highcharts.Options,
) => {
  const { series: seriesConfig } = options;

  // @ts-expect-error due to time constraints, this remains untyped, read comment on top
  const chartSeries = chartData?.series.map((series, index) => ({
    ...seriesConfig,
    name: series.name,
    // @ts-expect-error due to time constraints, this remains untyped, read comment on top
    data: series.data.map((dataPoint, index) => ({
      // @ts-expect-error due to time constraints, this remains untyped, read comment on top
      y: dataPoint?.y?.value.raw,
      // @ts-expect-error due to time constraints, this remains untyped, read comment on top
      ...(series?.marker_format === 'CIRCLE' && {
        marker: {
          // @ts-expect-error due to time constraints, this remains untyped, read comment on top
          enabled: index === chartData.series.length - 1,
          radius: 24,
          fillColor: colors.neutrals.white,
          lineColor: '#00FF66',
          lineWidth: 0,
          symbol: 'circle',
        },
      }),
    })),
    lineWidth: 4,
    color: chartSeriesColors[index],
    visible: true,
  }));

  chartSeries.push({
    // @ts-expect-error due to time constraints, this remains untyped, read comment on top
    type: 'area',
    data: [...chartSeries[0].data],
    marker: {
      enabled: false,
    },
    showInLegend: false,
    fillColor: {
      linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
      stops: [
        [0, 'rgba(72, 255, 155, .2)'],
        [1, 'rgba(72, 255, 155, 0)'],
      ],
    },
  });

  return chartSeries;
};
