import * as T from '@aily/graphql-sdk/schema';
import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';

import { AllHeaderRow } from './HeaderRow';

const transformData = (data: AllHeaderRow): AllHeaderRow => {
  const newData = cloneDeep(data);
  const rows = (newData.rows ?? []) as T.TableRowResult[];

  // Filter out columns that have a mainColumnDataKey
  newData.columns = newData.columns?.filter((c) => !c.mainColumnDataKey);

  const newRow = { cells: [], __typename: 'TableRowResult' } as unknown as T.TableRowResult;

  // Transform rows
  newData.rows = rows.map((row) => {
    row.cells = row.cells?.filter((cell) => {
      const column = data.columns?.find((c) => c.dataKey === cell.columnDataKey);
      if (column?.mainColumnDataKey) {
        // Adjust the cell to have the main column's dataKey
        cell.columnDataKey = column.mainColumnDataKey;
        newRow.cells?.push(cell);
        return false; // Exclude this cell from the current row
      }

      return true; // Include this cell in the current row
    });

    return row;
  });

  if (newRow.cells && newRow.cells.length > 0) {
    newData.rows?.push(newRow);
  }

  return newData;
};

/**
 *
 * The `useHeaderRowTransformedData` hook is a workaround solution that processes
 * table data by utilizing `mainColumnDataKey` mapping to artificially split cell
 * data across two rows, addressing backend limitations where it cannot directly
 * provide two distinct rows each containing different types of cells.
 */
export const useHeaderRowTransformedData = (
  initialData?: AllHeaderRow,
): AllHeaderRow | undefined => {
  return useMemo(() => initialData && transformData(initialData), [initialData]);
};
