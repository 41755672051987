import { Theme } from '@mui/material';
import React from 'react';
import { isMobileSafari, isSafari } from 'react-device-detect';

export const headerRowHeight = 48;

export const baseStickyStyle: React.CSSProperties = {
  position: 'sticky',
  zIndex: 1,
  top: isSafari || isMobileSafari ? 0 : headerRowHeight,
};

export const aggregationRowCellStyle = (theme: Theme): React.CSSProperties => ({
  ...baseStickyStyle,
  ...theme.typography.bodyBold,
  backgroundColor: theme.palette.background.default,
});

export const headerRowCellStyle = (theme: Theme): React.CSSProperties => ({
  ...baseStickyStyle,
  ...theme.typography.bodyBold,
  backgroundColor: theme.palette.divider,
});

export const hoverableRowIconCellStyle: React.CSSProperties = {
  visibility: 'visible',
};
