import { EventTrackingConfig, withEventTracking } from '@aily/analytics-service';
import { useAddToBrainStoryMutation } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { colors } from '@aily/saas-core/theme/default/colors';
import { AilyBrain, CheckIcon } from '@aily-labs/ui';
import { CircularProgress } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { usePage } from '../../hooks';
import { useModule } from '../../providers';
import { getStoryFocusArea } from '../../utils';
import { ActionButton } from './StoryView';

interface AddToBrainButtonProps {
  story: T.Story;
  onAddToBrain?: (story: T.Story) => void;
}

const AddToBrainButton: React.FC<AddToBrainButtonProps> = ({ story, onAddToBrain }) => {
  const location = useLocation();
  const [addToBrain, { loading }] = useAddToBrainStoryMutation();
  const [isAdded, setIsAdded] = useState(!!story.isAddedToBrain);
  const { page } = usePage();
  const module = useModule();

  const handleAddToBrainButtonClick = useCallback(() => {
    const parentPage = module?.pages?.find((p) =>
      matchPath(`${p.absolutePath}/*`, location.pathname),
    );
    const link = `${page?.absolutePath || parentPage?.absolutePath || location.pathname}${location.search}`;

    addToBrain({
      variables: {
        input: {
          storyId: story.id,
          addedToBrain: true,
          link,
        },
      },
      onCompleted: () => setIsAdded(true),
      onError: () => setIsAdded(false),
    });

    onAddToBrain?.(story);
  }, [story, addToBrain, location, module, page, onAddToBrain]);

  return (
    <ActionButton
      variant="secondary"
      startIcon={
        loading ? (
          <CircularProgress size={20} />
        ) : isAdded ? (
          <CheckIcon height={20} width={20} color={colors.neutrals.white} />
        ) : (
          <AilyBrain width={20} height={20} />
        )
      }
      data-testid="AddToBrainButton"
      onClick={handleAddToBrainButtonClick}
      disabled={loading || isAdded}
    >
      {isAdded ? 'Added to brain' : 'Add to brain'}
    </ActionButton>
  );
};

const trackingConfig: EventTrackingConfig<AddToBrainButtonProps> = {
  onAddToBrain: {
    eventName: 'story.clicked',
    getEventProps: (story) => ({
      component: 'story',
      name: story.headline,
      component_id: story.id,
      component_type: story.storyType,
      component_value: 'add_to_brain',
      intent: 'click',
      item_type: 'button',
      module: story.storyDataModule,
      focus_area: getStoryFocusArea(story),
      event_version: '1.0.0',
    }),
  },
};

export default withEventTracking(AddToBrainButton, trackingConfig);
