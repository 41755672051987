import { Box, styled } from '@mui/material';
import React, { useCallback } from 'react';

import { Slideshow, SlideshowProps, useSlideshow } from '../../../../components/Slideshow';
import { SoundControl } from '../../../../components/SoundControl';

export interface AgentSlideshowProps extends SlideshowProps {
  hideSoundControl?: boolean;
}

const StyledSlideshow = styled(Slideshow)(() => ({ paddingTop: 60 }));

export const AgentSlideshow: React.FC<AgentSlideshowProps> = ({
  children,
  hideSoundControl,
  ...rest
}) => {
  const { state, dispatch } = useSlideshow();
  const { audioEnabled } = state;

  const handleSoundControlChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch({ type: 'SET_AUDIO', payload: checked });
    },
    [dispatch],
  );

  return (
    <Box position="relative">
      <StyledSlideshow {...rest}>{children}</StyledSlideshow>
      <Box position="absolute" bottom={-3} left={60}>
        {!hideSoundControl && (
          <SoundControl checked={audioEnabled} onChange={handleSoundControlChange} />
        )}
      </Box>
    </Box>
  );
};
