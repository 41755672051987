import envConfig from '@/config/env';

export interface UserDisclaimerConfig {
  title: string;
  message: string;
}

const config: UserDisclaimerConfig = {
  title: envConfig.USER_DISCLAIMER_TITLE,
  message: envConfig.USER_DISCLAIMER_MESSAGE,
};

export default config;
