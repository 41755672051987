import { alpha, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const ItemHeader = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ItemContent = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const DetailsRowStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  background: `linear-gradient(360deg, ${alpha(theme.palette.background.paper, 0)} 0%, ${theme.palette.background.paper} 100%)`,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1.25, 2.5),
}));

interface DetailsRowItemContainerProps {
  flexSize?: number;
}

const DetailsRowItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'flexSize',
})<DetailsRowItemContainerProps>(({ theme, flexSize }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& + &': {
    marginLeft: theme.spacing(2.5),
    borderLeft: `1px solid ${alpha(theme.palette.text.secondary, 0.25)}`,
    padding: theme.spacing(0.5, 0, 0.5, 2.5),
  },
  flex: flexSize || 1,
}));

interface DetailsRowItemProps {
  header?: React.ReactNode;
  content?: React.ReactNode;
  size?: number;
}

export const DetailsRowItem: React.FC<DetailsRowItemProps> = ({ header, content, size }) => {
  return (
    <DetailsRowItemContainer flexSize={size} data-testid="Item">
      <ItemHeader data-testid="ItemHeader">{header}</ItemHeader>
      <ItemContent data-testid="ItemContent">{content}</ItemContent>
    </DetailsRowItemContainer>
  );
};

export const DetailsRow: React.FC<PropsWithChildren> & { Item: typeof DetailsRowItem } = ({
  children,
}) => {
  return <DetailsRowStyled>{children}</DetailsRowStyled>;
};

DetailsRow.Item = DetailsRowItem;
