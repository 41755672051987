import { FilterOptionResult, TreeFilterOptionResult } from '@aily/graphql-sdk/schema';
import {
  ListSubheader,
  ListSubheaderProps as MuiListSubheaderProps,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  Stack,
  Typography,
} from '@mui/material';
import { kebabCase } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { findFilterOption } from '../../utils';
import { Select, SelectProps } from '../Select';

type ValueType = NonNullable<FilterOptionResult['value']>;

export interface SelectFilterProps extends Omit<SelectProps<ValueType>, 'onChange'> {
  /**
   * An array of `FilterOptionResult` objects that will be used to populate the options in the select dropdown
   */
  filterOptions?: readonly FilterOptionResult[] | TreeFilterOptionResult[];
  /**
   * An optional callback function that will be called when a new option is selected in the dropdown, passing in the selected FilterOptionResult object as an argument
   * @param filterOption
   */
  onChange?: (filterOption: FilterOptionResult) => void;
  /**
   * A filter code
   */
  filterCode?: string;
  /**
   * An optional object of props that will be passed to the `ListSubheader` component
   */
  ListSubheaderProps?: MuiListSubheaderProps;
  /**
   * An optional object of props that will be passed to the `MenuItem` component
   */
  MenuItemProps?: MuiMenuItemProps;
}

export const SelectFilter: React.FC<SelectFilterProps> = ({
  filterOptions,
  onChange,
  filterCode,
  ListSubheaderProps,
  MenuItemProps,
  ...rest
}) => {
  /**
   * This function takes in the new selected value, and uses the `findFilterOption` utility function to find the corresponding `FilterOptionResult` object.
   * It then calls the `onChange` callback function (if it is defined) with that `FilterOptionResult` object as an argument.
   */
  const handleChange = useCallback(
    (newValue: ValueType) => {
      const filterOption = findFilterOption(
        filterOptions ?? [],
        ({ value }) => value === newValue,
      ) as FilterOptionResult;

      onChange?.(filterOption);
    },
    [onChange, filterOptions],
  );

  const renderValue = useCallback(
    (value: ValueType) => {
      const filterOption = findFilterOption(
        filterOptions ?? [],
        (filterOption) => filterOption.value === value,
      );

      // If there's an additional label, display it next to the main one
      if (filterOption?.additionalLabels?.[0]) {
        return (
          <Stack direction="row" alignItems="baseline" spacing={0.5}>
            <Typography variant="bodyBold">{filterOption.label}</Typography>
            <Typography variant="xSmall" color="text.secondary">
              {filterOption.additionalLabels[0]}
            </Typography>
          </Stack>
        );
      }

      return filterOption?.label;
    },
    [filterOptions],
  );

  const renderFilterOption = useCallback(
    (filterOption: FilterOptionResult | TreeFilterOptionResult, indent = 0): React.ReactNode => {
      const { id, value, label } = filterOption;
      const paddingLeft = 2 + indent * 2;
      const menuItem = (
        <MenuItem key={id} value={value ?? undefined} sx={{ paddingLeft }} {...MenuItemProps}>
          {label}
        </MenuItem>
      );

      if ('children' in filterOption && filterOption.children && filterOption.children.length) {
        return [
          menuItem,
          ...filterOption.children.map((child) => renderFilterOption(child, indent + 1)),
        ];
      }

      return menuItem;
    },
    [MenuItemProps],
  );

  const children = useMemo<React.ReactNode>(
    () =>
      filterOptions?.map((filterOption) => {
        const { id, label } = filterOption;

        if ('children' in filterOption && filterOption.children && filterOption.children.length) {
          return [
            <ListSubheader key={id} {...ListSubheaderProps}>
              {label}
            </ListSubheader>,
            ...filterOption.children.map((child) => renderFilterOption(child, 1)),
          ];
        }

        return renderFilterOption(filterOption);
      }),
    [filterOptions, renderFilterOption, ListSubheaderProps],
  );

  // Don't show filter with empty options
  if (!filterOptions?.length) {
    return null;
  }

  return (
    <Select
      onChange={handleChange}
      renderValue={renderValue}
      data-testid="SelectFilter"
      data-x-filter-code={filterCode ? kebabCase(filterCode) : undefined}
      {...rest}
    >
      {children}
    </Select>
  );
};
