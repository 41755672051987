/**
 * `io-ts` validators for runtime validation of incoming JSON data against the defined TypeScript types.
 * This setup helps enforce dynamic type safety, ensuring the integrity and safety in the app.
 *
 * **Important:**
 * It's crucial to update these validators alongside the TypeScript schema in `types/realJsonData.ts` when changes
 * to the JSON data structure occur. This synchronization prevents runtime errors.
 */
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';

import * as R from '../types/agentJsonData';

const Style = t.union([
  t.literal(R.Style.Base),
  t.literal(R.Style.BaseTiny),
  t.literal(R.Style.BaseTinyGreen),
  t.literal(R.Style.BaseTinyRed),
  t.literal(R.Style.BaseSmall),
  t.literal(R.Style.BaseSmallGreen),
  t.literal(R.Style.BaseSmallRed),
  t.literal(R.Style.BigGreen),
  t.literal(R.Style.BigBoldGreen),
  t.literal(R.Style.MediumGreen),
  t.literal(R.Style.SmallGreen),
  t.literal(R.Style.MediumRed),
  t.literal(R.Style.BigRed),
  t.literal(R.Style.SmallRed),
  t.literal(R.Style.SmallTile),
]);

const ScreenType = t.union([t.literal(R.ScreenType.Default), t.literal(R.ScreenType.Decision)]);

const Text = t.type({
  content: t.string,
  style: Style,
});

const PopupContent = t.array(
  t.partial({
    texts: t.array(t.union([Text, t.array(Text)])),
  }),
);

const Popup = t.partial({
  button_title: t.string,
  audio_track: t.string,
  screen_title: t.string,
  content: PopupContent,
});

const ScreenContent = t.partial({
  texts: t.array(Text),
});

const Screen = t.partial({
  screen_id: t.union([t.string, t.number]), // Should be just a string, but the data also contains a number in some cases
  screen_type: ScreenType,
  screen_title: t.string,
  audio_track: t.string,
  content: t.array(ScreenContent),
  popup: Popup,
});

const OptimisationLever = t.partial({
  asset: t.array(Screen),
  enterprise_time_horizon_short: t.array(Screen),
  enterprise_time_horizon_medium: t.array(Screen),
  enterprise_time_horizon_long: t.array(Screen),
});

const Tags = t.partial({
  priorities: t.array(t.string),
  geography: t.array(t.string),
  sentiment: t.string,
});

const SecurityInfo = t.partial({
  view_code: t.array(t.string),
  security_group: t.array(t.string),
});

const RealJsonData = t.partial({
  real_id: t.string,
  run_id: t.string,
  security_info: SecurityInfo,
  tags: Tags,
  publication_date: t.string,
  expiration_date: t.string,
  time_stamp: t.string,
  real_title: t.string,
  audio_path: t.string,
  real_headline: t.string,
  real_sub_headline: t.string,
  screens: t.array(Screen),
  optimisation_levers: t.array(OptimisationLever),
});

// Function to validate Agent JSON data and strip out invalid fields
export function validateAgentJsonData(jsonData: string): R.AgentJsonData | null {
  try {
    const parsed = JSON.parse(jsonData);
    const decoded = RealJsonData.decode(parsed);

    return pipe(
      decoded,
      fold(
        () => {
          return parsed;
        },
        (value) => value,
      ),
    );
  } catch (error) {
    return null;
  }
}
