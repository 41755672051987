import * as T from '@aily/graphql-sdk/schema';
import { alpha } from '@mui/material';

import { colors } from '../../theme/default/colors';
import { mapSentimentToColor, mapSentimentToGradientColors } from '../../utils';

export function getStoryGradientColors(story: T.Story): [string, string] {
  switch (story.storyType) {
    case T.StoryDisplayType.AiInspired:
    case T.StoryDisplayType.AiXplained:
      return colors.gradients.purple;
  }

  if (story.sentiment) {
    return mapSentimentToGradientColors(story.sentiment);
  }

  return colors.gradients.aqua;
}

export function getStoryGradient(story: T.Story) {
  if (story.storyType === T.StoryDisplayType.Whisper) {
    return 'linear-gradient(0deg, #D6FDEA -35.29%, #74E7AC 3.13%, #33A373 43.2%, #1D7056 71%, #12534A 96.34%, #09383E 121.69%)';
  }

  if (story.storyType === T.StoryDisplayType.Media) {
    return colors.primary.aqua;
  }

  if (story.storyType === T.StoryDisplayType.WhisperReal) {
    return 'linear-gradient(180deg, #037773, #0D363D 30%, #001320 100%)';
  }

  const gradientColors = getStoryGradientColors(story);

  return `linear-gradient(180deg, ${gradientColors.join(', ')})`;
}

export function getTriangularCornerProps(story: T.Story) {
  let color = colors.primary.aqua;

  if ([T.StoryDisplayType.AiInspired, T.StoryDisplayType.AiXplained].includes(story.storyType)) {
    color = colors.secondary.purple;
  } else if (story.storyType === T.StoryDisplayType.Whisper) {
    color = colors.primary.green;
  } else if (story.storyType === T.StoryDisplayType.WhisperReal) {
    color = colors.secondary.darkGreen;
  } else if (story.storyType === T.StoryDisplayType.Media) {
    color = colors.primary.aqua;
  } else if (story.sentiment) {
    color = mapSentimentToColor(story.sentiment);
  }

  return { sx: { backgroundColor: alpha(color, 0.4) } };
}
