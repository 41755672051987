import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowCircleUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44448 6.37079C7.74454 6.66644 7.74812 7.14936 7.45247 7.44942C5.03511 9.90286 3.52204 13.292 3.52204 17.0032C3.52204 24.4628 9.53857 30.4794 16.9982 30.4794C24.4579 30.4794 30.4744 24.4628 30.4744 17.0032C30.4744 13.658 29.2161 10.5937 27.191 8.20708C26.9185 7.88587 26.9579 7.40455 27.2791 7.13202C27.6003 6.85948 28.0817 6.89894 28.3542 7.22014C30.5932 9.85898 31.9999 13.2669 31.9999 17.0032C31.9999 25.3053 25.3004 32.0048 16.9982 32.0048C8.69609 32.0048 1.99658 25.3053 1.99658 17.0032C1.99658 12.8715 3.68156 9.10312 6.36584 6.37878C6.66149 6.07871 7.14441 6.07514 7.44448 6.37079Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8517 6.79631C27.1496 6.49845 27.6325 6.49845 27.9304 6.79631C28.0837 6.94968 28.2261 7.13966 28.3299 7.27817C28.3346 7.28449 28.3393 7.2907 28.3439 7.2968C28.5966 7.63379 28.5283 8.11187 28.1913 8.36462C27.8543 8.61736 27.3762 8.54907 27.1235 8.21207C26.9999 8.04723 26.9198 7.94305 26.8517 7.87497C26.5538 7.57711 26.5538 7.09417 26.8517 6.79631Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9605 3.52351C13.8611 3.52351 11.0172 4.56783 8.74837 6.33245C8.41586 6.59107 7.93666 6.53117 7.67804 6.19866C7.41942 5.86615 7.47932 5.38695 7.81183 5.12833C10.3401 3.16189 13.5115 1.99805 16.9605 1.99805C20.4131 1.99805 23.8972 3.27883 26.47 5.40945C26.7945 5.67812 26.8397 6.15894 26.571 6.48337C26.3023 6.80781 25.8215 6.85302 25.4971 6.58434C23.1967 4.67933 20.0563 3.52351 16.9605 3.52351Z"
      fill="#48FF9B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2358 12.3035L14.6926 13.9093C14.4007 14.213 13.9178 14.2226 13.6141 13.9307C13.3104 13.6389 13.3008 13.156 13.5927 12.8523L16.41 9.92075C16.5645 9.75998 16.7725 9.68162 16.9791 9.68677C17.1894 9.68146 17.3972 9.76332 17.5485 9.92077L20.3658 12.8523C20.6577 13.156 20.6481 13.6389 20.3444 13.9308C20.0407 14.2227 19.5578 14.2131 19.2659 13.9093L17.7613 12.3437V23.6983C17.7613 24.1196 17.4198 24.4611 16.9985 24.4611C16.5773 24.4611 16.2358 24.1196 16.2358 23.6983V12.3035Z"
      fill="#48FF9B"
    />
  </SvgIcon>
);
