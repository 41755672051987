import { Sentiment } from '@aily/graphql-sdk/schema';
import { AiIcon, VStack } from '@aily-labs/ui';

import decision from '../assets/decision.png';
import minusCircle from '../assets/minus-circle.png';
import plusCircle from '../assets/plus-circle.png';

export const IconMap = {
  ['OPPORTUNITY']: (
    <VStack marginRight={10}>
      <AiIcon width={28} />
    </VStack>
  ),
  [Sentiment.Positive]: (
    <VStack marginRight={10}>
      <img src={plusCircle} style={{ width: 28, height: 28 }} />
    </VStack>
  ),
  [Sentiment.Negative]: (
    <VStack marginRight={10}>
      <img src={minusCircle} style={{ width: 28, height: 28 }} />
    </VStack>
  ),
  ['DECISION']: (
    <VStack marginRight={10} width={28} alignItems="center">
      <img src={decision} style={{ width: 16, height: 32 }} />
    </VStack>
  ),
};
