import * as T from '@aily/graphql-sdk/schema';
import React from 'react';

import { negativeTextColor, positiveTextColor } from './tokens';

export function getTextColorStyles(color: string | [string, string]): React.CSSProperties {
  return Array.isArray(color)
    ? {
        background: `linear-gradient(to right, ${color[0]}, ${color[1]})`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }
    : { color };
}

export function translateSentimentToColor(
  sentiment: T.Sentiment,
): string | [string, string] | undefined {
  switch (sentiment) {
    case T.Sentiment.SlightlyPositive:
    case T.Sentiment.Positive:
    case T.Sentiment.VeryPositive:
      return positiveTextColor;

    case T.Sentiment.SlightlyNegative:
    case T.Sentiment.Negative:
    case T.Sentiment.VeryNegative:
      return negativeTextColor;

    default:
      return undefined;
  }
}
