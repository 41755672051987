import { getToken } from '@aily/auth-service';
import { setContext } from '@apollo/client/link/context';

export const authLink = setContext((_, { headers }) => {
  // Pulling the access token from `localStorage` every time a request is sent
  const token = getToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'x-origin-name': 'web',
      'x-correlation-id': Date.now(),
    },
  };
});
