import { Grid2 as Grid } from '@mui/material';
import { motion, Variants } from 'framer-motion';
import React from 'react';

import { MessageListItem, MessageListItemProps } from './MessageListItem';
import { MessageListItemSkeleton } from './MessageListItemSkeleton';

export interface MessageListProps {
  children?: React.ReactElement<MessageListItemProps> | React.ReactElement<MessageListItemProps>[];
}

// Animation variants
const variants: Variants = {
  initial: { opacity: 0 },
  visible: (index: number) => ({
    opacity: 1,
    transition: { delay: index * 0.03, duration: 0.15 },
  }),
};

export const MessageList: React.FC<MessageListProps> & {
  Item: typeof MessageListItem;
  ItemSkeleton: typeof MessageListItemSkeleton;
} = ({ children }) => (
  <Grid container columnSpacing={2} rowSpacing={4} marginY={1} data-testid="message-list">
    {React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return (
          <Grid
            key={index}
            size={{ xs: 12, md: 6 }}
            component={motion.div}
            custom={index}
            initial="initial"
            animate="visible"
            variants={variants}
          >
            {child}
          </Grid>
        );
      }

      return child;
    })}
  </Grid>
);

MessageList.Item = MessageListItem;
MessageList.ItemSkeleton = MessageListItemSkeleton;
