import React, { useMemo } from 'react';

import { isComponentType } from '@/utils/react';

import { InterceptActions, InterceptActionsProps } from './InterceptActions';
import { InterceptContent, InterceptContentProps } from './InterceptContent';
import { InterceptMedia, InterceptMediaProps } from './InterceptMedia';

/**
 * A custom hook to extract Intercept-specific components from children.
 */
export function useInterceptComponents(children: React.ReactNode) {
  let InterceptMediaComponent: React.ReactElement<InterceptMediaProps> | null = null;
  let InterceptContentComponent: React.ReactElement<InterceptContentProps> | null = null;
  let InterceptActionsComponent: React.ReactElement<InterceptActionsProps> | null = null;

  return useMemo(() => {
    React.Children.forEach(children, (child) => {
      if (isComponentType<InterceptMediaProps>(child, InterceptMedia)) {
        InterceptMediaComponent = child;
      } else if (isComponentType<InterceptContentProps>(child, InterceptContent)) {
        InterceptContentComponent = child;
      } else if (isComponentType<InterceptActionsProps>(child, InterceptActions)) {
        InterceptActionsComponent = child;
      }
    });

    return {
      InterceptMediaComponent,
      InterceptContentComponent,
      InterceptActionsComponent,
    };
  }, [children]);
}
