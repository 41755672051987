import { LoadingSkeleton } from '@aily/saas-core';
import { Box, Container } from '@mui/material';
import rndRoutes from '@rnd/routes';
import React, { lazy, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';

import { getModules } from '@/store/modules/slices';

import DefaultModuleRedirect from './DefaultModuleRedirect';
import { createModuleRoute } from './utils';

const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));
const ServerErrorPage = lazy(() => import('@/pages/ServerErrorPage'));
const UnauthorizedPage = lazy(() => import('@/pages/UnauthorizedPage'));

const SuspenseFallback: React.FC = () => (
  <Container maxWidth="xl">
    <Box sx={{ py: 7.5 }}>
      <LoadingSkeleton />
    </Box>
  </Container>
);

const AppRouter: React.FC = () => {
  const modules = useSelector(getModules);

  const memoizedRoutes: RouteObject[] = useMemo(
    () => [
      {
        path: '/',
        element: <Outlet />,
        children: [
          ...modules.map(createModuleRoute),
          { index: true, element: <DefaultModuleRedirect /> },
        ] satisfies RouteObject[],
      },
      ...rndRoutes,
      { path: '401', element: <UnauthorizedPage /> },
      { path: '404', element: <NotFoundPage /> },
      { path: '500', element: <ServerErrorPage /> },
      { path: '', element: <Navigate to="web" replace /> },
      { path: 'web', element: <DefaultModuleRedirect /> },
      { path: '*', element: <NotFoundPage /> },
    ],
    [modules],
  );

  const routes = useRoutes(memoizedRoutes);

  return <Suspense fallback={<SuspenseFallback />}>{routes}</Suspense>;
};

export default AppRouter;
