import * as T from '@aily/graphql-sdk/schema';
import React from 'react';

import { DataViewContainer } from '../DataViewContainer';
import { HeaderRow } from './HeaderRow';

interface HeaderRowComponentProps {
  component: T.HeaderRowComponent;
}

export const HeaderRowComponent: React.FC<HeaderRowComponentProps> = ({
  component: { id, drillIds, filters, variant, code },
}) => (
  <DataViewContainer id={id} drillIds={drillIds} filters={filters} componentCode={code}>
    {({ dataView, loading }) => (
      <HeaderRow
        dataView={T.isHeaderRow(dataView) ? dataView : undefined}
        variant={variant}
        loading={loading}
      />
    )}
  </DataViewContainer>
);

export default HeaderRowComponent;
