import * as T from '@aily/graphql-sdk/schema';
import { Stack, Typography } from '@mui/material';
import React, { FC, memo } from 'react';

import { colors } from '../../theme/default/colors';
import { mapSentimentToColor } from '../../utils';
import { TableCellTextResult } from '../TableCellContent';
import { RiskIndicatorIcon } from './RiskIndicatorIcon';

interface Props {
  actual?: string;
  previousSentiment?: T.Sentiment;
  sentiment?: T.Sentiment;
  target?: string;
}

export const RiskIndicator: FC<Props> = memo(({ actual, previousSentiment, sentiment, target }) => {
  const renderRiskIndicatorIcon = () => {
    if (!previousSentiment) return null;

    return <RiskIndicatorIcon sentiment={previousSentiment} />;
  };

  if (!target && sentiment === T.Sentiment.Neutral && previousSentiment === T.Sentiment.Neutral) {
    const value: T.TextResult = { __typename: 'TextResult', value: actual };
    return <TableCellTextResult value={value} />;
  }

  if (target) {
    return (
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="h9" color={mapSentimentToColor(sentiment ?? T.Sentiment.Neutral)}>
            {actual}
          </Typography>

          <Typography variant="small" color={colors.neutrals.grey4}>{`/ ${target}`}</Typography>
        </Stack>
        {renderRiskIndicatorIcon()}
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body">{actual}</Typography>
      {renderRiskIndicatorIcon()}
    </Stack>
  );
});
