import { styled } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';

import { typography } from '../../theme/default/typography';
import { Chip } from '../Chip';

const backgroundColor = '#301f09';

const Stamp = styled((props: ChipProps) => <Chip variant="outlined" {...props} />)(({ theme }) => ({
  height: 22,
  borderRadius: 22,
  backgroundColor,
  borderColor: 'inherit',
  '&.MuiChip-colorSuccess': {
    color: theme.palette.success.main,
  },
  '&.MuiChip-colorInfo': {
    color: theme.palette.info.main,
  },
  '&.MuiChip-colorWarning': {
    color: theme.palette.warning.main,
  },
  '&.MuiChip-colorError': {
    color: theme.palette.error.main,
  },
  '& .MuiChip-label': {
    ...typography.small,
    padding: theme.spacing(0, 1.25),
  },
  '& .MuiChip-icon': {
    marginRight: theme.spacing(-0.5),
    fontSize: 14,
    '& path': {
      fill: 'currentColor',
    },
  },
}));

export default Stamp;
