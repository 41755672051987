import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Reals: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="41" height="40" viewBox="0 0 41 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.03737 31.8123C5.00975 32.4612 3.70015 31.6738 3.70015 30.3197V9.64548C3.70015 8.3721 4.99799 7.53952 6.05281 8.16242L16.3488 14.4794V25.4426L6.03737 31.8123ZM6.94817 33.4973C4.67682 34.9273 1.89062 33.1309 1.89062 30.3197V9.64548C1.89062 6.86399 4.68802 5.12289 6.93206 6.45805L18.1584 13.3457V26.5725L6.94817 33.4973Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4944 31.8123C19.4668 32.4612 18.1572 31.6738 18.1572 30.3197V9.64548C18.1572 8.37211 19.455 7.53953 20.5098 8.16241L37.0127 18.2897L37.2903 18.4649C38.3424 19.1293 38.3436 20.7764 37.2938 21.443L20.4944 31.8123ZM21.4051 33.4974C19.1337 34.9273 16.3477 33.1309 16.3477 30.3197V9.64548C16.3477 6.86399 19.1451 5.12289 21.3891 6.45805L38.6524 17.0519V17.1172C40.4399 18.6918 40.2908 21.8107 38.205 23.1277L21.4051 33.4974Z"
      fill="#48FF9B"
    />
  </SvgIcon>
);
