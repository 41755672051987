import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type {
  DataViewFields_ProHomeFeedDataView_Fragment,
  RealFieldsFragment,
  StoryFieldsFragment,
} from '../../../../core/__generated__/fragments';
import {
  DataViewFieldsFragmentDoc,
  RealFieldsFragmentDoc,
  StoryFieldsFragmentDoc,
} from '../../../../core/__generated__/fragments';
import type * as T from '../../../../schema/__generated__/types';
import type { ComponentFields_ProHomeFeedComponent_Fragment } from '../../../rnd/__generated__/fragments';
import { ComponentFieldsFragmentDoc } from '../../../rnd/__generated__/fragments';
const defaultOptions = {} as const;
export type GetProHomePageContentQueryVariables = T.Exact<{
  pageId: T.Scalars['Uuid']['input'];
  priorityId?: T.InputMaybe<T.Scalars['Int']['input']>;
}>;

export type GetProHomePageContentQuery = {
  __typename?: 'Query';
  pageContent: {
    __typename?: 'PageContentResult';
    body: Array<
      { __typename?: 'ProHomeFeedComponent' } & ComponentFields_ProHomeFeedComponent_Fragment
    >;
  };
};

export type GetProHomeFeedInputQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProHomeFeedDataInput>;
}>;

export type GetProHomeFeedInputQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    home?: {
      __typename?: 'HomeResult';
      feed?:
        | ({
            __typename?: 'ProHomeFeedDataView';
            id: string;
            items?: Array<
              | ({ __typename?: 'Real' } & RealFieldsFragment)
              | ({ __typename?: 'Story' } & StoryFieldsFragment)
              | null
            > | null;
            feedFilterOptions?: Array<{
              __typename?: 'AdditionalFilterOptions';
              filterValue: string;
              filterCode: string;
              filterLabel: string;
              subFilters?: Array<{
                __typename?: 'AdditionalFilterOptions';
                filterValue: string;
                filterCode: string;
                filterLabel: string;
              } | null> | null;
            } | null> | null;
            userAdditionalFilters?: Array<{
              __typename?: 'UserAdditionalFilters';
              filterValue: string;
              filterCode: string;
              filterLabel: string;
              filterType: string;
              isDefault: boolean;
              isValid: boolean;
            } | null> | null;
          } & DataViewFields_ProHomeFeedDataView_Fragment)
        | null;
    } | null;
  };
};

export const GetProHomePageContentDocument = gql`
  query GetProHomePageContent($pageId: Uuid!, $priorityId: Int) {
    pageContent(pageId: $pageId, priorityId: $priorityId) {
      body {
        ... on ProHomeFeedComponent {
          ...ComponentFields
        }
      }
    }
  }
  ${ComponentFieldsFragmentDoc}
`;

/**
 * __useGetProHomePageContentQuery__
 *
 * To run a query within a React component, call `useGetProHomePageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomePageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomePageContentQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      priorityId: // value for 'priorityId'
 *   },
 * });
 */
export function useGetProHomePageContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  > &
    ({ variables: GetProHomePageContentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomePageContentQuery, GetProHomePageContentQueryVariables>(
    GetProHomePageContentDocument,
    options,
  );
}
export function useGetProHomePageContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >(GetProHomePageContentDocument, options);
}
export function useGetProHomePageContentSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomePageContentQuery,
    GetProHomePageContentQueryVariables
  >(GetProHomePageContentDocument, options);
}
export type GetProHomePageContentQueryHookResult = ReturnType<typeof useGetProHomePageContentQuery>;
export type GetProHomePageContentLazyQueryHookResult = ReturnType<
  typeof useGetProHomePageContentLazyQuery
>;
export type GetProHomePageContentSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomePageContentSuspenseQuery
>;
export type GetProHomePageContentQueryResult = Apollo.QueryResult<
  GetProHomePageContentQuery,
  GetProHomePageContentQueryVariables
>;
export const GetProHomeFeedInputDocument = gql`
  query GetProHomeFeedInput($input: ProHomeFeedDataInput) {
    pro {
      home {
        feed(input: $input) {
          ...DataViewFields
          ... on ProHomeFeedDataView {
            id
          }
          items {
            ...RealFields
            ...StoryFields
          }
          feedFilterOptions {
            filterValue
            filterCode
            filterLabel
            subFilters {
              filterValue
              filterCode
              filterLabel
            }
          }
          userAdditionalFilters {
            filterValue
            filterCode
            filterLabel
            filterType
            isDefault
            isValid
          }
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${RealFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetProHomeFeedInputQuery__
 *
 * To run a query within a React component, call `useGetProHomeFeedInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeFeedInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeFeedInputQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProHomeFeedInputQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProHomeFeedInputQuery, GetProHomeFeedInputQueryVariables>(
    GetProHomeFeedInputDocument,
    options,
  );
}
export function useGetProHomeFeedInputLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetProHomeFeedInputQuery, GetProHomeFeedInputQueryVariables>(
    GetProHomeFeedInputDocument,
    options,
  );
}
export function useGetProHomeFeedInputSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeFeedInputQuery,
    GetProHomeFeedInputQueryVariables
  >(GetProHomeFeedInputDocument, options);
}
export type GetProHomeFeedInputQueryHookResult = ReturnType<typeof useGetProHomeFeedInputQuery>;
export type GetProHomeFeedInputLazyQueryHookResult = ReturnType<
  typeof useGetProHomeFeedInputLazyQuery
>;
export type GetProHomeFeedInputSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeFeedInputSuspenseQuery
>;
export type GetProHomeFeedInputQueryResult = Apollo.QueryResult<
  GetProHomeFeedInputQuery,
  GetProHomeFeedInputQueryVariables
>;
