import * as T from '@aily/graphql-sdk/schema';
import { Button, ButtonProps, styled, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ChevronDown, ChevronUp } from '../../icons';
import { colors } from '../../theme/default/colors';
import { lineClamp } from '../../theme/utils';
import { findPlailistFilterOption } from '../../utils';
import { FitText } from '../FitText';
import PlailistFilterDropdown from './PlailistFilterDropdown';

interface FilterButtonProps {
  children: string;
  open: boolean;
}

const FilterButton = styled(({ children, open, ...rest }: ButtonProps & FilterButtonProps) => (
  <Button variant="outlined" {...rest} endIcon={open ? <ChevronUp /> : <ChevronDown />}>
    <Typography variant="subtitle" sx={{ ...lineClamp(2, { lineHeight: 1.1 }) }}>
      <FitText>{children}</FitText>
    </Typography>
  </Button>
))(() => ({
  width: 136,
  height: 36,
  borderRadius: 66,
  borderColor: colors.neutrals.white,
  borderWidth: 1,
  color: colors.neutrals.white,
  backgroundColor: 'transparent',
  '&:hover': {
    borderColor: colors.neutrals.white,
    backgroundColor: 'transparent',
  },
}));

interface Props {
  filter: T.Maybe<T.PlailistFilter>;
  onFilterChange: (filterCode?: string, value?: number) => void;
}

const PlailistFilter: React.FC<Props> = ({ filter, onFilterChange }) => {
  const [anchorElement, setAnchorElement] = useState<Element>();
  const [selectedOption, setSelectedOption] = useState<T.PlailistFilterOption>();

  const filterOptions = useMemo(() => {
    return (filter?.options ?? []) as T.PlailistFilterOption[];
  }, []);

  useEffect(() => {
    if (filterOptions.length) {
      const defaultOption =
        findPlailistFilterOption(filterOptions, (option) => !!option.isDefault) ??
        findPlailistFilterOption(filterOptions, (option) => !!option.value);

      if (defaultOption) {
        setSelectedOption(defaultOption);
      }
    }
  }, [filterOptions]);

  const selectOption = useCallback(
    (option: T.PlailistFilterOption) => {
      setSelectedOption(option);
      onFilterChange(filter?.filterCode, option.value ?? undefined);
    },
    [filter, onFilterChange],
  );

  const openFilter = useCallback(
    (event: { currentTarget: React.SetStateAction<Element | undefined> }) => {
      setAnchorElement(event.currentTarget);
    },
    [],
  );

  const closeFilter = useCallback(() => {
    setAnchorElement(undefined);
  }, []);

  return (
    <>
      <FilterButton open={!!anchorElement} onClick={openFilter}>
        {selectedOption?.label ?? ''}
      </FilterButton>
      <PlailistFilterDropdown
        anchorElement={anchorElement}
        options={filterOptions}
        selectedOption={selectedOption}
        onSelect={selectOption}
        onClose={closeFilter}
      />
    </>
  );
};

export default PlailistFilter;
