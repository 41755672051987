import { UserSetting, UserSettingInput } from '@aily/graphql-sdk/schema';
import { unionBy } from 'lodash-es';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type Listener = (updatedSettings: UserSetting[]) => void;

export type SubscribeFunction = (callback: Listener) => void;
export type UnsubscribeFunction = (callback: Listener) => void;
export type PublishFunction = (updatedSettings: UserSetting[]) => void;

export interface UserSettingsContextState {
  userSettings: UserSetting[];
  userSettingsInput: UserSettingInput[];
  subscribe: SubscribeFunction;
  unsubscribe: UnsubscribeFunction;
  publish: PublishFunction;
}

export const UserSettingsContext = createContext<UserSettingsContextState>({
  userSettings: [],
  userSettingsInput: [],
  subscribe: () => {},
  unsubscribe: () => {},
  publish: () => {},
});

export const UserSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [userSettings, setUserSettings] = useState<UserSetting[]>([]);
  const [listeners, setListeners] = useState<Listener[]>([]);

  const subscribe: SubscribeFunction = useCallback((callback) => {
    setListeners((prevListeners) => {
      // Check if the callback already exists in the listeners array
      if (prevListeners.includes(callback)) {
        return prevListeners; // No change needed
      }

      return [...prevListeners, callback];
    });
  }, []);

  const unsubscribe: UnsubscribeFunction = useCallback((callback) => {
    setListeners((prevListeners) => prevListeners.filter((listener) => listener !== callback));
  }, []);

  const publish: PublishFunction = useCallback(
    (updatedSettings) => {
      setUserSettings((prevState) => unionBy(updatedSettings, prevState, 'key'));
      listeners.forEach((listener) => listener(updatedSettings));
    },
    [listeners],
  );

  const userSettingsInput = useMemo(
    () => userSettings.map(({ key, userValue }) => ({ key, value: userValue ?? -1 })),
    [userSettings],
  );

  return (
    <UserSettingsContext.Provider
      value={{ userSettings, userSettingsInput, subscribe, unsubscribe, publish }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export function useUserSettingsContext() {
  return useContext(UserSettingsContext);
}

export default UserSettingsContext;
