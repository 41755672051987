import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';

import { StyledListItem } from './MessageListItem';

export const MessageListItemSkeleton = () => (
  <StyledListItem sx={{ '.MuiSkeleton-root': { marginTop: 0 } }}>
    <ListItemButton>
      <ListItemAvatar>
        <Avatar>
          <Skeleton variant="circular" animation="pulse" width="100%" height="100%" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="h9">
            <Skeleton variant="text" animation="pulse" width="60%" />
          </Typography>
        }
        secondary={
          <Typography variant="body">
            <Skeleton variant="text" animation="pulse" width="40%" />
          </Typography>
        }
      />
    </ListItemButton>
  </StyledListItem>
);
