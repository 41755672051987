import * as T from '@aily/graphql-sdk/schema';
import { styled } from '@mui/material';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Timeline,
  TimelineAxisProps,
  TimelineIntervalProps,
  TimelineSeriesProps,
  translateTimelineInterval,
  translateTimelineSeries,
} from '../Timeline';

export interface TableCellTimelineProps {
  timeline: T.Timeline;
  name?: string;
}

// This component negates cell padding so that the contained element is stretched to full size
const TableCellFiller = styled('div')(({ theme }) => ({
  height: 47, // Row height - 1px bottom border
  margin: theme.spacing(-1.5, -2),
  padding: theme.spacing(0, 2), // This should match the year timeline horizontal padding in the header row
}));

const FadeIn = styled((props: MotionProps) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} {...props} />
))({
  height: '100%',
});

export const TableCellTimeline: React.FC<TableCellTimelineProps> = ({ timeline, name }) => {
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '25%' });

  const timelineAxis = useMemo<TimelineAxisProps>(
    () => ({ minX: timeline.axis.minX, maxX: timeline.axis.maxX, y: '35%' }),
    [timeline],
  );

  const timelineIntervals = useMemo<TimelineIntervalProps[]>(
    () => timeline.intervals?.map(translateTimelineInterval) ?? [],
    [timeline],
  );

  const timelineSeries = useMemo<TimelineSeriesProps[]>(
    () => timeline.series?.map(translateTimelineSeries) ?? [],
    [timeline],
  );

  return (
    <TableCellFiller ref={ref}>
      <AnimatePresence>
        {inView && (
          <FadeIn>
            <Timeline
              axis={timelineAxis}
              intervals={timelineIntervals}
              series={timelineSeries}
              name={name}
            />
          </FadeIn>
        )}
      </AnimatePresence>
    </TableCellFiller>
  );
};

export default TableCellTimeline;
