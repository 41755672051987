import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type * as T from '../../schema/__generated__/types';
import type { InterceptFieldsFragment } from './fragments';
import { InterceptFieldsFragmentDoc } from './fragments';
const defaultOptions = {} as const;
export type InterceptSubscriptionVariables = T.Exact<{
  input: T.InterceptSubscriptionInput;
}>;

export type InterceptSubscription = {
  __typename?: 'Subscriptions';
  intercept: { __typename?: 'InterceptResult' } & InterceptFieldsFragment;
};

export const InterceptDocument = gql`
  subscription Intercept($input: InterceptSubscriptionInput!) {
    intercept(input: $input) {
      ...InterceptFields
    }
  }
  ${InterceptFieldsFragmentDoc}
`;

/**
 * __useInterceptSubscription__
 *
 * To run a query within a React component, call `useInterceptSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInterceptSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterceptSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterceptSubscription(
  baseOptions: ApolloReactHooks.SubscriptionHookOptions<
    InterceptSubscription,
    InterceptSubscriptionVariables
  > &
    ({ variables: InterceptSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<InterceptSubscription, InterceptSubscriptionVariables>(
    InterceptDocument,
    options,
  );
}
export type InterceptSubscriptionHookResult = ReturnType<typeof useInterceptSubscription>;
export type InterceptSubscriptionResult = Apollo.SubscriptionResult<InterceptSubscription>;
