import { Chip as MuiChip, Stack, Typography } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';
import React from 'react';

interface Props extends ChipProps {
  /**
   * A label to be displayed next to the chip
   */
  annotation?: string | null;
}

export const Chip: React.FC<Props> = ({ annotation, ...props }) =>
  annotation ? (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <MuiChip {...props} />
      <Typography variant="small" color="text.secondary">
        {annotation}
      </Typography>
    </Stack>
  ) : (
    <MuiChip {...props} />
  );
