import {
  ApolloClient,
  DocumentNode,
  from,
  InMemoryCache,
  LoadableQueryHookOptions,
  OperationVariables,
  PossibleTypesMap,
  QueryHookOptions,
  TypedDocumentNode,
  useBackgroundQuery as useApolloBackgroundQuery,
  useLazyQuery as useApolloLazyQuery,
  useLoadableQuery as useApolloLoadableQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
  useSubscription as useApolloSubscription,
  useSuspenseQuery as useApolloSuspenseQuery,
} from '@apollo/client';
import { ApolloCache, ApolloError, DefaultContext } from '@apollo/client/core';
import {
  BackgroundQueryHookOptions,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationHookOptions,
  MutationTuple,
  NoInfer,
  QueryResult,
  SubscriptionHookOptions,
  SuspenseQueryHookOptions,
} from '@apollo/client/react/types/types';
import { SentryLink } from 'apollo-link-sentry';

import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';
import { getHttpLink } from './links/httpLink';
import { resetTokenLink } from './links/resetTokenLink';

export let client: ApolloClient<any>;

export const initSaaSApolloClient = (
  uri: string,
  sentryURI: string,
  possibleTypes?: PossibleTypesMap,
) => {
  const httpLink = getHttpLink(uri);
  const sentryLink = new SentryLink({ uri: sentryURI });
  const link = from([resetTokenLink, authLink, errorLink, sentryLink, httpLink]);

  const cache = new InMemoryCache({
    possibleTypes,
  });

  client = new ApolloClient({
    connectToDevTools: true,
    link,
    cache,
  });

  /**
   * The `defaultOptions` setting is being ignored in the `ApolloClient` constructor,
   * that's why it's passed after the instance is created
   * @see https://github.com/apollographql/react-apollo/issues/3750
   */
  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      // This is needed for the `loading` state to be updated when the query's `refetch` function is called
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
};

export const useLazyQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): LazyQueryResultTuple<TData, TVariables> => useApolloLazyQuery(query, { client, ...options });

export const useQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> => useApolloQuery(query, { client, ...options });

export const useMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>, TContext, TCache>,
): MutationTuple<TData, TVariables, TContext, TCache> =>
  useApolloMutation(mutation, { client, ...options });

export const useSuspenseQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SuspenseQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) => useApolloSuspenseQuery(query, { client, ...options });

export const useSubscription = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): {
  restart(): void;
  loading: boolean;
  data?: TData | undefined;
  error?: ApolloError;
  variables?: TVariables | undefined;
} => useApolloSubscription(subscription, { client, ...options });

type BackgroundQueryHookOptionsNoInfer<
  TData,
  TVariables extends OperationVariables,
> = BackgroundQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>;
export const useBackgroundQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: BackgroundQueryHookOptionsNoInfer<TData, TVariables>,
) => useApolloBackgroundQuery(query, { client, ...options });

export const useLoadableQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LoadableQueryHookOptions,
) => useApolloLoadableQuery(query, { client, ...options });

export * from './utils';
export type * from '@apollo/client';
export { ApolloClient, ApolloProvider } from '@apollo/client';
