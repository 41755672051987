import {
  Avatar,
  Box,
  BoxProps,
  Chip,
  ChipProps,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { capitalize } from 'lodash-es';
import React from 'react';

import { FitText } from '../FitText';

export interface MessageListItemProps {
  message: string;
  tag?: string;
  note?: string;
  contentType?: string;
  sender?: string;
  date?: string;
  isNew?: boolean;
  isUrgent?: boolean;
  labels?: ChipProps[];
  onClick?: () => void;
  disabled?: boolean;
}

// Constants for sizes
const avatarSize = 73;
const notificationDotSize = 9;

export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isUrgent',
})<{ isUrgent?: boolean }>(({ theme, isUrgent }) => ({
  '&, & .MuiListItemButton-root': {
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: 0,
  },
  '& .MuiListItemAvatar-root': {
    position: 'relative',
  },
  '& .MuiAvatar-root': {
    width: avatarSize,
    height: avatarSize,
    borderRadius: avatarSize,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: isUrgent ? `0 0 4.575px 0 ${theme.palette.error.main}` : 'none', // Show glow if urgent
    padding: theme.spacing(1),
  },
  '& .MuiListItemText-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    margin: 0,
  },
  '& .MuiChip-root': {
    justifyContent: 'center',
    minWidth: 'auto',
    height: 20,
    borderRadius: '20px',
    padding: theme.spacing(0, 1),
    background: 'transparent',
    ...theme.typography.small,
    '&.MuiChip-colorSuccess, &.MuiChip-colorError': {
      background: 'transparent',
    },
  },
}));

const NotificationDot = styled(Box)(({ theme }) => {
  const avatarRadius = avatarSize / 2;
  const dotRadius = notificationDotSize / 2;

  return {
    position: 'absolute',
    top: `calc(50% - ${dotRadius}px)`, // Center the dot vertically
    left: `calc(50% - ${dotRadius}px)`, // Center the dot horizontally
    width: notificationDotSize,
    height: notificationDotSize,
    backgroundColor: theme.palette.error.main, // Dot color
    borderRadius: '50%',
    transform: `rotate(-45deg) translate(${avatarRadius}px)`, // Rotate and translate the dot
    transformOrigin: 'center',
  };
});

const Dot = ({ size }: { size: number }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <circle cx={size / 2} cy={size / 2} r={size / 2} fill="currentColor" />
  </svg>
);

const Divider = (props: BoxProps) => (
  <Box {...props}>
    <Dot size={2} />
  </Box>
);

export const MessageListItem: React.FC<MessageListItemProps> = ({
  message,
  tag,
  note,
  contentType,
  sender,
  date,
  isNew,
  isUrgent,
  labels,
  onClick,
  disabled,
}) => (
  <StyledListItem isUrgent={isUrgent} data-testid="message-list-item">
    <ListItemButton onClick={onClick} disabled={disabled}>
      <ListItemAvatar>
        {tag && (
          <Avatar>
            <Typography variant="h7" noWrap data-testid="message-list-item__tag">
              <FitText minFontSize={60}>{tag}</FitText>
            </Typography>
          </Avatar>
        )}
        {isNew && <NotificationDot data-testid="message-list-item__notification-dot" />}
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="h9" noWrap data-testid="message-list-item__message">
            {message}
          </Typography>
        }
        secondary={
          <Stack direction="column" spacing={0.5}>
            {/* Secondary Text Stack */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider sx={{ display: 'inline-flex', px: 0.5 }} />}
              sx={{ color: 'grey.400' }}
            >
              {/* Note */}
              {note && (
                <Typography
                  variant="bodyBold"
                  color="text.primary"
                  noWrap
                  data-testid="message-list-item__note"
                >
                  {`“${note}”`}
                </Typography>
              )}

              {/* Content Type */}
              {contentType && (
                <Typography variant="body" noWrap data-testid="message-list-item__contentType">
                  {capitalize(contentType)}
                </Typography>
              )}

              {/* Sender */}
              {sender && (
                <Typography variant="body" noWrap data-testid="message-list-item__sender">
                  {sender}
                </Typography>
              )}

              {/* Date */}
              {date && (
                <Typography variant="body" noWrap data-testid="message-list-item__date">
                  {date}
                </Typography>
              )}
            </Stack>

            {/* Labels */}
            {labels?.length && (
              <Stack direction="row" spacing={1}>
                {labels?.map((label, index) => <Chip key={index} variant="outlined" {...label} />)}
              </Stack>
            )}
          </Stack>
        }
      />
    </ListItemButton>
  </StyledListItem>
);
