import { Radio as MuiRadio, RadioProps, styled, SvgIcon } from '@mui/material';
import React from 'react';

const Icon = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="none" stroke="currentColor" />
  </SvgIcon>
);

const CheckedIcon = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="none" stroke="currentColor" />
    <rect
      x="4.5"
      y="4.5"
      width="11"
      height="11"
      rx="5.5"
      fill="currentColor"
      stroke="currentColor"
    />
  </SvgIcon>
);

const StyledRadio = styled(MuiRadio)({
  '& > .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export const Radio: React.FC<RadioProps> = (props) => (
  <StyledRadio icon={<Icon />} checkedIcon={<CheckedIcon />} {...props} />
);
