export function convertDateFormat(
  inputDate: string,
  dateReplace?: string,
  dateTimeOpts?: Intl.DateTimeFormatOptions,
) {
  // Adjust the input date format to be more easily parsed
  const adjustedDateStr = inputDate.replace('.', dateReplace ?? '');

  // Parse the adjusted date string into a Date object
  const date = new Date(adjustedDateStr);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return inputDate;
  }

  return new Intl.DateTimeFormat(
    'en-US',
    dateTimeOpts ?? {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
  ).format(date);
}
