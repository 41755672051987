import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  RadioProps,
  styled,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';

export type OptionType = Omit<FormControlLabelProps, 'control'> & {
  value: string;
  label: string;
};

export interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'onChange'> {
  options?: OptionType[];
  onChange?: (newValue: string) => void;
  RadioProps?: RadioProps;
}

const StyledRadioGroup = styled(MuiRadioGroup)(({ theme }) => ({
  minHeight: theme.spacing(3),
  '& .MuiRadio-root': {
    display: 'none',
  },
  '& .MuiFormControlLabel-root': {
    marginLeft: 0,
    marginRight: theme.spacing(3.75),
    padding: theme.spacing(0.625, 1.25),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  '& .MuiFormControlLabel-label': {
    ...theme.typography.subtitle,
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.short,
    }),
  },
  '& .MuiFormControlLabel-root:hover .MuiFormControlLabel-label:not(.Mui-disabled), .Mui-checked + .MuiFormControlLabel-label':
    {
      color: theme.palette.text.primary,
    },
}));

export const RadioGroup: React.FC<RadioGroupProps> = ({
  onChange,
  options,
  RadioProps,
  ...rest
}) => {
  const handleChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
      onChange?.(newValue);
    },
    [onChange],
  );

  const children = useMemo(
    () =>
      options?.map((option, index) => (
        <FormControlLabel key={index} control={<Radio {...RadioProps} />} {...option} />
      )),
    [options, RadioProps],
  );

  return (
    <StyledRadioGroup row onChange={handleChange} {...rest}>
      {children}
    </StyledRadioGroup>
  );
};
