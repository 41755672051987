import { styled } from '@mui/material';
import { motion, Transition, useAnimation } from 'framer-motion';
import React, { CSSProperties, useEffect } from 'react';

import { colors } from '../../theme/default/colors';
import { roundToDecimals, useProgressBar } from './useProgressBar';

export interface ProgressBarProps {
  width?: string | number;
  height?: string | number;
  min?: number;
  max?: number;
  baseValue?: number;
  value?: number;
  trackBackground?: string | number;
  trackBorderRadius?: string | number;
  barBackground?: string;
  barStartBorderRadius?: string | number;
  barEndBorderRadius?: string | number;
  inverted?: boolean;
}

const ProgressBarContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden',
});

const Track = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

const Bar = styled(motion.div)({
  position: 'absolute',
  top: 0,
  height: '100%',
});

const transition: Transition = { duration: 0.15, ease: 'easeOut' };

export const ProgressBar: React.FC<ProgressBarProps> = ({
  width = 180,
  height = 15,
  min = 0,
  max = 100,
  baseValue = 0,
  value = 0,
  trackBackground = colors.secondary.ailyBlue,
  trackBorderRadius = `${height}px`,
  barBackground = colors.primary.aqua,
  barStartBorderRadius = 0,
  barEndBorderRadius = 0,
  inverted,
}) => {
  const barControls = useAnimation();
  const {
    normalizedBaseValue,
    normalizedValue,
    barWidth: calculatedBarWidth,
  } = useProgressBar({
    min,
    max,
    baseValue,
    value,
    inverted,
  });

  // Check whether the value is before the base value
  const isBeforeBaseValue = normalizedValue < normalizedBaseValue;

  // Calculate the position style of the bar based on whether the value is before or after the base value
  const barStyle: CSSProperties = isBeforeBaseValue
    ? {
        left: `${roundToDecimals(100 - normalizedBaseValue)}%`,
        borderTopLeftRadius: barStartBorderRadius,
        borderBottomLeftRadius: barStartBorderRadius,
        borderTopRightRadius: barEndBorderRadius,
        borderBottomRightRadius: barEndBorderRadius,
      }
    : {
        right: `${normalizedBaseValue}%`,
        borderTopLeftRadius: barEndBorderRadius,
        borderBottomLeftRadius: barEndBorderRadius,
        borderTopRightRadius: barStartBorderRadius,
        borderBottomRightRadius: barStartBorderRadius,
      };

  useEffect(() => {
    barControls.start({ width: `${calculatedBarWidth}%` });
  }, [calculatedBarWidth]);

  // Render the progress bar
  return (
    <ProgressBarContainer sx={{ width, height }}>
      <Track
        sx={{ background: trackBackground, borderRadius: trackBorderRadius, overflow: 'hidden' }}
      >
        <Bar
          sx={{ background: barBackground, ...barStyle }}
          initial={{ width: '0%' }}
          transition={transition}
          animate={barControls}
        />
      </Track>
    </ProgressBarContainer>
  );
};
