import * as T from '@aily/graphql-sdk/schema';
import { PlayActive } from '@aily/saas-core/icons';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

import {
  Ai,
  AiActive,
  AiLogoOutlined,
  Home,
  HomeActive,
  Play,
  Search,
  SearchActive,
  Settings,
  SettingsActive,
  Star,
  StarActive,
} from '@/icons';

type IconVariant = 'default' | 'active';
type IconType = T.MenuIcon | 'SEARCH_OUTLINED';

type IconComponentMap = Partial<
  Record<IconType, Partial<Record<IconVariant, React.ComponentType<SvgIconProps>>>>
>;

const themeIconMappings: IconComponentMap = {
  [T.MenuIcon.AilyLogoOutlined]: {
    default: Ai,
    active: AiActive,
  },
  [T.MenuIcon.HomeOutlined]: {
    default: Home,
    active: HomeActive,
  },
  [T.MenuIcon.PlayCircleOutlined]: {
    default: Play,
    active: PlayActive,
  },
  SEARCH_OUTLINED: {
    default: Search,
    active: SearchActive,
  },
  [T.MenuIcon.SettingsOutlined]: {
    default: Settings,
    active: SettingsActive,
  },
  [T.MenuIcon.StarOutlined]: {
    default: Star,
    active: StarActive,
  },
};

interface Props extends SvgIconProps {
  menuIcon: IconType;
  active?: boolean;
}

export const MenuIcon: React.FC<Props> = ({ menuIcon, active = false, ...rest }) => {
  const variant: IconVariant = active ? 'active' : 'default';
  const IconComponent = themeIconMappings?.[menuIcon]?.[variant] || AiLogoOutlined; // Fallback to a default icon

  return <IconComponent {...rest} />;
};
