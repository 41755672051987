import React from 'react';

export enum SymbolType {
  Circle = 'CIRCLE',
  Dot = 'DOT',
  Rhombus = 'RHOMBUS',
  Square = 'SQUARE',
  Triangle = 'TRIANGLE',
}

export interface TimelineSymbolProps extends Omit<SymbolProps, 'type'> {
  type: SymbolType;
}

export interface SymbolProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Symbol size. A more convenient way to set the `width` and `height` at the same time.
   */
  size?: number;
}

export const CircleSymbol: React.FC<SymbolProps> = ({ size = 28, stroke, fill, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" {...rest}>
    <path
      d="M14 22C18.4183 22 22 18.4183 22 14C22 9.58172 18.4183 6 14 6C9.58172 6 6 9.58172 6 14C6 18.4183 9.58172 22 14 22ZM14 26C20.6274 26 26 20.6274 26 14C26 7.37258 20.6274 2 14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26Z"
      fill={stroke}
    />
    <path
      d="M14 22C18.4183 22 22 18.4183 22 14C22 9.58172 18.4183 6 14 6C9.58172 6 6 9.58172 6 14C6 18.4183 9.58172 22 14 22Z"
      fill={fill}
    />
  </svg>
);

export const DotSymbol: React.FC<SymbolProps> = ({ size = 10, stroke, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 10 10" {...rest}>
    <circle cx="5" cy="5" r="5" fill={stroke} />
  </svg>
);

export const RhombusSymbol: React.FC<SymbolProps> = ({ size = 28, stroke, fill, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" {...rest}>
    <path
      d="M14 6.65685L6.65685 14L14 21.3431L21.3431 14L14 6.65685ZM1 14L14 27L27 14L14 1L1 14Z"
      fill={stroke}
    />
    <path d="M14.0004 6.65686L6.65723 14L14.0004 21.3431L21.3435 14L14.0004 6.65686Z" fill={fill} />
  </svg>
);

export const SquareSymbol: React.FC<SymbolProps> = ({ size = 28, stroke, fill, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" {...rest}>
    <path d="M22 6H6V22H22V6ZM2 2V26H26V2H2Z" fill={stroke} />
    <path d="M22 6H6V22H22V6Z" fill={fill} />
  </svg>
);

export const TriangleSymbol: React.FC<SymbolProps> = ({ size = 28, stroke, fill, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" {...rest}>
    <path
      d="M13.8564 2L0 26H27.7128L13.8564 2ZM13.8564 10L6.9282 22H20.7846L13.8564 10Z"
      fill={stroke}
    />
    <path d="M13.9995 10L7.07129 22H20.9277L13.9995 10Z" fill={fill} />
  </svg>
);

export const symbolComponentMap: Record<SymbolType, React.ElementType> = {
  [SymbolType.Circle]: CircleSymbol,
  [SymbolType.Dot]: DotSymbol,
  [SymbolType.Rhombus]: RhombusSymbol,
  [SymbolType.Square]: SquareSymbol,
  [SymbolType.Triangle]: TriangleSymbol,
};

export const TimelineSymbol: React.FC<TimelineSymbolProps> = ({ type, ...rest }) => {
  const SymbolComponent = symbolComponentMap[type];
  return SymbolComponent ? <SymbolComponent {...rest} /> : null;
};
