import * as T from '@aily/graphql-sdk/schema';
import { Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';

import { useTableDataViewContext, VarianceDisplayMode } from '../../contexts';
import { mapSentimentToColor } from '../../utils';
import { FitText } from '../FitText';

export interface TableCellActualWithVarianceProps {
  actualWithVariance: T.ValueWithVarianceResult;
}

export function useActualWithVariance(
  actualWithVariance: T.ValueWithVarianceResult,
  isAbsolute: boolean,
) {
  const {
    absoluteSentiment,
    absoluteVariance,
    percentageVariance,
    percentageSentiment,
    value: actualValue,
  } = actualWithVariance;

  const { varianceValue, sentiment } = useMemo(() => {
    let varianceValue = isAbsolute ? absoluteVariance : percentageVariance;
    let sentiment = isAbsolute ? absoluteSentiment : percentageSentiment;

    if (varianceValue === null) {
      varianceValue = isAbsolute ? percentageVariance : absoluteVariance;
      sentiment = isAbsolute ? percentageSentiment : absoluteSentiment;
    }

    return { varianceValue, sentiment };
  }, [isAbsolute, absoluteVariance, absoluteSentiment, percentageVariance, percentageSentiment]);

  return { actualValue, varianceValue, sentiment } as const;
}

export const TableCellActualWithVariance: React.FC<TableCellActualWithVarianceProps> = ({
  actualWithVariance,
}) => {
  const tableContext = useTableDataViewContext();
  const isAbsolute = tableContext?.varianceDisplayMode === VarianceDisplayMode.Absolute;

  const { actualValue, varianceValue, sentiment } = useActualWithVariance(
    actualWithVariance,
    isAbsolute,
  );

  const color = sentiment ? mapSentimentToColor(sentiment) : undefined;

  const handleClick = useCallback(() => {
    tableContext?.toggleVarianceDisplayMode();
  }, [tableContext]);

  if (actualValue && varianceValue) {
    return (
      <Stack direction="column" spacing={0} data-testid="ActualWithVarianceCell">
        <FitText data-testid="Actual">{actualValue}</FitText>
        <Typography
          onClick={handleClick}
          variant="small"
          fontWeight="inherit"
          sx={{ color, cursor: 'pointer' }}
          data-testid="Variance"
        >
          <FitText>{varianceValue}</FitText>
        </Typography>
      </Stack>
    );
  }

  if (actualValue) {
    return <FitText data-testid="ActualWithVarianceCell">{actualValue}</FitText>;
  }

  return null;
};
