import { AudioLoader } from './AudioLoader';
import { AudioVisualizer } from './AudioVisualizer';
import { SVGAnimator } from './SVGAnimator';

export interface AilyAgentOperation {
  audioPath: string;
  elementRef: HTMLElement;
  delayBeforeStart?: number;
  delayAfterEnd?: number;
  corner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export class AilyAgent {
  private static instance: AilyAgent | null = null;
  private readonly audioContext?: AudioContext;
  private readonly audioLoader?: AudioLoader;
  private readonly audioVisualizer?: AudioVisualizer;
  private svgAnimator?: SVGAnimator;
  private operations: AilyAgentOperation[] = [];
  private currentIndex: number = 0;
  private currentAudioSource?: AudioBufferSourceNode;
  private idleTimeout?: ReturnType<typeof setTimeout>;
  private idleTimeoutDuration: number = 3000; // 3 seconds idle timeout

  private constructor() {
    this.audioContext = this.createAudioContext();
    if (this.audioContext) {
      this.audioLoader = new AudioLoader(this.audioContext);
      this.audioVisualizer = new AudioVisualizer(this.audioContext);
    }
  }

  public static getInstance(): AilyAgent {
    if (!AilyAgent.instance) {
      AilyAgent.instance = new AilyAgent();
    }

    return AilyAgent.instance;
  }

  private createAudioContext(): AudioContext | undefined {
    if (window.AudioContext) {
      return new AudioContext();
    }

    console.warn('AudioContext is not supported in this browser.');
    return undefined;
  }

  /**
   * Sets the main SVG element for animations and connects subcomponents to it.
   * @param {SVGSVGElement} svgElement - The main SVG element used for animations.
   * @param {string} selector - The selector to find a specific child element for animations.
   */
  public setSvgElement(svgElement: SVGSVGElement, selector: string): void {
    this.svgAnimator = new SVGAnimator(svgElement);
    const svgEllipse = svgElement.querySelector<SVGElement>(selector);
    if (svgEllipse) {
      this.audioVisualizer?.setSvgElement(svgEllipse);
    }
  }

  /**
   * Sets the list of operations to be processed by the agent.
   * @param {AilyAgentOperation[]} operations - The operations to be processed.
   */
  public setOperations(operations: AilyAgentOperation[]): void {
    this.operations = operations;
  }

  /**
   * Starts processing the operations array from the beginning.
   */
  public start(): void {
    this.currentIndex = 0;
    this.svgAnimator?.enter();
    this.processNextOperation();
  }

  /**
   * Processes the next operation in the queue or schedules an exit if done.
   */
  private processNextOperation(): void {
    if (this.currentIndex < this.operations.length) {
      const operation = this.operations[this.currentIndex];
      setTimeout(() => this.play(operation), operation.delayBeforeStart || 0);
    } else {
      this.idleTimeout = setTimeout(() => this.exit(), this.idleTimeoutDuration);
    }
  }

  /**
   * Plays the current operation's audio and initiates SVG animation.
   * @param {AilyAgentOperation} operation - The operation to play.
   */
  private play(operation: AilyAgentOperation): void {
    this.cleanupAudioSource();
    this.svgAnimator?.moveToElement(operation.elementRef, operation.corner);
    this.audioLoader?.load(operation.audioPath, (buffer) =>
      this.handleLoadedBuffer(buffer, operation),
    );
  }

  /**
   * Handles the loaded audio buffer, starting playback or logging an error.
   * @param {AudioBuffer | null} buffer - The loaded audio buffer.
   * @param {AilyAgentOperation} operation - The operation related to the buffer.
   */
  private handleLoadedBuffer(buffer: AudioBuffer | null, operation: AilyAgentOperation): void {
    if (!buffer) {
      console.warn('Failed to load or decode audio:', operation.audioPath);
      this.handleOperationEnded(operation);
      return;
    }

    this.setupAudioSource(buffer, operation);
  }

  /**
   * Sets up the audio source node for playback.
   * @param {AudioBuffer} buffer - The audio buffer to be played.
   * @param {AilyAgentOperation} operation - The operation related to the audio.
   */
  private setupAudioSource(buffer: AudioBuffer, operation: AilyAgentOperation): void {
    if (!this.audioContext) return;

    const source = this.audioContext.createBufferSource();
    source.buffer = buffer;
    source.onended = () => this.handleAudioSourceEnded(source, operation);

    this.currentAudioSource = source;
    this.audioVisualizer?.connectSource(source);

    source.start();
  }

  /**
   * Handles the end of audio playback, advancing to the next operation.
   * @param {AudioBufferSourceNode} source - The audio source that ended.
   * @param {AilyAgentOperation} operation - The operation related to the audio.
   */
  private handleAudioSourceEnded(
    source: AudioBufferSourceNode,
    operation: AilyAgentOperation,
  ): void {
    if (source === this.currentAudioSource) {
      this.handleOperationEnded(operation);
    }
  }

  /**
   * Completes the current operation and schedules the next one.
   * @param {AilyAgentOperation} operation - The operation that has ended.
   */
  private handleOperationEnded(operation: AilyAgentOperation): void {
    this.cleanupAudioSource();

    setTimeout(() => {
      this.currentIndex++;
      this.processNextOperation();
    }, operation.delayAfterEnd || 0);
  }

  /**
   * Cleans up the current audio source and any active timeouts.
   */
  private cleanupAudioSource(): void {
    if (this.currentAudioSource) {
      this.currentAudioSource.stop();
      this.currentAudioSource.disconnect();
      this.currentAudioSource = undefined;
    }

    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = undefined;
    }
  }

  /**
   * Exits the agent, cleaning up resources and resetting state.
   */
  public exit(): void {
    this.cleanupAudioSource();
    this.svgAnimator?.exit();
    this.operations = [];
    this.currentIndex = 0;
  }
}
