import { MenuItem, MenuItemProps } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

interface UseSegmentControlProps<TValue> {
  defaultValue?: TValue;
  value?: TValue;
  onChange?: (newValue: TValue) => void;
  children: React.ReactNode;
}

export function useSegmentControl<TValue>({
  defaultValue,
  value,
  onChange,
  children,
}: UseSegmentControlProps<TValue>) {
  // Convert children into an array of valid `MenuItem` elements
  const itemsArray = useMemo(
    () =>
      React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && child.type === MenuItem,
      ) as React.ReactElement<MenuItemProps>[],
    [children],
  );

  const itemValues = useMemo(
    () => itemsArray.map((item) => item.props.value as TValue),
    [itemsArray],
  );

  const [localValue, setLocalValue] = useState(defaultValue);
  const currentValue = value ?? localValue;
  const selectedIndex = itemValues.findIndex((value) => value === currentValue);
  const currentIndex = selectedIndex !== -1 ? selectedIndex : 0;

  const handleChange = useCallback(
    (newValue: TValue) => {
      setLocalValue(newValue);
      onChange?.(newValue);
    },
    [onChange],
  );

  return { currentIndex, currentValue, itemsArray, itemValues, handleChange };
}
