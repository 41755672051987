import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Home: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7808 3.7079C15.4697 2.76403 17.5303 2.76403 19.2192 3.7079L28.1637 8.70679C29.9155 9.68583 31 11.5303 31 13.5305V26.4684C31 29.5234 28.5127 32 25.4445 32H21.8955C20.6683 32 19.6734 31.0094 19.6734 29.7874V27.4103C19.6734 25.8125 18.3725 24.5172 16.7678 24.5172C15.1631 24.5172 13.8622 25.8125 13.8622 27.4103V29.7874C13.8622 31.0094 12.8673 32 11.64 32H7.55549C4.48728 32 2 29.5234 2 26.4684V13.5305C2 11.5303 3.08451 9.68583 4.8363 8.70679L13.7808 3.7079ZM18.1315 5.63739C17.1182 5.07106 15.8818 5.07106 14.8685 5.63739L5.92398 10.6363C4.8729 11.2237 4.2222 12.3304 4.2222 13.5305V26.4684C4.2222 28.3014 5.71456 29.7874 7.55549 29.7874H11.64V27.4103C11.64 24.5905 13.9358 22.3046 16.7678 22.3046C19.5998 22.3046 21.8955 24.5905 21.8955 27.4103V29.7874H25.4445C27.2854 29.7874 28.7778 28.3014 28.7778 26.4684V13.5305C28.7778 12.3304 28.1271 11.2237 27.076 10.6363L18.1315 5.63739Z"
    />
  </SvgIcon>
);
