import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

interface AuxFiltersContextState {
  auxFilters: ReactNode[];
  setAuxFilters: React.Dispatch<React.SetStateAction<ReactNode[]>>;
  addFilter: (filterComponent: ReactNode) => void;
  removeFilter: (filterComponent: ReactNode) => void;
}

interface AuxFiltersProviderProps {
  children: ReactNode;
}

const AuxFiltersContext = createContext<AuxFiltersContextState | undefined>(undefined);

export const AuxFiltersProvider: React.FC<AuxFiltersProviderProps> = ({ children }) => {
  const [auxFilters, setAuxFilters] = useState<ReactNode[]>([]);

  const addFilter = useCallback((filterComponent: ReactNode) => {
    setAuxFilters((prevFilters) => [...prevFilters, filterComponent]);
  }, []);

  const removeFilter = useCallback((filterComponent: ReactNode) => {
    setAuxFilters((prevFilters) => prevFilters.filter((filter) => filter !== filterComponent));
  }, []);

  const value = useMemo(() => {
    return {
      auxFilters,
      setAuxFilters,
      addFilter,
      removeFilter,
    };
  }, [auxFilters, setAuxFilters, addFilter, removeFilter]);

  return <AuxFiltersContext.Provider value={value}>{children}</AuxFiltersContext.Provider>;
};

export function useAuxFilters(): AuxFiltersContextState | undefined {
  return useContext(AuxFiltersContext);
}
