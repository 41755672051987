import H from '../../config/highchartsConfig';
import { colors } from '../../theme/default/colors';
import { typography } from '../../theme/default/typography';
import { customNumberFormat, renderCustomLineGroup } from './utils';

export const baseOptions: H.Options & { series: [H.SeriesOptionsType] } = {
  chart: {
    type: 'waterfall',
    height: 450,
    events: {
      render() {
        renderCustomLineGroup(this);
      },
    },
  },
  xAxis: {
    labels: {
      style: {
        ...typography.body,
        color: colors.neutrals.white,
      } as H.CSSObject,
    },
    type: 'category',
    // This needs to be populated from data
    categories: [],
  },
  yAxis: {
    opposite: true,
    // If there are only zero points, this ensures that the 0 grid line is aligned at the bottom
    softMin: 0,
    softMax: 5,
  },
  series: [
    {
      type: 'waterfall',
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, colors.gradients.pinkTransparent[0]],
          [1, colors.gradients.pinkTransparent[1]],
        ],
      },
      pointPadding: 0.2,
      groupPadding: 0,
      lineWidth: 0,
      borderWidth: 0,
      upColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, colors.gradients.green[1]],
          [1, colors.gradients.green[0]],
        ],
      },
      dataLabels: {
        enabled: true,
        verticalAlign: 'top',
        overflow: 'justify',
        crop: false,
        x: 0,
        y: -25,
        style: {
          ...typography.body,
          color: colors.neutrals.white,
          textOutline: 'none',
        } as H.CSSObject,
        formatter() {
          if (this.point.name) {
            return this.point.name;
          }

          if (typeof this.point.y === 'number') {
            // Format the value using the customNumberFormat() function
            const formattedValue = customNumberFormat(this.point.y, 0);
            // Add a "+" sign for positive numbers, otherwise just display the number
            return this.point.y > 0 ? `+${formattedValue}` : formattedValue;
          }

          return undefined;
        },
      },
      // This needs to be populated from data
      data: [],
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
};
