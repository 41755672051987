import * as T from '@aily/graphql-sdk/schema';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  categories: T.PlailistCategory[];
  metadata: T.MetaData[];
  loading: boolean;
  error: Error | null;
}

export const initialState: State = {
  categories: [],
  metadata: [],
  loading: true,
  error: null,
};

/**
 * Helper function to update a specific story in the state.
 *
 * @param {State} state - The current state of the plailists.
 * @param {string} storyId - The ID of the story to update.
 * @param {(story: Draft<T.Story>) => void} updateCallback - A callback function that performs the update.
 */
const updateStory = (
  state: State,
  storyId: string,
  updateCallback: (story: Draft<T.Story>) => void,
) => {
  // Iterate over categories
  state.categories.forEach((category) => {
    // Iterate over plailists within each category
    category.plailists?.forEach((plailist) => {
      // Find the story by ID
      const story = plailist?.stories?.find((s) => s?.id === storyId);
      if (story) {
        // Apply the update callback to the found story
        updateCallback(story);
      }
    });
  });
};

// Create a slice for plailists with defined reducers and actions
const plailistsSlice = createSlice({
  name: 'plailists',
  initialState,
  reducers: {
    // Reducer for handling completed queries
    queryCompleted: (state, action: PayloadAction<{ plailists: T.PlailistsResult }>) => {
      const { categories, metadata } = action.payload.plailists;
      state.categories = (categories ?? []) as T.PlailistCategory[];
      state.metadata = (metadata ?? []) as T.MetaData[];
      state.loading = false;
    },
    // Reducer for handling errors in queries
    queryError: (state, action: PayloadAction<{ error: Error }>) => {
      const { error } = action.payload;
      state.error = error;
      state.loading = false;
    },
    // Reducer for marking a story as read
    storyRead: (state, action: PayloadAction<{ storyId: string }>) => {
      const { storyId } = action.payload;
      updateStory(state, storyId, (story) => {
        story.isRead = true;
      });
    },
  },
});

export default plailistsSlice;
