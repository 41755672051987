import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';

import H from '../../config/highchartsConfig';
import { baseOptions } from './baseOptions';

export interface WaterfallChartProps {
  categories: string[];
  data: H.PointOptionsObject[];
}

export const WaterfallChart: React.FC<WaterfallChartProps> = ({ categories, data }) => {
  const options = useMemo(
    () =>
      H.merge(baseOptions, {
        xAxis: { categories },
        series: [H.merge(baseOptions.series[0], { data })],
      } as H.Options),
    [categories, data, baseOptions],
  );

  return <HighchartsReact highcharts={H} options={options} />;
};
