import { alpha, Divider, DividerProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export interface NeonDividerProps extends DividerProps {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

const StyledDivider = styled(Divider)<NeonDividerProps>(({
  theme,
  orientation,
  color = 'primary',
}) => {
  const mainColor = theme.palette[color].main;
  const transparentColor = alpha(mainColor, 0);
  const gradientDirection = orientation === 'horizontal' ? '90deg' : '0deg';

  return {
    width: orientation === 'horizontal' ? 'auto' : 1,
    height: orientation === 'horizontal' ? 1 : 'auto',
    border: 'none',
    background: `linear-gradient(${gradientDirection}, ${transparentColor} 0%, ${mainColor} 50%, ${transparentColor})`,
    boxShadow: `0 0 5px 0 ${alpha(mainColor, 0.5)}`,
    filter: `drop-shadow(0px 0px 10px ${mainColor})`,
  };
});

export const NeonDivider: React.FC<NeonDividerProps> = (props) => <StyledDivider {...props} />;
