import { findPageDepthFirst } from '@aily/saas-core';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useCurrentModule } from './useCurrentModule';

/**
 * Custom hook to get the current page and its parents. It only searches the pages of the current module.
 */
export function useCurrentPage() {
  const currentModule = useCurrentModule();
  const { pathname } = useLocation();

  return useMemo(
    () => findPageDepthFirst(currentModule?.pages ?? [], pathname),
    [currentModule?.pages, pathname],
  );
}
