import { TableCell, TableRow as MuiTableRow } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow/TableRow';
import React, { useCallback, useMemo, useState } from 'react';

import { FirstColumnCellContent } from './FirstColumnCellContent';
import { TableColumnProps } from './Table';

export interface TableRowProps<RowType> extends MuiTableRowProps {
  row: RowType;
  rowIndex: number;
  rowKey?: (row: RowType) => string | number;
  columns: TableColumnProps[];
  getChildrenRows?: (row: RowType) => RowType[];
  onRow?: (row: RowType, rowIndex: number) => MuiTableRowProps;
  onCell?: (row: RowType, column: TableColumnProps, columnIndex: number) => TableCellProps;
  onCellRender?: (row: RowType, column: TableColumnProps, columnIndex: number) => React.ReactNode;
  indent?: number;
}

const TableRow = <RowType,>({
  row,
  rowIndex,
  rowKey,
  columns,
  getChildrenRows,
  onRow,
  onCell,
  onCellRender,
  indent = 0,
  ...rest
}: TableRowProps<RowType>) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded((prevState) => !prevState), []);
  const childrenRows = useMemo(() => getChildrenRows?.(row) ?? [], [getChildrenRows, row]);
  const expandable = !!childrenRows?.length;

  const TableRowProps: MuiTableRowProps = useMemo(
    () => ({ ...onRow?.(row, rowIndex), ...rest }),
    [onRow, row, rowIndex, rest],
  );

  return (
    <>
      <MuiTableRow {...TableRowProps}>
        {columns.map((column, columnIndex) => (
          <TableCell
            key={columnIndex}
            align={column.align}
            variant="body"
            {...onCell?.(row, column, columnIndex)}
          >
            {columnIndex === 0 ? (
              <FirstColumnCellContent
                expandable={expandable}
                expanded={expanded}
                toggleExpanded={toggleExpanded}
                indent={indent}
              >
                {onCellRender?.(row, column, columnIndex)}
              </FirstColumnCellContent>
            ) : (
              onCellRender?.(row, column, columnIndex)
            )}
          </TableCell>
        ))}
      </MuiTableRow>
      {expandable &&
        expanded &&
        childrenRows.map((childrenRow, childrenIndex) => (
          <TableRow
            key={rowKey ? rowKey(childrenRow) : `${rowIndex}-${childrenIndex}`}
            row={childrenRow}
            rowIndex={childrenIndex}
            rowKey={rowKey}
            getChildrenRows={getChildrenRows}
            columns={columns}
            onRow={onRow}
            onCell={onCell}
            onCellRender={onCellRender}
            indent={indent + 1}
          />
        ))}
    </>
  );
};

export default React.memo(TableRow) as typeof TableRow;
