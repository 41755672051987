import * as T from '@aily/graphql-sdk/schema';
import { useCallback } from 'react';

import { FilterSearchParams, PlailistSearchParams } from '../constants';
import { useModule, usePriorityFilter } from '../providers';
import { normalizeAbsolutePath } from '../utils';
import { useNavigateSearch } from './useNavigateSearch';

export function mapSearchParams(
  link: T.Link | any,
  currentModule?: T.Module | null,
  priorityFilter?: T.Filter,
  dimensionId?: number,
) {
  const searchParams = new URLSearchParams();
  const pathname = link.absolutePath ? normalizeAbsolutePath(link.absolutePath) : '/';
  const isSameModule =
    currentModule && pathname.startsWith(normalizeAbsolutePath(currentModule.path));
  const multiSelectValue = link.filters?.find(T.isMultiSelectFilterValue);

  if (isSameModule && priorityFilter) {
    const priorityFilterValue = link.filters?.find(
      (filter: { id: any }) => filter.id === priorityFilter.id,
    );
    if (T.isSelectFilterValue(priorityFilterValue)) {
      searchParams.set(FilterSearchParams.PRIORITY_ID, String(priorityFilterValue.value));
    }
  }

  if (dimensionId != null) {
    searchParams.set(FilterSearchParams.DIMENSION_ID, String(dimensionId));
  }

  if (link.dimensionId != undefined) {
    searchParams.set(FilterSearchParams.DIMENSION_ID, link.dimensionId.join('.'));
  }

  if (multiSelectValue) {
    searchParams.set(
      FilterSearchParams.DIMENSION_ID,
      String(multiSelectValue.selectedValues.join('.')),
    );
  }

  if (link.drillIds?.length) {
    searchParams.set(FilterSearchParams.DRILL_ID, link.drillIds.join('.'));
  }

  if (link.plailistId) {
    searchParams.set(PlailistSearchParams.PLAILIST_ID, link.plailistId);
  }

  return { pathname, searchParams };
}

export function useHandleLink() {
  const currentModule = useModule();
  const priorityFilter = usePriorityFilter();
  const navigateSearch = useNavigateSearch();

  return useCallback(
    (link: T.Link, backLink?: T.Link) => {
      const { pathname, searchParams } = mapSearchParams(
        link,
        currentModule,
        priorityFilter?.priorityFilter,
      );
      navigateSearch(pathname, searchParams, { state: { link, backLink } });
    },
    [currentModule, priorityFilter?.priorityFilter],
  );
}
