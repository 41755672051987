import * as T from '@aily/graphql-sdk/schema';
import React from 'react';

export interface ComponentProps {
  component: T.Component;
  componentIndex: number;
  components: T.Component[];
  onComponentRender?: (
    component: T.Component,
    componentIndex: number,
    components: T.Component[],
  ) => React.ReactNode;
}

// prettier-ignore
const ComponentMap = {
  Button:                   React.lazy(() => import('../ButtonDataView')),
  Cards:                    React.lazy(() => import('../GridDataView/GridDataViewContainer')),
  Chart:                    React.lazy(() => import('../ChartDataView')),
  Dials:                    React.lazy(() => import('../Dials/DialsComponent')),
  Filter:                   React.lazy(() => import('../FilterComponent/FilterComponent')),
  Filters:                  React.lazy(() => import('../FiltersComponent/FiltersComponent')),
  Grid:                     React.lazy(() => import('../GridDataView/GridDataViewContainer')),
  HeaderRow:                React.lazy(() => import('../HeaderRow')),
  Kpi:                      React.lazy(() => import('../KpiStatusOverview/KpiStatusOverviewContainer')),
  Plailist:                 React.lazy(() => import('../Plailist/PlailistComponent')),
  Recommendations:          React.lazy(() => import('../Recommendations')),
  RecommendationsBreakdown: React.lazy(() => import('../RecommendationsBreakdown/RecommendationsBreakdownComponent')),
  Recommender:              React.lazy(() => import('../Recommender/RecommenderComponent')),
  StoryList:                React.lazy(() => import('../StoryList/StoryListContainer')),
  SummaryInfo:              React.lazy(() => import('../SummaryInfoDataView/SummaryInfoDataViewContainer')),
  Table:                    React.lazy(() => import('../TableDataView/TableDataView').then(({ TableDataView }) => ({ default: TableDataView }))),
  TableSwitcher:            React.lazy(() => import('../TableSwitcher/TableSwitcher')),
  NavigationTabs:           React.lazy(() => import('../NavigationTabs/NavigationTabs')),
  ColumnLayout:             React.lazy(() => import('../ColumnLayoutComponent/ColumnLayoutComponent')),
  UserAnalytics:            React.lazy(() => import('../UserAnalytics/UserAnalyticsComponent')),
  UserPriorities:           React.lazy(() => import('../UserPriorities/UserPrioritiesComponent')),
};

const Component: React.FC<ComponentProps> = ({
  component,
  componentIndex,
  components,
  onComponentRender,
}) => {
  if (onComponentRender) {
    const element = onComponentRender(component, componentIndex, components);
    if (element !== undefined) {
      return element;
    }
  }

  if (T.isCardsComponent(component)) {
    return <ComponentMap.Cards key={component.id} component={component} />;
  }

  if (T.isChartComponent(component)) {
    return <ComponentMap.Chart key={component.id} component={component} />;
  }

  if (T.isDialsComponent(component)) {
    return <ComponentMap.Dials key={component.id} component={component} />;
  }

  if (T.isFilterComponent(component)) {
    return <ComponentMap.Filter key={component.id} component={component} />;
  }

  if (T.isFiltersComponent(component)) {
    return <ComponentMap.Filters key={component.id} component={component} />;
  }

  if (T.isGridComponent(component)) {
    return <ComponentMap.Grid key={component.id} component={component} />;
  }

  if (T.isHeaderRowComponent(component)) {
    return <ComponentMap.HeaderRow key={component.id} component={component} />;
  }

  if (T.isKpiComponent(component)) {
    return <ComponentMap.Kpi key={component.id} component={component} />;
  }

  if (T.isPlailistComponent(component)) {
    return <ComponentMap.Plailist key={component.id} component={component} />;
  }

  if (T.isRecommendationsActionsComponent(component)) {
    return <ComponentMap.Recommendations key={component.id} component={component} />;
  }

  if (T.isRecommendationsBreakdownComponent(component)) {
    return <ComponentMap.RecommendationsBreakdown key={component.id} component={component} />;
  }

  if (T.isRecommenderComponent(component)) {
    return <ComponentMap.Recommender key={component.id} component={component} />;
  }

  if (T.isStoryListComponent(component)) {
    return <ComponentMap.StoryList key={component.id} component={component} />;
  }

  if (T.isSummaryInfoComponent(component)) {
    return <ComponentMap.SummaryInfo key={component.id} component={component} />;
  }

  if (T.isTableComponent(component)) {
    return <ComponentMap.Table key={component.id} component={component} />;
  }

  if (T.isTableSwitcherComponent(component)) {
    return <ComponentMap.TableSwitcher key={component.id} component={component} />;
  }

  if (T.isNavigationTabsComponent(component)) {
    return <ComponentMap.NavigationTabs key={component.id} component={component} />;
  }

  if (T.isColumnLayoutComponent(component)) {
    return <ComponentMap.ColumnLayout key={component.id} component={component} />;
  }

  if (T.isUserAnalyticsComponent(component)) {
    return <ComponentMap.UserAnalytics key={component.id} component={component} />;
  }

  if (T.isUserPrioritiesComponent(component)) {
    return <ComponentMap.UserPriorities key={component.id} component={component} />;
  }

  if (T.isButtonComponent(component)) {
    return <ComponentMap.Button key={component.id} component={component} />;
  }

  return null;
};

export default Component;
