import { FilterSearchParams } from '../constants';
import { useSearchParams } from './useSearchParams';
import { useSerializedNumber } from './useSerializedNumber';

/**
 * Custom hook to retrieve the priority id from the current URL params.
 *
 * @returns {number | null} - Returns the priority id or undefined if the priority parameter is not a valid number.
 */
export function usePriorityId() {
  const searchParams = useSearchParams();
  return useSerializedNumber(searchParams.get(FilterSearchParams.PRIORITY_ID)) ?? undefined;
}
