import * as T from '@aily/graphql-sdk/schema';
import { AiIcon } from '@aily-labs/ui';
import { ExperimentIcon } from '@aily-labs/ui/beta';
import { styled } from '@mui/material';
import React, { FC, useCallback } from 'react';

import { CalendarOutlined, EuroCircleOutlined, OpenInNew, PeopleOutlined } from '../../icons';
import { colors } from '../../theme/default/colors';
import { RhombusSymbol } from '../Timeline';

export interface TableCellIconLinkProps {
  iconLink: T.IconLinkResult;
  onClick?: (icon: T.IconLinkResult) => void;
}

const IconWrapper = styled('div')<{
  showIcon?: boolean;
}>(({ showIcon }) => ({
  visibility: showIcon ? 'visible' : 'hidden',
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer',
    '& path': {
      fill: showIcon ? colors.neutrals.grey3 : colors.neutrals.white,
    },
  },
}));

const getCellIconComponent = (icon: T.Icon) => {
  return {
    [T.Icon.OpenInNew]: (
      <OpenInNew
        style={{ color: colors.neutrals.grey3, height: 18 }}
        data-testid="OpenInNewCellIcon"
      />
    ),
    [T.Icon.Analyzer]: <ExperimentIcon color={colors.neutrals.grey3} />,
    [T.Icon.Calendar]: <CalendarOutlined />,
    [T.Icon.Euro]: <EuroCircleOutlined />,
    [T.Icon.People]: <PeopleOutlined />,
    [T.Icon.Rhombus]: <RhombusSymbol />,
    [T.Icon.Ai]: <AiIcon />,
  }[icon];
};

export const TableCellIconLink: FC<TableCellIconLinkProps> = ({ iconLink, onClick }) => {
  const handleClick = useCallback(() => {
    onClick?.(iconLink);
  }, [onClick, iconLink]);

  const cellIcon =
    iconLink.linkResult && iconLink.icon ? getCellIconComponent(iconLink.icon) : <></>;

  return (
    <IconWrapper onClick={handleClick} showIcon={!!iconLink.showIcon}>
      <>{cellIcon}</>
    </IconWrapper>
  );
};

export default TableCellIconLink;
