import * as T from '@aily/graphql-sdk/schema';
import { Box, Popover, styled, Tab, Tabs } from '@mui/material';
import React, { useCallback, useState } from 'react';

import NestedList from '../NestedList';
import { SearchBar } from '../SearchBar';

const StyledPopover = styled(Popover)(({ theme }) => ({
  '&.MuiPopover-root': {
    zIndex: 10000,
  },
  '& .MuiPopover-paper': {
    width: 375,
    maxHeight: 574,
    background: theme.palette.background.default,
    boxShadow: theme.shadows[10],
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

interface Props {
  anchorElement?: Element;
  options: T.PlailistFilterOption[];
  selectedOption?: T.PlailistFilterOption;
  onSelect?: (selectedOption: T.PlailistFilterOption) => void;
  onClose?: () => void;
}

const PlailistFilterDropdown: React.FC<Props> = ({
  anchorElement,
  options,
  selectedOption,
  onSelect,
  onClose,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const close = useCallback(() => {
    onClose?.();
    setSearchTerm('');
  }, [onClose]);

  const select = useCallback(
    (option: T.PlailistFilterOption) => {
      close();
      onSelect?.(option);
    },
    [onSelect, close],
  );

  const open = !!anchorElement;

  return (
    <StyledPopover
      open={open}
      anchorEl={anchorElement}
      onClose={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <SearchBar value={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Tabs value={0}>
        <Tab label="Filter" />
      </Tabs>
      <div role="tabpanel">
        <NestedList<T.PlailistFilterOption>
          containerMaxHeight={414}
          itemHeight={46}
          items={options}
          currentItem={selectedOption}
          itemKey={({ id }) => id.toString()}
          itemLabel={({ label, value }) => label ?? String(value)}
          itemChildren={(filterOption) => filterOption.children ?? []}
          selectable={({ value }) => !!value}
          onSelect={select}
          search={searchTerm}
        />
      </div>
    </StyledPopover>
  );
};

export default PlailistFilterDropdown;
