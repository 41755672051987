export class SVGAnimator {
  private svgElement: SVGSVGElement;
  private targetElement: HTMLElement | null = null;
  private currentCorner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'top-right';
  private resizeObserver: ResizeObserver | null = null;

  constructor(svgElement: SVGSVGElement) {
    this.svgElement = svgElement;
    this.handleResize = this.handleResize.bind(this);
  }

  /**
   * Adds 'active' class to the SVG element to start any CSS-based animations.
   */
  public enter(): void {
    this.svgElement.classList.add('active');
  }

  /**
   * Removes 'active' class from the SVG element to end any CSS-based animations.
   */
  public exit(): void {
    this.svgElement.classList.remove('active');
  }

  /**
   * Moves the SVG element to align with the specified corner of the target HTML element.
   * @param {HTMLElement} element - The target HTML element to align the SVG with.
   * @param {'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'} corner - The corner of the target element to align the SVG with.
   */
  public moveToElement(
    element: HTMLElement,
    corner: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'top-right',
  ): void {
    this.targetElement = element;
    this.currentCorner = corner;
    this.updatePosition();
    this.addResizeObserver();
  }

  private handleResize(): void {
    this.updatePosition();
  }

  private addResizeObserver(): void {
    if ('ResizeObserver' in window) {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(document.body);

      if (this.targetElement) {
        this.resizeObserver.observe(this.targetElement);
      }
    }
  }

  private removeResizeObserver(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  private updatePosition(): void {
    if (!this.targetElement) return;

    const rect = this.targetElement.getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    let x = 0;
    let y = 0;

    switch (this.currentCorner) {
      case 'top-left':
        x = rect.left + scrollX;
        y = rect.top + scrollY;
        break;
      case 'top-right':
        x = rect.right + scrollX;
        y = rect.top + scrollY;
        break;
      case 'bottom-left':
        x = rect.left + scrollX;
        y = rect.bottom + scrollY;
        break;
      case 'bottom-right':
        x = rect.right + scrollX;
        y = rect.bottom + scrollY;
        break;
    }

    // Apply a CSS transform to position the SVG element at the calculated coordinates
    this.svgElement.style.transform = `translate(${x - this.svgElement.clientWidth / 2}px, ${y - this.svgElement.clientHeight / 2}px)`;
  }

  public dispose(): void {
    this.removeResizeObserver();
    this.targetElement = null;
  }
}
