import * as T from '@aily/graphql-sdk/schema';

import { mapColorUnionToColor } from '../../utils';
import { getStoryGradient } from '../Recommender/utils';

export function getPlailistGradient(plailist: T.Plailist) {
  const whisperRealStory = plailist.stories?.find(
    (story) => story?.storyType === T.StoryDisplayType.WhisperReal,
  );

  if (whisperRealStory) {
    return getStoryGradient(whisperRealStory);
  }

  const { colorStart, colorEnd } = plailist;
  const gradientColors =
    colorStart && colorEnd
      ? [mapColorUnionToColor(colorStart), mapColorUnionToColor(colorEnd)]
      : undefined;

  if (gradientColors) {
    return `linear-gradient(180deg, ${gradientColors.join(', ')})`;
  }

  return undefined;
}
