import { styled } from '@mui/material';
import React from 'react';

const StyledSvg = styled('svg')(() => ({
  position: 'absolute',
  zIndex: 9999,
  overflow: 'visible',
  opacity: 0,
  top: 0,
  left: 0,
  transformOrigin: 'center',
  transition: 'opacity 300ms ease-in, transform 1.5s cubic-bezier(0.22, 1, 0.36, 1)',
  willChange: 'transform',
  pointerEvents: 'none',
  '&.active': {
    opacity: 1,
  },
  '& .body': {
    animation: 'hoverBody 5s infinite ease-in-out',
  },
  '& .body__shadow': {
    transformOrigin: 'center',
    animation: 'pulseShadow 4s infinite ease-in-out',
  },
  '& .body__glow': {
    transformOrigin: 'center',
    animation: 'pulseGlow 6s infinite ease-in-out, rotateGlow 8s alternate infinite linear',
  },
  '& .body__core': {
    transformOrigin: 'center',
    animation: 'pulseCore 8s infinite ease-in-out, rotateCore 10s alternate infinite linear',
  },
  '@keyframes hoverBody': {
    '0%, 100%': { transform: 'translateY(0px)' },
    '50%': { transform: 'translateY(-15px)' },
  },
  '@keyframes pulseShadow': {
    '0%, 100%': { r: 29 },
    '50%': { r: 50 },
  },
  '@keyframes pulseGlow': {
    '0%, 100%': { rx: 27, ry: 25 },
    '50%': { rx: 33, ry: 31 },
  },
  '@keyframes pulseCore': {
    '0%, 100%': { rx: 19.5, ry: 18 },
    '50%': { rx: 25, ry: 22 },
  },
  '@keyframes rotateGlow': {
    '0%, 100%': { transform: 'rotate(0deg)' },
    '50%': { transform: 'rotate(180deg)' },
  },
  '@keyframes rotateCore': {
    '0%, 100%': { transform: 'rotate(0deg)' },
    '50%': { transform: 'rotate(180deg)' },
  },
}));

export const WispSvg = React.forwardRef(
  (props: React.SVGAttributes<SVGSVGElement>, ref: React.Ref<SVGSVGElement>) => (
    <StyledSvg
      ref={ref}
      id="wisp"
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="186"
      viewBox="0 0 186 186"
      fill="none"
      {...props}
    >
      <g className="body">
        <g className="body__shadow" filter="url(#filter0_f_25523_81989)">
          <circle cx="92.9084" cy="92.9084" r="29.9084" fill="black" />
        </g>
        <g className="body__glow" filter="url(#filter1_f_25523_81989)">
          <ellipse cx="93.159" cy="95.027" rx="27.1668" ry="25.796" fill="#38E787" />
        </g>
        <g className="body__core" filter="url(#filter2_f_25523_81989)">
          <ellipse cx="100.5" cy="86.8169" rx="19.5" ry="18" fill="white" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_25523_81989"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="31.25" result="effect1_foregroundBlur_25523_81989" />
        </filter>
        <filter
          id="filter1_f_25523_81989"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_25523_81989" />
        </filter>
        <filter
          id="filter2_f_25523_81989"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12.5" result="effect1_foregroundBlur_25523_81989" />
        </filter>
      </defs>
    </StyledSvg>
  ),
);
