import { Link } from '@mui/material';
import React from 'react';

import { OpenInNew } from '../../../icons';
import { colors } from '../../../theme/default/colors';

interface Props {
  children: React.ReactNode;
  href: string;
}

/**
 * A custom Markdown component to handle rendering of an inline Link
 */
const MarkdownLink: React.FC<Props> = ({ children, href }) => {
  return (
    <Link
      href={href}
      variant={'h8'}
      underline="none"
      color={colors.neutrals.grey3}
      sx={{ '&:hover': { color: colors.neutrals.grey4 } }}
    >
      <OpenInNew sx={{ fontSize: 18, mr: 1, verticalAlign: 'bottom', mb: 0.5 }} />
      {children}
    </Link>
  );
};

export default MarkdownLink;
