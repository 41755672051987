import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const AiActive: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <g clipPath="url(#clip0_20329_154038)">
      <path
        d="M24.1878 19.929C24.1878 20.2401 24.0811 20.4934 23.8678 20.689C23.6633 20.8845 23.4144 20.9823 23.1211 20.9823C22.8189 20.9823 22.5656 20.8845 22.3611 20.689C22.1567 20.4934 22.0544 20.2401 22.0544 19.929C22.0544 19.6179 22.1567 19.3645 22.3611 19.169C22.5656 18.9645 22.8189 18.8623 23.1211 18.8623C23.4144 18.8623 23.6633 18.9645 23.8678 19.169C24.0811 19.3645 24.1878 19.6179 24.1878 19.929Z"
        fill="#31D4B6"
      />
      <path
        d="M20.3158 20.8092H18.7024V13.8359H20.3158V20.8092ZM20.4891 11.7959C20.4891 12.0981 20.3913 12.3381 20.1958 12.5159C20.0002 12.6937 19.7691 12.7826 19.5024 12.7826C19.2269 12.7826 18.9913 12.6937 18.7958 12.5159C18.6002 12.3381 18.5024 12.0981 18.5024 11.7959C18.5024 11.4848 18.6002 11.2403 18.7958 11.0626C18.9913 10.8848 19.2269 10.7959 19.5024 10.7959C19.7691 10.7959 20.0002 10.8848 20.1958 11.0626C20.3913 11.2403 20.4891 11.4848 20.4891 11.7959Z"
        fill="white"
      />
      <path
        d="M14.0202 19.6754C14.5179 19.6754 14.8957 19.6488 15.1535 19.5954V17.8088C15.0646 17.7821 14.9357 17.7554 14.7668 17.7288C14.5979 17.7021 14.4113 17.6888 14.2068 17.6888C14.029 17.6888 13.8468 17.7021 13.6602 17.7288C13.4824 17.7554 13.3179 17.8043 13.1668 17.8754C13.0246 17.9466 12.909 18.0488 12.8202 18.1821C12.7313 18.3066 12.6868 18.4666 12.6868 18.6621C12.6868 19.0443 12.8068 19.311 13.0468 19.4621C13.2868 19.6043 13.6113 19.6754 14.0202 19.6754ZM13.8868 13.6621C14.4202 13.6621 14.869 13.7288 15.2335 13.8621C15.5979 13.9954 15.8868 14.1821 16.1002 14.4221C16.3224 14.6621 16.4779 14.9554 16.5668 15.3021C16.6646 15.6399 16.7135 16.0132 16.7135 16.4221V20.6488C16.4646 20.7021 16.0868 20.7643 15.5802 20.8354C15.0824 20.9154 14.5179 20.9554 13.8868 20.9554C13.469 20.9554 13.0868 20.9154 12.7402 20.8354C12.3935 20.7554 12.0957 20.6266 11.8468 20.4488C11.6068 20.271 11.4157 20.0399 11.2735 19.7554C11.1402 19.471 11.0735 19.1199 11.0735 18.7021C11.0735 18.3021 11.149 17.9643 11.3002 17.6888C11.4602 17.4132 11.6735 17.191 11.9402 17.0221C12.2068 16.8443 12.5135 16.7199 12.8602 16.6488C13.2157 16.5688 13.5846 16.5288 13.9668 16.5288C14.1446 16.5288 14.3313 16.5421 14.5268 16.5688C14.7224 16.5866 14.9313 16.6221 15.1535 16.6754V16.4088C15.1535 16.2221 15.1313 16.0443 15.0868 15.8754C15.0424 15.7066 14.9624 15.5599 14.8468 15.4354C14.7402 15.3021 14.5935 15.1999 14.4068 15.1288C14.229 15.0577 14.0024 15.0221 13.7268 15.0221C13.3535 15.0221 13.0113 15.0488 12.7002 15.1021C12.389 15.1554 12.1357 15.2177 11.9402 15.2888L11.7402 13.9821C11.9446 13.911 12.2424 13.8399 12.6335 13.7688C13.0246 13.6977 13.4424 13.6621 13.8868 13.6621Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8095 3.19048C15.8095 2.53299 16.3425 2 17 2C25.2843 2 32 8.71573 32 17C32 25.2843 25.2843 32 17 32C8.71573 32 2 25.2843 2 17C2 16.3425 2.53299 15.8095 3.19048 15.8095C3.84796 15.8095 4.38095 16.3425 4.38095 17C4.38095 23.9693 10.0307 29.619 17 29.619C23.9693 29.619 29.619 23.9693 29.619 17C29.619 10.0307 23.9693 4.38095 17 4.38095C16.3425 4.38095 15.8095 3.84796 15.8095 3.19048Z"
        fill="url(#paint0_linear_20329_154038)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_20329_154038"
        x1="2"
        y1="17"
        x2="32"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48FF9B" />
        <stop offset="1" stopColor="#31D4B6" />
      </linearGradient>
      <clipPath id="clip0_20329_154038">
        <rect width="34" height="34" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
