import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

export interface InterceptContentProps extends BoxProps {}

const StyledBox = styled(Box)({
  width: 400,
});

export const InterceptContent: React.FC<InterceptContentProps> = ({ children, ...rest }) => (
  <StyledBox data-testid="InterceptContent" {...rest}>
    {children}
  </StyledBox>
);
