import { MenuItem } from '@mui/material';
import { find } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { Select, SelectProps } from '../Select';

export interface TableColumnAbstractType {
  dataKey: string;
  title?: string | null;
}

export interface TableColumnSelectProps<T> extends Omit<SelectProps<string>, 'onChange'> {
  columns: T[];
  onChange?: (newColumn: T) => void;
}

function mapColumnToMenuItem({ dataKey, title }: TableColumnAbstractType) {
  return (
    <MenuItem key={dataKey} value={dataKey}>
      {title ?? dataKey}
    </MenuItem>
  );
}

/**
 * This component enables the selection of a column defined by an abstract type.
 */
export const TableColumnSelect = <T extends TableColumnAbstractType>({
  columns,
  onChange,
  ...rest
}: TableColumnSelectProps<T>) => {
  const handleChange = useCallback(
    (newDataKey: string) => {
      const newColumn = find(columns, ({ dataKey }) => dataKey === newDataKey);
      if (newColumn) {
        onChange?.(newColumn);
      }
    },
    [columns, onChange],
  );

  const memoizedMenuItems = useMemo(() => columns.map(mapColumnToMenuItem), [columns]);

  return (
    <Select onChange={handleChange} {...rest}>
      {memoizedMenuItems}
    </Select>
  );
};
