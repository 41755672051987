import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { usePlailists } from './usePlailists';

type PlailistsContextState = ReturnType<typeof usePlailists>;

const PlailistsContext = createContext<PlailistsContextState | null>(null);

export const PlailistsContextProvider: React.FC<PropsWithChildren<PlailistsContextState>> = ({
  children,
  state,
  actions,
}) => {
  const value = useMemo<PlailistsContextState>(() => ({ state, actions }), [state, actions]);
  return <PlailistsContext.Provider value={value}>{children}</PlailistsContext.Provider>;
};

export function usePlailistsContext() {
  return useContext(PlailistsContext);
}

export default PlailistsContext;
