import * as T from '@aily/graphql-sdk/schema';
import { StoryDisplayType } from '@aily/graphql-sdk/schema';
import { AilyBrainCard, Box, ChevronRightIcon, useTheme } from '@aily-labs/ui';
import {
  alpha,
  ListItemAvatar as MuiListItemAvatar,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
  ListItemText,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { kebabCase } from 'lodash-es';
import React from 'react';

import { lineClamp } from '../../theme/utils';
import { getTriangularCornerProps } from '../Recommender/utils';
import StoryAvatar from './StoryAvatar';

export interface Props extends ListItemButtonProps {
  story: T.Story;
  checked?: boolean;
}

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  padding: 0,
  '& .MuiListItemAvatar-root::after': {
    content: '""',
    overflow: 'hidden',
    backfaceVisibility: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 'inherit',
    opacity: 0,
    backgroundColor: 'currentColor',
    transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& .MuiListItemAvatar-root::after': {
      opacity: 0.04,
    },
  },
  '& .MuiListItemText-root + .MuiSvgIcon-root': {
    marginLeft: theme.spacing(2),
    fill: 'none',
    stroke: theme.palette.text.secondary,
    fontSize: 17,
  },
}));

const ListItemAvatar = styled(MuiListItemAvatar)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  minWidth: 'auto',
  marginRight: theme.spacing(2),
  borderRadius: 12,
}));

const Headline = styled(({ children, ...rest }: TypographyProps<'div'>) => (
  <Typography variant="body" component="div" {...rest}>
    {children}
  </Typography>
))({
  ...lineClamp(2, { lineHeight: 1.2 }),
});

const TriangularCorner = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: -1,
  bottom: -1,
  width: 20,
  height: 20,
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)',
  pointerEvents: 'none',
  color: theme.palette.text.primary,
  '& > svg': {
    position: 'absolute',
    right: 4,
    bottom: 4,
  },
}));

const CheckedIcon = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="currentColor">
    <path d="M7.43124 0.230469H6.83163C6.74756 0.230469 6.66779 0.268521 6.61632 0.333633L3.07957 4.75024L1.38368 2.63199C1.35803 2.59989 1.32533 2.57392 1.28804 2.55606C1.25075 2.53819 1.20983 2.52888 1.16837 2.52883H0.568764C0.51129 2.52883 0.479551 2.59394 0.514722 2.63791L2.86426 5.57217C2.97406 5.70916 3.18508 5.70916 3.29574 5.57217L7.48528 0.338707C7.52045 0.295581 7.48871 0.230469 7.43124 0.230469Z" />
  </svg>
);

export function getItemTitle(story: T.Story): string {
  if (story.storyType === StoryDisplayType.Real) {
    try {
      const jsonData = story.caption ? JSON.parse(story.caption) : {};
      if (typeof jsonData.real_title === 'string') {
        return jsonData.real_title;
      }
    } catch (e) {
      // Nothing here
    }
  }

  return story.insightsType === T.InsightsType.Story
    ? story?.headline
    : (story as T.Real & T.Story)?.title;
}

const StoryListItem: React.FC<Props> = ({ story, checked, ...rest }) => {
  const {
    colors: { colorNeutralGreyLight, colorNeutralWhite },
  } = useTheme();

  return (
    <ListItemButton
      data-testid="story-list-item"
      data-x-story-type={kebabCase(story.storyType)}
      data-x-sentiment={story.sentiment ? kebabCase(story.sentiment) : undefined}
      {...rest}
    >
      <ListItemAvatar>
        <StoryAvatar story={story} />
        {!!checked && (
          <TriangularCorner {...getTriangularCornerProps(story)}>
            <CheckedIcon />
          </TriangularCorner>
        )}
      </ListItemAvatar>
      <ListItemText>
        <Headline data-testid="headline">{getItemTitle(story)}</Headline>
        <Typography variant="small" color="text.secondary" data-testid="publish-date">
          {story.publishDate}
        </Typography>
        {Boolean(story?.brainsCount) && (
          <Box flexDirection="row" gap={5}>
            <AilyBrainCard color={colorNeutralGreyLight} />
            <Typography variant="small" color="text.secondary" data-testid="publish-date">
              Added to {story?.brainsCount} {story?.brainsCount === 1 ? 'brain' : 'brains'}
            </Typography>
          </Box>
        )}
      </ListItemText>
      <ChevronRightIcon color={colorNeutralWhite} height={10} width={10} />
    </ListItemButton>
  );
};

export default StoryListItem;
