import { motion } from 'framer-motion';
import React from 'react';

export interface LogoProps {
  loading?: boolean;
}

const Logo = ({ loading }: LogoProps) => (
  <svg
    width="200"
    height="308"
    viewBox="0 0 200 308"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible', marginBottom: -60 }}
  >
    <g filter="url(#filter0_d_24316_27592)">
      <circle cx="100" cy="100" r="80" fill="black" />
    </g>
    <path
      d="M26.2223 100C22.7858 100 19.9748 102.79 20.2418 106.216C21.3042 119.849 25.8473 133.019 33.4824 144.446C42.2729 157.602 54.7672 167.855 69.3853 173.91C84.0034 179.965 100.089 181.55 115.607 178.463C131.126 175.376 145.38 167.757 156.569 156.569C167.757 145.38 175.376 131.126 178.463 115.607C181.55 100.089 179.965 84.0034 173.91 69.3853C167.855 54.7672 157.602 42.2729 144.446 33.4824C133.019 25.8473 119.849 21.3042 106.216 20.2418C102.79 19.9748 100 22.7858 100 26.2223C100 29.6587 102.792 32.4148 106.214 32.7308C117.382 33.7624 128.152 37.5625 137.532 43.8297C148.641 51.2528 157.3 61.8035 162.413 74.1476C167.526 86.4918 168.864 100.075 166.257 113.179C163.651 126.284 157.217 138.321 147.769 147.769C138.321 157.217 126.284 163.651 113.179 166.257C100.075 168.864 86.4918 167.526 74.1476 162.413C61.8035 157.3 51.2528 148.641 43.8297 137.532C37.5625 128.152 33.7624 117.382 32.7308 106.214C32.4148 102.792 29.6587 100 26.2223 100Z"
      fill="url(#paint0_linear_24316_27592)"
    />
    <path
      d="M87.6742 110.637C89.6851 110.637 92.0178 110.261 93.5461 109.913V102.968C91.8837 102.727 89.4974 102.486 87.5938 102.486C83.0089 102.486 81.1588 104.014 81.1588 106.534C81.1857 109.403 83.9473 110.637 87.6742 110.637ZM87.9155 83.6099C95.5033 83.6099 100.651 87.4172 100.651 96.0507V113.934C100.651 114.417 100.41 114.792 99.981 114.98C96.7903 115.972 92.2054 116.83 87.6742 116.83C80.569 116.83 74 114.256 74 106.588C74 99.9117 79.5769 96.7746 87.4329 96.7746C89.4438 96.7746 91.7228 97.016 93.5729 97.2573V96.1043C93.5729 91.7608 91.991 89.9107 87.4329 89.9107C84.3763 89.9107 81.561 90.7151 79.5769 91.868C79.1479 92.163 78.6653 92.1093 78.3436 91.6267L75.9037 87.8998C75.6087 87.4172 75.6087 87.0954 75.9037 86.8541C78.853 84.8164 82.9553 83.6099 87.9155 83.6099Z"
      fill="white"
    />
    <path
      d="M110.572 80.0437C108.293 80.0437 106.523 78.2741 106.523 75.995C106.523 73.6624 108.293 72 110.572 72C112.905 72 114.567 73.6624 114.567 75.995C114.567 78.2741 112.905 80.0437 110.572 80.0437Z"
      fill="white"
    />
    <path
      d="M122.771 116.106C120.492 116.106 118.723 114.337 118.723 112.058C118.723 109.725 120.492 108.062 122.771 108.062C125.104 108.062 126.766 109.725 126.766 112.058C126.766 114.337 125.104 116.106 122.771 116.106Z"
      fill="#31D4B6"
    />
    <path
      d="M107.89 116.079C107.327 116.079 106.979 115.784 106.979 115.167V85.1915C106.979 84.6284 107.354 84.3335 107.89 84.3335H113.226C113.789 84.3335 114.084 84.6284 114.084 85.1915V115.167C114.084 115.784 113.789 116.079 113.226 116.079H107.89Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_24316_27592"
        x="-108"
        y="-108"
        width="416"
        height="416"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="28"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_24316_27592"
        >
          {loading && (
            <animate
              attributeName="radius"
              values="7;28;7"
              keyTimes="0;0.5;1"
              dur="2.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.4 0 0.6 1; 0.4 0 0.6 1"
            />
          )}
        </feMorphology>
        <feOffset />
        <feGaussianBlur stdDeviation="50">
          {loading && (
            <animate
              attributeName="stdDeviation"
              values="40;60;40"
              keyTimes="0;0.5;1"
              dur="2.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0.4 0 0.6 1; 0.4 0 0.6 1"
            />
          )}
        </feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.32549 0 0 0 0 1 0 0 0 0 0.607843 0 0 0 0.25 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_24316_27592" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_24316_27592"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_24316_27592"
        x1="20"
        y1="100"
        x2="180"
        y2="100"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#48FF9B" />
        <stop offset="1" stopColor="#31D4B6" />
      </linearGradient>
    </defs>
  </svg>
);

const AnimatedLogo = (props: LogoProps) => (
  <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }}>
    <Logo {...props} />
  </motion.div>
);

export { AnimatedLogo as Logo };
