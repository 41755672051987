import * as T from '@aily/graphql-sdk/schema';

import H from '../../config/highchartsConfig';
import { ExtendedSeriesOptions } from './types';
import { resolveSeriesColor } from './utils';

export const usePlotLines = () => {
  const getTextAndValueLineDescription = (series: T.Maybe<T.ChartSeries[]> | undefined) => {
    const labels: H.YAxisPlotLinesOptions[] = [];

    series
      ?.filter((x: T.ChartSeries) => !!(x.renderer as ExtendedSeriesOptions)?.label)
      .forEach((series: T.ChartSeries) => {
        const yValue = series.data?.slice(-1)[0].y?.value?.raw;
        const yFormatted = series.data?.slice(-1)[0].y?.value?.formatted ?? '';
        const labelText: H.YAxisPlotLinesOptions = {
          color: 'transparent',
          value: yValue?.valueOf(),
          label: {
            text: yFormatted,
            align: 'right',
            verticalAlign: 'bottom',
            x: +35,
            y: +2,
            style: {
              color: resolveSeriesColor(series)?.toString(),
            },
          },
        };

        const labelValue: H.YAxisPlotLinesOptions = {
          color: 'transparent',
          value: yValue?.valueOf(),
          label: {
            text: (series.renderer as ExtendedSeriesOptions)?.label?.for,
            align: 'left',
            verticalAlign: 'bottom',
            x: +2,
            style: {
              color: resolveSeriesColor(series)?.toString(),
            },
          },
        };

        labels.push(labelText);
        labels.push(labelValue);
      });

    return labels;
  };

  return {
    getTextAndValueLineDescription,
  };
};
