import React from 'react';

import { AchievementBadgeProps } from './AchievementBadge';

export const AchievementBadgeSilver: React.FC<AchievementBadgeProps> = ({
  width = 212,
  height = 220,
  backgroundColor = '#000',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 212 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="achievement-badge__silver"
  >
    <path
      d="M20 38.4444L107.022 0.113535L193 37.4054V99.7477C192.307 130.919 174.089 198.249 106.76 218.198C42.755 201.574 22.2513 132.304 20 99.7477V38.4444Z"
      fill={backgroundColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.368 3.47886L26.0877 40.4601V96.4412C26.0877 150.627 60.3519 200.504 106.368 215.731C152.385 200.504 186.649 150.627 186.649 96.4412V40.4601L106.368 3.47886ZM189.737 38.4036L106.368 0L23 38.4036V96.4412C23 152.256 58.4911 203.749 106.368 219.061C154.246 203.749 189.737 152.256 189.737 96.4412V38.4036Z"
      fill="url(#paint0_linear_5951_27614)"
    />
    <path
      d="M171.211 52.8357L106.368 22.2241L41.5264 52.8357V99.0975C41.5264 143.588 69.1306 184.633 106.368 196.838C143.606 184.633 171.211 143.588 171.211 99.0975V52.8357Z"
      fill="url(#paint1_linear_5951_27614)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.368 25.7176L44.6141 54.8714V99.0975C44.6141 141.991 71.0261 181.38 106.368 193.499C141.711 181.38 168.123 141.991 168.123 99.0975V54.8714L106.368 25.7176ZM171.211 52.8357L106.368 22.2241L41.5264 52.8357V99.0975C41.5264 143.588 69.1306 184.633 106.368 196.838C143.606 184.633 171.211 143.588 171.211 99.0975V52.8357Z"
      fill="url(#paint2_linear_5951_27614)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.367 62.8325L118.637 90.6274L150.486 93.2744L126.367 113.295L133.514 142.877L106.367 127.199L79.2208 142.877L86.368 113.295L62.249 93.2744L94.0979 90.6274L106.367 62.8325Z"
      fill="url(#paint3_linear_5951_27614)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5951_27614"
        x1="113.038"
        y1="-1.47068"
        x2="113.038"
        y2="219.061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A92AC" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5951_27614"
        x1="106.368"
        y1="22.2241"
        x2="106.368"
        y2="196.838"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.609375" stopColor="white" stopOpacity="0.4" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5951_27614"
        x1="111.556"
        y1="21.0518"
        x2="111.556"
        y2="196.838"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8A92AC" />
        <stop offset="0.260417" stopColor="#D9D9D9" />
        <stop offset="0.489583" stopColor="white" />
        <stop offset="1" stopColor="#5D77A4" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5951_27614"
        x1="109.579"
        y1="66.1831"
        x2="109.579"
        y2="139.694"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.26" />
        <stop offset="0.479167" stopColor="#EDEDED" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0.49" />
      </linearGradient>
    </defs>
  </svg>
);
