import { styled, Typography } from '@mui/material';
import React from 'react';

import { FitText } from '../FitText';

export interface LocationMarkerProps {
  lat: number;
  lng: number;
  size?: number;
  lineWidth?: number;
  color?: string;
  label?: string;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  textAlign: 'center',
  userSelect: 'none',
  '& > *': {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const LocationMarker: React.FC<LocationMarkerProps> = ({
  size = 60,
  lineWidth = 2,
  color = '#fff',
  label,
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    x={-size / 2}
    y={-size / 2}
  >
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 2 - lineWidth / 2}
      stroke={color}
      strokeWidth={lineWidth}
    />
    {!!label && (
      <foreignObject width={size} height={size}>
        <StyledTypography variant="body" sx={{ width: size, height: size, lineHeight: 1.2 }}>
          <FitText maxFontSize={120}>{label}</FitText>
        </StyledTypography>
      </foreignObject>
    )}
  </svg>
);
