import { StoryDecision } from '@aily/graphql-sdk/schema';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { useSlideshow } from '../../../../components';
import { useAilyAgent } from '../../providers';
import { AgentJsonData, Popup as PopupType, Screen, ScreenType } from '../../types/agentJsonData';
import { getAgentAudioPath } from '../../utils/getAgentAudioPath';
import { AgentDecisionLeversPopup } from '../AgentDecisionLeversPopup/AgentDecisionLeversPopup';
import AgentDefault from '../AgentDefault/AgentDefault';
import { AgentElementRenderer } from '../AgentElementRenderer/AgentElementRenderer';
import { DecisionLevers } from '../AgentScreenItems';
import { PopupProps } from '../Popup';
import { PopupButton, PopupButtonProps } from '../PopupButton';

export interface AgentSlideshowSlideSectionBuilderProps {
  screen: Screen;
  titleRef?: React.Ref<HTMLElement>;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  StackProps?: StackProps;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

// Popup component that includes the PopupContent
const ScreenPopupComponent: React.FC<
  PopupProps & {
    screens: Screen[];
    baseAudioURL: string;
    agentJsonData: AgentJsonData;
    decision: StoryDecision | null;
    onAgentClose?: () => void;
    onBackdropClose?: (forceRefetch: boolean) => void;
  }
> = ({
  screens,
  title,
  open,
  onClose,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
}) => {
  const { startOperations, exit } = useAilyAgent();
  const { state } = useSlideshow();
  const { audioEnabled } = state;

  const handleClose = useCallback(() => {
    exit();
    onClose?.();
  }, [onClose, exit]);

  const elementRef = useCallback(
    (node: HTMLElement | null) => {
      if (node && open && screens?.[0].audio_track && audioEnabled) {
        startOperations([
          {
            audioPath: getAgentAudioPath(baseAudioURL, screens?.[0].audio_track),
            elementRef: node,
            delayAfterEnd: 1000,
            corner: 'top-right',
          },
        ]);
      }
    },
    [screens, open, audioEnabled, baseAudioURL, startOperations],
  );

  if (!screens) return null;

  return (
    <AgentDecisionLeversPopup
      title={
        <Typography ref={elementRef} variant="h5">
          {title}
        </Typography>
      }
      screens={screens}
      open={open}
      onClose={handleClose}
      baseAudioURL={baseAudioURL}
      agentJsonData={agentJsonData}
      decision={decision}
      textElementStyle={{ paddingBottom: screens[0].screen_type === ScreenType.Default ? 15 : 5 }}
      onAgentClose={onAgentClose}
      onBackdropClose={onBackdropClose}
    />
  );
};

// Button component for the popup
const ScreenPopupButtonComponent: React.FC<
  Omit<PopupButtonProps, 'children'> & { popup: PopupType }
> = ({ popup, ...rest }) => {
  if (!popup || !popup.button_title) return null;

  const { button_logo, button_title } = popup;

  return (
    <PopupButton prefix={button_logo} {...rest}>
      {button_title}
    </PopupButton>
  );
};

// Slideshow section builder
export const AgentSlideshowSlideSectionBuilder: React.FC<
  AgentSlideshowSlideSectionBuilderProps
> = ({
  screen,
  titleRef,
  onAfterScreen,
  StackProps,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
}) => {
  const renderPopupComponent = useCallback(
    (props: PopupProps) => {
      return (
        <>
          {screen.popup && (
            <ScreenPopupComponent
              title={screen.popup?.button_title || ''}
              screens={screen.popup.screens}
              baseAudioURL={baseAudioURL}
              agentJsonData={agentJsonData}
              decision={decision}
              onAgentClose={onAgentClose}
              onBackdropClose={onBackdropClose}
              {...props}
            />
          )}
        </>
      );
    },
    [screen, baseAudioURL],
  );

  const renderTypeAgnosticScreens = useMemo(() => {
    return (
      <Box>
        {screen.content?.map(({ elements: row }, rowIndex) => (
          <Stack key={`row-${rowIndex}`}>
            {row?.map((col, colIndex) => (
              <Stack key={`col-${rowIndex}-${colIndex}`}>
                {col.map((elements, elementsIndex) => (
                  <Stack key={`elements-${elementsIndex}`}>
                    {elements.map((element, elementKey) => (
                      <AgentElementRenderer
                        key={elementKey}
                        element={element}
                        screen={screen}
                        titleRef={titleRef}
                        agentJsonData={agentJsonData}
                        decision={decision}
                        chartHeight={320}
                        chartWidth={265}
                        onAgentClose={onAgentClose}
                        onBackdropClose={onBackdropClose}
                      />
                    ))}
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        ))}
      </Box>
    );
  }, [screen, titleRef, agentJsonData, decision]);

  const renderScreen = useMemo(() => {
    if (!isNil(decision)) {
      return <>{renderTypeAgnosticScreens}</>;
    }

    if (screen.screen_type === ScreenType.Intro) {
      return <>{renderTypeAgnosticScreens}</>;
    } else if (screen.screen_type === ScreenType.Levers) {
      return (
        <Box display="flex" alignItems="center">
          <DecisionLevers
            screen={screen}
            baseAudioURL={baseAudioURL}
            agentJsonData={agentJsonData}
            decision={decision}
            onAgentClose={onAgentClose}
            onBackdropClose={onBackdropClose}
          />
        </Box>
      );
    } else if (screen.screen_type === ScreenType.Default) {
      return <AgentDefault screen={screen} />;
    } else {
      return <>{renderTypeAgnosticScreens}</>;
    }
  }, [screen]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      paddingX={1}
      width="calc(100% / 3)"
      minWidth={0}
      {...StackProps}
    >
      <Stack
        direction="column"
        spacing={3}
        width="100%"
        flex={1}
        maxWidth="320px"
        alignItems="flex-start"
        sx={{ background: '#1A1A1A', borderRadius: '12px', padding: 3 }}
        {...(screen.screen_type === ScreenType.Levers && { alignItems: 'center' })}
      >
        <Typography ref={titleRef} variant="h9">
          {screen.screen_title}
        </Typography>
        <Box alignItems="center" display="flex" flex={1}>
          {renderScreen}
        </Box>
      </Stack>
      {screen.popup && screen.popup.button_title && (
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ScreenPopupButtonComponent
            popup={screen.popup}
            PopupComponent={(props) => renderPopupComponent(props)}
            sx={{
              mt: 1,
            }}
          />
        </Stack>
      )}
      {onAfterScreen?.(screen)}
    </Box>
  );
};
