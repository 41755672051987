import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store/rootReducer';

import { AppState } from './types';

const initialState: AppState = {
  isInitialized: false,
  isUpdateAvailable: false,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appStarted: (state) => state,
    appInitialized: (state) => {
      state.isInitialized = true;
    },
    setIsUpdateAvailable: (state, action) => {
      state.isUpdateAvailable = action.payload;
    },
  },
});

export const getAppIsInitialized = (state: RootState) => state.app.isInitialized;
export const getAppIsUpdateAvailable = (state: RootState) => state.app.isUpdateAvailable;

export const { appStarted, appInitialized, setIsUpdateAvailable } = slice.actions;

export default slice.reducer;
