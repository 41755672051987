import { HStack } from '@aily-labs/ui';
import { MenuItem, MenuItemProps, styled } from '@mui/material';
import React from 'react';

import { Radio } from '../Radio';

interface RadioMenuItemProps extends MenuItemProps {
  renderBefore?: () => React.ReactNode;
  renderAfter?: () => React.ReactNode;
}

const StyledDiv = styled('div')(() => ({
  whiteSpace: 'break-spaces',
  maxWidth: '90%',
  hyphens: 'auto',
}));

export const RadioMenuItem: React.FC<RadioMenuItemProps> = ({
  selected,
  children,
  renderBefore,
  renderAfter,
  ...rest
}) => {
  return (
    <MenuItem selected={selected} {...rest}>
      <Radio checked={selected} />
      <HStack justifyContent="space-between" width="90%" maxWidth="90%" alignItems="center">
        {renderBefore && renderBefore()}
        <StyledDiv>{children}</StyledDiv>
        {renderAfter && renderAfter()}
      </HStack>
    </MenuItem>
  );
};
