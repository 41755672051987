import { styled } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import React, { RefObject, useCallback, useEffect, useRef } from 'react';

interface Props {
  children: React.ReactNode;
  containerRef: RefObject<HTMLElement>;
}

const Wrapper = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

/**
 * This component calculates the width of the longest line in the multiline text (specified by "ref")
 * and sets it to the wrapper element. It is recalculated when the container (specified by "containerRef") is resized.
 *
 * @param children
 * @param containerRef
 * @constructor
 */
export const OptimallyWideMultilineText: React.FC<Props> = ({ children, containerRef }) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const ref = useRef<HTMLElement>(null);

  const onResize = useCallback(() => {
    if (wrapperRef.current && ref.current) {
      // This will allow the correct recalculation of client rects on the next line
      wrapperRef.current.style.width = '';

      const clientRects = ref.current.getClientRects();
      const maxWidth = Array.from(clientRects).reduce((max, rect) => Math.max(max, rect.width), 0);

      if (clientRects.length > 1) {
        // Set the width according to the longest line
        wrapperRef.current.style.width = `${maxWidth}px`;
      }
    }
  }, [wrapperRef, ref]);

  // Trigger `onResize` callback on container mount and resize
  useEffect(onResize);
  useResizeObserver(containerRef.current, onResize);

  if (React.isValidElement(children)) {
    return (
      <Wrapper ref={wrapperRef}>
        {
          // Inject the ref to the child element
          React.cloneElement(children as React.ReactElement, { ref })
        }
      </Wrapper>
    );
  }

  return null;
};
