import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { ExpandCollapseButton } from './ExpandCollapseButton';

interface FirstColumnCellContentProps {
  children?: React.ReactNode;
  expandable?: boolean;
  expanded?: boolean;
  toggleExpanded?: () => void;
  indent?: number;
}

export const FirstColumnCellContent: React.FC<FirstColumnCellContentProps> = ({
  children,
  expandable,
  expanded,
  toggleExpanded,
  indent,
}) => {
  const expandableCellContent = useMemo(
    () =>
      expandable ? (
        <ExpandCollapseButton expanded={expanded} onClick={toggleExpanded}>
          {children}
        </ExpandCollapseButton>
      ) : (
        children
      ),
    [expandable, expanded, toggleExpanded, children],
  );

  const indentedCellContent = useMemo(
    () =>
      indent ? <Box sx={{ pl: indent * 2 }}>{expandableCellContent}</Box> : expandableCellContent,
    [indent, expandableCellContent],
  );

  return <>{indentedCellContent}</>;
};
