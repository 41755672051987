import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type * as T from '../../../../schema/__generated__/types';
const defaultOptions = {} as const;
export type EditUserAdditionalFiltersMutationVariables = T.Exact<{
  input: T.EditUserAdditionalFiltersInput;
}>;

export type EditUserAdditionalFiltersMutation = {
  __typename?: 'Mutation';
  editUserAdditionalFilters: boolean;
};

export type TakeAgentDecisionByUserMutationVariables = T.Exact<{
  input: T.TakeAgentDecisionInput;
}>;

export type TakeAgentDecisionByUserMutation = {
  __typename?: 'Mutation';
  takeAgentDecisionByUser: boolean;
};

export type RevertAgentDecisionByUserMutationVariables = T.Exact<{
  input: T.RevertAgentDecisionInput;
}>;

export type RevertAgentDecisionByUserMutation = {
  __typename?: 'Mutation';
  revertAgentDecisionByUser: boolean;
};

export const EditUserAdditionalFiltersDocument = gql`
  mutation EditUserAdditionalFilters($input: EditUserAdditionalFiltersInput!) {
    editUserAdditionalFilters(input: $input)
  }
`;
export type EditUserAdditionalFiltersMutationFn = Apollo.MutationFunction<
  EditUserAdditionalFiltersMutation,
  EditUserAdditionalFiltersMutationVariables
>;

/**
 * __useEditUserAdditionalFiltersMutation__
 *
 * To run a mutation, you first call `useEditUserAdditionalFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserAdditionalFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserAdditionalFiltersMutation, { data, loading, error }] = useEditUserAdditionalFiltersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserAdditionalFiltersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditUserAdditionalFiltersMutation,
    EditUserAdditionalFiltersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditUserAdditionalFiltersMutation,
    EditUserAdditionalFiltersMutationVariables
  >(EditUserAdditionalFiltersDocument, options);
}
export type EditUserAdditionalFiltersMutationHookResult = ReturnType<
  typeof useEditUserAdditionalFiltersMutation
>;
export type EditUserAdditionalFiltersMutationResult =
  Apollo.MutationResult<EditUserAdditionalFiltersMutation>;
export type EditUserAdditionalFiltersMutationOptions = Apollo.BaseMutationOptions<
  EditUserAdditionalFiltersMutation,
  EditUserAdditionalFiltersMutationVariables
>;
export const TakeAgentDecisionByUserDocument = gql`
  mutation TakeAgentDecisionByUser($input: TakeAgentDecisionInput!) {
    takeAgentDecisionByUser(input: $input)
  }
`;
export type TakeAgentDecisionByUserMutationFn = Apollo.MutationFunction<
  TakeAgentDecisionByUserMutation,
  TakeAgentDecisionByUserMutationVariables
>;

/**
 * __useTakeAgentDecisionByUserMutation__
 *
 * To run a mutation, you first call `useTakeAgentDecisionByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeAgentDecisionByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeAgentDecisionByUserMutation, { data, loading, error }] = useTakeAgentDecisionByUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTakeAgentDecisionByUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TakeAgentDecisionByUserMutation,
    TakeAgentDecisionByUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TakeAgentDecisionByUserMutation,
    TakeAgentDecisionByUserMutationVariables
  >(TakeAgentDecisionByUserDocument, options);
}
export type TakeAgentDecisionByUserMutationHookResult = ReturnType<
  typeof useTakeAgentDecisionByUserMutation
>;
export type TakeAgentDecisionByUserMutationResult =
  Apollo.MutationResult<TakeAgentDecisionByUserMutation>;
export type TakeAgentDecisionByUserMutationOptions = Apollo.BaseMutationOptions<
  TakeAgentDecisionByUserMutation,
  TakeAgentDecisionByUserMutationVariables
>;
export const RevertAgentDecisionByUserDocument = gql`
  mutation RevertAgentDecisionByUser($input: RevertAgentDecisionInput!) {
    revertAgentDecisionByUser(input: $input)
  }
`;
export type RevertAgentDecisionByUserMutationFn = Apollo.MutationFunction<
  RevertAgentDecisionByUserMutation,
  RevertAgentDecisionByUserMutationVariables
>;

/**
 * __useRevertAgentDecisionByUserMutation__
 *
 * To run a mutation, you first call `useRevertAgentDecisionByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertAgentDecisionByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertAgentDecisionByUserMutation, { data, loading, error }] = useRevertAgentDecisionByUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertAgentDecisionByUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RevertAgentDecisionByUserMutation,
    RevertAgentDecisionByUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RevertAgentDecisionByUserMutation,
    RevertAgentDecisionByUserMutationVariables
  >(RevertAgentDecisionByUserDocument, options);
}
export type RevertAgentDecisionByUserMutationHookResult = ReturnType<
  typeof useRevertAgentDecisionByUserMutation
>;
export type RevertAgentDecisionByUserMutationResult =
  Apollo.MutationResult<RevertAgentDecisionByUserMutation>;
export type RevertAgentDecisionByUserMutationOptions = Apollo.BaseMutationOptions<
  RevertAgentDecisionByUserMutation,
  RevertAgentDecisionByUserMutationVariables
>;
