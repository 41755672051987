import { PaletteOptions } from '@mui/material/styles/createPalette';

import { colors } from './colors';

export const palette: PaletteOptions = {
  primary: {
    main: colors.primary.aqua,
  },
  text: {
    primary: colors.neutrals.white,
    secondary: colors.neutrals.grey4,
  },
  background: {
    default: colors.neutrals.black,
    paper: '#1a1a1a',
  },
  grey: {
    100: colors.neutrals.grey1,
    200: colors.neutrals.grey2,
    300: colors.neutrals.grey3,
    400: colors.neutrals.grey4,
  },
  error: {
    main: colors.primary.pink,
    gradient: colors.gradients.pink,
  },
  success: {
    main: colors.primary.green,
    contrastText: colors.neutrals.black,
    gradient: colors.gradients.green,
  },
  warning: {
    main: colors.primary.orange,
    gradient: colors.gradients.orange,
  },
  info: {
    main: colors.primary.aqua,
    gradient: colors.gradients.aqua,
  },
  divider: '#1a1a1a',
};
