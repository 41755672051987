import * as T from '@aily/graphql-sdk/schema';
import { findIndex } from 'lodash-es';
import React from 'react';

import ChartDataView, { ChartDataViewProps } from '../ChartDataView';
import { ComponentIcon } from '../ComponentIcon';
import { IconTabs, IconTabsProps } from '../IconTabs';

export interface GroupedChartProps {
  component: T.ChartComponent;
  componentIndex: number;
  components: T.Component[];
  ChartDataViewProps?: Partial<ChartDataViewProps>;
  IconTabsProps?: Partial<IconTabsProps>;
}

export const GroupedChartDataView: React.FC<GroupedChartProps> = ({
  component,
  componentIndex,
  components,
  ChartDataViewProps,
  IconTabsProps,
}) => {
  const nextNonChartComponentIndex = findIndex(
    components.slice(componentIndex),
    (c) => !T.isChartComponent(c),
  );

  const adjacentChartComponents = components.slice(
    componentIndex,
    nextNonChartComponentIndex !== -1 ? nextNonChartComponentIndex : undefined,
  ) as T.ChartComponent[];

  // Group adjacent chart components into icon tabs
  if (adjacentChartComponents.length > 1) {
    return (
      <IconTabs
        icons={adjacentChartComponents.map((c) => (
          <ComponentIcon
            key={c.id}
            componentIcon={c.icon !== T.ComponentIcon.None ? c.icon : T.ComponentIcon.BarChart}
          />
        ))}
        {...IconTabsProps}
      >
        {adjacentChartComponents.map((c) => (
          <ChartDataView key={c.id} component={c} {...ChartDataViewProps} />
        ))}
      </IconTabs>
    );
  }

  return <ChartDataView key={component.id} component={component} {...ChartDataViewProps} />;
};
