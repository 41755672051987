import { styled, Typography, TypographyProps } from '@mui/material';
import React from 'react';

import { Refresh } from '../../icons';

export interface LastUpdateDateProps extends TypographyProps {}

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.75),
    fontSize: 15,
  },
}));

export const LastUpdateDate: React.FC<LastUpdateDateProps> = ({ children, ...rest }) => (
  <StyledTypography variant="small" color="text.secondary" data-testid="LastUpdateDate" {...rest}>
    <Refresh />
    {children}
  </StyledTypography>
);
