import { getToken } from '@aily/auth-service';
import { createHttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { getWebSocketUrl } from '../utils';

const getWsLink = (uri: string) => {
  return new GraphQLWsLink(
    createClient({
      // We are trying to derive the WebSocket URL here, this is subject of a change
      url: getWebSocketUrl(`${uri.replace(/\/{1,}$/, '')}/ws`) ?? '',
      connectionParams: () => {
        const authToken = getToken();
        return {
          authToken,
        };
      },
    }),
  );
};

const getSaasHttpLink = (uri: string) => createHttpLink({ uri });

export const getHttpLink = (uri: string) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    getWsLink(uri),
    getSaasHttpLink(uri),
  );
};
