import { useSaveUserSettingsMutation } from '@aily/graphql-sdk/core';
import { SaveUserSettingsInput, SettingOption, UserSetting } from '@aily/graphql-sdk/schema';
import { Text } from '@aily-labs/ui';
import { Divider, MenuItem, Stack } from '@mui/material';
import { find } from 'lodash-es';
import React, { useCallback } from 'react';

import { useUserSettingsContext } from '../../contexts';
import { useModule } from '../../providers';
import { Select } from '../Select';

export interface UserSettingsBarProps {
  userSettings: UserSetting[];
  onSettingChange?: (setting: UserSetting) => void;
  onSettingChangeCommitted?: (setting: UserSetting) => void;
}

export const UserSettingsBar: React.FC<UserSettingsBarProps> = ({
  userSettings,
  onSettingChange,
  onSettingChangeCommitted,
}) => {
  const moduleId = useModule()?.id ?? '';
  const [saveUserSettings] = useSaveUserSettingsMutation();
  const userSettingsContext = useUserSettingsContext();

  const handleSettingChange = useCallback(
    (setting: UserSetting) => async (value: number) => {
      const updatedSetting: UserSetting = { ...setting, userValue: value };
      const input: SaveUserSettingsInput = { moduleId, settings: [{ key: setting.key, value }] };

      onSettingChange?.(updatedSetting);

      try {
        await saveUserSettings({ variables: { input } });
        onSettingChangeCommitted?.(updatedSetting);
        userSettingsContext?.publish([updatedSetting]);
      } catch (e) {
        console.error(e);
      }
    },
    [moduleId, onSettingChange, onSettingChangeCommitted, userSettingsContext],
  );

  const handleRenderValue = useCallback(
    (setting: UserSetting) => (value: number) => {
      const settingOption = find(setting.options, { value });
      return settingOption?.label ?? value;
    },
    [],
  );

  const renderSettingOption = (setting: UserSetting) => {
    if (
      setting.options.length === 1 &&
      setting.options[0].value === (setting.userValue ?? setting.defaultValue)
    ) {
      return (
        <Text
          key={setting.key}
          fontSize="$fontSize12"
          fontWeight="$fontWeightRegular"
          color="$colorNeutralGreyLight"
          lineHeight="$lineHeightHeading"
        >
          {setting.options[0].label}
        </Text>
      );
    }

    return (
      <Select
        key={setting.key}
        defaultValue={setting.defaultValue ?? undefined}
        value={setting.userValue ?? undefined}
        onChange={handleSettingChange(setting)}
        renderValue={handleRenderValue(setting)}
        color="secondary"
        size="small"
      >
        {setting.options?.map((option: SettingOption) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      divider={
        <Divider
          orientation="vertical"
          flexItem
          sx={{ alignSelf: 'auto', borderColor: 'text.secondary', height: 14 }}
        />
      }
    >
      {userSettings.filter(({ options }) => !!options.length).map(renderSettingOption)}
    </Stack>
  );
};
