import * as T from '@aily/graphql-sdk/schema';
import { Stack, styled, useTheme } from '@mui/material';
import React from 'react';

import { SymbolType, TimelineSymbol, translateTimelineMarker } from '../Timeline';

const LegendItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
}));

const LegendItemSymbol = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 5,
});

const LegendItemText = styled('span')(({ theme }) => ({
  ...theme.typography.body,
}));

interface Props {
  series: T.TimelineSeries[];
}

export const TimelineSeriesLegend: React.FC<Props> = ({ series }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={2.5} alignItems="center" justifyContent="center">
      {series.map((s, index) => {
        const marker = translateTimelineMarker(s.marker);
        return (
          <LegendItem key={index}>
            <LegendItemSymbol>
              <TimelineSymbol
                type={marker.symbol ?? SymbolType.Dot}
                size={15}
                stroke={theme.palette.info.main}
                fill={theme.palette.background.default}
              />
            </LegendItemSymbol>
            <LegendItemText>{s.name}</LegendItemText>
          </LegendItem>
        );
      })}
    </Stack>
  );
};
