import { GetPageContentDocument } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { ApolloError, TypedDocumentNode, useQuery } from '@aily/saas-graphql-client';
import type { OperationVariables } from '@apollo/client/core';
import { Alert } from '@mui/material';
import React from 'react';

import { LoadingSkeleton } from '../LoadingSkeleton';
import PageContent from './index';
import { Props as PageContentProps } from './PageContent';

export type ComponentAbstractType =
  | (T.Component & {
      filters?: T.FilterComponent[] | null;
      drillIds?: number[] | null;
      additionalFiltersInput?: T.UserAdditionalFiltersInput[] | null;
    })
  | { __typename?: string };

export type QueryAbstractType = {
  __typename?: 'Query';
  pageContent?: { body?: ComponentAbstractType[] };
};

export interface PageContentContainerProps<
  TData extends QueryAbstractType,
  TVariables extends OperationVariables = OperationVariables,
> extends Pick<PageContentProps, 'onComponentRender'> {
  pageId: T.Page['id'];
  priorityId?: number;
  loadingComponent?: React.ReactNode;
  suspenseFallback?: React.ReactNode;
  renderBeforeContent?: (components: T.Component[]) => React.ReactNode;
  query?: TypedDocumentNode<TData, TVariables>;
  onCompleted?: (data: TData) => void;
  onError?: (error: ApolloError) => void;
}

const PageContentContainer = <
  TData extends QueryAbstractType,
  TVariables extends OperationVariables = OperationVariables,
>({
  pageId,
  priorityId,
  onComponentRender,
  loadingComponent = <LoadingSkeleton />,
  suspenseFallback,
  renderBeforeContent,
  query = GetPageContentDocument,
  onCompleted,
  onError,
}: PageContentContainerProps<TData, TVariables>) => {
  const { data, loading, error } = useQuery<TData>(query, {
    variables: { pageId, priorityId },
    onCompleted,
    onError,
  });

  if (loading) {
    return loadingComponent;
  }

  if (error) {
    return (
      <Alert variant="outlined" severity="error">
        {error.message}
      </Alert>
    );
  }

  if (!data?.pageContent) {
    return (
      <Alert variant="outlined" severity="info">
        No data
      </Alert>
    );
  }

  return (
    <PageContent
      renderBeforeContent={renderBeforeContent}
      pageContent={data.pageContent as T.PageContentResult}
      onComponentRender={onComponentRender}
      suspenseFallback={suspenseFallback}
    />
  );
};

export default PageContentContainer;
