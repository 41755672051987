import { AnalyticsBrowser, InitOptions } from '@segment/analytics-next';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface NoopAnalytics<T extends Page = Page, P extends Module = Module> {
  identify: () => void;
  page: () => void;
  track: () => void;
  useCurrentPage: () => { page: T | undefined } | undefined;
  useCurrentModule: () => P | undefined;
  env: string;
  tenant: string;
  appVersion: string;
}

interface Page {
  absolutePath: string;
}

interface Module {
  path: string;
  name: string;
}

export interface AnalyticsProviderProps<T extends Page = Page, P extends Module = Module> {
  writeKey?: string;
  cdnURL?: string;
  useCurrentPage: () => { page?: T } | undefined;
  useCurrentModule: () => P | undefined;
  env: string;
  tenant: string;
  appVersion: string;
}

const AnalyticsContext = createContext<
  (AnalyticsProviderProps & AnalyticsBrowser) | NoopAnalytics | null
>(null);

const noopAnalytics: NoopAnalytics = {
  identify: () => {},
  page: () => {},
  track: () => {},
  useCurrentPage: () => undefined,
  useCurrentModule: () => undefined,
  env: 'dev',
  tenant: '',
  appVersion: '',
};

const initOptions: InitOptions = {
  obfuscate: true,
  integrations: {
    'Segment.io': {
      deliveryStrategy: {
        strategy: 'batching',
        config: { size: 5, timeout: 3000 },
      },
    },
  },
};

export const AnalyticsProvider: React.FC<PropsWithChildren<AnalyticsProviderProps>> = ({
  writeKey,
  children,
  useCurrentPage,
  useCurrentModule,
  env,
  tenant,
  appVersion,
  cdnURL,
}) => {
  const analytics = useMemo(
    () =>
      writeKey && cdnURL
        ? Object.assign(AnalyticsBrowser.load({ writeKey, cdnURL }, initOptions), {
            useCurrentModule,
            useCurrentPage,
            env,
            tenant,
            appVersion,
          })
        : noopAnalytics,
    [writeKey, useCurrentModule, useCurrentPage, env, tenant, appVersion, cdnURL],
  );

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const NoopAnalyticsProvider = ({ children }: { children: React.ReactNode }) => (
  <AnalyticsContext.Provider value={noopAnalytics}>{children}</AnalyticsContext.Provider>
);

export function useAnalytics() {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be called within AnalyticsProvider');
  }

  return context;
}
