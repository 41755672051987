import { getToken } from '@aily/auth-service';

export type AudioCallback = (buffer: AudioBuffer | null) => void;

/**
 * Manages the loading and decoding of audio data.
 */
export class AudioLoader {
  private audioContext: AudioContext;

  /**
   * Initializes a new instance of the AudioLoader.
   * @param {AudioContext} audioContext - The audio context to use for decoding audio data.
   */
  constructor(audioContext: AudioContext) {
    this.audioContext = audioContext;
  }

  /**
   * Loads audio from a specified path and decodes it.
   * @param {string} audioPath - The path to the audio file.
   * @param {AudioCallback} callback - The callback to handle the loaded audio buffer.
   */
  public load(audioPath: string, callback: AudioCallback): void {
    const request = new XMLHttpRequest();
    this.configureRequest(request, audioPath, callback);
    request.send();
  }

  /**
   * Configures an XMLHttpRequest for loading audio.
   * @param {XMLHttpRequest} request - The request to configure.
   * @param {string} audioPath - The path to the audio file.
   * @param {AudioCallback} callback - The callback to handle the loaded audio buffer.
   */
  private configureRequest(
    request: XMLHttpRequest,
    audioPath: string,
    callback: AudioCallback,
  ): void {
    const token = getToken();
    if (!token) {
      return;
    }

    request.open('GET', audioPath, true);
    request.setRequestHeader('Authorization', `Bearer ${token}`);
    request.responseType = 'arraybuffer';
    request.onload = () => this.handleLoad(request, audioPath, callback);
    request.onerror = () => this.handleError(audioPath, callback);
  }

  /**
   * Handles the onload event of XMLHttpRequest.
   * @param {XMLHttpRequest} request - The request object with the audio data.
   * @param {string} audioPath - The path to the audio file.
   * @param {AudioCallback} callback - The callback to handle the decoded audio buffer.
   */
  private handleLoad(request: XMLHttpRequest, audioPath: string, callback: AudioCallback): void {
    this.audioContext
      .decodeAudioData(request.response)
      .then(callback)
      .catch((err) => this.handleDecodeError(err, audioPath, callback));
  }

  /**
   * Handles errors during the decoding of audio data.
   * @param {Error} err - The error thrown during decoding.
   * @param {string} audioPath - The path to the audio file.
   * @param {AudioCallback} callback - The callback to handle the error state.
   */
  private handleDecodeError(err: Error, audioPath: string, callback: AudioCallback): void {
    console.warn(`Error decoding audio data from ${audioPath}: ${err}`);
    callback(null);
  }

  /**
   * Handles network errors when attempting to fetch the audio file.
   * @param {string} audioPath - The path to the audio file.
   * @param {AudioCallback} callback - The callback to handle the error state.
   */
  private handleError(audioPath: string, callback: AudioCallback): void {
    console.warn(`Network error while fetching audio file: ${audioPath}`);
    callback(null);
  }
}
