import { Components, Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderBottom: `1px solid ${theme.palette.divider}`,
      height: 48,
      padding: theme.spacing(0.5, 2),
      ...theme.typography.bodyTight,
    }),
    head: ({ theme }) => ({
      padding: theme.spacing(0, 2),
      borderBottom: `2px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      ...theme.typography.bodyBoldTight,
    }),
    footer: ({ theme }) => ({
      position: 'sticky',
      zIndex: 1,
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.grey['300']}`,
    }),
    stickyHeader: {
      zIndex: 200,
    },
  },
};
