import { VolumeOff, VolumeUp } from '@mui/icons-material';
import { Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react';

interface SoundControlProps extends CheckboxProps {}

export const SoundControl = ({ checked, defaultChecked, onChange, ...rest }: SoundControlProps) => {
  const [localChecked, setLocalChecked] = useState(defaultChecked);
  const currentChecked = checked ?? localChecked;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setLocalChecked(checked);
    onChange?.(event, checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={currentChecked}
          onChange={handleChange}
          icon={<VolumeOff />}
          checkedIcon={<VolumeUp />}
          {...rest}
        />
      }
      disableTypography
      label={<Typography variant="body">{currentChecked ? 'Sound On' : 'Sound Off'}</Typography>}
    />
  );
};
