import React, { useEffect, useRef } from 'react';

/**
 * Custom React hook to create a dynamic canvas backdrop based on a video element.
 * The canvas is updated at regular intervals to reflect the current frame of the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {number} [canvasUpdateTimeout=100] - Interval in milliseconds at which the canvas is updated.
 * @return {React.RefObject<HTMLCanvasElement>} - Reference to the created canvas element.
 */
export function useVideoCanvasBackdrop(
  videoRef: React.RefObject<HTMLVideoElement>,
  canvasUpdateTimeout: number = 100,
): React.RefObject<HTMLCanvasElement> {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (!canvas || !video) return;

    const context = canvas.getContext('2d');
    const updateCanvas = () => {
      context?.drawImage(video, 0, 0, canvas.width, canvas.height);
    };

    const interval = setInterval(updateCanvas, canvasUpdateTimeout);

    return () => {
      clearInterval(interval);
    };
  }, [videoRef, canvasUpdateTimeout]);

  return canvasRef;
}
