import { alpha, Button, ButtonProps, styled } from '@mui/material';
import React from 'react';

export interface Props extends ButtonProps {
  active?: boolean;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'themeName',
})<Props>(({ theme, active }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 104,
  height: 104,
  letterSpacing: 0,
  textTransform: 'none',
  opacity: active ? 1 : 0.7,
  borderRadius: 12,
  background: `linear-gradient(139deg, ${theme.palette.primary.main} 2.71%, ${alpha(
    theme.palette.primary.main,
    0.8,
  )} 27.77%)`,
  color: theme.palette.text.primary,
  transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    opacity: 1,
  },
  ...theme.typography.moduleSwitchMedium,
}));

const ModuleSelectItem: React.FC<Props> = ({ children, active, ...rest }) => (
  <StyledButton active={active} {...rest}>
    {children}
  </StyledButton>
);

export default ModuleSelectItem;
