import React from 'react';

import { useTimelineContext } from './TimelineContext';

export interface TimelineIntervalProps extends React.SVGProps<SVGLineElement> {
  x1: number;
  x2: number;
}

export const TimelineInterval: React.FC<TimelineIntervalProps> = ({ x1, x2, ...rest }) => {
  const { axis, xScale } = useTimelineContext();
  const { y } = axis;

  return (
    <line
      x1={`${xScale(x1)}%`}
      x2={`${xScale(x2)}%`}
      y1={y}
      y2={y}
      strokeLinecap="butt"
      {...rest}
    />
  );
};
