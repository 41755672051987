import React from 'react';

import { AchievementBadgeProps } from './AchievementBadge';

export const AchievementBadgeGold: React.FC<AchievementBadgeProps> = ({
  width = 212,
  height = 220,
  backgroundColor = '#000',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 212 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="achievement-badge__gold"
  >
    <path
      d="M5 87.8954L106.68 13.1917L207.322 86.8552L168.314 207H44.008L5 87.8954Z"
      fill={backgroundColor}
    />
    <path
      d="M150.192 97.4424L86.5512 153.089C84.2107 155.135 85.6501 159.006 88.7518 159.006H144.864C146.541 159.006 147.961 157.759 148.187 156.088L155.716 100.442C156.133 97.3579 152.528 95.4003 150.192 97.4424Z"
      fill="#FFB800"
      fillOpacity="0.5"
    />
    <path
      d="M66.4216 97.7433L127.022 153.139C129.29 155.213 127.831 159.007 124.766 159.007H71.3347C69.6499 159.007 68.2266 157.75 68.0092 156.07L60.8401 100.674C60.4373 97.5607 64.1115 95.6317 66.4216 97.7433Z"
      fill="#FFB800"
      fillOpacity="0.5"
    />
    <path
      d="M105.336 86.8937L69.0553 154.02C67.8408 156.267 69.4586 159.002 72.0019 159.002H144.563C147.106 159.002 148.724 156.267 147.509 154.02L111.229 86.8938C109.959 84.5445 106.606 84.5445 105.336 86.8937Z"
      fill="url(#paint0_linear_5951_27628)"
      fillOpacity="0.8"
    />
    <path
      d="M104.61 41.6459C105.801 40.7534 107.415 40.7534 108.607 41.6459L178.986 94.3547C180.177 95.2472 180.676 96.8292 180.221 98.2733L153.338 183.558C152.883 185.002 151.578 185.98 150.105 185.98H63.1118C61.6388 185.98 60.3332 185.002 59.878 183.558L32.9957 98.2733C32.5406 96.8292 33.0392 95.2472 34.2309 94.3547L104.61 41.6459Z"
      fill="url(#paint1_linear_5951_27628)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.987 97.1903L106.608 44.4815L36.2295 97.1903L63.1118 182.475H150.105L176.987 97.1903ZM108.607 41.6459C107.415 40.7534 105.801 40.7534 104.61 41.6459L34.2309 94.3547C33.0392 95.2472 32.5406 96.8292 32.9957 98.2733L59.878 183.558C60.3332 185.002 61.6388 185.98 63.1118 185.98H150.105C151.578 185.98 152.883 185.002 153.338 183.558L180.221 98.2733C180.676 96.8292 180.177 95.2472 178.986 94.3547L108.607 41.6459Z"
      fill="url(#paint2_linear_5951_27628)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M203.271 88.3525L106.608 17.9544L9.94521 88.3525L46.8671 202.259H166.349L203.271 88.3525ZM108.927 14.7552C107.544 13.7483 105.672 13.7483 104.289 14.7552L7.62643 85.1534C6.24378 86.1603 5.66522 87.9452 6.19335 89.5745L43.1152 203.481C43.6433 205.11 45.158 206.214 46.8671 206.214H166.349C168.058 206.214 169.573 205.11 170.101 203.481L207.023 89.5745C207.551 87.9452 206.972 86.1603 205.589 85.1534L108.927 14.7552Z"
      fill="url(#paint3_linear_5951_27628)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5951_27628"
        x1="108.282"
        y1="81.4421"
        x2="108.282"
        y2="159.002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBD0B" />
        <stop offset="0.526042" stopColor="#FFE49C" />
        <stop offset="0.989583" stopColor="#FFB800" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5951_27628"
        x1="106.608"
        y1="40.1492"
        x2="106.608"
        y2="201.376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7BE2C" stopOpacity="0.46" />
        <stop offset="0.458333" stopColor="#FFD700" stopOpacity="0.47" />
        <stop offset="1" stopColor="#F7BE2C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5951_27628"
        x1="106.608"
        y1="40.1492"
        x2="106.608"
        y2="201.376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB800" stopOpacity="0.36" />
        <stop offset="0.395833" stopColor="#FFD700" />
        <stop offset="0.510417" stopColor="#FFEFAD" />
        <stop offset="1" stopColor="#FFB800" stopOpacity="0.76" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5951_27628"
        x1="106.608"
        y1="13.0665"
        x2="106.608"
        y2="226.605"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB800" stopOpacity="0.36" />
        <stop offset="0.395833" stopColor="#FFD700" />
        <stop offset="0.510417" stopColor="#FFEFAD" />
        <stop offset="1" stopColor="#FFB800" stopOpacity="0.76" />
      </linearGradient>
    </defs>
  </svg>
);
