import { createTheme, Theme } from '@mui/material';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { typography } from './typography';

const theme: Theme = createTheme({
  breakpoints,
  components,
  palette,
  typography,
});

export default theme;
