import * as T from '@aily/graphql-sdk/schema';
import React, { createContext, ProviderProps, useContext } from 'react';

export const ModuleContext = createContext<T.Module | null>(null);

export const ModuleProvider = ({ value, children }: ProviderProps<T.Module | null>) => {
  return <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>;
};

export function useModule() {
  return useContext(ModuleContext);
}
