import moduleConfig from '@rnd/config';
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: `v3/${moduleConfig.name}`,
    element: <Outlet />,
    children: [{ path: 'home', element: <>Home</> }],
  },
];

export default routes;
