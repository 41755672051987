import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

export enum VarianceDisplayMode {
  Absolute,
  Relative,
}

export interface TableDataViewContextState {
  varianceDisplayMode: VarianceDisplayMode;
  toggleVarianceDisplayMode: () => void;
  resetVarianceDisplayMode: () => void;
}

export const TableDataViewContext = createContext<TableDataViewContextState | undefined>(undefined);

export const TableDataViewContextProvider = ({
  children,
  defaultVarianceDisplayMode = VarianceDisplayMode.Relative,
}: {
  children: React.ReactNode;
  defaultVarianceDisplayMode?: VarianceDisplayMode;
}) => {
  const [varianceDisplayMode, setVarianceDisplayMode] = useState(defaultVarianceDisplayMode);

  const toggleVarianceDisplayMode = useCallback(
    () =>
      setVarianceDisplayMode((prevMode) =>
        prevMode === VarianceDisplayMode.Absolute
          ? VarianceDisplayMode.Relative
          : VarianceDisplayMode.Absolute,
      ),
    [],
  );

  const resetVarianceDisplayMode = useCallback(
    () => setVarianceDisplayMode(defaultVarianceDisplayMode),
    [defaultVarianceDisplayMode],
  );

  const contextValue = useMemo(
    () => ({
      varianceDisplayMode,
      toggleVarianceDisplayMode,
      resetVarianceDisplayMode,
    }),
    [varianceDisplayMode, toggleVarianceDisplayMode, resetVarianceDisplayMode],
  );

  return (
    <TableDataViewContext.Provider value={contextValue}>{children}</TableDataViewContext.Provider>
  );
};

export function useTableDataViewContext() {
  return useContext(TableDataViewContext);
}

export default TableDataViewContext;
