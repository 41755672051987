import {
  alpha,
  Backdrop,
  BackdropProps,
  CircularProgress,
  CircularProgressProps,
  styled,
} from '@mui/material';
import React from 'react';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: alpha(theme.palette.background.default, 0.7),
  color: '#999',
}));

interface Props extends Omit<BackdropProps, 'open'> {
  CircularProgressProps?: CircularProgressProps;
}

export const LoadingBackdrop: React.FC<Props> = ({ CircularProgressProps, ...rest }) => (
  <StyledBackdrop open {...rest}>
    <CircularProgress color="inherit" size={30} {...CircularProgressProps} />
  </StyledBackdrop>
);
