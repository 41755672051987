import * as T from '@aily/graphql-sdk/schema';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export type PriorityFilterType = T.SelectFilter | T.TreeSelectFilter;

export interface PriorityFilterContextState {
  priorityId?: number;
  setPriorityId?: (priorityId?: number) => void;
  priorityFilter?: PriorityFilterType;
}

export type PriorityFilterProviderProps = PropsWithChildren<PriorityFilterContextState>;

export const PriorityFilterContext = createContext<PriorityFilterProviderProps | undefined>(
  undefined,
);

export const PriorityFilterProvider = ({
  priorityFilter,
  priorityId,
  setPriorityId,
  children,
}: PriorityFilterProviderProps) => {
  return (
    <PriorityFilterContext.Provider value={{ priorityId, setPriorityId, priorityFilter }}>
      {children}
    </PriorityFilterContext.Provider>
  );
};
export function usePriorityFilter() {
  return useContext(PriorityFilterContext);
}
