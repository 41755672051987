import { gql } from '@apollo/client';

import type * as T from '../../../schema/__generated__/types';
export type MessageFieldsFragment = {
  __typename?: 'MessagesResult';
  sharedLinks: Array<{ __typename?: 'SharedLink' } & SharedLinkFieldsFragment>;
};

export type SharedLinkFieldsFragment = {
  __typename?: 'SharedLink';
  id: string;
  moduleCode: T.ModuleCode;
  title: string;
  subtitle?: string | null;
  contentType: T.InboxContentType;
  link: string;
  sentAt?: string | null;
  sharedBy: string;
  isFollowed: boolean;
  isExpired: boolean;
  tag?: { __typename?: 'InboxItemTag'; type: T.TagType; message: string } | null;
};

export type YourBrainItemFieldsFragment = {
  __typename?: 'YourBrainItem';
  title: string;
  moduleCode: T.ModuleCode;
  contentType: T.InboxContentType;
  resourceId: string;
  link: string;
  createdAt?: string | null;
  isExpired: boolean;
};

export type DecisionItemFieldsFragment = {
  __typename?: 'DecisionItem';
  title: string;
  moduleCode: T.ModuleCode;
  contentType: T.DecisionContentType;
  resourceId: string;
  link: string;
  createdAt?: string | null;
  status: T.DecisionStatusEnum;
  isExpired: boolean;
};

export const SharedLinkFieldsFragmentDoc = gql`
  fragment SharedLinkFields on SharedLink {
    id
    moduleCode
    title
    subtitle
    tag {
      type
      message
    }
    contentType
    link
    sentAt
    sharedBy
    isFollowed
    isExpired
  }
`;
export const MessageFieldsFragmentDoc = gql`
  fragment MessageFields on MessagesResult {
    sharedLinks {
      ...SharedLinkFields
    }
  }
  ${SharedLinkFieldsFragmentDoc}
`;
export const YourBrainItemFieldsFragmentDoc = gql`
  fragment YourBrainItemFields on YourBrainItem {
    title
    moduleCode
    contentType
    resourceId
    link
    createdAt
    isExpired
  }
`;
export const DecisionItemFieldsFragmentDoc = gql`
  fragment DecisionItemFields on DecisionItem {
    title
    moduleCode
    contentType
    resourceId
    link
    createdAt
    link
    status
    isExpired
  }
`;
