import { CoverImage, getFullUrl } from '@aily/saas-core';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';

import envConfig from '../../config/env';
import { Intercept, InterceptProps } from './Intercept';
import { InterceptActions } from './InterceptActions';
import { InterceptContent } from './InterceptContent';
import { InterceptMedia } from './InterceptMedia';

interface DefaultInterceptProps extends InterceptProps {
  title: string;
  description?: string;
  imageUrl?: string;
  imageLqipUrl?: string;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

export const DefaultIntercept: React.FC<DefaultInterceptProps> = ({
  title,
  description,
  imageUrl,
  imageLqipUrl,
  okText,
  cancelText,
  onOk,
  onCancel,
  ...rest
}) => (
  <Intercept onClose={onCancel} {...rest}>
    {!!imageUrl && (
      <InterceptMedia>
        <CoverImage
          lqipUrl={getFullUrl(imageLqipUrl || imageUrl, envConfig.API_URL)}
          loadedStyle={(loaded) => ({
            filter: loaded ? 'blur(0px)' : 'blur(15px)',
          })}
        >
          <source srcSet={getFullUrl(imageUrl, envConfig.API_URL)} type="image/png" />
        </CoverImage>
      </InterceptMedia>
    )}
    <InterceptContent>
      <Stack direction="column" spacing={5}>
        <Typography variant="h2" data-testid="Title">
          {title}
        </Typography>
        {!!description && (
          <Typography variant="h8" data-testid="Description">
            {description}
          </Typography>
        )}
      </Stack>
    </InterceptContent>
    <InterceptActions>
      <Stack direction="row" spacing={3}>
        <Button variant="primary" onClick={onOk} data-testid="OkButton">
          {okText || 'See more'}
        </Button>
        <Button variant="tertiary" onClick={onCancel} data-testid="CancelButton">
          {cancelText || 'Dismiss'}
        </Button>
      </Stack>
    </InterceptActions>
  </Intercept>
);
