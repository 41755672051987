import React, { useCallback } from 'react';

import Dots, { DotsProps } from '../Dots';
import useCarouselState from './useCarouselState';

export interface CarouselDotNavigationProps
  extends Omit<DotsProps, 'count' | 'index' | 'onDotClick'> {}

const CarouselDotNavigation: React.FC<CarouselDotNavigationProps> = (props) => {
  const { currentPage, totalPages, visibleSlides, carouselContext } = useCarouselState();

  const handleItemClick = useCallback(
    (dotIndex: number) => {
      // Set the current slide to the first slide of the clicked page
      carouselContext.setStoreState({ currentSlide: dotIndex * visibleSlides });
    },
    [carouselContext, visibleSlides],
  );

  return <Dots count={totalPages} index={currentPage} onDotClick={handleItemClick} {...props} />;
};

export default CarouselDotNavigation;
