import { arc, pie, PieArcDatum, scaleOrdinal } from 'd3';
import React, { useMemo } from 'react';

interface Props {
  data: number[];
  colors: string[];
  width?: number;
  height?: number;
}

export const MicroPieChart: React.FC<Props> = ({
  data = [],
  colors = [],
  width = 100,
  height = 100,
}) => {
  const colorScale = scaleOrdinal(colors);
  const radius = useMemo(() => Math.min(width, height) / 2, [width, height]);
  const arcGenerator = arc<PieArcDatum<number>>().innerRadius(0).outerRadius(radius);

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(${width / 2},${height / 2})`}>
        {pie<number>()(data).map((d, i) => (
          <path key={i} d={arcGenerator(d) ?? undefined} fill={colorScale(String(i))} />
        ))}
      </g>
    </svg>
  );
};
