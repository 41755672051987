import {
  CircularProgress,
  CircularProgressProps,
  InputBase,
  MenuItem,
  Select as MuiSelect,
  styled,
  SvgIcon,
} from '@mui/material';
import { SelectProps as MuiSelectProps } from '@mui/material/Select/Select';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';

import { RadioMenuItem } from './RadioMenuItem';

export interface SelectProps<T> extends Omit<MuiSelectProps<T>, 'variant' | 'onChange'> {
  onChange?: (newValue: T) => void;
  loading?: boolean;
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  cursor: 'default',
  ...theme.typography.bodyBold,
  '&.MuiInputBase-colorPrimary': {
    color: theme.palette.primary.main,
  },
  '&.MuiInputBase-colorSecondary': {
    color: theme.palette.text.secondary,
  },
  '&.MuiInputBase-colorInfo': {
    color: theme.palette.info.main,
  },
  '&.MuiInputBase-sizeSmall': {
    ...theme.typography.small,
    paddingTop: 4,
  },
  // Boosting the specificity to avoid default style overrides
  '&&& .MuiInputBase-input': {
    minHeight: 'auto',
    paddingRight: 20,
    '&:focus': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: 17,
    color: theme.palette.text.secondary,
    fill: 'none',
  },
}));

const StyledMenu = styled('div')(({ theme }) => ({
  '& .MuiList-root': {
    padding: theme.spacing(1.375, 0),
  },
  '& .MuiMenuItem-root': {
    paddingTop: theme.spacing(0.625),
    paddingBottom: theme.spacing(0.625),
  },
  '& .MuiRadio-root': {
    marginRight: theme.spacing(1.5),
    padding: 0,
  },
}));

const StyledCircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgress size="1em" {...props} />
))({
  position: 'absolute',
  right: 0,
  top: 'calc(50% - 0.5em)',
});

export const IconComponent: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path d="M13.7835 8.10889L10.0011 11.8912L6.21875 8.10889" stroke="currentColor" />
  </SvgIcon>
);

export const Select = <T,>({
  onChange,
  loading,
  children,
  ...rest
}: PropsWithChildren<SelectProps<T>>) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent<T>) => {
      onChange?.(event.target.value as T);
    },
    [onChange],
  );

  const transformChildren = useCallback(
    (children: React.ReactNode): React.ReactNode => {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === MenuItem) {
          return <RadioMenuItem {...child.props} />;
        }

        return child;
      });
    },
    [children],
  );

  const transformedChildren = useMemo(() => {
    return transformChildren(children);
  }, [transformChildren, children]);

  return (
    <MuiSelect<T>
      input={<StyledInputBase />}
      onChange={handleChange}
      IconComponent={loading ? StyledCircularProgress : IconComponent}
      disabled={loading}
      MenuProps={{ component: StyledMenu }}
      data-testid="Select"
      {...rest}
    >
      {transformedChildren}
    </MuiSelect>
  );
};
