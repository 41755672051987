import * as T from '@aily/graphql-sdk/schema';
import { useCallback, useMemo } from 'react';

import { ToggleItems, useToggleState } from './useToggleState';

export function mapColumnsToToggleItems(columns: T.TableColumnResult[]): ToggleItems {
  const toggleItems: ToggleItems = {};

  columns.forEach((column) => {
    const { dataKey, options } = column;

    if (options) {
      const groupId = options.toggleGroup.toString();
      const isDefault = !!options.toggleDefault;

      if (!toggleItems[groupId]) {
        toggleItems[groupId] = [];
      }

      toggleItems[groupId].push({ id: dataKey, isDefault });
    }
  });

  return toggleItems;
}

export function useColumnToggling(columns?: T.TableColumnResult[] | null) {
  const toggleItems = useMemo(() => (columns ? mapColumnsToToggleItems(columns) : {}), [columns]);
  const { isToggleable, isToggled, isVisible, toggleNextItem } = useToggleState(toggleItems);

  const isColumnToggleable = useCallback(
    ({ options }: T.TableColumnResult): boolean =>
      options ? isToggleable(options.toggleGroup.toString()) : false,
    [isToggleable],
  );

  const isColumnToggled = useCallback(
    ({ dataKey, options }: T.TableColumnResult): boolean =>
      options ? isToggled(options.toggleGroup.toString(), dataKey) : false,
    [isToggled],
  );

  const isColumnVisible = useCallback(
    ({ dataKey, options }: T.TableColumnResult): boolean =>
      options ? isVisible(options.toggleGroup.toString(), dataKey) : true,
    [isVisible],
  );

  const toggleColumn = useCallback(
    ({ options }: T.TableColumnResult): T.TableColumnResult | undefined => {
      if (options) {
        const toggledColumnDataKey = toggleNextItem(options.toggleGroup.toString());
        return columns?.find(({ dataKey }) => dataKey === toggledColumnDataKey);
      }

      return undefined;
    },
    [toggleNextItem, columns],
  );

  return {
    isColumnToggleable,
    isColumnToggled,
    isColumnVisible,
    toggleColumn,
  };
}
