import { Link as MuiLink, LinkProps, Stack, styled, useTheme } from '@mui/material';
import React from 'react';

export type NavLinkProps = (
  | { back: boolean; forward?: never }
  | { back?: never; forward: boolean }
  | { back?: never; forward?: never }
) &
  LinkProps;

const Link = styled(MuiLink)(({ theme, onClick }) => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  maxWidth: '100%',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  userSelect: 'none',
  cursor: onClick ? 'pointer' : undefined,
  '& > svg': {
    flexShrink: 0,
  },
  '& .MuiTypography-root': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const BackIcon = () => {
  const theme = useTheme();
  return (
    <svg data-testid="BackIcon" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.71436 11.4284L6.28578 7.99986L9.71436 4.57129"
        stroke={theme.palette.text.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ForwardIcon = () => {
  const theme = useTheme();
  return (
    <svg data-testid="ForwardIcon" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.28577 11.4284L9.71434 7.99986L6.28577 4.57129"
        stroke={theme.palette.text.secondary}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NavLink: React.FC<NavLinkProps> = ({ children, back, forward, ...rest }) => (
  <Link {...rest}>
    <Stack direction="row" spacing={1} alignItems="center" sx={{ '& svg': { flexShrink: 0 } }}>
      {!!back && <BackIcon />}
      {children}
      {!!forward && <ForwardIcon />}
    </Stack>
  </Link>
);
