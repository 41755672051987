import { useEffect, useRef, useState } from 'react';

import { CARD_TOTAL_WIDTH, CARD_WIDTH } from '../components/RealScreen';

export const useHorizontalScroll = (activeIndex: number, visibleCards: number | null) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const smoothScroll = (element: HTMLElement, target: number, duration: number) => {
    const start = element.scrollLeft;
    const change = target - start;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easeInOutCubic =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      element.scrollLeft = start + change * easeInOutCubic;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    let dragging = isDragging;
    if (!slider) return;

    const handleMouseDown = (e: MouseEvent) => {
      dragging = true;
      setIsDragging(dragging);
      setStartX(e.pageX - slider.offsetLeft);
      setScrollLeft(slider.scrollLeft);
    };

    const handleMouseUp = () => {
      dragging = false;
      setIsDragging(dragging);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !dragging) return;
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', handleMouseDown);
    slider.addEventListener('mouseleave', handleMouseUp);
    slider.addEventListener('mouseup', handleMouseUp);
    slider.addEventListener('mousemove', handleMouseMove);

    return () => {
      slider.removeEventListener('mousedown', handleMouseDown);
      slider.removeEventListener('mouseleave', handleMouseUp);
      slider.removeEventListener('mouseup', handleMouseUp);
      slider.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging, startX, scrollLeft]);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider || isDragging) return;

    const viewportWidth = slider.clientWidth;
    const activeCardPosition = activeIndex * CARD_TOTAL_WIDTH;
    const currentScroll = slider.scrollLeft;

    // Calculate the visible range
    const visibleStart = currentScroll;
    const visibleEnd = currentScroll + viewportWidth;

    // Position of the active card's right edge
    const activeCardEnd = activeCardPosition + CARD_WIDTH;

    // Buffer zone (one card width) to trigger scroll before card is hidden
    const buffer = CARD_TOTAL_WIDTH;

    let targetScroll = currentScroll;

    // If active card is moving towards the right edge
    if (activeCardEnd > visibleEnd - buffer) {
      targetScroll = activeCardPosition - viewportWidth + CARD_TOTAL_WIDTH * 2;
    }
    // If active card is moving towards the left edge
    else if (activeCardPosition < visibleStart + buffer) {
      targetScroll = Math.max(0, activeCardPosition - CARD_TOTAL_WIDTH);
    }

    // Ensure we don't scroll beyond bounds
    const maxScroll = slider.scrollWidth - viewportWidth;
    targetScroll = Math.max(0, Math.min(targetScroll, maxScroll));

    // Only scroll if the target position is different
    if (targetScroll !== currentScroll) {
      smoothScroll(slider, targetScroll, 1000);
    }
  }, [activeIndex, visibleCards, isDragging, scrollLeft]);

  return { sliderRef };
};
