import * as T from '@aily/graphql-sdk/schema';
import { Toolbar } from '@mui/material';
import { find } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';

import { LinkContextProvider } from '../../contexts';
import { BackButton } from '../BackButton';
import { Props as PageContentProps } from '../PageContent/PageContent';
import PageContentContainer from '../PageContent/PageContentContainer';
import { TableColumnProps } from '../Table';
import { TableCellMicroChart } from '../TableCellContent';
import { TableDataView, TableDataViewProps } from './TableDataView';

interface Props extends Pick<PageContentProps, 'onComponentRender'> {
  TableDataViewProps: TableDataViewProps;
}

const TableDataViewWithPageContent: React.FC<Props> = ({
  TableDataViewProps,
  onComponentRender,
}) => {
  const [link, setLink] = useState<T.Link>();

  const handleMicroChartClick = useCallback(
    ({ linkResult }: T.MicroChartResult) =>
      () => {
        setLink(linkResult ?? undefined);
      },
    [],
  );

  const handleCellRender = useCallback(
    (row: T.TableRowResult, column: TableColumnProps) => {
      const { cellContent } = find(row.cells, { columnDataKey: column.dataKey }) ?? {};
      if (T.isMicroChartResult(cellContent)) {
        return (
          <TableCellMicroChart
            microChart={cellContent}
            onClick={handleMicroChartClick(cellContent)}
          />
        );
      }

      return undefined;
    },
    [handleMicroChartClick],
  );

  const priorityId = useMemo(
    () =>
      TableDataViewProps.component.filters?.find((f) => f.code === 'priority_filter')
        ?.defaultValue ?? undefined,
    [TableDataViewProps.component.filters],
  );

  return (
    <>
      <TableDataView
        {...TableDataViewProps}
        onCellRender={handleCellRender}
        ContainerProps={{ sx: { display: link ? 'none' : undefined, height: '100%' } }}
      />
      {!!link && (
        <LinkContextProvider link={link}>
          <Toolbar>
            <BackButton onClick={() => setLink(undefined)} />
          </Toolbar>
          <PageContentContainer
            pageId={link.pageId as string}
            priorityId={priorityId}
            onComponentRender={onComponentRender}
          />
        </LinkContextProvider>
      )}
    </>
  );
};

export default TableDataViewWithPageContent;
