import { EventTrackingConfig, withEventTracking } from '@aily/analytics-service';
import * as T from '@aily/graphql-sdk/schema';
import React, { useCallback, useRef } from 'react';

import { getStoryFocusArea } from '../../utils';
import { ClosableModal, ClosableModalProps } from '../ClosableModal';
import StorySlider, { StorySliderProps } from './StorySlider';

export interface StorySliderModalProps
  extends StorySliderProps,
    Omit<ClosableModalProps, 'children'> {
  onTrackClose?: (story: T.Story) => void;
}

const StorySliderModal: React.FC<StorySliderModalProps> = ({
  stories,
  defaultSlide,
  onClose,
  isPlaying,
  onCurrentSlideChange,
  onStoryRead,
  onStorySeeMoreButtonClick,
  onStoryResetButtonClick,
  onTrackClose,
  ...rest
}) => {
  const currentStoryRef = useRef<T.Story | undefined>(stories?.[defaultSlide ?? 0]);

  const changeCurrentStory = useCallback(
    (story: T.Story, index: number) => {
      currentStoryRef.current = story;
      onCurrentSlideChange?.(story, index);
    },
    [currentStoryRef, onCurrentSlideChange],
  );

  const handleClose = useCallback(() => {
    if (currentStoryRef.current) {
      onTrackClose?.(currentStoryRef.current);
    }

    onClose?.();
  }, [onClose, currentStoryRef, onTrackClose]);

  const handleStorySeeMoreButtonClick = useCallback(
    (story: T.Story) => {
      onClose?.();
      onStorySeeMoreButtonClick?.(story);
    },
    [onStorySeeMoreButtonClick, onClose],
  );

  return (
    <ClosableModal onClose={handleClose} {...rest}>
      <>
        {defaultSlide !== undefined && (
          <StorySlider
            stories={stories}
            defaultSlide={defaultSlide}
            isPlaying={isPlaying}
            onCurrentSlideChange={changeCurrentStory}
            onStoryRead={onStoryRead}
            onStorySeeMoreButtonClick={handleStorySeeMoreButtonClick}
            onStoryResetButtonClick={onStoryResetButtonClick}
          />
        )}
      </>
    </ClosableModal>
  );
};

const trackingConfig: EventTrackingConfig<StorySliderModalProps> = {
  onTrackClose: {
    eventName: `story.closed`,
    getEventProps: (story) => ({
      component: 'story',
      intent: 'close',
      item_type: 'button',
      component_id: story.id,
      component_type: story.storyType,
      event_version: '2.0.0',
      name: story.headline,
      focus_area: getStoryFocusArea(story),
    }),
  },
};

export default withEventTracking(StorySliderModal, trackingConfig);
