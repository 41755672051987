import { Stack, StackProps, styled, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import React from 'react';

import { AiActive } from '../../icons';

export interface AiHeaderProps extends StackProps {
  title?: string;
  subTitle?: string;
  orientation?: 'horizontal' | 'vertical';
}

const StyledStack = styled(Stack)(({ theme }) => ({
  '& .MuiTypography-root': {
    lineHeight: 1.2, // Compatibility fix
    '& > *': { background: 'none', textDecoration: 'none' },
    '& > mark': { color: theme.palette.success.main },
    '& > del': { color: theme.palette.error.main },
  },
  // Typography style inconsistencies
  '& .MuiTypography-h6': { fontFamily: 'SF-Pro-Display', fontWeight: 300 },
}));

export const AiHeader: React.FC<AiHeaderProps> = ({ title, subTitle, orientation, ...rest }) => {
  if (orientation === 'vertical') {
    return (
      <StyledStack direction="column" alignItems="center" spacing={2} textAlign="center" {...rest}>
        <AiActive sx={{ fontSize: 80 }} />
        <Typography variant="h4">{title}</Typography>
        {!!subTitle && (
          <Typography variant="h6">
            <Markdown options={{ wrapper: React.Fragment }}>{subTitle}</Markdown>
          </Typography>
        )}
      </StyledStack>
    );
  }

  return (
    <StyledStack direction="row" alignItems="center" spacing={2} {...rest}>
      <AiActive sx={{ fontSize: 40 }} />
      <Stack direction="column" spacing={0}>
        <Typography variant="h4">{title}</Typography>
        {!!subTitle && (
          <Typography variant="h6">
            <Markdown options={{ wrapper: React.Fragment }}>{subTitle}</Markdown>
          </Typography>
        )}
      </Stack>
    </StyledStack>
  );
};
