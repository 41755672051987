import { find } from 'lodash-es';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

type PageAbstractType = {
  path: string;
  isMenuHidden?: boolean | null;
};

export interface DefaultPageRedirectProps {
  pages?: PageAbstractType[] | null;
}

const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));

const DefaultPageRedirect: React.FC<DefaultPageRedirectProps> = ({ pages }) => {
  // Get the first page that is not hidden in the menu
  const defaultPage = find(pages, { isMenuHidden: false });
  return defaultPage ? <Navigate to={defaultPage.path} replace /> : <NotFoundPage />;
};

export default DefaultPageRedirect;
