import * as T from '@aily/graphql-sdk/schema';
import { filter, find } from 'lodash-es';
import React, { useMemo } from 'react';

import { TableColumnSelect, TableColumnSelectProps } from './TableColumnSelect';

export interface TableColumnSelectHeaderCellProps
  extends TableColumnSelectProps<T.TableColumnResult> {
  columns: T.TableColumnResult[];
  selectGroupId: number;
  isColumnSelected: (column: T.TableColumnResult) => boolean;
  isColumnToggled: (column: T.TableColumnResult) => boolean;
  selectColumn: (column: T.TableColumnResult) => void;
}

/**
 * This component encapsulates the logic for selecting a column from a group defined by `selectGroupId` in a table header cell.
 * It's not quite ideal in terms of the need to provide callbacks for individual operations, but better than before.
 */
export const TableColumnSelectHeaderCell: React.FC<TableColumnSelectHeaderCellProps> = ({
  columns,
  selectGroupId,
  isColumnSelected,
  isColumnToggled,
  selectColumn,
  ...rest
}) => {
  const selectableColumns = useMemo(
    () =>
      filter(
        filter(columns, ({ options }) => options?.selectGroupId === selectGroupId),
        isColumnToggled, // Provide only columns that are toggled by default
      ),
    [columns, selectGroupId, isColumnToggled],
  );

  const value = useMemo(
    () => find(selectableColumns, isColumnSelected)?.dataKey,
    [selectableColumns, isColumnSelected],
  );

  return (
    <TableColumnSelect
      columns={selectableColumns}
      value={value}
      onChange={selectColumn}
      {...rest}
    />
  );
};
