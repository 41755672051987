import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PlayActive: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <g clipPath="url(#clip0_20329_154023)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48331 4C2.11521 4 1 5.11521 1 6.48331V29.3263C1 30.6944 2.11521 31.8096 3.48331 31.8096H30.5167C31.8848 31.8096 33 30.6944 33 29.3263V6.48331C33 5.11521 31.8848 4 30.5167 4H3.48331ZM3 6.48331C3 6.21978 3.21978 6 3.48331 6H30.5167C30.7802 6 31 6.21978 31 6.48331V29.3263C31 29.5899 30.7802 29.8096 30.5167 29.8096H3.48331C3.21978 29.8096 3 29.5899 3 29.3263V6.48331Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2806 10.6169C12.6831 9.72232 10.7524 10.8782 10.7524 12.6761V23.1334C10.7524 24.9825 12.7591 26.0168 14.2683 25.1994L14.2751 25.1957L23.4828 19.9608L23.4924 19.9552C25.0439 19.0501 25.0439 16.7964 23.4924 15.8914L14.2889 10.6216L14.2806 10.6169ZM12.7524 12.6761C12.7524 12.3995 13.0436 12.219 13.3004 12.3603L22.487 17.6203C22.712 17.7539 22.7124 18.0916 22.4879 18.2257L13.3093 23.4442C13.0516 23.5779 12.7524 23.372 12.7524 23.1334V12.6761Z"
        fill="#48FF9B"
      />
    </g>
    <defs>
      <clipPath id="clip0_20329_154023">
        <rect width="34" height="34" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
