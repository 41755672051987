import { useMemo } from 'react';

export interface UseProgressBarProps {
  min: number;
  max: number;
  baseValue: number;
  value: number;
  inverted?: boolean;
}

export interface UseProgressBarResult {
  normalizedBaseValue: number;
  normalizedValue: number;
  barWidth: number;
}

export function roundToDecimals(num: number, decimalNumbers: number = 2): number {
  const factor = Math.pow(10, decimalNumbers);
  return Math.round(num * factor) / factor;
}

export function useProgressBar({
  min,
  max,
  baseValue,
  value,
  inverted,
}: UseProgressBarProps): UseProgressBarResult {
  // Calculate the range
  const range = max - min;

  // Normalize the `baseValue` to the range of 0 to 100
  const baseValueRelativeToMin = baseValue - min;
  let normalizedBaseValue = range ? roundToDecimals((baseValueRelativeToMin / range) * 100) : 0;

  // Normalize the `value` to the range of 0 to 100
  const valueRelativeToMin = value - min;
  let normalizedValue = range ? roundToDecimals((valueRelativeToMin / range) * 100) : 0;

  // Invert the values if `inverted` is true
  if (inverted) {
    normalizedBaseValue = roundToDecimals(100 - normalizedBaseValue);
    normalizedValue = roundToDecimals(100 - normalizedValue);
  }

  const barWidth = useMemo(() => {
    const calculatedWidth = Math.min(
      100,
      Math.max(0, Math.abs(normalizedValue - normalizedBaseValue)),
    );

    return roundToDecimals(calculatedWidth);
  }, [normalizedValue, normalizedBaseValue]);

  return {
    normalizedBaseValue,
    normalizedValue,
    barWidth,
  };
}
