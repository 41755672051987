import * as T from '@aily/graphql-sdk/schema';
import { useControlled } from '@mui/material';
import { isEqual, sortBy, unionBy } from 'lodash-es';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import { useDeepCompareMemoize, useLink } from '../hooks';
import { PriorityFilterType } from '../providers';
import { reduceFilterValueToFilterInput, resolveFilterValues } from '../utils';

export interface FiltersContextState {
  filterValues: T.FilterValue[];
  setFilterValues: React.Dispatch<React.SetStateAction<T.FilterValue[]>>;
  updateFilterValues: (newFilterValues: T.FilterValue[]) => void;
  filterInputs: T.FilterInput[];
}

export interface FiltersContextProviderProps {
  filterValues?: T.FilterValue[];
  defaultFilterValues?: T.FilterValue[];
  priorityFilter?: PriorityFilterType;
  children?: React.ReactNode;
  onControlledFilterValuesChange?: (
    newFilterValues: T.FilterValue[] | ((newFilterValues: T.FilterValue[]) => T.FilterValue[]),
  ) => void;
}

const FiltersContext = createContext<FiltersContextState | null>(null);

export const FiltersContextProvider = ({
  filterValues,
  defaultFilterValues,
  priorityFilter,
  children,
  onControlledFilterValuesChange,
}: FiltersContextProviderProps) => {
  const link = useLink();
  const [currentFilterValues, setFilterValues] = useControlled({
    name: 'FiltersContextProvider',
    state: 'filterValues',
    default: defaultFilterValues ?? link?.filters ?? [],
    controlled: filterValues,
  });

  const filterInputs = useMemo(
    () => currentFilterValues.reduce(reduceFilterValueToFilterInput, []),
    [currentFilterValues],
  );

  const updateFilterValues = useCallback(
    (newFilterValues: T.FilterValue[]) => {
      const updateStateCallback = (prevState: T.FilterValue[]) => {
        const newState = unionBy(newFilterValues, prevState, 'id');

        if (!isEqual(sortBy(newState, 'id'), sortBy(prevState, 'id'))) {
          return newState;
        }

        return prevState;
      };

      // If the filterValues are controlled from the outside, the setFilterValues won't work.
      // In that case, call the external change handler, if there is one
      if (filterValues && onControlledFilterValuesChange) {
        onControlledFilterValuesChange(updateStateCallback);
      } else {
        setFilterValues(updateStateCallback);
      }
    },
    [onControlledFilterValuesChange],
  );

  useEffect(() => {
    if (!link?.filters) {
      return;
    }

    if (priorityFilter) {
      updateFilterValues(
        unionBy(resolveFilterValues([priorityFilter], link?.filters), link?.filters, 'id'),
      );
    } else {
      updateFilterValues(link?.filters);
    }
  }, [link?.filters, priorityFilter]);

  const value = useDeepCompareMemoize<FiltersContextState>({
    filterValues: currentFilterValues,
    setFilterValues,
    updateFilterValues,
    filterInputs,
  });

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export function useFiltersContext(): FiltersContextState {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('useFiltersContext must be called within FiltersContextProvider');
  }

  return context;
}

export default FiltersContext;
