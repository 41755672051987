import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export function useCurrentBreakpoint(): Breakpoint | undefined {
  // Get the theme breakpoints
  const { breakpoints } = useTheme();

  /**
   * Find the largest breakpoint that matches the current media query.
   * @note This assumes that the breakpoints are defined from smallest to largest.
   */
  const matchedBreakpoints = breakpoints.keys.filter((breakpoint) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMediaQuery(breakpoints.up(breakpoint)),
  );

  return matchedBreakpoints[matchedBreakpoints.length - 1];
}
