import React, { useId } from 'react';

interface Props {
  size: number;
}

const Bullet: React.FC<Props> = ({ size }) => {
  const id = useId();
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} style={{ overflow: 'visible' }}>
      <defs>
        <filter id={`${id}-shadow`} x="0%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation={Math.sqrt(size)} result="blur" />
          <feOffset in="blur" dx={Math.sqrt(size)} dy="0" result="offsetBlur" />
          <feFlood floodColor="black" floodOpacity="0.8" result="color" />
          <feComposite in="color" in2="offsetBlur" operator="in" result="shadow" />
          <feComposite in="shadow" in2="SourceGraphic" operator="out" result="finalShadow" />
        </filter>
      </defs>
      <circle
        filter={`url(#${id}-shadow)`}
        cx="50%"
        cy="50%"
        r={size / 2}
        fill="black"
        stroke="none"
      />
    </svg>
  );
};

export default Bullet;
