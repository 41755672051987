import { geoEquirectangular, GeoProjection } from 'd3';
import { useMemo } from 'react';

export function useGeoProjection(width: number, height: number): GeoProjection {
  return useMemo(
    () =>
      geoEquirectangular()
        .scale(width / Math.PI / 2)
        .translate([width / 2, height / 2]),
    [width, height],
  );
}
