import { area, line, scaleLinear } from 'd3';
import React, { useMemo } from 'react';

import { MicroChartProvider } from './MicroChartContext';
import { MicroChartSeries, MicroChartSeriesProps } from './MicroChartSeries';
import { calculateDataExtent, calculateMaxDataLength } from './utils';

export type DataPoint = number | null;

export enum SeriesType {
  Area,
  Line,
}

export enum LineStyle {
  Dashed,
  Solid,
}

export interface MicroChartProps extends React.SVGAttributes<SVGSVGElement> {
  /**
   * An explicit width for the chart
   */
  width?: number;
  /**
   * An explicit height for the chart
   */
  height?: number;
  /**
   * Padding of the max Y value relative to the length of the axis
   */
  maxPadding?: number;
  /**
   * Padding of the min Y value relative to the length of the axis
   */
  minPadding?: number;
  /**
   * Series definitions provided as React elements
   */
  children?:
    | React.ReactElement<MicroChartSeriesProps>
    | React.ReactElement<MicroChartSeriesProps>[]
    | React.ReactNode;
}

export const MicroChart: React.FC<MicroChartProps> & { Series: typeof MicroChartSeries } = ({
  width = 100,
  height = 30,
  maxPadding = 0,
  minPadding = 0,
  children,
  ...rest
}) => {
  const maxDataLength = useMemo(() => calculateMaxDataLength(children), [children]);
  const [minY = 0, maxY = 0] = useMemo(() => calculateDataExtent(children), [children]);

  const xScale = useMemo(
    () =>
      scaleLinear()
        .domain([0, Math.max(0, maxDataLength - 1)])
        .range([0, width]),
    [maxDataLength, width],
  );

  const yScale = useMemo(
    () => scaleLinear().domain([minY, maxY]).range([height, 0]),
    [minY, maxY, height],
  );

  const yScalePadded = useMemo(
    () =>
      scaleLinear()
        .domain([minY, maxY])
        .range([height - height * minPadding, height * maxPadding]),
    [minY, maxY, height, minPadding, maxPadding],
  );

  const lineGenerator = useMemo(
    () =>
      line<DataPoint>()
        .defined((d) => typeof d === 'number')
        .x((_d, i) => xScale(i))
        .y((d) => yScalePadded(d as number)),
    [xScale, yScalePadded],
  );

  const areaGenerator = useMemo(
    () =>
      area<DataPoint>()
        .defined((d) => typeof d === 'number')
        .x((_d, i) => xScale(i))
        .y0((d) => yScalePadded(d as number))
        .y1(yScale(minY)),
    [minY, xScale, yScale, yScalePadded],
  );

  return (
    <MicroChartProvider
      width={width}
      height={height}
      lineGenerator={lineGenerator}
      areaGenerator={areaGenerator}
    >
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="none"
        data-testid="MicroChart"
        {...rest}
      >
        {children}
      </svg>
    </MicroChartProvider>
  );
};

MicroChart.Series = MicroChartSeries;
