import { useCallback, useEffect } from 'react';
import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';

export interface UseSearchParamStateProp<T> {
  paramName: string;
  serialize: (state: T) => string;
  deserialize: (state: string) => T;
  initialState?: T;
}

export function useSearchParamState<T>({
  paramName,
  serialize,
  deserialize,
  initialState,
}: UseSearchParamStateProp<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(paramName);
  const navigate = useNavigate();

  // Get current state directly from URL search params or fall back to initialState
  const state = paramValue !== null ? deserialize(paramValue) : initialState;

  // Ensure the initialState is reflected in the URL if the param is missing or empty
  useEffect(() => {
    if (!paramValue && initialState !== undefined) {
      const newSearchParams = new URLSearchParams(searchParams);

      newSearchParams.set(paramName, serialize(initialState));
      setSearchParams(newSearchParams);
    }
  }, [paramValue, initialState, paramName, searchParams, serialize, setSearchParams]);

  // Handle state change, update URL search params, and optionally redirect to a new pathname
  const handleChange = useCallback(
    (
      newState: T,
      navigateOptions?: NavigateOptions,
      paramsToRemove?: string[],
      newPathname?: string,
    ) => {
      const serializedState = serialize(newState);
      const newSearchParams = new URLSearchParams(searchParams);

      // Set or remove the current param
      if (serializedState) {
        newSearchParams.set(paramName, serializedState);
      } else {
        newSearchParams.delete(paramName);
      }

      // Remove any additional params specified
      if (paramsToRemove) {
        paramsToRemove.forEach((param) => {
          newSearchParams.delete(param);
        });
      }

      // Check if a new pathname is provided
      if (newPathname) {
        // Redirect to the new pathname with updated search params
        navigate({ pathname: newPathname, search: newSearchParams.toString() }, navigateOptions);
      } else {
        // Update only the search params for the current pathname
        setSearchParams(newSearchParams, navigateOptions);
      }
    },
    [paramName, serialize, searchParams, setSearchParams, navigate],
  );

  return [state, handleChange] as const;
}
