import { styled } from '@mui/material';
import { CarouselContext } from 'pure-react-carousel';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { colors } from '../../theme/default/colors';

const StyledWrapper = styled('div')({
  position: 'fixed',
  zIndex: 1000,
  top: 0,
  left: 0,
  display: 'flex',
  width: '100%',
  height: 6,
});

const Item = styled('div')<ItemProps>(({ theme, active }) => ({
  flexGrow: 1,
  marginRight: 2,
  backgroundColor: active ? colors.primary.aqua : theme.palette.text.secondary,
  transition: 'background-color 0.3s linear',
  cursor: 'pointer',
  '&:last-of-type': {
    marginRight: 0,
  },
}));

interface ItemProps {
  active?: boolean;
}

interface Props {}

const StorySliderNavigation: React.FC<Props> = () => {
  const carouselContext = useContext(CarouselContext);
  const [totalSlides, setTotalSlides] = useState(carouselContext.state.totalSlides);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

  const handleCarouselStateChange = useCallback(() => {
    setTotalSlides(carouselContext.state.totalSlides);
    setCurrentSlide(carouselContext.state.currentSlide);
  }, [carouselContext]);

  const handleItemClick = useCallback(
    (index: number) => () => {
      carouselContext.setStoreState({ currentSlide: index });
    },
    [carouselContext],
  );

  useEffect(() => {
    carouselContext.subscribe(handleCarouselStateChange);
    return () => carouselContext.unsubscribe(handleCarouselStateChange);
  }, [carouselContext, handleCarouselStateChange]);

  return (
    <StyledWrapper>
      {[...Array(totalSlides)].map((_, i) => (
        <Item key={i} active={currentSlide >= i} onClick={handleItemClick(i)} />
      ))}
    </StyledWrapper>
  );
};

export default StorySliderNavigation;
