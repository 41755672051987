import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';

export interface Props extends CardProps {
  title?: string;
  BackgroundBoxProps?: BoxProps;
}

export const StyledCard = styled(Card)(() => ({
  position: 'relative',
  height: 113,
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '& .MuiCardActionArea-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
  },
  '& .MuiCardContent-root': {
    width: '100%',
    paddingBottom: 0,
  },
}));

const BackgroundBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.8,
  backgroundColor: theme.palette.background.paper,
}));

const PlailistListItem: React.FC<Props> = ({ title, BackgroundBoxProps, onClick, ...rest }) => (
  <StyledCard {...rest}>
    <BackgroundBox {...BackgroundBoxProps} />
    <CardActionArea component="div" onClick={onClick}>
      <CardContent>
        <Typography variant="bodyBold" data-testid="Title">
          {title}
        </Typography>
      </CardContent>
    </CardActionArea>
  </StyledCard>
);

export default PlailistListItem;
