import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type * as T from '../../../schema/__generated__/types';
import type {
  DecisionItemFieldsFragment,
  MessageFieldsFragment,
  YourBrainItemFieldsFragment,
} from './fragments';
import {
  DecisionItemFieldsFragmentDoc,
  MessageFieldsFragmentDoc,
  YourBrainItemFieldsFragmentDoc,
} from './fragments';
const defaultOptions = {} as const;
export type GetInboxMessagesQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetInboxMessagesQuery = {
  __typename?: 'Query';
  inbox: {
    __typename?: 'InboxResult';
    messages?: ({ __typename?: 'MessagesResult' } & MessageFieldsFragment) | null;
  };
};

export type GetInboxDecisionsQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetInboxDecisionsQuery = {
  __typename?: 'Query';
  inbox: {
    __typename?: 'InboxResult';
    decisions?: Array<({ __typename?: 'DecisionItem' } & DecisionItemFieldsFragment) | null> | null;
  };
};

export type GetInboxYourBrainItemsQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetInboxYourBrainItemsQuery = {
  __typename?: 'Query';
  inbox: {
    __typename?: 'InboxResult';
    yourBrain?: Array<
      ({ __typename?: 'YourBrainItem' } & YourBrainItemFieldsFragment) | null
    > | null;
  };
};

export const GetInboxMessagesDocument = gql`
  query GetInboxMessages {
    inbox {
      messages {
        ...MessageFields
      }
    }
  }
  ${MessageFieldsFragmentDoc}
`;

/**
 * __useGetInboxMessagesQuery__
 *
 * To run a query within a React component, call `useGetInboxMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboxMessagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetInboxMessagesQuery,
    GetInboxMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetInboxMessagesQuery, GetInboxMessagesQueryVariables>(
    GetInboxMessagesDocument,
    options,
  );
}
export function useGetInboxMessagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetInboxMessagesQuery,
    GetInboxMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetInboxMessagesQuery, GetInboxMessagesQueryVariables>(
    GetInboxMessagesDocument,
    options,
  );
}
export function useGetInboxMessagesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetInboxMessagesQuery,
    GetInboxMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetInboxMessagesQuery, GetInboxMessagesQueryVariables>(
    GetInboxMessagesDocument,
    options,
  );
}
export type GetInboxMessagesQueryHookResult = ReturnType<typeof useGetInboxMessagesQuery>;
export type GetInboxMessagesLazyQueryHookResult = ReturnType<typeof useGetInboxMessagesLazyQuery>;
export type GetInboxMessagesSuspenseQueryHookResult = ReturnType<
  typeof useGetInboxMessagesSuspenseQuery
>;
export type GetInboxMessagesQueryResult = Apollo.QueryResult<
  GetInboxMessagesQuery,
  GetInboxMessagesQueryVariables
>;
export const GetInboxDecisionsDocument = gql`
  query GetInboxDecisions {
    inbox {
      decisions {
        ...DecisionItemFields
      }
    }
  }
  ${DecisionItemFieldsFragmentDoc}
`;

/**
 * __useGetInboxDecisionsQuery__
 *
 * To run a query within a React component, call `useGetInboxDecisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxDecisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxDecisionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboxDecisionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetInboxDecisionsQuery,
    GetInboxDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetInboxDecisionsQuery, GetInboxDecisionsQueryVariables>(
    GetInboxDecisionsDocument,
    options,
  );
}
export function useGetInboxDecisionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetInboxDecisionsQuery,
    GetInboxDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetInboxDecisionsQuery, GetInboxDecisionsQueryVariables>(
    GetInboxDecisionsDocument,
    options,
  );
}
export function useGetInboxDecisionsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetInboxDecisionsQuery,
    GetInboxDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetInboxDecisionsQuery, GetInboxDecisionsQueryVariables>(
    GetInboxDecisionsDocument,
    options,
  );
}
export type GetInboxDecisionsQueryHookResult = ReturnType<typeof useGetInboxDecisionsQuery>;
export type GetInboxDecisionsLazyQueryHookResult = ReturnType<typeof useGetInboxDecisionsLazyQuery>;
export type GetInboxDecisionsSuspenseQueryHookResult = ReturnType<
  typeof useGetInboxDecisionsSuspenseQuery
>;
export type GetInboxDecisionsQueryResult = Apollo.QueryResult<
  GetInboxDecisionsQuery,
  GetInboxDecisionsQueryVariables
>;
export const GetInboxYourBrainItemsDocument = gql`
  query GetInboxYourBrainItems {
    inbox {
      yourBrain {
        ...YourBrainItemFields
      }
    }
  }
  ${YourBrainItemFieldsFragmentDoc}
`;

/**
 * __useGetInboxYourBrainItemsQuery__
 *
 * To run a query within a React component, call `useGetInboxYourBrainItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxYourBrainItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxYourBrainItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboxYourBrainItemsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetInboxYourBrainItemsQuery,
    GetInboxYourBrainItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetInboxYourBrainItemsQuery,
    GetInboxYourBrainItemsQueryVariables
  >(GetInboxYourBrainItemsDocument, options);
}
export function useGetInboxYourBrainItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetInboxYourBrainItemsQuery,
    GetInboxYourBrainItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetInboxYourBrainItemsQuery,
    GetInboxYourBrainItemsQueryVariables
  >(GetInboxYourBrainItemsDocument, options);
}
export function useGetInboxYourBrainItemsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetInboxYourBrainItemsQuery,
    GetInboxYourBrainItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetInboxYourBrainItemsQuery,
    GetInboxYourBrainItemsQueryVariables
  >(GetInboxYourBrainItemsDocument, options);
}
export type GetInboxYourBrainItemsQueryHookResult = ReturnType<
  typeof useGetInboxYourBrainItemsQuery
>;
export type GetInboxYourBrainItemsLazyQueryHookResult = ReturnType<
  typeof useGetInboxYourBrainItemsLazyQuery
>;
export type GetInboxYourBrainItemsSuspenseQueryHookResult = ReturnType<
  typeof useGetInboxYourBrainItemsSuspenseQuery
>;
export type GetInboxYourBrainItemsQueryResult = Apollo.QueryResult<
  GetInboxYourBrainItemsQuery,
  GetInboxYourBrainItemsQueryVariables
>;
