import { GetDataViewDocument, GetDataViewQueryVariables } from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { useLazyQuery } from '@aily/saas-graphql-client';
import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { unionBy } from 'lodash-es';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useFiltersContext, useUserSettingsContext } from '../../contexts';
import { useDrillId } from '../../hooks';
import { useModule } from '../../providers';
import {
  mapFilterComponentToFilterValue,
  reduceFilterValueToDependentFilterValue,
  reduceFilterValueToFilterInput,
} from '../../utils';

interface RenderProps<TData> {
  id: T.IDataView['id'];
  drillIds?: number[] | null;
  filters?: T.FilterComponent[] | null;
  additionalFiltersInput?: T.UserAdditionalFiltersInput[] | null;
  componentCode?: string;
  dataView?: T.IDataView | null;
  loading?: boolean;
  error?: ApolloError;
  refetch?: () => Promise<ApolloQueryResult<TData>>;
}

export type QueryAbstractType = { __typename?: 'Query'; dataView?: T.IDataView };

export interface DataViewContainerProps<
  TData extends QueryAbstractType = QueryAbstractType,
  TVariables extends OperationVariables = OperationVariables,
> extends Omit<RenderProps<TData>, 'dataView' | 'loading'> {
  children: (props: RenderProps<TData>) => React.ReactNode;
  disableInput?: boolean;
  query?: TypedDocumentNode<TData, TVariables>;
  additionalInput?: T.InputMaybe<Omit<T.DataViewInput, 'id' | 'moduleId'>>;
}

export const DataViewContainer = memo(
  <TData extends QueryAbstractType, TVariables extends OperationVariables>({
    id: dataViewId,
    drillIds: defaultDrillIds,
    filters: filterComponents,
    additionalFiltersInput,
    componentCode,
    children,
    disableInput,
    query = GetDataViewDocument,
    additionalInput,
  }: DataViewContainerProps<TData, TVariables>) => {
    const [dataView, setDataView] = useState<T.IDataView>();
    const [loading, setLoading] = useState(true);
    const moduleId = useModule()?.id ?? '';
    const filtersContext = useFiltersContext();
    const { userSettingsInput } = useUserSettingsContext();
    const drillIds = useDrillId() ?? defaultDrillIds;

    const filterValues = useMemo(
      () =>
        filterComponents
          ? reduceFilterValueToDependentFilterValue(
              unionBy(
                filtersContext.filterValues,
                filterComponents?.map(mapFilterComponentToFilterValue),
                'id',
              ),
              filterComponents,
            )
          : undefined,
      [filterComponents, filtersContext.filterValues],
    );

    const filterInputs = useMemo(
      () => filterValues?.reduce(reduceFilterValueToFilterInput, []),
      [filterValues],
    );

    const handleCompleted = useCallback((data: TData) => {
      setDataView(data.dataView ?? undefined);
      setLoading(false);
    }, []);

    const handleError = useCallback(() => {
      setLoading(false);
    }, []);

    const [fetchData, { error, refetch }] = useLazyQuery<TData>(query, {
      onCompleted: handleCompleted,
      onError: handleError,
    });

    const variables = useMemo(() => {
      return {
        input: {
          id: dataViewId,
          moduleId,
          drillIds: !disableInput ? drillIds : undefined,
          filters: !disableInput ? filterInputs : [],
          userSettings: !disableInput ? userSettingsInput : [],
          additionalFiltersInput: !disableInput ? additionalFiltersInput : [],
          componentCode,
          ...(additionalInput || {}),
        },
      } satisfies GetDataViewQueryVariables;
    }, [
      dataViewId,
      moduleId,
      drillIds,
      disableInput,
      filterInputs,
      additionalFiltersInput,
      userSettingsInput,
      componentCode,
      additionalInput,
    ]);

    const doFetchData = useCallback(() => {
      fetchData({ variables });
      setLoading(true);
    }, [variables]);

    useEffect(doFetchData, [doFetchData]);

    return children({
      id: dataViewId,
      filters: filterComponents,
      dataView,
      loading,
      error,
      refetch,
    });
  },
);
