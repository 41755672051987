import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowCircleUpThick: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="33" height="32" viewBox="0 0 33 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0103 10.2006C26.9478 11.6827 27.5589 13.3608 27.7928 15.1194C28.0969 17.4061 27.7508 19.7335 26.7954 21.8268C25.8399 23.9201 24.3149 25.6919 22.4005 26.933C20.4861 28.1741 18.2622 28.8326 15.9913 28.831C13.7205 28.8293 11.4975 28.1674 9.58486 26.9235C7.67223 25.6795 6.14981 23.9055 5.19734 21.8108C4.24487 19.716 3.90213 17.3881 4.20957 15.1019C4.44602 13.3436 5.05943 11.6665 5.99908 10.1858C6.09238 10.0388 6.1889 9.89371 6.28858 9.75068C6.31934 9.70654 6.34687 9.66079 6.37114 9.61382C6.61133 9.14907 6.53365 8.56389 6.13234 8.21732C5.69046 7.83572 5.02426 7.88819 4.68288 8.36452C4.64651 8.41527 4.61049 8.46624 4.57481 8.51744C4.4501 8.69638 4.3296 8.87804 4.21338 9.06228C3.11968 10.7961 2.40533 12.7575 2.12891 14.813C1.76721 17.5027 2.17044 20.2414 3.29099 22.7058C4.41154 25.1701 6.20263 27.2573 8.45278 28.7207C10.7029 30.1842 13.3182 30.9629 15.9898 30.9648C18.6614 30.9668 21.2778 30.192 23.53 28.7319C25.7823 27.2718 27.5763 25.1873 28.7004 22.7246C29.8245 20.2619 30.2317 17.5238 29.8738 14.8336C29.6004 12.7776 28.8888 10.8153 27.7976 9.07977C27.6817 8.89536 27.5614 8.71352 27.437 8.53439C27.4014 8.48314 27.3654 8.43212 27.3291 8.38132C26.9884 7.90448 26.3223 7.85102 25.8799 8.23197C25.4781 8.57794 25.3996 9.16301 25.6391 9.62811C25.6633 9.67512 25.6908 9.7209 25.7215 9.76509C25.8209 9.90827 25.9172 10.0535 26.0103 10.2006Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43959 7.10155C9.26316 6.38617 10.1738 5.78902 11.1487 5.32768C12.6769 4.60458 14.3292 4.23107 16 4.23107C17.6708 4.23107 19.3231 4.60458 20.8513 5.32768C21.8262 5.78902 22.7368 6.38617 23.5604 7.10155C23.7545 7.27015 23.9438 7.44533 24.1279 7.62684C24.1674 7.66576 24.2089 7.70143 24.252 7.73379C24.6784 8.0539 25.2643 8.05014 25.657 7.66763C26.0894 7.24646 26.1236 6.5263 25.6988 6.0963C25.6536 6.05049 25.608 6.00502 25.5623 5.95987C25.3437 5.74446 25.1191 5.53664 24.8886 5.33668C23.9213 4.49736 22.852 3.79661 21.7074 3.25498C19.9096 2.40427 17.9656 1.96484 16 1.96484C14.0344 1.96484 12.0904 2.40427 10.2926 3.25497C9.14801 3.79661 8.07872 4.49736 7.11139 5.33668C6.88094 5.53663 6.65627 5.74446 6.43775 5.95987C6.39195 6.00501 6.34643 6.05049 6.30117 6.0963C5.87639 6.5263 5.91057 7.24645 6.34301 7.66763C6.73575 8.05013 7.32161 8.0539 7.74803 7.73379C7.79113 7.70143 7.83261 7.66576 7.87209 7.62684C8.05622 7.44533 8.24549 7.27015 8.43959 7.10155Z"
      fill="#48FF9B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.563 9.91388C16.4111 9.75587 16.2029 9.6734 15.992 9.67791C15.7849 9.6735 15.5761 9.7525 15.4211 9.91386L13.006 12.4268C12.7118 12.733 12.7214 13.2198 13.0276 13.514C13.3338 13.8083 13.8206 13.7986 14.1148 13.4924L15.2397 12.322V21.804C15.2397 22.2286 15.5839 22.5729 16.0086 22.5729C16.4333 22.5729 16.7775 22.2286 16.7775 21.804V12.3565L17.8692 13.4924C18.1635 13.7986 18.6503 13.8083 18.9564 13.5141C19.2626 13.2198 19.2723 12.733 18.978 12.4268L16.563 9.91388Z"
      fill="#48FF9B"
    />
  </SvgIcon>
);
