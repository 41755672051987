import * as T from '@aily/graphql-sdk/schema';
import { useCallback } from 'react';

import { FilterAlignFunction } from './FilterToolbar';

export function useHandleFilterAlign(filterComponents?: T.FilterComponent[] | null) {
  return useCallback<FilterAlignFunction>(
    (filterId) =>
      filterComponents?.find(({ id }) => id === filterId)?.alignment ?? T.Alignment.Left,
    [filterComponents],
  );
}
