import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTreeItem2Utils } from '@mui/x-tree-view/hooks';
import { RichTreeViewApiRef } from '@mui/x-tree-view/RichTreeView/RichTreeView.types';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import React, { useCallback, useMemo } from 'react';

import { SelectionControl } from './SelectionControl';

export interface CustomTreeItemProps<T extends object> extends TreeItem2Props {
  isItemSelectable?: (item: T) => boolean;
  apiRef?: RichTreeViewApiRef;
  disableSelection?: boolean;
  checkboxSelection?: boolean;
  multiSelect?: boolean;
}

const StyledTreeItem = styled(TreeItem2, {
  shouldForwardProp: (prop) => prop !== 'expandable',
})<{ expandable: boolean }>(({ theme, expandable }) => ({
  '& .MuiTreeItem-content': {
    height: 48,
    marginTop: 1,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    borderRadius: 0,
    backgroundColor: theme.palette.background.paper,
    userSelect: 'none', // Prevent text selection conflicts during expansion
    '&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  '& .MuiTreeItem-label': {
    font: 'unset', // Disable default typography styles
    '& .MuiCheckbox-root, & .MuiRadio-root': {
      padding: 0,
    },
  },
  '& .MuiTreeItem-iconContainer': {
    order: 1, // Move expand/collapse icon to the right
    '& svg': {
      fontSize: 17,
      color: theme.palette.grey[400],
      fill: 'none',
    },
  },
  ...(expandable && {
    '& > .MuiTreeItem-content': {
      backgroundColor: theme.palette.grey[100],
      '&:hover, &.Mui-selected, &.Mui-selected:hover, &.Mui-focused': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  }),
}));

export const CustomTreeItem = React.forwardRef<HTMLLIElement, CustomTreeItemProps<object>>(
  (props, ref) => {
    const { isItemSelectable, apiRef, disableSelection, checkboxSelection, multiSelect, ...rest } =
      props;

    const { status, interactions } = useTreeItem2Utils({
      itemId: props.itemId,
      children: props.children,
    });

    const selectable = useMemo(() => {
      if (disableSelection) {
        return false;
      }

      if (isItemSelectable) {
        const item = apiRef?.current?.getItem(props.itemId);
        return item && isItemSelectable(item);
      }

      return true;
    }, [disableSelection, isItemSelectable, apiRef, props.itemId]);

    const handleSelectionClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation(); // Prevent expansion during selection
        interactions.handleSelection(event);
      },
      [interactions],
    );

    const handleExpansionClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        interactions.handleExpansion(event);
      },
      [interactions],
    );

    const handleContentClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        // If the item is selectable and not expandable, use the content for selection
        if (selectable && !status.expandable) {
          handleSelectionClick(event);
        } else {
          handleExpansionClick(event);
        }
      },
      [selectable, status.expandable, handleSelectionClick, handleExpansionClick],
    );

    const handleLabelClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        // If the item is selectable, use the label for selection
        if (selectable) {
          handleSelectionClick(event);
        }
      },
      [selectable, handleSelectionClick],
    );

    return (
      <StyledTreeItem
        {...rest}
        ref={ref}
        slots={{
          checkbox: React.forwardRef<HTMLElement>(() => null), // Remove the original selection element
        }}
        slotProps={{
          content: { onClick: handleContentClick, 'data-testid': 'tree-item__content' } as never, // Type assertion to suppress TypeScript error
        }}
        label={
          <Stack direction="row" spacing={1.5} alignItems="center">
            {selectable && checkboxSelection && (
              <SelectionControl
                selected={status.selected}
                multiSelect={multiSelect}
                onClick={handleSelectionClick}
              />
            )}
            <Typography
              variant="body"
              noWrap
              onClick={handleLabelClick}
              data-testid="tree-item__label"
            >
              {props.label}
            </Typography>
          </Stack>
        }
        expandable={status.expandable}
      />
    );
  },
);
