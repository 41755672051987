import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 68,
      height: 20,
      borderRadius: 3,
      justifyContent: 'flex-end',
      color: theme.palette.text.primary,
      ...theme.typography.bodyTight,
      '&.MuiChip-colorSuccess': {
        backgroundColor: '#36bf74',
      },
      '&.MuiChip-colorError': {
        backgroundColor: `${theme.palette.error.main}`,
      },
    }),
    colorSuccess: () => ({
      backgroundColor: '#36bf74',
    }),
    label: ({ theme }) => ({
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    }),
  },
};
