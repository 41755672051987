import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.background.paper,
    }),
  },
  defaultProps: {
    arrow: true,
    enterDelay: 0,
  },
};
