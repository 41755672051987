import theme from '@aily/saas-core/theme/default';
import { Drawer, keyframes } from '@mui/material';
import { debounce } from 'lodash-es';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

interface Props {
  expanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const drawerWidth = 88;
const expandedDrawerWidth = 153;
const transition = 'width 300ms cubic-bezier(0.7, -0.4, 0.4, 1.4), box-shadow 300ms linear';
const transitionDelay = 500;

const Sidebar: React.FC<PropsWithChildren<Props>> = ({
  expanded: defaultExpanded,
  onExpandedChange,
  children,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const debouncedHandleMouseLeave = useMemo(
    () =>
      debounce(() => {
        setExpanded(false);
        onExpandedChange?.(false);
      }, transitionDelay),
    [onExpandedChange],
  );

  const handleMouseEnter = useCallback(() => {
    setExpanded(true);
    onExpandedChange?.(true);
    debouncedHandleMouseLeave.cancel();
  }, [onExpandedChange, debouncedHandleMouseLeave]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: expanded ? expandedDrawerWidth : drawerWidth,
        transition,
        transitionDelay: `${expanded ? 0 : transitionDelay}ms`,
        '& .MuiDrawer-paper': {
          width: expanded ? expandedDrawerWidth : drawerWidth,
          transition,
          transitionDelay: `${expanded ? 0 : transitionDelay}ms`,
          animation: `${slideIn} 150ms ease-out`,
          backgroundColor: 'divider',
          boxShadow: theme.shadows[expanded ? 24 : 0],
          overflow: 'hidden',
          borderLeft: 'none',
          borderRadius: 0,
          padding: theme.spacing(10, 3),
        },
      }}
      PaperProps={{
        onMouseEnter: handleMouseEnter,
        onMouseLeave: debouncedHandleMouseLeave,
      }}
    >
      {children}
    </Drawer>
  );
};

export default Sidebar;
