import { Stack, StackProps } from '@mui/material';
import React from 'react';

export interface GaugeContentProps extends StackProps {
  children: React.ReactNode;
}

export const GaugeContent: React.FC<GaugeContentProps> = ({ children, ...rest }) => (
  <Stack direction="column" alignItems="center" justifyContent="center" spacing={0} {...rest}>
    {children}
  </Stack>
);
