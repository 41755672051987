import { useAuth } from '@aily/auth-service';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { appStarted } from '@/store/app/slices';

/**
 * Custom hook to dispatch the appStarted action when the user is authenticated
 */
export function useAppStartedDispatcher() {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(appStarted());
    }
  }, [isAuthenticated]);
}
