import { Button, ButtonProps, styled } from '@mui/material';
import React from 'react';

import { ChevronLeft } from '../../icons';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
  // If it's the first child in the toolbar, align it to the left edge by collapsing the left padding
  '.MuiToolbar-root > &:first-of-type': {
    marginLeft: theme.spacing(-1),
  },
}));

export const BackButton: React.FC<ButtonProps> = ({ children = 'Back', ...rest }) => (
  <StyledButton
    variant="text"
    startIcon={<ChevronLeft />}
    sx={{ typography: 'subtitle' }}
    {...rest}
  >
    {children}
  </StyledButton>
);
