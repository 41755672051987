import { Divider, Stack, StackProps } from '@mui/material';
import React from 'react';

export interface FooterMetaBarProps extends StackProps {}

export const FooterMetaBar: React.FC<FooterMetaBarProps> = ({ children, ...rest }) => (
  <Stack
    direction="row"
    alignItems="center"
    spacing={1}
    divider={
      <Divider
        flexItem
        orientation="vertical"
        sx={{ alignSelf: 'auto', borderColor: 'text.secondary', height: 14 }}
      />
    }
    {...rest}
  >
    {children}
  </Stack>
);
