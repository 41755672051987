import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ChevronUp: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" data-testid="ChevronUp" {...props}>
    <path
      d="M19.9762 17.4847L13.5462 11.0547L7.11621 17.4847"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
