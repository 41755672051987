import * as T from '@aily/graphql-sdk/schema';
import { createContext, useContext } from 'react';

interface StoriesAdapterContextProps {
  redirect?: (link: T.LegacyLink) => void;
  isSeeMoreButtonAvailable?: (story: T.Story) => boolean;
}

export const StoriesAdapterContext = createContext<StoriesAdapterContextProps>({});

export const StoriesAdapterProvider = StoriesAdapterContext.Provider;

export const useStoriesAdapter = (): StoriesAdapterContextProps =>
  useContext(StoriesAdapterContext);
