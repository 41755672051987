import * as T from '@aily/graphql-sdk/schema';
import { Heading, HStack } from '@aily-labs/ui';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';

import { TextElement } from '../../types';
import { IconMap } from '../../utils/IconMap';

interface AgentTextElementProps {
  element: TextElement;
  style?: React.CSSProperties;
}

const AgentTextElement: React.FC<AgentTextElementProps> = ({ element, style }) => {
  const handleTextPress = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  return (
    <HStack sx={{ paddingBottom: element?.display_icon ? 10 : 0, ...style }}>
      <Box alignSelf={'center'}>
        {/* reason why this can't be properly typed now is that we're missing the type from the AI team that will include all options 
      @ts-expect-error */}
        {element?.display_icon && IconMap[element?.sentiment?.toUpperCase()]}
      </Box>

      <Heading
        textAlign={element.alignment}
        adjustsFontSizeToFit
        minimumFontScale={0.1}
        type={element.text_type}
        color={mapSentimentToDSColor(
          // @ts-expect-error reason why this can't be properly typed now is that we're missing the type from the AI team that will include all options
          element?.display_icon ? T.Sentiment.Neutral : element?.sentiment.toUpperCase(),
        )}
        {...(element.url && { onPress: () => handleTextPress(element.url) })}
      >
        {/* missing definitive conclusion what all can be rendered here
        @ts-expect-error */}
        {element.content as string}
      </Heading>
    </HStack>
  );
};

const mapSentimentToDSColor = (
  sentiment: T.Sentiment.Positive | T.Sentiment.Neutral | T.Sentiment.Negative,
) => {
  return { ...sentimentColorMap?.[sentiment] }.color;
};

const sentimentColorMap = {
  [T.Sentiment.Positive]: { color: '$colorSentimentPositive' },
  [T.Sentiment.Neutral]: { color: '$textColorRegular' },
  [T.Sentiment.Negative]: { color: '$colorSentimentNegative' },
};

export default AgentTextElement;
