import React, { useEffect } from 'react';

export function useVideoAutoplay(
  videoRef: React.RefObject<HTMLVideoElement>,
  autoPlay?: boolean,
  inViewAutoplay?: boolean,
  inView?: boolean,
  disabled?: boolean,
) {
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const shouldAutoPlay = !!autoPlay && !disabled;
    const shouldInViewAutoPlay = !!inViewAutoplay && !!inView && !disabled;

    if (shouldAutoPlay || shouldInViewAutoPlay) {
      video.play().catch((e) => console.error('Error playing video:', e));
    } else if (!video.paused) {
      video.pause();
      video.currentTime = 0;
    }
  }, [autoPlay, inViewAutoplay, inView, disabled, videoRef]);
}
