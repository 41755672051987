import * as T from '@aily/graphql-sdk/schema';
import { useMemo } from 'react';

type AlignFiltersResult = {
  [T.Alignment.Left]: T.Filter[];
  [T.Alignment.Right]: T.Filter[];
  [T.Alignment.Center]: T.Filter[];
};

export const useAlignFilters = (
  filters: T.Filter[],
  onFilterAlign?: (filterId: string) => T.Alignment | undefined,
): AlignFiltersResult => {
  return useMemo(
    () => ({
      [T.Alignment.Left]: filters.filter(({ id }) => {
        const align = onFilterAlign?.(id);
        return !align || align === T.Alignment.Left;
      }),
      [T.Alignment.Right]: filters.filter(({ id }) => onFilterAlign?.(id) === T.Alignment.Right),
      [T.Alignment.Center]: filters.filter(({ id }) => onFilterAlign?.(id) === T.Alignment.Center),
    }),
    [filters, onFilterAlign],
  );
};
