import { alpha, Box, Stack, styled, Typography } from '@mui/material';
import useResizeObserver, { type UseResizeObserverCallback } from '@react-hook/resize-observer';
import { scaleLinear } from 'd3';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable, { DraggableBounds, DraggableData, DraggableEvent } from 'react-draggable';

import { ellipsis } from '../../theme/utils';

export type Option = {
  value: number;
  labels: string[];
};

export interface SliderSelectProps {
  options?: Option[];
  slotWidth?: number;
  gapSize?: number;
  labels?: string[];
  defaultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
  backgroundColor?: string;
}

const LabelBox = styled('div')<{ gapSize: number; selected?: boolean }>(
  ({ theme, gapSize, selected }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    zIndex: 1,
    opacity: selected ? 1 : 0.5,
    height: 30,
    borderRadius: 4,
    background: theme.palette.info.main,
    color: theme.palette.text.primary,
    ...(selected ? theme.typography.h9 : theme.typography.body),
    ...(selected && {
      position: 'relative',
      '& + &': {
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: -gapSize,
          width: gapSize,
          height: gapSize,
          borderRadius: gapSize,
          backgroundColor: theme.palette.background.default,
        },
        '&::before': {
          left: -gapSize / 2,
        },
        '&::after': {
          right: -gapSize / 2,
        },
      },
    }),
  }),
);

const SliderItem = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'slotWidth' && prop !== 'selected',
})<{ slotWidth: number; selected?: boolean }>(({ theme, slotWidth, selected }) => ({
  width: slotWidth,
  padding: theme.spacing(1.375, 0),
  borderRadius: 4,
  backgroundColor: selected ? theme.palette.info.main : 'transparent',
}));

const Label = styled('span')(({ theme }) => ({
  padding: theme.spacing(0, 0.25),
  ...ellipsis(),
}));

const Shadow = styled('div')<{ left?: boolean; right?: boolean }>(({ theme, left, right }) => ({
  position: 'absolute',
  top: 0,
  zIndex: 2,
  width: 67,
  height: '100%',
  pointerEvents: 'none',
  ...(left && {
    left: 0,
    background: `linear-gradient(
      90deg,
      ${theme.palette.background.default} 0%,
      ${alpha(theme.palette.background.default, 0)} 100%
    )`,
  }),
  ...(right && {
    right: 0,
    background: `linear-gradient(
      -90deg,
      ${theme.palette.background.default} 0%,
      ${alpha(theme.palette.background.default, 0)} 100%
    )`,
  }),
}));

const SelectionHighlight = styled('div')<{ slotWidth: number }>(({ theme, slotWidth }) => ({
  position: 'absolute',
  width: slotWidth,
  top: -5,
  '&::before, &::after': {
    display: 'inline-block',
    position: 'relative',
    content: '""',
    width: 0,
    height: 0,
    border: '6px solid transparent',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '&::before': {
    borderTop: `10.5px solid ${theme.palette.grey['200']}`,
    borderBottom: 'none',
  },
  '&::after': {
    borderTop: 'none',
    borderBottom: `10.5px solid ${theme.palette.grey['200']}`,
  },
}));

function calculatePosition(
  wrapperWidth: number,
  slotWidth: number,
  gapSize: number,
  index: number,
) {
  const centerOffset = wrapperWidth / 2; // Center offset of the rail
  const slotOffset = (index + 0.5) * (slotWidth + gapSize); // Offset based on slot position
  const gapOffset = gapSize / 2; // Half of the gap size

  return Math.round(centerOffset - slotOffset + gapOffset);
}

export const SliderSelect: React.FC<SliderSelectProps> = ({
  options = [],
  slotWidth = 80,
  gapSize = 6,
  labels = [],
  defaultValue,
  value,
  onChange,
}) => {
  const [localValue, setLocalValue] = useState(defaultValue);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const labelFontSizeScale = scaleLinear()
    .domain([slotWidth / 10, (slotWidth / 10) * 1.3])
    .range([100, 70])
    .clamp(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  // Current value based on whether the component is controlled or uncontrolled
  const currentValue = value !== undefined ? value : localValue;

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    const optionIndex = options.findIndex(({ value }) => value === currentValue);
    if (optionIndex === -1) {
      return;
    }

    const { offsetWidth } = wrapperRef.current;
    const newPosition = calculatePosition(offsetWidth, slotWidth, gapSize, optionIndex);

    if (currentPosition !== newPosition) {
      setCurrentPosition(newPosition);
    }

    setCurrentIndex(optionIndex);
  }, [wrapperRef.current, options, currentValue, currentPosition, slotWidth, gapSize]);

  const handleChange = useCallback(
    (newValue: number) => {
      setLocalValue(newValue);

      if (newValue !== currentValue) {
        onChange?.(newValue);
      }
    },
    [currentValue, onChange],
  );

  const onResize = useCallback<UseResizeObserverCallback>(
    (entry) => {
      const { width } = entry.contentRect;
      const optionIndex = options.findIndex(({ value }) => value === currentValue);
      const newPosition = calculatePosition(width, slotWidth, gapSize, optionIndex);

      setCurrentPosition(newPosition);
    },
    [options, currentValue, slotWidth, gapSize],
  );

  useResizeObserver(wrapperRef.current, onResize);

  const handleStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDrag = useCallback(
    (event: DraggableEvent, data: DraggableData) => {
      if (wrapperRef.current) {
        const { offsetWidth } = wrapperRef.current;
        const centerOffset = offsetWidth / 2;
        const dragOffset = centerOffset - data.x - (slotWidth + gapSize) / 2;
        const slotIndex = Math.round(dragOffset / (slotWidth + gapSize));
        const closestOptionIndex = Math.max(0, Math.min(slotIndex, options.length - 1));

        setCurrentIndex(closestOptionIndex);
      }
    },
    [wrapperRef.current, options, slotWidth, gapSize],
  );

  const handleStop = useCallback(() => {
    if (wrapperRef.current) {
      const { offsetWidth } = wrapperRef.current;
      const newPosition = calculatePosition(offsetWidth, slotWidth, gapSize, currentIndex);

      setCurrentPosition(newPosition);
      setIsDragging(false);
      handleChange(options[currentIndex]?.value);
    }
  }, [wrapperRef.current, options, slotWidth, gapSize, currentIndex, handleChange]);

  const handleItemDoubleClick = useCallback(
    (option: Option) => () => {
      handleChange(option.value);
    },
    [handleChange],
  );

  const renderSelectedItem = () => {
    const selectedOption = options[currentIndex];
    if (selectedOption && wrapperRef.current) {
      const { labels } = selectedOption;
      const centerOffset = wrapperRef.current.offsetWidth / 2;
      return (
        <SelectionHighlight
          slotWidth={slotWidth}
          sx={{ left: Math.round(centerOffset - slotWidth / 2) }}
        >
          <SliderItem
            direction="column"
            spacing={`${gapSize}px`}
            slotWidth={slotWidth}
            selected
            sx={{
              // Prevents it from interfering with the dragging
              pointerEvents: 'none',
            }}
          >
            {labels.map((label, labelIndex) => {
              const isFirst = labels.length > 1 && labelIndex === 0;
              const isLast = labels.length > 1 && labelIndex === labels.length - 1;
              return (
                <LabelBox key={labelIndex} gapSize={gapSize} selected>
                  <Label
                    sx={{
                      position: 'relative',
                      fontSize: `${labelFontSizeScale(label.length)}%`,
                      // Label position adjustment according to the design (can be done better)
                      ...(isFirst && { top: -gapSize / 2 }),
                      ...(isLast && { bottom: -gapSize / 2 }),
                    }}
                  >
                    {label}
                  </Label>
                </LabelBox>
              );
            })}
          </SliderItem>
        </SelectionHighlight>
      );
    }
  };

  const bounds = useMemo<DraggableBounds | undefined>(() => {
    if (wrapperRef.current) {
      const railWidth = options.length * slotWidth + (options.length - 1) * gapSize;
      const centerOffset = wrapperRef.current.offsetWidth / 2;
      return {
        left: Math.round(centerOffset - railWidth + slotWidth / 2),
        right: Math.round(centerOffset - slotWidth / 2),
      };
    }
  }, [wrapperRef.current?.offsetWidth, options, slotWidth, gapSize]);

  return (
    <Box sx={{ display: 'flex', py: 1.25, backgroundColor: 'background.default' }}>
      <Stack
        direction="column"
        justifyContent="center"
        spacing={1.875}
        sx={{ width: 120, flexShrink: 0, '& > .MuiTypography-root': { ...ellipsis() } }}
      >
        {labels.map((label, labelIndex) => (
          <Typography key={labelIndex} variant="bodyBoldTight">
            {label}
          </Typography>
        ))}
      </Stack>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          overflow: 'hidden',
          minWidth: `${slotWidth * ((options?.length ?? 1) + 1.5)}px`,
          py: 2,
        }}
        ref={wrapperRef}
      >
        {options?.length > 5 && <Shadow left />}
        {options?.length > 5 && <Shadow right />}
        <Draggable
          nodeRef={nodeRef}
          axis="x"
          bounds={bounds}
          position={{ x: currentPosition, y: 0 }}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
        >
          <Stack
            ref={nodeRef}
            direction="row"
            spacing={`${gapSize}px`}
            sx={{
              alignItems: 'center',
              // Animate to the current position when not dragging
              transition: isDragging ? 'none' : 'transform 150ms ease-out',
            }}
          >
            {options.map((option, optionIndex) => (
              <SliderItem
                key={optionIndex}
                onDoubleClick={handleItemDoubleClick(option)}
                direction="column"
                spacing={`${gapSize}px`}
                slotWidth={slotWidth}
              >
                {option.labels.map((label, labelIndex) => (
                  <LabelBox key={labelIndex} gapSize={gapSize}>
                    <Label sx={{ fontSize: `${labelFontSizeScale(label.length)}%` }}>{label}</Label>
                  </LabelBox>
                ))}
              </SliderItem>
            ))}
          </Stack>
        </Draggable>
        {renderSelectedItem()}
      </Box>
    </Box>
  );
};
