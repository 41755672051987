import { useEffect } from 'react';

import { useAnalytics } from '../providers';
import { EventMap } from '../types';
import { getPathDetails } from '../utils';
import { useAnalyticsCommonEventProps } from '.';

export function useAnalyticsTrackPage() {
  const { page, useCurrentPage, useCurrentModule } = useAnalytics();
  const { page: currentPage } = useCurrentPage() ?? {};
  const commonEventProps = useAnalyticsCommonEventProps();
  const currentModule = useCurrentModule();

  const { moduleName, pageName, focusArea } = getPathDetails(currentPage?.absolutePath || '');

  useEffect(() => {
    if (!currentPage) return;

    if (!pageName || pageName === 'undefined') return;

    if (!moduleName || moduleName === 'undefined') return;

    page(undefined, pageName, {
      ...commonEventProps,
      module: currentModule?.name || moduleName,
      name: pageName,
      focus_area: focusArea,
      item_type: 'page',
      event_version: '2.0.0',
      intent: 'see',
    } satisfies EventMap['page.seen']);
  }, [
    commonEventProps,
    currentModule?.name,
    currentPage?.absolutePath,
    page,
    moduleName,
    pageName,
    focusArea,
  ]);
}
