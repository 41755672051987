import { Box, BoxProps, Skeleton, SkeletonProps, styled } from '@mui/material';
import React from 'react';

interface LoadingSkeletonProps extends BoxProps {
  SkeletonProps?: SkeletonProps;
}

const StyledBox = styled(Box)({
  // Collapse multiple adjacent elements into one
  '& + &': {
    display: 'none',
  },
});

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({ SkeletonProps, ...rest }) => (
  <StyledBox {...rest}>
    <Skeleton width="38%" {...SkeletonProps} />
    <Skeleton {...SkeletonProps} />
    <Skeleton {...SkeletonProps} />
    <Skeleton width="61%" {...SkeletonProps} />
  </StyledBox>
);
