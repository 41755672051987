import { combineReducers } from '@reduxjs/toolkit';

import app from './app/slices';
import dimensionFilter from './dimensionFilter/slices';
import modals from './modals/slices';
import modules from './modules/slices';
import priorityFilter from './priorityFilter/slices';
import userDisclaimer from './userDisclaimer/slices';

const rootReducer = combineReducers({
  app,
  modals,
  modules,
  priorityFilter,
  dimensionFilter,
  userDisclaimer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
