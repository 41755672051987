import { FilterOptionResult } from '@aily/graphql-sdk/schema';
import { find } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';

import { OptionType, RadioGroup, RadioGroupProps } from '../RadioGroup';

export interface SelectFilterRadioGroupProps
  extends Omit<RadioGroupProps, 'value' | 'defaultValue' | 'onChange'> {
  filterOptions?: readonly FilterOptionResult[];
  value?: string;
  defaultValue?: string;
  onChange?: (filterOption: FilterOptionResult) => void;
}

const SelectFilterRadioGroup: React.FC<SelectFilterRadioGroupProps> = ({
  filterOptions,
  onChange,
  ...rest
}) => {
  const options = useMemo<OptionType[]>(
    () =>
      filterOptions?.map(({ value, label }) => ({ value: String(value), label: String(label) })) ??
      [],
    [filterOptions],
  );

  const handleChange = useCallback(
    (newValue: string) => {
      const filterOption = find(
        filterOptions,
        ({ value }) => String(value) === newValue,
      ) as FilterOptionResult;

      onChange?.(filterOption);
    },
    [onChange, filterOptions],
  );

  if (!filterOptions?.length) {
    return null;
  }

  return <RadioGroup options={options} onChange={handleChange} {...rest} />;
};

export default SelectFilterRadioGroup;
