import { useThemeProps } from '@mui/material';
import React from 'react';

import { useThemeColor } from '../../hooks';
import { colors } from '../../theme/default/colors';
import { ModuleLogoVariantProps } from './ModuleLogo';

export const AilyFin: React.FC<ModuleLogoVariantProps> = (props) => {
  const themeProps = useThemeProps({ props, name: 'ModuleLogo' });
  const primaryColor = useThemeColor(themeProps.primaryColor ?? colors.primary.green);
  const secondaryColor = useThemeColor(themeProps.secondaryColor ?? colors.primary.aqua);
  return (
    <svg width="101" height="33" viewBox="0 0 101 33" fill="none">
      <path
        d="M60.029 23.43C60.029 24.2 59.765 24.827 59.237 25.311C58.731 25.795 58.115 26.037 57.389 26.037C56.641 26.037 56.014 25.795 55.508 25.311C55.002 24.827 54.749 24.2 54.749 23.43C54.749 22.66 55.002 22.033 55.508 21.549C56.014 21.043 56.641 20.79 57.389 20.79C58.115 20.79 58.731 21.043 59.237 21.549C59.765 22.033 60.029 22.66 60.029 23.43Z"
        fill={secondaryColor}
      />
      <path
        d="M71.0056 0C71.9296 0 72.7436 0.0880001 73.4476 0.264C74.1516 0.418 74.6796 0.560999 75.0316 0.692999L74.2726 3.993C73.8986 3.817 73.4586 3.674 72.9526 3.564C72.4686 3.454 71.9406 3.399 71.3686 3.399C70.7306 3.399 70.1916 3.498 69.7516 3.696C69.3116 3.872 68.9596 4.125 68.6956 4.455C68.4316 4.785 68.2446 5.192 68.1346 5.676C68.0246 6.138 67.9696 6.644 67.9696 7.194V8.349H74.2396V11.682H67.9696V25.608H63.9766V7.128C63.9766 4.906 64.5596 3.168 65.7256 1.914C66.8916 0.638 68.6516 0 71.0056 0Z"
        fill={secondaryColor}
      />
      <path
        d="M80.8855 25.608H76.8925V8.349H80.8855V25.608ZM81.3145 3.3C81.3145 4.048 81.0725 4.642 80.5885 5.082C80.1045 5.522 79.5325 5.742 78.8725 5.742C78.1905 5.742 77.6075 5.522 77.1235 5.082C76.6395 4.642 76.3975 4.048 76.3975 3.3C76.3975 2.53 76.6395 1.925 77.1235 1.485C77.6075 1.045 78.1905 0.824999 78.8725 0.824999C79.5325 0.824999 80.1045 1.045 80.5885 1.485C81.0725 1.925 81.3145 2.53 81.3145 3.3Z"
        fill={secondaryColor}
      />
      <path
        d="M85.6497 8.91C86.4197 8.69 87.4207 8.481 88.6527 8.283C89.8847 8.085 91.2487 7.986 92.7447 7.986C94.1527 7.986 95.3297 8.184 96.2757 8.58C97.2217 8.954 97.9697 9.493 98.5197 10.197C99.0917 10.879 99.4877 11.715 99.7077 12.705C99.9497 13.673 100.071 14.74 100.071 15.906V25.608H96.0777V16.533C96.0777 15.609 96.0117 14.828 95.8797 14.19C95.7697 13.53 95.5717 13.002 95.2857 12.606C95.0217 12.188 94.6477 11.891 94.1637 11.715C93.7017 11.517 93.1297 11.418 92.4477 11.418C91.9417 11.418 91.4137 11.451 90.8637 11.517C90.3137 11.583 89.9067 11.638 89.6427 11.682V25.608H85.6497V8.91Z"
        fill={secondaryColor}
      />
      <path
        d="M34.1539 26.1654C33.5722 26.2328 32.9346 26.2666 32.2411 26.2666C30.4513 26.2666 29.0642 25.6931 28.0798 24.5462C27.1177 23.3993 26.6367 21.7914 26.6367 19.7225V2.24915C26.6367 1.91182 26.8045 1.74316 27.1401 1.74316H30.0262C30.3618 1.74316 30.5296 1.91182 30.5296 2.24915V19.5876C30.5296 20.6895 30.7085 21.4878 31.0665 21.9826C31.4245 22.4548 31.9838 22.6909 32.7445 22.6909C33.0129 22.6909 33.438 22.6685 34.0197 22.6235C34.3553 22.6235 34.5231 22.7921 34.5231 23.1295V25.6257C34.5231 25.963 34.4 26.1429 34.1539 26.1654Z"
        fill={secondaryColor}
      />
      <path
        d="M47.1075 21.1393V8.86069C47.1075 8.54585 47.2753 8.38843 47.6108 8.38843H50.4969C50.8325 8.38843 51.0003 8.54585 51.0003 8.86069V24.7486C51.0003 29.9884 48.5057 32.6083 43.5166 32.6083C40.8319 32.6083 38.6394 31.8999 36.9391 30.4831C36.693 30.3032 36.6594 30.0783 36.8384 29.8085L38.2814 27.7508C38.4604 27.4584 38.673 27.4022 38.9191 27.5821C40.418 28.6166 41.9394 29.1338 43.4831 29.1338C44.736 29.1338 45.6756 28.7965 46.302 28.1218C46.9285 27.4697 47.2081 26.3453 47.141 24.7486C45.8434 25.1534 44.6465 25.3558 43.5502 25.3558C41.6261 25.3558 39.9482 24.7599 38.5163 23.568C37.0845 22.3536 36.3686 20.6558 36.3686 18.4744V8.86069C36.3686 8.54585 36.5364 8.38843 36.872 8.38843H39.7916C40.1048 8.38843 40.2614 8.54585 40.2614 8.86069V18.3057C40.2614 19.5201 40.6194 20.4084 41.3353 20.9706C42.0512 21.5328 42.9685 21.8139 44.0871 21.8139C45.2282 21.8364 46.2349 21.6115 47.1075 21.1393Z"
        fill={secondaryColor}
      />
      <path
        d="M1.04033 9.77162C2.76303 8.57975 4.95555 7.98381 7.6179 7.98381C9.78805 7.98381 11.4884 8.55726 12.7189 9.70416C13.9717 10.8286 14.5982 12.5377 14.5982 14.8315V24.6813C14.5982 24.9512 14.4751 25.1423 14.229 25.2548C12.0589 25.9294 9.81042 26.2667 7.48366 26.2667C5.31351 26.2667 3.5237 25.817 2.11422 24.9174C0.70474 24.0179 0 22.5899 0 20.6334C0 18.8569 0.659994 17.5188 1.97998 16.6193C3.32234 15.6973 5.11216 15.2363 7.34942 15.2363C8.24433 15.2363 9.36296 15.3262 10.7053 15.5061V14.8652C10.7053 13.6508 10.448 12.785 9.93347 12.2678C9.44127 11.7281 8.57992 11.4582 7.34942 11.4582C5.69385 11.4582 4.262 11.8181 3.05387 12.5377C2.7854 12.7401 2.56167 12.6951 2.38269 12.4027L1.04033 10.3451C0.883721 10.0977 0.883721 9.90655 1.04033 9.77162ZM3.9264 20.5997C3.9264 21.3643 4.25081 21.9378 4.89962 22.3201C5.54842 22.6799 6.40977 22.8598 7.48366 22.8598C8.5128 22.8598 9.58669 22.7248 10.7053 22.455V18.6432C9.4972 18.4633 8.41213 18.3734 7.4501 18.3734C5.10097 18.3734 3.9264 19.1155 3.9264 20.5997Z"
        fill={primaryColor}
      />
      <path
        d="M22.0325 25.8619H19.1129C18.7773 25.8619 18.6095 25.6933 18.6095 25.356V8.86085C18.6095 8.54602 18.7773 8.3886 19.1129 8.3886H22.0325C22.3458 8.3886 22.5024 8.54602 22.5024 8.86085V25.356C22.5024 25.6933 22.3458 25.8619 22.0325 25.8619ZM22.1332 2.24931C22.5583 2.6541 22.7708 3.17133 22.7708 3.801C22.7708 4.43067 22.5583 4.95914 22.1332 5.38642C21.7305 5.8137 21.2159 6.02734 20.5895 6.02734C19.9631 6.02734 19.4373 5.8137 19.0122 5.38642C18.5872 4.95914 18.3746 4.43067 18.3746 3.801C18.3746 3.17133 18.5872 2.6541 19.0122 2.24931C19.4373 1.82204 19.9631 1.6084 20.5895 1.6084C21.2159 1.6084 21.7305 1.82204 22.1332 2.24931Z"
        fill={primaryColor}
      />
    </svg>
  );
};
