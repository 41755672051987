import { styled } from '@mui/material';
import { motion, SVGMotionProps, Transition, useAnimation } from 'framer-motion';
import React, { PropsWithChildren, useEffect, useId } from 'react';

import Bullet from './Bullet';

type CommonCircleProps = Pick<
  React.SVGProps<SVGCircleElement>,
  'cx' | 'cy' | 'r' | 'strokeWidth' | 'strokeLinecap' | 'fill'
>;

interface Props extends SVGMotionProps<SVGSVGElement> {
  size: number;
  lineWidth: number;
  lineColor: string;
  trackColor: string;
  progress: number;
  transition?: Transition;
}

const BarBackground = styled(motion.div)<{ background: string }>(({ background }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  background,
}));

const defaultTransition: Transition = { duration: 0.4, ease: 'easeInOut' };

const AnimatedCircle: React.FC<PropsWithChildren<Props>> = ({
  size,
  lineWidth,
  lineColor,
  trackColor,
  progress,
  transition = defaultTransition,
  children,
  ...rest
}) => {
  const id = useId();
  const pathLengthControls = useAnimation();
  const rotationControls = useAnimation();
  const circleProps: CommonCircleProps = {
    cx: '50%',
    cy: '50%',
    r: size / 2 - lineWidth / 2,
    strokeWidth: lineWidth,
    strokeLinecap: 'round',
    fill: 'none',
  };

  useEffect(() => {
    pathLengthControls.start({ pathLength: progress });
    rotationControls.start({ rotate: progress * 360 });
  }, [pathLengthControls, rotationControls, progress]);

  return (
    <motion.svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      initial="initial"
      animate="animate"
      {...rest}
    >
      <defs>
        <mask id={`${id}-mask`}>
          <g style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
            <motion.circle
              {...circleProps}
              stroke="white"
              initial={{ pathLength: 0 }}
              animate={pathLengthControls}
              transition={transition}
            />
          </g>
        </mask>
      </defs>
      <g style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}>
        <circle className="bg" stroke={trackColor} {...circleProps} />
      </g>
      <g mask={`url(#${id}-mask)`}>
        <foreignObject x="0" y="0" width="100%" height="100%">
          <BarBackground background={lineColor} />
        </foreignObject>
      </g>
      <motion.g
        style={{ originX: `${lineWidth / 2}px`, originY: '50%' }}
        transformTemplate={({ rotate }) =>
          `translateX(calc(50% - ${lineWidth / 2}px)) rotate(${rotate})`
        }
        initial={{ rotate: 0 }}
        animate={rotationControls}
        transition={transition}
      >
        <Bullet size={lineWidth} />
      </motion.g>
      {children}
    </motion.svg>
  );
};

export default AnimatedCircle;
