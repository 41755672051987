import * as T from '@aily/graphql-sdk/schema';
import { ModuleProvider } from '@aily/saas-core';
import React, { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

import DefaultPageRedirect from '@/components/AppRouter/DefaultPageRedirect';
import { isProfilePageEnabled } from '@/config/profile';

const PageWrapper = lazy(() => import('@/pages/PageWrapper'));
const AddPrioritiesPage = lazy(
  () => import('@aily/saas-core/features/MyPriorities/pages/MyPriorities/Add.tsx'),
);
const MyPrioritiesPage = lazy(
  () => import('@aily/saas-core/features/MyPriorities/pages/MyPriorities.tsx'),
);
const ProfilePage = lazy(() => import('@aily/saas-core/features/Profile/pages/Profile.tsx'));

export function createPageRoute(page: T.Page, parentPage?: T.Page): RouteObject {
  // If the path is empty, use the last segment of the absolute path as a fallback
  const path = page.path || page.absolutePath.split('/').pop();

  return {
    // Allow component-based routing for content pages
    path: `${path}/*`,
    element: <PageWrapper key={page.id} page={page} parentPage={parentPage} />,
    children: page.children?.map((childPage) => createPageRoute(childPage, page)),
  };
}

export function createModuleRoute(module: T.Module): RouteObject {
  return {
    path: module.path,
    element: (
      <ModuleProvider value={module}>
        <Outlet />
      </ModuleProvider>
    ),
    children: [
      {
        path: 'my-priorities',
        element: <Outlet />,
        children: [
          { path: 'add', element: <AddPrioritiesPage /> },
          { index: true, element: <MyPrioritiesPage /> },
        ],
      },
      ...(isProfilePageEnabled(module) ? [{ path: 'profile/*', element: <ProfilePage /> }] : []),
      ...(module.pages?.map((page) => createPageRoute(page)) ?? []),
      { index: true, element: <DefaultPageRedirect pages={module.pages} /> },
    ] satisfies RouteObject[],
  };
}
