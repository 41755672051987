import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ArrowCircleDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.53 26.5406C26.2306 26.8446 26.2342 27.3337 26.5381 27.6332C26.8421 27.9327 27.3312 27.9291 27.6307 27.6251C30.315 24.9008 32 21.1324 32 17.0006C32 8.69826 25.3003 1.9986 16.998 1.9986C8.69572 1.9986 1.99606 8.69826 1.99606 17.0006C1.99606 20.737 3.4028 24.145 5.64178 26.7838C5.69881 26.851 5.76488 26.906 5.83667 26.9486C5.90705 27.0364 5.98445 27.1269 6.06491 27.2073C6.36662 27.509 6.85579 27.509 7.15751 27.2073C7.45922 26.9056 7.45922 26.4164 7.15751 26.1147C7.09011 26.0473 7.01053 25.9438 6.88685 25.779C6.81744 25.6864 6.73126 25.6139 6.63569 25.5624C4.72169 23.2124 3.54123 20.2381 3.54123 17.0006C3.54123 9.55163 9.54909 3.54377 16.998 3.54377C24.447 3.54377 30.4548 9.55163 30.4548 17.0006C30.4548 20.7064 28.9439 24.0907 26.53 26.5406Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0362 30.4607C20.1311 30.4607 22.9709 29.4179 25.2365 27.6558C25.5733 27.3939 26.0587 27.4545 26.3206 27.7913C26.5826 28.1281 26.5219 28.6135 26.1851 28.8755C23.6568 30.842 20.4852 32.0059 17.0362 32.0059C13.5835 32.0059 10.0993 30.7251 7.5264 28.5944C7.19777 28.3222 7.15198 27.8352 7.42413 27.5066C7.69627 27.1779 8.1833 27.1321 8.51193 27.4043C10.8089 29.3065 13.9449 30.4607 17.0362 30.4607Z"
      fill="#FF3469"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0172 24.3203C16.8044 24.3255 16.5943 24.2426 16.4411 24.0833L13.6257 21.1537C13.33 20.846 13.3398 20.357 13.6474 20.0613C13.955 19.7656 14.4441 19.7754 14.7398 20.083L16.2256 21.6291L16.2256 10.3077C16.2256 9.88105 16.5715 9.53516 16.9982 9.53516C17.4249 9.53516 17.7708 9.88105 17.7708 10.3077L17.7708 21.6686L19.2946 20.083C19.5902 19.7754 20.0793 19.7657 20.3869 20.0613C20.6946 20.357 20.7043 20.8461 20.4086 21.1537L17.5932 24.0833C17.4368 24.246 17.2263 24.3254 17.0172 24.3203Z"
      fill="#FF3469"
    />
  </SvgIcon>
);
