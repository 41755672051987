import { getCurrentUser } from '@aily/auth-service';
import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import envConfig from '@/config/env';

function getSentryOptions(): BrowserOptions | undefined {
  if (envConfig.SENTRY_DSN) {
    return {
      dsn: envConfig.SENTRY_DSN,
      environment: envConfig.SENTRY_ENVIRONMENT,
      release: envConfig.SENTRY_RELEASE,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
          traceFetch: false,
        }),
      ],
      tracesSampleRate: 1,
    };
  }

  return undefined;
}

const sentryOptions = getSentryOptions();

if (sentryOptions) {
  Sentry.init(sentryOptions);

  const user = getCurrentUser();
  if (user) {
    Sentry.setUser({ id: user.profile?.sub });
  }
}
