import { trim } from 'lodash-es';
import React, { useCallback, useMemo, useRef } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { PageMenu } from '../../../../components';
import { Shield } from '../../../../icons';
import { useModule } from '../../../../providers';

export const ProfilePageMenuItem = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const module = useModule();
  const menuItemRef = useRef<HTMLDivElement>(null);
  const path = useMemo(() => `${module?.path ? trim(module.path, '/') : ''}/profile`, [module]);

  // Match this page and its subpages
  const selected = !!matchPath(`${path}/*`, pathname);

  const handleClick = useCallback(() => {
    navigate(path, { state: { from: `${pathname}${search}` } });

    // Remove focus from menu item to prevent highlighting when opening and closing Profile modal page
    if (menuItemRef.current) {
      menuItemRef.current.blur();
    }
  }, [navigate, path, pathname, search]);

  return (
    <PageMenu.Item
      key="profile"
      title="Profile"
      icon={<Shield />}
      selected={selected}
      onClick={handleClick}
      ref={menuItemRef}
      data-x-path="profile"
    />
  );
};
