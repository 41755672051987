import * as T from '@aily/graphql-sdk/schema';
import { BoxProps } from '@mui/material';
import mime from 'mime/lite';
import React, { useEffect, useState } from 'react';

import { useEnvironment } from '../../providers';
import { downloadMedia, getFullUrl } from '../../utils';
import CoverImage from '../CoverImage/CoverImage';

export interface StoryCoverImageProps extends Omit<BoxProps, 'children' | 'onLoad'> {
  story: T.Story;
}

const StoryCoverImage: React.FC<StoryCoverImageProps> = ({ story, ...rest }) => {
  const { API_URL } = useEnvironment();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [lqipImageUrl, setLqipImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchUrls = async () => {
      if (story.storyType === T.StoryDisplayType.Media) {
        const videoContent = story.content?.find(T.isVideoComponent)?.videoContent;

        if (videoContent?.thumbnailUrl) {
          const mimeType = mime.getType(videoContent.thumbnailUrl);
          if (mimeType?.startsWith('image/')) {
            const fullThumbnailUrl = getFullUrl(videoContent.thumbnailUrl, API_URL);
            try {
              const authorizedThumbnailUrl = await downloadMedia(fullThumbnailUrl);
              if (authorizedThumbnailUrl) {
                setThumbnailUrl(authorizedThumbnailUrl);
              }
            } catch (error) {
              console.error('Error fetching thumbnail image:', error);
            }
          }
        }

        if (videoContent?.lqipUrl) {
          const fullLqipUrl = getFullUrl(videoContent.lqipUrl, API_URL);
          try {
            const authorizedLqipUrl = await downloadMedia(fullLqipUrl);
            if (authorizedLqipUrl) {
              setLqipImageUrl(authorizedLqipUrl);
            }
          } catch (error) {
            console.error('Error fetching LQIP image:', error);
          }
        }
      }
    };

    fetchUrls();
  }, [API_URL, story]);

  const opacity = 0.3;

  if (!thumbnailUrl) {
    return null;
  }

  return (
    <CoverImage
      lqipUrl={lqipImageUrl || ''}
      sx={{ mixBlendMode: 'darken', opacity, transition: 'filter 100ms linear' }}
      loadedStyle={(loaded) => ({ filter: loaded ? 'blur(0px)' : 'blur(10px)' })}
      {...rest}
    >
      <source srcSet={thumbnailUrl} type={mime.getType(thumbnailUrl) as string} />
    </CoverImage>
  );
};

export default React.memo(StoryCoverImage);
