import { initAuth } from '@aily/auth-service';

import envConfig from '@/config/env';

initAuth({
  authority: envConfig.OIDC_AUTH_URL,
  client_id: envConfig.OIDC_CLIENT_ID,
  redirect_uri: envConfig.OIDC_REDIRECT_URL,
  post_logout_redirect_uri: envConfig.OIDC_REDIRECT_URL,
  scope: envConfig.OIDC_SCOPE,
});
