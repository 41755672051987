import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useModule } from '../providers';
import { findPageDepthFirst } from '../utils';

/**
 * Custom hook to get the current page and its parents. It only searches the pages of the current module.
 */
export function usePage() {
  const module = useModule();
  const { pathname } = useLocation();

  return useMemo(
    () => findPageDepthFirst(module?.pages ?? [], pathname),
    [module?.pages, pathname],
  );
}
