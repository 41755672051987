import useResizeObserver, { UseResizeObserverCallback } from '@react-hook/resize-observer';
import { RefObject, useCallback, useEffect, useState } from 'react';

interface Overflow {
  x: number;
  y: number;
}

function calculateOverflow(element: HTMLElement): Overflow {
  return {
    x: element.scrollWidth - element.clientWidth,
    y: element.scrollHeight - element.clientHeight,
  };
}

export function useOverflow<T extends HTMLElement>(ref: RefObject<T>): Overflow {
  const [overflow, setOverflow] = useState<Overflow>({ x: 0, y: 0 });

  const onResize: UseResizeObserverCallback = useCallback(({ target }) => {
    setOverflow(calculateOverflow(target as HTMLElement));
  }, []);

  useResizeObserver(ref.current, onResize);

  // Effect to trigger on first render
  useEffect(() => {
    if (ref.current) {
      setOverflow(calculateOverflow(ref.current));
    }
  }, [ref.current]);

  return overflow;
}
