import * as T from '@aily/graphql-sdk/schema';
import { Button, Skeleton, styled } from '@mui/material';
import React, { useCallback } from 'react';

import { useHandleLink } from '../../hooks';
import { DetailsRow } from '../DetailsRow';

const StyledLink = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: 0,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const LinkLabel = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const LoadingSkeleton = styled(Skeleton)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  height: 72,
}));

interface Props {
  dataView?: T.SummaryInfoDataView;
  loading?: boolean;
}

const SummaryInfoDataView: React.FC<Props> = ({ dataView, loading }) => {
  const handleLink = useHandleLink();

  const renderCellContent = useCallback(
    (cellContent: T.TableCellContent) => {
      if (T.isTextLinkResult(cellContent)) {
        return (
          <>
            {!!cellContent.label && <LinkLabel>{cellContent.label}:</LinkLabel>}
            <StyledLink variant="text" onClick={() => handleLink(cellContent.linkResult as T.Link)}>
              {cellContent.value}
            </StyledLink>
          </>
        );
      }

      if (T.isTextResult(cellContent)) {
        return cellContent.value;
      }

      return null;
    },
    [handleLink],
  );

  if (loading) {
    return <LoadingSkeleton variant="rectangular" />;
  }

  return (
    <div data-testid="SummaryInfoDataView">
      <DetailsRow>
        {dataView?.summaryInfo.items?.map((item, index) => (
          <DetailsRow.Item
            key={index}
            header={renderCellContent(item.header)}
            content={renderCellContent(item.content)}
          />
        ))}
      </DetailsRow>
    </div>
  );
};

export default SummaryInfoDataView;
