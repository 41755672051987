import {
  alpha,
  Backdrop,
  Box,
  Fade,
  IconButton,
  IconButtonProps,
  Modal,
  ModalProps,
  styled,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import React from 'react';

export interface PopupProps extends Omit<ModalProps, 'title' | 'children'> {
  title?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
  width?: number;
}

const StyledModal = styled(Modal)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 2,
}));

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M4 22L2 20L10 12L2 4L4 2L12 10L20 2L22 4L14 12L22 20L20 22L12 14L4 22Z" />
  </SvgIcon>
);

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.modal + 3,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(4, 7.5, 4, 7.5),
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)',
  outline: 'none',
  borderRadius: 12,
  background: alpha(theme.palette.grey[300], 0.3),
  backdropFilter: `blur(${theme.spacing(2.5)})`,
  maxHeight: '810px',
  overflow: 'auto',
}));

const PopupContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(5),
}));

const PopupHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 30,
});

const CloseButton = styled((props: IconButtonProps) => (
  <IconButton disableRipple {...props}>
    <CloseIcon />
  </IconButton>
))(({ theme }) => ({
  color: theme.palette.grey[300],
  '& .MuiIcon-root': {
    fontSize: 24,
  },
}));

export const Popup: React.FC<PopupProps> = ({
  title,
  children,
  open,
  onClose,
  width = 800,
  ...rest
}) => (
  <StyledModal
    open={open}
    onClose={onClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{ backdrop: { timeout: 500 } }}
    disableScrollLock={false}
    {...rest}
  >
    <Fade in={open}>
      <PopupContainer width={width}>
        <PopupContent>
          <PopupHeader>
            {title}
            <CloseButton onClick={onClose} />
          </PopupHeader>
          {children}
        </PopupContent>
      </PopupContainer>
    </Fade>
  </StyledModal>
);
