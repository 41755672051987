import { GoalResultType } from '@aily/graphql-sdk/schema';
import React, { createContext, useContext, useMemo } from 'react';

export type ScreenType =
  | 'DAILYAGENT_PROGRESS_SCREEN'
  | 'DAILYAGENT_ACTIONS_SCREEN'
  | 'DAILYAGENT_FIREWORKS_SCREEN'
  | 'DAILYAGENT_INTERCEPT_SCREEN'
  | 'DAILYAGENT_EDIT_GOALS_SCREEN'
  | 'DAILYAGENT_HOME_SCREEN';

export interface DailyAgentContextState {
  goalsSelected: Array<GoalResultType>;
  setGoalsSelected: (goals: Array<GoalResultType>) => void;
  currentScreen: ScreenType;
  setCurrentScreen: (screen: ScreenType) => void;
}

export interface DailyAgentContextProviderProps {
  children?: React.ReactNode;
}

const DailyAgentContext = createContext<DailyAgentContextState | null>(null);

export const DailyAgentContextProvider: React.FC<DailyAgentContextProviderProps> = ({
  children,
}) => {
  const [goalsSelected, setGoalsSelected] = React.useState<Array<GoalResultType>>([]);
  const [currentScreen, setCurrentScreen] = React.useState<ScreenType>('DAILYAGENT_HOME_SCREEN');

  const contextValue = useMemo(
    () => ({
      goalsSelected,
      setGoalsSelected,
      currentScreen,
      setCurrentScreen,
    }),
    [goalsSelected, currentScreen],
  );

  return <DailyAgentContext.Provider value={contextValue}>{children}</DailyAgentContext.Provider>;
};

export function useDailyAgentContext(): DailyAgentContextState {
  const context = useContext(DailyAgentContext);

  if (!context) {
    throw new Error('useDailyAgentContext must be called within DailyAgentContextProvider');
  }

  return context;
}

export default DailyAgentContext;
