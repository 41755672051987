import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type { DataViewFields_ProDailyAgentDataView_Fragment } from '../../../../core/__generated__/fragments';
import { DataViewFieldsFragmentDoc } from '../../../../core/__generated__/fragments';
import type * as T from '../../../../schema/__generated__/types';
import type { ComponentFields_ProDailyAgentComponent_Fragment } from '../../../rnd/__generated__/fragments';
import { ComponentFieldsFragmentDoc } from '../../../rnd/__generated__/fragments';
const defaultOptions = {} as const;
export type GetDailyAgentInterceptsQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetDailyAgentInterceptsQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    dailyAgent?: {
      __typename?: 'DailyAgentResult';
      intercepts?: Array<{
        __typename?: 'InterceptResultType';
        isVisible: boolean;
        filterCode: string;
        productLabel: string;
        countryLabel: string;
        goalId: number;
        goalLongLabel: string;
        interceptUpdate: string;
        lastAccessDate?: string | null;
        numberOfConsecutiveDaysActive: number;
      } | null> | null;
    } | null;
  };
};

export type GetProDailyAgentGoalsQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetProDailyAgentGoalsQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    dailyAgent?: {
      __typename?: 'DailyAgentResult';
      goals?: Array<{
        __typename?: 'GoalResultType';
        goalId: number;
        goalLongLabel: string;
      } | null> | null;
    } | null;
  };
};

export type GetProHomeDailyAgentScreensQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetProHomeDailyAgentScreensQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    dailyAgent?: {
      __typename?: 'DailyAgentResult';
      screens?: Array<Array<
        | {
            __typename?: 'DailyAgentActionsScreenResult';
            content: string;
            redirectLabel?: string | null;
            screenType: T.ScreenType;
            title: {
              __typename?: 'FormattedTextResult';
              content: string;
              sentiment?: T.Sentiment | null;
              size?: T.TextSize | null;
            };
          }
        | {
            __typename?: 'DailyAgentFireworksScreenResult';
            headline: string;
            screenType: T.ScreenType;
            streak: {
              __typename?: 'DailyAgentStreakResult';
              label: string;
              value: {
                __typename?: 'FormattedTextResult';
                content: string;
                sentiment?: T.Sentiment | null;
                size?: T.TextSize | null;
              };
              days: Array<{
                __typename?: 'DailyAgentStreakDayItemResult';
                day: string;
                value: boolean;
              } | null>;
            };
          }
        | {
            __typename?: 'DailyAgentProgressScreenResult';
            message: string;
            formattedDate: string;
            shortAudioPath?: string | null;
            goalShortLabel?: string | null;
            longAudioPath?: string | null;
            screenType: T.ScreenType;
            dial: {
              __typename?: 'DailyAgentDialResult';
              label: string;
              sentiment: T.Sentiment;
              target: number;
              querterTarget: string;
              value: number;
            };
            currentPosition: {
              __typename?: 'LeaderboardPositionResult';
              label: string;
              progressIndicator: T.Direction;
              rank: {
                __typename?: 'FormattedTextResult';
                content: string;
                sentiment?: T.Sentiment | null;
                size?: T.TextSize | null;
              };
              value: {
                __typename?: 'FormattedTextResult';
                content: string;
                sentiment?: T.Sentiment | null;
                size?: T.TextSize | null;
              };
            };
            leaderboard: {
              __typename?: 'LeaderboardResult';
              title: string;
              subtitle: string;
              currentPosition: {
                __typename?: 'LeaderboardPositionResult';
                label: string;
                progressIndicator: T.Direction;
                rank: {
                  __typename?: 'FormattedTextResult';
                  content: string;
                  sentiment?: T.Sentiment | null;
                  size?: T.TextSize | null;
                };
                value: {
                  __typename?: 'FormattedTextResult';
                  content: string;
                  sentiment?: T.Sentiment | null;
                  size?: T.TextSize | null;
                };
              };
              positions: Array<{
                __typename?: 'LeaderboardPositionResult';
                label: string;
                progressIndicator: T.Direction;
                value: {
                  __typename?: 'FormattedTextResult';
                  content: string;
                  sentiment?: T.Sentiment | null;
                  size?: T.TextSize | null;
                };
                rank: {
                  __typename?: 'FormattedTextResult';
                  content: string;
                  sentiment?: T.Sentiment | null;
                  size?: T.TextSize | null;
                };
              } | null>;
            };
          }
        | null
      > | null> | null;
    } | null;
  };
};

export type GetProHomeDailyAgentInterceptOptionsQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.ProDailyAgentDetailsDataInput>;
}>;

export type GetProHomeDailyAgentInterceptOptionsQuery = {
  __typename?: 'Query';
  pro: {
    __typename?: 'ProModuleResult';
    dailyAgent?: {
      __typename?: 'DailyAgentResult';
      details?:
        | ({
            __typename?: 'ProDailyAgentDataView';
            id: string;
            title: string;
            filterCode?: string | null;
            scopes: Array<string>;
            availableGoals: Array<{
              __typename?: 'ProDailyAgentGoalRace';
              label: string;
              geographies: Array<{
                __typename?: 'ProDailyAgentGoalGeography';
                label: string;
                value: string;
                growthRate: {
                  __typename?: 'FormattedTextResult';
                  content: string;
                  sentiment?: T.Sentiment | null;
                  size?: T.TextSize | null;
                };
              }>;
            }>;
            availableUserSettings: Array<{
              __typename?: 'UserSetting';
              key: string;
              label: string;
              userValue?: number | null;
              defaultValue?: number | null;
              options: Array<{ __typename?: 'SettingOption'; label: string; value: number }>;
            }>;
          } & DataViewFields_ProDailyAgentDataView_Fragment)
        | null;
    } | null;
  };
};

export type GetProHomeDailyAgentPageContentQueryVariables = T.Exact<{
  pageId: T.Scalars['Uuid']['input'];
}>;

export type GetProHomeDailyAgentPageContentQuery = {
  __typename?: 'Query';
  pageContent: {
    __typename?: 'PageContentResult';
    body: Array<
      { __typename?: 'ProDailyAgentComponent' } & ComponentFields_ProDailyAgentComponent_Fragment
    >;
  };
};

export const GetDailyAgentInterceptsDocument = gql`
  query GetDailyAgentIntercepts {
    pro {
      dailyAgent {
        intercepts {
          isVisible
          filterCode
          productLabel
          countryLabel
          goalId
          goalLongLabel
          interceptUpdate
          lastAccessDate
          numberOfConsecutiveDaysActive
        }
      }
    }
  }
`;

/**
 * __useGetDailyAgentInterceptsQuery__
 *
 * To run a query within a React component, call `useGetDailyAgentInterceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyAgentInterceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyAgentInterceptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDailyAgentInterceptsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetDailyAgentInterceptsQuery,
    GetDailyAgentInterceptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDailyAgentInterceptsQuery,
    GetDailyAgentInterceptsQueryVariables
  >(GetDailyAgentInterceptsDocument, options);
}
export function useGetDailyAgentInterceptsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDailyAgentInterceptsQuery,
    GetDailyAgentInterceptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDailyAgentInterceptsQuery,
    GetDailyAgentInterceptsQueryVariables
  >(GetDailyAgentInterceptsDocument, options);
}
export function useGetDailyAgentInterceptsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetDailyAgentInterceptsQuery,
    GetDailyAgentInterceptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetDailyAgentInterceptsQuery,
    GetDailyAgentInterceptsQueryVariables
  >(GetDailyAgentInterceptsDocument, options);
}
export type GetDailyAgentInterceptsQueryHookResult = ReturnType<
  typeof useGetDailyAgentInterceptsQuery
>;
export type GetDailyAgentInterceptsLazyQueryHookResult = ReturnType<
  typeof useGetDailyAgentInterceptsLazyQuery
>;
export type GetDailyAgentInterceptsSuspenseQueryHookResult = ReturnType<
  typeof useGetDailyAgentInterceptsSuspenseQuery
>;
export type GetDailyAgentInterceptsQueryResult = Apollo.QueryResult<
  GetDailyAgentInterceptsQuery,
  GetDailyAgentInterceptsQueryVariables
>;
export const GetProDailyAgentGoalsDocument = gql`
  query GetProDailyAgentGoals {
    pro {
      dailyAgent {
        goals {
          goalId
          goalLongLabel
        }
      }
    }
  }
`;

/**
 * __useGetProDailyAgentGoalsQuery__
 *
 * To run a query within a React component, call `useGetProDailyAgentGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProDailyAgentGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProDailyAgentGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProDailyAgentGoalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProDailyAgentGoalsQuery,
    GetProDailyAgentGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProDailyAgentGoalsQuery, GetProDailyAgentGoalsQueryVariables>(
    GetProDailyAgentGoalsDocument,
    options,
  );
}
export function useGetProDailyAgentGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProDailyAgentGoalsQuery,
    GetProDailyAgentGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProDailyAgentGoalsQuery,
    GetProDailyAgentGoalsQueryVariables
  >(GetProDailyAgentGoalsDocument, options);
}
export function useGetProDailyAgentGoalsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProDailyAgentGoalsQuery,
    GetProDailyAgentGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProDailyAgentGoalsQuery,
    GetProDailyAgentGoalsQueryVariables
  >(GetProDailyAgentGoalsDocument, options);
}
export type GetProDailyAgentGoalsQueryHookResult = ReturnType<typeof useGetProDailyAgentGoalsQuery>;
export type GetProDailyAgentGoalsLazyQueryHookResult = ReturnType<
  typeof useGetProDailyAgentGoalsLazyQuery
>;
export type GetProDailyAgentGoalsSuspenseQueryHookResult = ReturnType<
  typeof useGetProDailyAgentGoalsSuspenseQuery
>;
export type GetProDailyAgentGoalsQueryResult = Apollo.QueryResult<
  GetProDailyAgentGoalsQuery,
  GetProDailyAgentGoalsQueryVariables
>;
export const GetProHomeDailyAgentScreensDocument = gql`
  query GetProHomeDailyAgentScreens {
    pro {
      dailyAgent {
        screens {
          ... on DailyAgentFireworksScreenResult {
            headline
            screenType
            streak {
              label
              value {
                content
                sentiment
                size
              }
              days {
                day
                value
              }
            }
          }
          ... on DailyAgentProgressScreenResult {
            message
            formattedDate
            shortAudioPath
            goalShortLabel
            longAudioPath
            screenType
            dial {
              label
              sentiment
              target
              querterTarget
              value
            }
            currentPosition {
              label
              progressIndicator
              rank {
                content
                sentiment
                size
              }
              value {
                content
                sentiment
                size
              }
            }
            leaderboard {
              title
              subtitle
              currentPosition {
                label
                progressIndicator
                rank {
                  content
                  sentiment
                  size
                }
                value {
                  content
                  sentiment
                  size
                }
              }
              positions {
                label
                progressIndicator
                value {
                  content
                  sentiment
                  size
                }
                rank {
                  content
                  sentiment
                  size
                }
              }
            }
          }
          ... on DailyAgentActionsScreenResult {
            content
            redirectLabel
            screenType
            title {
              content
              sentiment
              size
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetProHomeDailyAgentScreensQuery__
 *
 * To run a query within a React component, call `useGetProHomeDailyAgentScreensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeDailyAgentScreensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeDailyAgentScreensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProHomeDailyAgentScreensQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeDailyAgentScreensQuery,
    GetProHomeDailyAgentScreensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetProHomeDailyAgentScreensQuery,
    GetProHomeDailyAgentScreensQueryVariables
  >(GetProHomeDailyAgentScreensDocument, options);
}
export function useGetProHomeDailyAgentScreensLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeDailyAgentScreensQuery,
    GetProHomeDailyAgentScreensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomeDailyAgentScreensQuery,
    GetProHomeDailyAgentScreensQueryVariables
  >(GetProHomeDailyAgentScreensDocument, options);
}
export function useGetProHomeDailyAgentScreensSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeDailyAgentScreensQuery,
    GetProHomeDailyAgentScreensQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeDailyAgentScreensQuery,
    GetProHomeDailyAgentScreensQueryVariables
  >(GetProHomeDailyAgentScreensDocument, options);
}
export type GetProHomeDailyAgentScreensQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentScreensQuery
>;
export type GetProHomeDailyAgentScreensLazyQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentScreensLazyQuery
>;
export type GetProHomeDailyAgentScreensSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentScreensSuspenseQuery
>;
export type GetProHomeDailyAgentScreensQueryResult = Apollo.QueryResult<
  GetProHomeDailyAgentScreensQuery,
  GetProHomeDailyAgentScreensQueryVariables
>;
export const GetProHomeDailyAgentInterceptOptionsDocument = gql`
  query GetProHomeDailyAgentInterceptOptions($input: ProDailyAgentDetailsDataInput) {
    pro {
      dailyAgent {
        details(input: $input) {
          ...DataViewFields
          ... on ProDailyAgentDataView {
            id
          }
          id
          title
          filterCode
          availableGoals {
            label
            geographies {
              label
              value
              growthRate {
                content
                sentiment
                size
              }
            }
          }
          scopes
          availableUserSettings {
            key
            label
            userValue
            defaultValue
            options {
              label
              value
            }
          }
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
`;

/**
 * __useGetProHomeDailyAgentInterceptOptionsQuery__
 *
 * To run a query within a React component, call `useGetProHomeDailyAgentInterceptOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeDailyAgentInterceptOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeDailyAgentInterceptOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProHomeDailyAgentInterceptOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetProHomeDailyAgentInterceptOptionsQuery,
    GetProHomeDailyAgentInterceptOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetProHomeDailyAgentInterceptOptionsQuery,
    GetProHomeDailyAgentInterceptOptionsQueryVariables
  >(GetProHomeDailyAgentInterceptOptionsDocument, options);
}
export function useGetProHomeDailyAgentInterceptOptionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeDailyAgentInterceptOptionsQuery,
    GetProHomeDailyAgentInterceptOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomeDailyAgentInterceptOptionsQuery,
    GetProHomeDailyAgentInterceptOptionsQueryVariables
  >(GetProHomeDailyAgentInterceptOptionsDocument, options);
}
export function useGetProHomeDailyAgentInterceptOptionsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeDailyAgentInterceptOptionsQuery,
    GetProHomeDailyAgentInterceptOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeDailyAgentInterceptOptionsQuery,
    GetProHomeDailyAgentInterceptOptionsQueryVariables
  >(GetProHomeDailyAgentInterceptOptionsDocument, options);
}
export type GetProHomeDailyAgentInterceptOptionsQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentInterceptOptionsQuery
>;
export type GetProHomeDailyAgentInterceptOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentInterceptOptionsLazyQuery
>;
export type GetProHomeDailyAgentInterceptOptionsSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentInterceptOptionsSuspenseQuery
>;
export type GetProHomeDailyAgentInterceptOptionsQueryResult = Apollo.QueryResult<
  GetProHomeDailyAgentInterceptOptionsQuery,
  GetProHomeDailyAgentInterceptOptionsQueryVariables
>;
export const GetProHomeDailyAgentPageContentDocument = gql`
  query GetProHomeDailyAgentPageContent($pageId: Uuid!) {
    pageContent(pageId: $pageId) {
      body {
        ... on ProDailyAgentComponent {
          ...ComponentFields
        }
      }
    }
  }
  ${ComponentFieldsFragmentDoc}
`;

/**
 * __useGetProHomeDailyAgentPageContentQuery__
 *
 * To run a query within a React component, call `useGetProHomeDailyAgentPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProHomeDailyAgentPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProHomeDailyAgentPageContentQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function useGetProHomeDailyAgentPageContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetProHomeDailyAgentPageContentQuery,
    GetProHomeDailyAgentPageContentQueryVariables
  > &
    (
      | { variables: GetProHomeDailyAgentPageContentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetProHomeDailyAgentPageContentQuery,
    GetProHomeDailyAgentPageContentQueryVariables
  >(GetProHomeDailyAgentPageContentDocument, options);
}
export function useGetProHomeDailyAgentPageContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetProHomeDailyAgentPageContentQuery,
    GetProHomeDailyAgentPageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetProHomeDailyAgentPageContentQuery,
    GetProHomeDailyAgentPageContentQueryVariables
  >(GetProHomeDailyAgentPageContentDocument, options);
}
export function useGetProHomeDailyAgentPageContentSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetProHomeDailyAgentPageContentQuery,
    GetProHomeDailyAgentPageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetProHomeDailyAgentPageContentQuery,
    GetProHomeDailyAgentPageContentQueryVariables
  >(GetProHomeDailyAgentPageContentDocument, options);
}
export type GetProHomeDailyAgentPageContentQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentPageContentQuery
>;
export type GetProHomeDailyAgentPageContentLazyQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentPageContentLazyQuery
>;
export type GetProHomeDailyAgentPageContentSuspenseQueryHookResult = ReturnType<
  typeof useGetProHomeDailyAgentPageContentSuspenseQuery
>;
export type GetProHomeDailyAgentPageContentQueryResult = Apollo.QueryResult<
  GetProHomeDailyAgentPageContentQuery,
  GetProHomeDailyAgentPageContentQueryVariables
>;
