import { useAuth } from '@aily/auth-service';
import { useEffect } from 'react';

import { useAnalytics } from '../providers';
import { useAnalyticsCommonEventProps } from '.';

export function useAnalyticsIdentifyUser() {
  const { isAuthenticated } = useAuth();
  const { identify } = useAnalytics();
  const commonEventProps = useAnalyticsCommonEventProps();

  useEffect(() => {
    if (isAuthenticated) {
      identify(commonEventProps.user_id, {
        ...commonEventProps,
        event_version: '2.0.0',
        intent: 'identify',
      });
    }
  }, [isAuthenticated, commonEventProps]);
}
