import React from 'react';

import { DataPoint, LineStyle, SeriesType } from './MicroChart';
import { MicroChartAreaSeries } from './MicroChartAreaSeries';
import { MicroChartLineSeries } from './MicroChartLineSeries';

export interface MicroChartSeriesProps {
  type: SeriesType;
  data: DataPoint[];
  lineWidth?: number;
  lineOpacity?: number;
  lineStyle?: LineStyle;
  color?: string;
  fillOpacity?: number;
  animationDuration?: number;
  animationEasing?: (normalizedTime: number) => number;
}

export const MicroChartSeries: React.FC<MicroChartSeriesProps> = (props) => {
  const { type } = props;
  switch (type) {
    case SeriesType.Area:
      return <MicroChartAreaSeries {...props} />;
    case SeriesType.Line:
      return <MicroChartLineSeries {...props} />;
    default:
      return null;
  }
};
