import { styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { Color, getLinearGradient } from './utils';

interface GradientBoxProps {
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
  borderWidth?: number;
  borderColor?: string;
  background?: string;
  backgroundColor?: string;
  borderGradientColors?: Color[];
  contentGradientColors?: Color[];
}

// Serves as the root container for the `GradientBox` component
const GradientBoxContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'borderRadius',
})<Pick<GradientBoxProps, 'width' | 'height' | 'borderRadius'>>(
  ({ width, height, borderRadius }) => ({
    position: 'relative',
    overflow: 'hidden',
    width,
    height,
    borderRadius,
  }),
);

// Base styled component with common styles
const GradientBoxBase = styled('div')({
  position: 'relative',
  overflow: 'auto',
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

// Represents the border gradient
const GradientBoxBorder = styled(GradientBoxBase, {
  shouldForwardProp: (prop) =>
    prop !== 'borderWidth' && prop !== 'borderColor' && prop !== 'borderGradientColors',
})<Pick<GradientBoxProps, 'borderWidth' | 'borderColor' | 'borderGradientColors'>>(
  ({ borderWidth, borderColor, borderGradientColors }) => ({
    padding: borderWidth,
    backgroundColor: borderColor,
    backgroundImage: getLinearGradient(borderGradientColors),
  }),
);

// Represents the content gradient
const GradientBoxContent = styled(GradientBoxBase, {
  shouldForwardProp: (prop) =>
    prop !== 'background' && prop !== 'backgroundColor' && prop !== 'contentGradientColors',
})<Pick<GradientBoxProps, 'background' | 'backgroundColor' | 'contentGradientColors'>>(
  ({ background, backgroundColor, contentGradientColors }) => ({
    // Provides a solid background layer under the content gradient, preventing it from blending with the border gradient when transparent
    background,
    backgroundColor,
    backgroundImage: getLinearGradient(contentGradientColors),
  }),
);

export const GradientBox: React.FC<PropsWithChildren<GradientBoxProps>> = ({
  width,
  height,
  borderWidth,
  borderRadius,
  borderColor,
  background,
  backgroundColor,
  borderGradientColors,
  contentGradientColors,
  children,
}) => (
  <GradientBoxContainer width={width} height={height} borderRadius={borderRadius}>
    <GradientBoxBorder
      borderWidth={borderWidth}
      borderColor={borderColor}
      borderGradientColors={borderGradientColors}
    >
      <GradientBoxContent
        background={background}
        backgroundColor={backgroundColor}
        contentGradientColors={contentGradientColors}
      >
        {children}
      </GradientBoxContent>
    </GradientBoxBorder>
  </GradientBoxContainer>
);
