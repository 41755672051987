import * as T from '@aily/graphql-sdk/schema';
import { CardActionArea, CardContent, CardProps, Typography } from '@mui/material';
import React, { FC } from 'react';

import { ChevronDown, ChevronUp } from '../../icons';
import { lineClamp } from '../../theme/utils';
import { mapSentimentToColor } from '../../utils';
import { FitText } from '../FitText';
import { StyledCard } from '../GridDataView/GridDataView';

interface Props extends CardProps {
  card: T.SimpleCardResult;
  onCardClick?: () => void;
}

const SimpleCard: FC<Props> = ({ card, onCardClick, ...rest }) => {
  const sentimentColor = mapSentimentToColor(card.variance?.sentiment ?? T.Sentiment.Neutral);
  const isVariancePositive = card.variance?.sentiment === T.Sentiment.Positive;
  return (
    <StyledCard {...rest}>
      <CardActionArea component="div" onClick={onCardClick}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h9" data-testid="Title" sx={{ ...lineClamp(2) }}>
            <FitText>{card.title.value ?? ''}</FitText>
          </Typography>
          <Typography color={sentimentColor}>
            {card.variance?.actual ? isVariancePositive ? <ChevronUp /> : <ChevronDown /> : null}
          </Typography>
        </CardContent>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body" data-testid="actual">
            {card.textResult?.value}
          </Typography>
          <Typography variant="body" color={sentimentColor} data-testid="Variance">
            {card.variance?.actual}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default SimpleCard;
