import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const StarActive: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      d="M15.6175 4.9725C16.2086 3.77479 17.9165 3.77479 18.5076 4.97251L21.5814 11.2008C21.8161 11.6764 22.2699 12.0061 22.7948 12.0823L29.6681 13.0811C30.9898 13.2732 31.5176 14.8975 30.5612 15.8298L25.5876 20.6778C25.2078 21.048 25.0345 21.5814 25.1241 22.1042L26.2982 28.9497C26.524 30.2661 25.1423 31.27 23.9601 30.6485L17.8124 27.4165C17.3429 27.1697 16.7821 27.1697 16.3126 27.4165L10.1649 30.6485C8.98272 31.27 7.601 30.2661 7.82678 28.9497L9.00089 22.1042C9.09054 21.5814 8.91723 21.048 8.53743 20.6778L3.56384 15.8298C2.60741 14.8975 3.13518 13.2732 4.45694 13.0811L11.3303 12.0823C11.8551 12.0061 12.3089 11.6764 12.5436 11.2008L15.6175 4.9725Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6541 4.49714C15.6393 2.50095 18.4858 2.50096 19.471 4.49715L22.5448 10.7254C22.6231 10.884 22.7743 10.9939 22.9493 11.0193L29.8226 12.018C32.0255 12.3381 32.9052 15.0453 31.3111 16.5991L26.3375 21.4472C26.2109 21.5706 26.1531 21.7484 26.183 21.9227L27.3571 28.7682C27.7334 30.9622 25.4306 32.6354 23.4602 31.5995L17.3125 28.3675C17.156 28.2852 16.9691 28.2852 16.8126 28.3675L10.6649 31.5995C8.69454 32.6354 6.39167 30.9622 6.76798 28.7682L7.94208 21.9227C7.97197 21.7484 7.9142 21.5706 7.7876 21.4472L2.81401 16.5991C1.21995 15.0453 2.09958 12.3381 4.3025 12.018L11.1758 11.0193C11.3508 10.9939 11.502 10.884 11.5803 10.7254L14.6541 4.49714ZM17.5442 5.44805C17.3472 5.04881 16.7779 5.04881 16.5809 5.44805L13.507 11.6763C13.1158 12.469 12.3596 13.0185 11.4848 13.1456L4.61147 14.1443C4.17088 14.2084 3.99496 14.7498 4.31377 15.0605L9.28736 19.9086C9.92036 20.5256 10.2092 21.4146 10.0598 22.2859L8.88568 29.1314C8.81041 29.5702 9.27099 29.9049 9.66506 29.6977L15.8128 26.4656C16.5952 26.0543 17.5299 26.0543 18.3124 26.4656L24.46 29.6977C24.8541 29.9049 25.3147 29.5702 25.2394 29.1314L24.0653 22.2859C23.9159 21.4146 24.2047 20.5256 24.8377 19.9086L29.8113 15.0606C30.1301 14.7498 29.9542 14.2084 29.5136 14.1443L22.6403 13.1456C21.7655 13.0185 21.0093 12.469 20.6181 11.6763L17.5442 5.44805Z"
      fill="url(#paint0_linear_20329_154057)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20329_154057"
        x1="2"
        y1="17.457"
        x2="32.1251"
        y2="17.457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31D4B6" />
        <stop offset="1" stopColor="#48FF9B" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
