import { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      zIndex: theme.zIndex.modal + 1,
    }),
  },
  defaultProps: {
    disableScrollLock: true,
  },
};
