import { trimStart } from 'lodash-es';

/**
 * Ensures a given path string starts with "/" to normalize manually constructed
 * paths in API responses, mitigating potential errors.
 *
 * @param {string} path - The path to normalize.
 * @returns {string} Normalized absolute path.
 */
export function normalizeAbsolutePath(path: string): string {
  const trimmedPath = path.trim();
  if (!trimmedPath.startsWith('/')) {
    return '/' + trimmedPath;
  }

  return trimmedPath;
}

/**
 * This function takes a relative file path and appends it to the base URL derived
 * from the API URL specified in the env configuration. It ensures that there are
 * no double slashes in the resulting URL.
 *
 * @note
 * This is needed because BE is lazy to provide full URL to a file. They would have to
 * add a new env variable and that is too much work. Bummer.
 *
 * @param {string} filePath - The relative path of the file.
 * @param {string} apiUrl - The API URL.
 * @returns {string} The full URL to the file.
 */
export function getFullUrl(filePath: string, apiUrl: string): string {
  const parsedUrl = new URL(apiUrl);
  const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}/`;
  return urlConcat(baseUrl, filePath);
}

/**
 * Concatenates a base URL and a path, ensuring no duplicate slashes.
 *
 * @param {string} base The base URL, ending with a slash.
 * @param {string} path The path, leading slash removed if present.
 * @return {string} Concatenated URL.
 */
export function urlConcat(base: string, path: string): string {
  // Ensure the base URL ends with a slash
  const normalizedBase = base.endsWith('/') ? base : base + '/';
  // Remove leading slash from the path if present
  const normalizedPath = trimStart(path, '/');
  return normalizedBase + normalizedPath;
}

/**
 * Rewrites an HTTP/HTTPS URL to use the WebSocket protocol.
 * Optionally changes the port. Returns null if the URL is invalid.
 *
 * @note It standardizes the URL - including a trailing slash after the domain.
 *
 * @param {string} url - The URL to be rewritten.
 * @param {number} [newPort] - An optional new port number.
 * @returns {string | null} - The rewritten WebSocket URL, or null if the input is invalid.
 */
export function getWebSocketUrl(url: string, newPort?: number): string | null {
  try {
    const parsedUrl = new URL(url);

    // Replace the protocol based on the original one
    parsedUrl.protocol = parsedUrl.protocol === 'https:' ? 'wss:' : 'ws:';

    // If a new port is provided, replace the port
    if (newPort !== undefined) {
      parsedUrl.port = newPort.toString();
    }

    return parsedUrl.href;
  } catch (e) {
    console.error('Invalid URL:', e);
    return null;
  }
}

/**
 * Get focus area from url.
 *
 * @param {string} base The base URL.
 * @param {number} index where focus area should be located .
 * @return {string} focus area.
 */

type Maybe<T> = null | undefined | T;

export function getFocusArea(path?: Maybe<string>, index?: number) {
  return index ? path?.split('/')?.[index] : '';
}
