import { Link } from '@aily/graphql-sdk/schema';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useLinkContext } from '../contexts';

export function useLink() {
  const linkContext = useLinkContext();
  const { state } = useLocation();
  const locationState = state as { link?: Link };

  return useMemo(() => linkContext?.link ?? locationState?.link, [linkContext, state]);
}
