import * as T from '@aily/graphql-sdk/schema';
import React, { useCallback } from 'react';

import StoryView, { StoryViewProps } from './StoryView';

const TrackedStoryView: React.FC<StoryViewProps> = ({ onReferenceClick, ...rest }) => {
  const handleReferenceClick = useCallback(
    (story: T.Story, reference: T.ExternalLink) => {
      onReferenceClick?.(story, reference);
    },
    [onReferenceClick],
  );

  return <StoryView onReferenceClick={handleReferenceClick} {...rest} />;
};

export default TrackedStoryView;
