import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

export interface InterceptMediaProps extends BoxProps {}

const StyledBox = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  width: 400,
  height: 238,
  borderRadius: '20px',
});

export const InterceptMedia: React.FC<InterceptMediaProps> = ({ children, ...rest }) => (
  <StyledBox data-testid="InterceptMedia" {...rest}>
    {children}
  </StyledBox>
);
