import { styled, Toolbar, ToolbarProps } from '@mui/material';
import React from 'react';

export interface FooterToolbarProps extends ToolbarProps {}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: 38,
  minHeight: 38,
  padding: theme.spacing(0, 2),
}));

export const FooterToolbar: React.FC<FooterToolbarProps> = ({ children, ...rest }) => (
  <StyledToolbar data-testid="FooterToolbar" {...rest}>
    {children}
  </StyledToolbar>
);
