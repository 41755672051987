import { isEqual } from 'lodash-es';
import React, { CSSProperties, HTMLAttributes } from 'react';
import useFitText, { TOptions } from 'use-fit-text';

export interface FitTextProps extends TOptions, HTMLAttributes<HTMLDivElement> {
  children: string;
  style?: CSSProperties;
}

export const FitText = React.memo(
  ({
    children,
    style,
    minFontSize = 75,
    maxFontSize = 100,
    logLevel = 'none',
    onStart,
    onFinish,
    resolution,
    ...rest
  }: FitTextProps) => {
    const { fontSize, ref } = useFitText({
      minFontSize,
      maxFontSize,
      logLevel,
      onStart,
      onFinish,
      resolution,
    });

    return (
      <div ref={ref} style={{ ...style, fontSize }} {...rest}>
        {children}
      </div>
    );
  },
  isEqual,
);
