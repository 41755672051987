import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ChevronDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="28" height="28" viewBox="0 0 28 28" data-testid="ChevronDown" {...props}>
    <path
      d="M7.11621 11.0547L13.5462 17.4847L19.9762 11.0547"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
