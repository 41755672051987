import * as T from '@aily/graphql-sdk/schema';
import { styled, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { useStoryContext } from '../../../contexts';
import { colors } from '../../../theme/default/colors';
import { mapSentimentToColor } from '../../../utils/colors';
import StoryHeader from './StoryHeader';

export interface VarianceProps extends TypographyProps {
  value?: string;
  sentiment?: string;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'sentiment',
})<VarianceProps>(({ sentiment }) => ({
  color: mapSentimentToColor((sentiment ?? T.Sentiment.Neutral) as T.Sentiment, {
    [T.Sentiment.Neutral]: colors.primary.aqua,
  }),
}));

const Variance: React.FC<VarianceProps> = ({ value, sentiment, className, ...rest }) => {
  const storyContext = useStoryContext();

  // Data team abuses `Variance` component for headers in Whisper stories, despite it being just a textual change without BE.
  // I can't live with that therefore I proxy to the correct component here.
  if (
    storyContext?.story?.storyType === T.StoryDisplayType.Whisper ||
    storyContext?.story?.storyType === T.StoryDisplayType.WhisperReal
  ) {
    return <StoryHeader sentiment={sentiment as T.Sentiment}>{value}</StoryHeader>;
  }

  return (
    <StyledTypography
      className={clsx(className, 'Variance')}
      variant="h1"
      sentiment={sentiment}
      {...rest}
    >
      {value}
    </StyledTypography>
  );
};

export default React.memo(Variance);
