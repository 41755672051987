import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ReactNode } from 'react';

function getNonceValue() {
  const nonceElement = document.getElementById('nonce');
  return String(nonceElement?.textContent);
}

/**
 * EmotionProvider used for CSP
 *
 * @link https://mui.com/material-ui/guides/content-security-policy/
 * @param children
 * @constructor
 */
export const EmotionProvider = ({ children }: { children: ReactNode }) => {
  const cache = createCache({
    key: 'aily-saas-app-web',
    nonce: getNonceValue(),
    prepend: true,
  });

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
