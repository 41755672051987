import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const SearchActive: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7812 22.6877C25.8458 20.5996 27.1205 17.7288 27.1205 14.5603C27.1205 8.17571 21.9448 3 15.5603 3C9.17571 3 4 8.17571 4 14.5603C4 20.9448 9.17571 26.1205 15.5603 26.1205C17.9406 26.1205 20.1529 25.4011 21.9916 24.1679L28.6152 30.7914C29.0666 31.2429 29.7986 31.2429 30.25 30.7914C30.7015 30.3399 30.7015 29.608 30.25 29.1565L23.7812 22.6877ZM24.8085 14.5603C24.8085 19.6679 20.6679 23.8085 15.5603 23.8085C10.4526 23.8085 6.31205 19.6679 6.31205 14.5603C6.31205 9.45262 10.4526 5.31205 15.5603 5.31205C20.6679 5.31205 24.8085 9.45262 24.8085 14.5603Z"
      fill="url(#paint0_linear_21895_36874)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_21895_36874"
        x1="4"
        y1="17.065"
        x2="30.5886"
        y2="17.065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31D4B6" />
        <stop offset="1" stopColor="#48FF9B" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
