import { gql } from '@apollo/client';

import type * as T from '../../../../schema/__generated__/types';
import type { LinkFieldsFragment } from '../../../rnd/__generated__/fragments';
import { LinkFieldsFragmentDoc } from '../../../rnd/__generated__/fragments';
export type RealFieldsFragment = {
  __typename: 'Real';
  id: string;
  title: string;
  insightsType: T.InsightsType;
  hasSeenAllScreens: boolean;
  isShareable: boolean;
  header: {
    __typename?: 'RealHeader';
    title: string;
    subtitle?: string | null;
    icon?: T.RealIcon | null;
  };
  screens: Array<{
    __typename: 'RealScreen';
    screenId: string;
    screenType: T.RealScreenType;
    header?: {
      __typename?: 'RealScreenHeader';
      title: string;
      subtitle?: string | null;
      icon: T.RealIcon;
    } | null;
    audio?: { __typename?: 'RealAudio'; trackName: string } | null;
    seeMoreLink?: {
      __typename?: 'TextLinkResult';
      subLabels?: Array<string> | null;
      value?: string | null;
      label?: string | null;
      linkDirection?: T.LinkDirection | null;
      linkResult?:
        | ({
            __typename: 'Link';
            pageId?: string | null;
            absolutePath?: string | null;
          } & LinkFieldsFragment)
        | null;
    } | null;
    content?: {
      __typename: 'RealTextList';
      items?: Array<{
        __typename?: 'RealTextListItem';
        icon?: T.RealIcon | null;
        text: Array<{
          __typename?: 'FormattedTextResult';
          content: string;
          sentiment?: T.Sentiment | null;
          size?: T.TextSize | null;
        } | null>;
      } | null> | null;
    } | null;
  } | null>;
};

export const RealFieldsFragmentDoc = gql`
  fragment RealFields on Real {
    id
    title
    insightsType
    hasSeenAllScreens
    header {
      title
      subtitle
      icon
    }
    isShareable
    screens {
      screenId
      screenType
      header {
        title
        subtitle
        icon
      }
      audio {
        trackName
      }
      seeMoreLink {
        subLabels
        value
        label
        linkDirection
        linkResult {
          pageId
          absolutePath
          ...LinkFields
          __typename
        }
        linkDirection
      }
      content {
        __typename
        items {
          text {
            content
            sentiment
            size
          }
          icon
        }
      }
      __typename
    }
    __typename
  }
  ${LinkFieldsFragmentDoc}
`;
