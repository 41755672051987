import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import React, { forwardRef } from 'react';

export interface PageMenuItemProps extends ListItemButtonProps {
  title: string;
  icon?: React.ReactNode;
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  padding: 0,
  paddingLeft: 0,
  color: theme.palette.grey['300'],
  transition: theme.transitions.create('color', {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover, &:focus, &.Mui-selected, &:hover.Mui-selected, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible':
    {
      color: theme.palette.text.primary,
      background: 'transparent',
    },
  '& .MuiListItemIcon-root': {
    width: 40,
    minWidth: 'initial',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    color: 'inherit',
  },
  '& .MuiListItemText-root': {
    margin: theme.spacing(0.25, 0),
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
      easing: 'linear',
    }),
  },
}));

export const PageMenuItem = forwardRef<HTMLDivElement, PageMenuItemProps>(
  ({ title, icon, ...rest }, ref) => (
    <StyledListItemButton ref={ref} data-testid="page-menu__item" {...rest}>
      {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText disableTypography primary={<Typography variant="small">{title}</Typography>} />
    </StyledListItemButton>
  ),
);

PageMenuItem.displayName = 'PageMenuItem';

export default PageMenuItem;
