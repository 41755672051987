import * as T from '@aily/graphql-sdk/schema';
import { max, min, scaleLinear, scaleOrdinal } from 'd3';
import React, { useCallback, useMemo } from 'react';

import { colors } from '../../theme/default/colors';
import { ProgressSteps } from '../ProgressSteps';

interface Props {
  axis: T.TimelineAxis;
  intervals: T.TimelineInterval[];
}

export const TimelinePhases: React.FC<Props> = ({ axis, intervals }) => {
  const [minX, maxX] = useMemo(
    () => [
      axis?.minX ?? min(intervals, ({ x1 }) => x1) ?? 0,
      axis?.maxX ?? max(intervals, ({ x2 }) => x2) ?? 0,
    ],
    [axis?.minX, axis?.maxX, intervals],
  );

  // Create a relative scale 0-100%
  const xScale = useMemo(() => scaleLinear().domain([minX, maxX]).range([0, 100]), [minX, maxX]);
  const colorScale = scaleOrdinal(['#085f68', colors.primary.aqua]);

  const getWidth = useCallback(
    (index: number) => {
      const interval = intervals[index];
      const prevInterval = intervals[index - 1] as T.TimelineInterval | undefined;

      if (prevInterval) {
        return `${xScale(interval.x2) - xScale(prevInterval.x2)}%`;
      }

      return `${xScale(interval.x2)}%`;
    },
    [intervals, xScale],
  );

  return (
    <div data-testid="TimelinePhases">
      <ProgressSteps>
        {intervals.map((interval, index) => (
          <ProgressSteps.Step
            key={index}
            label={interval.name ?? undefined}
            width={getWidth(index)}
            lineColor={colorScale(String(index))}
          />
        ))}
      </ProgressSteps>
    </div>
  );
};
