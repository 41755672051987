import * as T from '@aily/graphql-sdk/schema';
import { snakeCase, trim } from 'lodash-es';

export function getStoryFocusArea(story: T.Story): string | undefined {
  if (story?.link?.absolutePath) {
    const path = trim(story.link.absolutePath, '/');
    const pathSegments = path?.split('/');
    return snakeCase(pathSegments?.[3]);
  }
}
