import * as T from '@aily/graphql-sdk/schema';
import {
  alpha,
  Skeleton,
  Stack,
  styled,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { find } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useHandleLink } from '../../hooks';
import { OpenInNew } from '../../icons';
import { mapSentimentToColor } from '../../utils';
import { getIconComponent } from '../../utils/icons';
import { Table, TableColumnProps } from '../Table';

const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginBottom: `${theme.spacing(0.5)} !important`,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: `${theme.spacing(0.5)} !important`,
  },
}));

const useStyles = makeStyles()((theme: Theme) => ({
  items: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.25),
  },
  itemsCompact: {
    margin: theme.spacing(-1),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  itemIcon: {
    fontSize: 20,
  },
  tooltipContent: {
    width: 250,
    // Default padding compensation
    margin: theme.spacing(-0.5, -1),
  },
  tooltipTitle: {
    padding: theme.spacing(1.25, 2),
    borderBottom: `1px solid ${alpha(theme.palette.text.secondary, 0.25)}`,
  },
  tooltipTable: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .MuiTableCell-root': {
      ...theme.typography.small,
      borderBottom: 'none',
      height: 22,
      padding: theme.spacing(1, 0.5),
      whiteSpace: 'nowrap',
      '&:first-of-type': {
        paddingLeft: theme.spacing(2),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(2),
      },
    },
  },
  linkIcon: {
    color: theme.palette.grey[400],
    height: 16,
  },
}));

const IconWrapper = styled('div')(() => ({
  marginLeft: 5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

interface Props {
  items: T.KpiStatus[];
  tooltip?: T.TableContent;
  compact?: boolean;
  loading?: boolean;
}

export const KpiStatusOverview: React.FC<Props> = ({ items, tooltip, compact, loading }) => {
  const { classes } = useStyles();
  const handleLink = useHandleLink();

  const columns = useMemo<TableColumnProps[]>(
    () =>
      tooltip?.columns?.map((column, index) => ({
        dataKey: column.dataKey,
        label: column.title ?? '',
        align: index === 0 ? 'left' : 'right',
      })) ?? [],
    [tooltip?.columns],
  );

  const handleCell = useCallback(
    (_row: T.TableRowResult, _column: TableColumnProps, columnIndex: number) =>
      ({
        variant: columnIndex === 0 ? 'head' : 'body',
      }) as TableCellProps,
    [],
  );

  const handleCellRender = useCallback(
    (row: T.TableRowResult, column: TableColumnProps, columnIndex: number) => {
      const cellContent = find(row.cells, { columnDataKey: column.dataKey })?.cellContent;

      if (T.isTextResult(cellContent)) {
        return <React.Fragment key={columnIndex}>{cellContent.value}</React.Fragment>;
      }

      if (T.isKpiStatus(cellContent)) {
        const IconComponent = getIconComponent(cellContent.icon);
        return (
          <span key={columnIndex} style={{ display: 'inline-flex', alignItems: 'center' }}>
            <IconComponent
              className={classes.itemIcon}
              style={{ color: mapSentimentToColor(cellContent.sentiment) }}
            />
            {!!cellContent.actual?.length && (
              <span style={{ marginLeft: 8 }}>{cellContent.actual}</span>
            )}
          </span>
        );
      }

      return null;
    },
    [],
  );

  const handleIconLinkClick = useCallback(
    (link?: T.Maybe<T.Link>) => {
      if (!link) {
        return;
      }
      handleLink(link);
    },
    [items],
  );

  const content = useMemo(
    () => (
      <Stack
        direction="row"
        style={{ display: 'inline-flex', margin: compact ? -8 : '0 0 10px 0' }}
        data-testid="KpiStatusOverview"
      >
        {items?.map((item, index) => {
          const IconComponent = getIconComponent(item.icon);
          return (
            <div key={index} className={classes.item}>
              <IconComponent
                className={classes.itemIcon}
                style={{ color: mapSentimentToColor(item.sentiment) }}
              />
              {!compact && !!item.actual && <span style={{ marginLeft: 8 }}>{item.actual}</span>}
              {!!item.iconLink && (
                <IconWrapper onClick={() => handleIconLinkClick(item?.iconLink?.linkResult)}>
                  <OpenInNew className={classes.linkIcon} data-testid="OpenInNewCellIcon" />
                </IconWrapper>
              )}
            </div>
          );
        })}
      </Stack>
    ),
    [items, compact],
  );

  if (loading) {
    return (
      <Stack direction="row" style={{ height: 38, marginBottom: 10 }}>
        <div className={classes.item}>
          <Skeleton className={classes.itemIcon} variant="circular" />
          <Skeleton style={{ width: 40, margin: 0, marginLeft: 8 }} />
        </div>
      </Stack>
    );
  }

  if (!tooltip?.rows?.length) {
    return content;
  }

  return (
    <StyledTooltip
      title={
        <div className={classes.tooltipContent}>
          {!!tooltip?.title && (
            <Typography className={classes.tooltipTitle} variant="subtitle" component="div">
              {tooltip.title}
            </Typography>
          )}
          <Table
            className={classes.tooltipTable}
            rows={tooltip?.rows ?? []}
            columns={columns}
            onCell={handleCell}
            onCellRender={handleCellRender}
          />
        </div>
      }
    >
      {content}
    </StyledTooltip>
  );
};
