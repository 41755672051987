/**
 * Translates a pixel value to its corresponding value within a specified axis range.
 *
 * @param {number} pixelValue - The pixel value to be translated.
 * @param {number} min - The minimum value of the axis range.
 * @param {number} max - The maximum value of the axis range.
 * @param {number} totalLength - The total pixel length of the axis container.
 * @returns {number} - The translated value within the axis range.
 */
export function translatePixelToValue(
  pixelValue: number,
  min: number,
  max: number,
  totalLength: number,
): number {
  const range = max - min; // Calculate the total range of the axis
  return (pixelValue / totalLength) * range; // Translate the pixel value to a value within the axis range
}

/**
 * Translates a value from a specified axis range to its corresponding pixel value.
 *
 * @param {number} value - The value to be translated.
 * @param {number} min - The minimum value of the axis range.
 * @param {number} max - The maximum value of the axis range.
 * @param {number} totalLength - The total pixel length of the axis representation.
 * @returns {number} - The translated pixel value within the container.
 */
export function translateValueToPixel(
  value: number,
  min: number,
  max: number,
  totalLength: number,
): number {
  const range = max - min; // Calculate the total range of the axis
  return ((value - min) / range) * totalLength; // Translate the value to a pixel value within the container
}

export function groupDataPoints<
  TDataPoint extends { x: number },
  TGroup extends { dataPoints: TDataPoint[]; minX: number; avgX: number; maxX: number },
>(dataPoints: TDataPoint[], cellSize: number): TGroup[] {
  const grid: Record<string, TDataPoint[]> = {};

  dataPoints.forEach((dataPoint) => {
    const cellIndex = Math.floor(dataPoint.x / cellSize);

    if (!grid[cellIndex]) {
      grid[cellIndex] = [];
    }

    grid[cellIndex].push(dataPoint);
  });

  const groupedDataPoints: TGroup[] = [];

  Object.keys(grid).forEach((cellIndex) => {
    const dataPoints = grid[cellIndex];

    if (dataPoints.length > 0) {
      const minX = Math.min(...dataPoints.map(({ x }) => x));
      const maxX = Math.max(...dataPoints.map(({ x }) => x));
      const avgX = dataPoints.reduce((sum, { x }) => sum + x, 0) / dataPoints.length;

      groupedDataPoints.push({ dataPoints, minX, avgX, maxX } as TGroup);
    }
  });

  return groupedDataPoints;
}
