// prettier-ignore
/**
 * Color scheme taken from the Figma design
 *
 * @see https://www.figma.com/file/xZBvpJ78GAaGwNlPhm3S5H/Aily-Design-system?node-id=1-29170&t=iR0n2errUflthol4-4
 */
const colors = {
  primary: {
    aqua:   '#31d4b6',
    green:  '#48ff9b',
    orange: '#f29a2e',
    pink:   '#ff3469',
  },
  darkPrimary: {
    pink: '#88294f',
  },
  lightPrimary: {
    aqua: '#c1e6e4',
  },
  secondary: {
    ailyBlue:  '#202945',
    blue:      '#4bade9',
    darkAqua:  '#055f5a',
    darkBlue:  '#111e35',
    darkGreen: '#071c27',
    purple:    '#a05195',
  },
  neutrals: {
    black: '#000000',
    grey1: '#404040',
    grey2: '#595959',
    grey3: '#808080',
    grey4: '#a6a6a6',
    white: '#ffffff',
  },
  overlays: {
    black: 'rgba(0, 0, 0, 0.75)',
  },
  gradients: {
    aqua:              ['#31d4b6', '#138682'],
    aquaBright:        ['#06d0c9', '#31d4b6'],
    aquaTransparent:   ['#31d4b6', 'rgba(0, 161, 155, 0)'],
    blue:              ['#5d77a4', '#4bade9'],
    blueTransparent:   ['#4bade9', 'rgba(93, 119, 164, 0)'],
    brown:             ['#ad5245', '#aa8751'],
    darkBlue:          ['rgba(17, 30, 53, 0.75)', '#111e35'],
    green:             ['#31d4b6', '#48ff9b'],
    greenTransparent:  ['#48ff9b', 'rgba(56, 212, 48, 0)'],
    orange:            ['#ff5005', '#f29a2e'],
    orangeTransparent: ['#f29a2e', 'rgba(239, 136, 51, 0)'],
    pink:              ['rgba(254, 44, 99, 0.8)', '#fe2c63'],
    pinkTransparent:   ['#eb4a6c', 'rgba(237, 30, 117, 0)'],
    purple:            ['#665191', '#31d4b6'],
    purpleTransparent: ['#a05195', 'rgba(160, 81, 149, 0)'],
    yellowTransparent: ['#f1e91d', 'rgba(241, 233, 29, 0)'],
    white:             ['#ffffff', '#ffffff']
  } as Record<string, [string, string]>,
  graphs: [
    '#31d4b6', '#f29a2e', '#ff3469', '#48ff9b', '#4bade9', '#f1e91d', '#5d77a4',
    '#f0b8b8', '#ff5005', '#665191', '#aecdc2', '#aa8751', '#ffffff', '#a05195',
    '#ed1e24', '#6aaa96', '#f95d6a', '#9bca3c', '#666666', '#e67f83', '#488f31',
    '#d45087', '#ad5245', '#999999', '#77782c', '#cccccc', '#06ddd3'
  ],
};

export { colors };
