import { Box, BoxProps, List, MenuItemProps, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import { useSegmentControl } from './useSegmentControl';

export interface SegmentControlProps<TValue>
  extends Omit<BoxProps, 'defaultValue' | 'value' | 'onChange'> {
  defaultValue?: TValue;
  value?: TValue;
  onChange?: (newValue: TValue) => void;
  children: React.ReactElement<MenuItemProps> | React.ReactElement<MenuItemProps>[];
}

const ContainerBox = styled(Box)({
  position: 'relative',
  width: '100%',
  borderRadius: '100px',
  background: '#0c0c0c',
});

const BackgroundSlider = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  padding: theme.spacing(0.5),
}));

const ActiveSegment = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.background.paper,
  borderRadius: 20,
  border: '0.5px solid rgba(255, 255, 255, 0.1)',
  borderLeftColor: 'rgba(255, 255, 255, 0.3)',
  borderTopColor: 'rgba(255, 255, 255, 0.3)',
  backgroundBlendMode: 'color-dodge, lighten',
  backgroundImage:
    'linear-gradient(0deg, rgba(94, 94, 94, 0.18) 0%, rgba(94, 94, 94, 0.18) 100%), rgba(255, 255, 255, 0.06)',
}));

const SegmentList = styled(List)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  height: 44,
  borderRadius: 44,
  padding: theme.spacing(0.5),
  boxShadow: '0 1px 0 0 rgba(255, 255, 255, 0.1)',
  '& .MuiMenuItem-root': {
    overflow: 'hidden',
    flex: 1,
    justifyContent: 'center',
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    transition: 'color 150ms ease-in-out',
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export const SegmentControl = <TValue,>({
  defaultValue,
  value,
  onChange,
  children,
  ...rest
}: SegmentControlProps<TValue>) => {
  const { currentIndex, itemsArray, handleChange } = useSegmentControl({
    defaultValue,
    value,
    onChange,
    children,
  });

  return (
    <ContainerBox {...rest}>
      <BackgroundSlider
        style={{ width: `${100 / itemsArray.length}%` }}
        animate={{ x: `${currentIndex * 100}%` }}
        transition={{ duration: 0.15, ease: 'easeInOut' }}
      >
        <ActiveSegment />
      </BackgroundSlider>
      <SegmentList>
        {itemsArray.map((item, index) => (
          <item.type
            key={index}
            {...item.props}
            selected={index === currentIndex}
            onClick={() => handleChange(item.props.value as TValue)}
          >
            <Typography variant="bodyBold">{item.props.children}</Typography>
          </item.type>
        ))}
      </SegmentList>
    </ContainerBox>
  );
};
