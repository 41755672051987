import * as T from '@aily/graphql-sdk/schema';
import { styled, Typography } from '@mui/material';
import React from 'react';

import { useStoryContext } from '../../../contexts';
import { colors } from '../../../theme/default/colors';
import { mapSentimentToColor } from '../../../utils';
import DefaultStoryMetaBar from '../../StoryMetaBar/DefaultStoryMetaBar';

export interface StoryHeaderProps {
  children: React.ReactNode;
  sentiment?: T.Sentiment;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'sentiment',
})<StoryHeaderProps>(({ sentiment }) => ({
  color: mapSentimentToColor((sentiment ?? T.Sentiment.Neutral) as T.Sentiment, {
    [T.Sentiment.Neutral]: colors.primary.aqua,
  }),
}));

const StoryHeader: React.FC<StoryHeaderProps> = ({ children, sentiment }) => {
  const storyContext = useStoryContext();

  if (
    storyContext?.story?.storyType === T.StoryDisplayType.Whisper ||
    storyContext?.story?.storyType === T.StoryDisplayType.WhisperReal
  ) {
    return (
      <>
        <StyledTypography variant="h6" sentiment={sentiment}>
          {children}
        </StyledTypography>
        <DefaultStoryMetaBar
          story={storyContext.story}
          onReferenceClick={storyContext.onStoryReferenceClick}
        />
      </>
    );
  }

  return (
    <StyledTypography variant="h6" sentiment={sentiment}>
      {children}
    </StyledTypography>
  );
};

export default React.memo(StoryHeader);
