import * as T from '@aily/graphql-sdk/schema';
import { Box, Stack } from '@mui/material';
import { find } from 'lodash-es';
import React, { useMemo } from 'react';

import { useResponsiveValue } from '../../hooks';
import { TableColumnProps } from '../Table';

type TableDataViewAbstractType = {
  columns?: T.TableColumnResult[] | null;
  rows?: T.TableRowResult[] | null;
};

export interface UseTableDataViewColumnsProps {
  dataView?: TableDataViewAbstractType;
  isColumnVisible: (column: T.TableColumnResult) => boolean;
  isColumnToggleable: (column: T.TableColumnResult) => boolean;
  isColumnToggled: (column: T.TableColumnResult) => boolean;
  isColumnSelectable: (column: T.TableColumnResult) => boolean;
  isColumnSelected: (column: T.TableColumnResult) => boolean;
}

export function useTableDataViewColumns({
  dataView,
  isColumnVisible,
  isColumnToggleable,
  isColumnToggled,
  isColumnSelectable,
  isColumnSelected,
}: UseTableDataViewColumnsProps) {
  const shortenYears = useResponsiveValue<boolean>({ xs: true, xl: false, xxl: false });
  return useMemo(
    () =>
      ((dataView?.columns ?? [])
        ?.filter((column) => {
          if (isColumnSelectable(column)) {
            return isColumnToggleable(column)
              ? isColumnSelected(column) && isColumnToggled(column)
              : isColumnSelected(column);
          }

          return isColumnVisible(column);
        })
        // TODO: Hiding a column based on a hard-coded `location` data key
        ?.filter((column) => column.dataKey !== 'location' && column.dataKey !== 'location_label')
        .map((column, index) => {
          const dataKey = column?.dataKey ?? '';
          let label: React.ReactNode = column?.title ?? '';
          // TODO: Deciding a column align based on a hard-coded `microchart` data key
          let align = index === 0 || dataKey === 'microchart' ? 'left' : 'right';

          // MOR-793 For the “Timeline” column header, use the `TimelineAxis.categories` from the first timeline found
          if (dataKey === 'Timeline') {
            let foundTimeline: T.Timeline | undefined;
            dataView?.rows?.every((row) => {
              foundTimeline = (
                find(row?.cells, (cell) => T.isTimeline(cell?.cellContent)) as
                  | (T.TableCellResult & { cellContent: T.Timeline })
                  | undefined
              )?.cellContent;

              return !foundTimeline;
            });

            if (foundTimeline?.axis?.categories?.length) {
              align = 'left';
              label = (
                <Stack direction="row" spacing={1} data-testid="TimelineCategories">
                  {foundTimeline.axis.categories.map((category, i) => {
                    let year = category;
                    if (
                      /^[0-9]{4}$/.test(category) &&
                      ((shortenYears && foundTimeline!.axis.categories!.length > 20) ||
                        foundTimeline!.axis.categories!.length > 30)
                    ) {
                      year = `'${year.slice(-2)}`;
                    }
                    return (
                      <Box key={i} flexGrow={1} data-testid="CategoryName">
                        {year}
                      </Box>
                    );
                  })}
                </Stack>
              );
            }
          }

          return { dataKey, label, align };
        }) ?? []) as TableColumnProps[],
    [
      dataView,
      isColumnVisible,
      isColumnToggleable,
      isColumnToggled,
      isColumnSelectable,
      isColumnSelected,
      shortenYears,
    ],
  );
}
