import { Stack, StackProps, Typography } from '@mui/material';
import React from 'react';

import { AchievementBadge, AchievementBadgeType } from '../AchievementBadge';
import { ActivityType, RocketFilled } from '../ActivityButton';

export interface ActivityRecommendationProps extends StackProps {
  activityType: ActivityType;
}

const activityContentMap: Record<
  ActivityType,
  { label: string; text: string; icon: React.ReactNode }
> = {
  monthly: {
    label: 'Go Silver!',
    text: 'Plai works best with brief, frequent visits. Use it weekly to level up to Gold.',
    icon: <AchievementBadge type={AchievementBadgeType.Silver} width={28} height={28} />,
  },
  weekly: {
    label: 'Go Gold!',
    text: 'Plai works best with brief, frequent visits. Use it daily to level up to Gold.',
    icon: <AchievementBadge type={AchievementBadgeType.Gold} width={28} height={28} />,
  },
  daily: {
    label: 'You are Gold!',
    text: 'Congratulations! Keep up with your daily activity to retain your golden status.',
    icon: <RocketFilled width={28} height={28} />,
  },
};

export const ActivityRecommendation: React.FC<ActivityRecommendationProps> = ({
  activityType,
  ...rest
}) => {
  const { label, text, icon } = activityContentMap[activityType]; // Get the label and icon based on activityType

  return (
    <Stack direction="column" spacing={1} {...rest}>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        {icon}
        <Typography variant="h9" color="text.primary">
          {label}
        </Typography>
      </Stack>
      <Typography variant="bodyLarge" color="text.primary">
        {text}
      </Typography>
    </Stack>
  );
};
