import { ButtonBase, ButtonBaseProps, Stack, styled } from '@mui/material';
import React, { useRef } from 'react';

import { ChevronDown, ChevronUp } from '../../icons';
import { OptimallyWideMultilineText } from '../OptimallyWideMultilineText';

export interface ExpandCollapseButtonProps extends ButtonBaseProps {
  children?: React.ReactNode;
  expanded?: boolean;
}

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  all: 'unset', // This prevents children from inheriting any styles from this button
  cursor: 'pointer',
  '& .MuiSvgIcon-root:last-of-type': {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
}));

export const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({
  children,
  expanded,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <StyledButton data-testid="ExpandCollapseButton" {...rest}>
      <Stack ref={ref} direction="row" spacing={1} alignItems="center">
        <OptimallyWideMultilineText containerRef={ref}>{children}</OptimallyWideMultilineText>
        {expanded ? <ChevronUp /> : <ChevronDown />}
      </Stack>
    </StyledButton>
  );
};
