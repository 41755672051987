import * as T from '@aily/graphql-sdk/schema';
import {
  CardActionArea,
  CardContent,
  CardHeader,
  CardProps,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC } from 'react';

import { mapSentimentToColor } from '../../utils';
import { getIconComponent } from '../../utils/icons';
import { FitText } from '../FitText';
import { StyledCard } from './MicroChartCard';

interface Props extends CardProps {
  card: T.KpiCardResult;
  onCardClick?: () => void;
}

const KpiCard: FC<Props> = ({ card, onCardClick, ...rest }) => {
  return (
    <StyledCard isKpiCard {...rest}>
      <CardActionArea component="div" onClick={onCardClick}>
        <CardHeader
          disableTypography
          title={
            <Typography variant="h9" data-testid="Title">
              <FitText minFontSize={90}>{card.title.value ?? ''}</FitText>
            </Typography>
          }
          subheader={
            !!card.title.subLabels?.length && (
              <Typography variant="small" color="text.secondary">
                {card.title.subLabels[0]}
              </Typography>
            )
          }
        />
        <CardContent>
          <Stack direction="column" spacing={1.5}>
            {card.items?.map((item, index) => {
              const IconComponent = getIconComponent(item.icon);
              return (
                <Stack key={index} direction="row" alignItems="center" spacing={1}>
                  <IconComponent
                    style={{ color: mapSentimentToColor(item.sentiment), fontSize: 18 }}
                  />
                  <Typography variant="small">{item.actual}</Typography>
                  {!!item.target && (
                    <Typography variant="small" color="text.secondary" sx={{ ml: 0.5 }}>
                      / {item.target}
                    </Typography>
                  )}
                </Stack>
              );
            })}
          </Stack>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default KpiCard;
