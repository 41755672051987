import { Stack, Typography } from '@mui/material';
import { sortBy } from 'lodash-es';
import React from 'react';

import { Table, TableColumnProps } from '../Table';
import { TimelineDataPointProps } from './Timeline';

export interface TimelineTooltipContentProps {
  dataPoint: TimelineDataPointProps;
  columns: TableColumnProps[];
  onCellRender: (
    dataPoint: TimelineDataPointProps,
    column: TableColumnProps,
    columnIndex: number,
  ) => React.ReactNode;
  title?: string;
}

export const TimelineTooltipContent: React.FC<TimelineTooltipContentProps> = React.memo(
  ({ dataPoint, columns, onCellRender, title }) => (
    <Stack direction="column" spacing={0.5} sx={{ p: 1.5 }}>
      {!!title && <Typography variant="bodyBold">{title}</Typography>}
      <Table
        columns={columns}
        rows={
          dataPoint.nearbyDataPoints?.length ? sortBy(dataPoint.nearbyDataPoints, 'x') : [dataPoint]
        }
        onCellRender={onCellRender}
      />
    </Stack>
  ),
);
