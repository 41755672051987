import { InfoOutlined } from '@mui/icons-material';
import { IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';

export interface InfoButtonProps extends IconButtonProps {}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.text.secondary,
  '& .MuiSvgIcon-root': {
    fontSize: 28,
  },
}));

export const InfoButton: React.FC<InfoButtonProps> = ({ children = <InfoOutlined />, ...rest }) => (
  <StyledIconButton disableRipple {...rest}>
    {children}
  </StyledIconButton>
);
