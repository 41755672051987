import { ChevronLeft } from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';
import React, { useCallback } from 'react';

import CarouselButtonBase from './CarouselButtonBase';
import useCarouselState from './useCarouselState';

export interface CarouselButtonBackProps extends IconButtonProps {}

const CarouselButtonBack: React.FC<CarouselButtonBackProps> = (props) => {
  const { currentPage, visibleSlides, carouselContext } = useCarouselState();

  const handleClick = useCallback(() => {
    if (currentPage > 0) {
      const newCurrentPage = currentPage - 1;
      const newCurrentSlide = newCurrentPage * visibleSlides;
      carouselContext.setStoreState({ currentSlide: newCurrentSlide });
    }
  }, [currentPage, visibleSlides, carouselContext]);

  return (
    <CarouselButtonBase onClick={handleClick} disabled={currentPage <= 0} {...props}>
      <ChevronLeft />
    </CarouselButtonBase>
  );
};

export default CarouselButtonBack;
