import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserDisclaimerState } from './types';

const initialState: UserDisclaimerState = {};

const slice = createSlice({
  name: 'userDisclaimer',
  initialState,
  reducers: {
    verifyUserDisclaimerRequest: (state) => state,
    verifyUserDisclaimerFailure: (state, _action: PayloadAction<Error>) => state,
    verifyUserDisclaimerSuccess: (state) => state,
  },
});

export const {
  verifyUserDisclaimerRequest,
  verifyUserDisclaimerFailure,
  verifyUserDisclaimerSuccess,
} = slice.actions;

export default slice.reducer;
