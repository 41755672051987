import { ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { convertSelectedItemsToArray } from '@mui/x-tree-view/internals/plugins/useTreeViewSelection/useTreeViewSelection.utils';
import React, { useCallback } from 'react';
import Highlighter from 'react-highlight-words';
import { areEqual, ListChildComponentProps } from 'react-window';

import { FilterDeepResult } from '../../hooks';
import { SelectionControl } from './SelectionControl';
import { TreeViewProps, TreeViewSelectionValue } from './TreeView';

export interface SearchListItemProps<
  T extends object,
  Multiple extends boolean | undefined = undefined,
> extends Required<Pick<TreeViewProps<T, Multiple>, 'getItemId' | 'getItemLabel'>>,
    Pick<TreeViewProps<T, Multiple>, 'selectedItems' | 'onSelectedItemsChange' | 'multiSelect'> {
  items: FilterDeepResult<T>[];
  search: string;
  selectable?: boolean;
}

export const SearchListItem = React.memo(
  <T extends object, Multiple extends boolean | undefined = undefined>(
    props: ListChildComponentProps<SearchListItemProps<T, Multiple>>,
  ) => {
    const { data, index, style } = props;
    const {
      items,
      getItemId,
      getItemLabel,
      search,
      selectable,
      selectedItems,
      onSelectedItemsChange,
      multiSelect,
    } = data;

    const item = items[index];
    const itemId = getItemId(item.node);
    const itemLabel = getItemLabel(item.node);
    const selectedItemsArray = selectedItems ? convertSelectedItemsToArray(selectedItems) : [];

    const handleListItemClick = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        if (!selectable) return;

        let newSelectedItems: TreeViewSelectionValue<Multiple>;

        if (!selectedItemsArray.includes(itemId)) {
          newSelectedItems = (
            multiSelect ? [...selectedItemsArray, itemId] : itemId
          ) as TreeViewSelectionValue<Multiple>;
        } else {
          newSelectedItems = selectedItems as TreeViewSelectionValue<Multiple>;
        }

        onSelectedItemsChange?.(event, newSelectedItems);
      },
      [selectable, multiSelect, selectedItemsArray, itemId, onSelectedItemsChange],
    );

    return (
      <ListItem
        style={style}
        component="div"
        sx={{
          px: 1.5,
          py: 1,
          backgroundColor: 'background.paper',
          borderTop: (theme) => `1px solid ${theme.palette.background.default}`,
          userSelect: 'none',
          ...(selectable && { cursor: 'pointer' }),
        }}
        onClick={handleListItemClick}
      >
        <ListItemText
          disableTypography
          primary={
            <Stack direction="row" spacing={1.5} alignItems="center">
              {selectable && (
                <SelectionControl
                  selected={selectedItemsArray.includes(itemId)}
                  multiSelect={multiSelect}
                />
              )}
              <Typography variant="body" component="div" noWrap>
                <Highlighter searchWords={[search]} textToHighlight={itemLabel} autoEscape />
              </Typography>
            </Stack>
          }
          sx={{
            color: 'text.secondary',
            '& mark': { backgroundColor: 'transparent', color: 'text.primary' },
            '& .MuiCheckbox-root, & .MuiRadio-root': { padding: 0 },
          }}
        />
      </ListItem>
    );
  },
  areEqual,
) as <T extends object, Multiple extends boolean | undefined = undefined>(
  props: ListChildComponentProps<SearchListItemProps<T, Multiple>>,
) => React.ReactNode;
