import { styled } from '@mui/material';
import React from 'react';

import { colors } from '../../theme/default/colors';

const Container = styled('div')(() => ({
  display: 'flex',
}));

const Step = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 10,
  marginLeft: -4,
  marginRight: -4,
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
}));

const StepLine = styled('div')(() => ({
  width: '100%',
  height: 18,
  borderTopLeftRadius: 2,
  borderBottomLeftRadius: 2,
  borderTopRightRadius: 9,
  borderBottomRightRadius: 9,
}));

const StepLabel = styled('div')(({ theme }) => ({
  ...theme.typography.xSmall,
  position: 'absolute',
  zIndex: 100,
  whiteSpace: 'nowrap',
}));

interface ProgressStepProps {
  lineColor?: string;
  width?: string;
  label?: string;
  zIndex?: number;
}

interface Props {
  lineColor?: string;
  children: React.ReactElement<ProgressStepProps>[];
}

export const ProgressStep: React.FC<ProgressStepProps> = ({
  lineColor = colors.primary.aqua,
  width = 'auto',
  label,
  zIndex,
}) => {
  return (
    <Step style={{ width }} data-testid="Step">
      <StepLine style={{ backgroundColor: lineColor, zIndex }} />
      <StepLabel title={label}>{label}</StepLabel>
    </Step>
  );
};

export const ProgressSteps: React.FC<Props> & { Step: typeof ProgressStep } = ({ children }) => {
  return (
    <Container data-testid="ProgressSteps">
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            // The z-index of the previous element must be lower than the next one for the lines to overlap correctly
            zIndex: React.Children.count(children) - index,
          });
        }

        return child;
      })}
    </Container>
  );
};

ProgressSteps.Step = ProgressStep;
