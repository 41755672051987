import { Box, CardProps, Grid2 as Grid, Skeleton, Toolbar, Typography } from '@mui/material';

interface CardGridSkeletonProps {
  numberOfItems: number;
  CardComponent: React.ComponentType<CardProps>;
}

const CardGridSkeleton: React.FC<CardGridSkeletonProps> = ({ numberOfItems, CardComponent }) => {
  return (
    <Box>
      <Toolbar>
        <Typography variant="h9" sx={{ width: 160 }} gutterBottom={true}>
          <Skeleton animation="pulse" />
        </Typography>
      </Toolbar>
      <Grid container rowSpacing={3.75} columnSpacing={2}>
        {Array.from({ length: numberOfItems }, (_, index) => (
          <Grid
            key={index}
            size={{
              xs: 12,
              sm: 6,
              md: 4,
              lg: 3,
            }}
            sx={{ '& > .MuiCard-root': { width: '100%' } }}
          >
            <CardComponent
              sx={{
                background: 'transparent',
                boxShadow: 'none',
                '& .MuiSkeleton-root': {
                  height: '100%',
                  // animationDelay: `${index * 0.1}s !important`,
                },
              }}
            >
              <Skeleton variant="rectangular" animation="pulse" />
            </CardComponent>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CardGridSkeleton;
