import { Components, Theme } from '@mui/material';

export const MuiPopover: Components<Theme>['MuiPopover'] = {
  styleOverrides: {
    paper: ({ theme }) => ({
      backgroundImage: 'none',
      color: theme.palette.text.secondary,
    }),
  },
  defaultProps: {
    disableScrollLock: true,
  },
};
