import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Star: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6016 4.5016C15.5827 2.49946 18.4173 2.49947 19.3984 4.5016L22.4595 10.7484C22.5374 10.9074 22.688 11.0176 22.8623 11.0431L29.7071 12.0449C31.9008 12.3659 32.7768 15.0812 31.1894 16.6396L26.2364 21.5021C26.1104 21.6259 26.0528 21.8042 26.0826 21.979L27.2518 28.8449C27.6266 31.0454 25.3333 32.7235 23.3711 31.6846L17.2489 28.4429C17.0931 28.3604 16.9069 28.3604 16.7511 28.4429L10.6289 31.6846C8.66674 32.7236 6.37343 31.0454 6.74818 28.8449L7.9174 21.979C7.94717 21.8042 7.88964 21.6259 7.76356 21.5021L2.81063 16.6396C1.22319 15.0812 2.09916 12.3659 4.29294 12.0449L11.1377 11.0431C11.312 11.0176 11.4626 10.9074 11.5405 10.7484L14.6016 4.5016ZM17.4797 5.45534C17.2835 5.05491 16.7165 5.05491 16.5203 5.45533L13.4592 11.7022C13.0696 12.4972 12.3166 13.0483 11.4454 13.1758L4.60063 14.1775C4.16187 14.2417 3.98668 14.7847 4.30416 15.0964L9.2571 19.9589C9.88747 20.5778 10.1751 21.4694 10.0263 22.3433L8.85708 29.2092C8.78213 29.6493 9.24079 29.9849 9.63323 29.7771L15.7554 26.5355C16.5346 26.1229 17.4654 26.1229 18.2446 26.5355L24.3668 29.7771C24.7592 29.9849 25.2179 29.6493 25.1429 29.2092L23.9737 22.3433C23.8249 21.4694 24.1125 20.5778 24.7429 19.9589L29.6958 15.0964C30.0133 14.7848 29.8381 14.2417 29.3994 14.1775L22.5546 13.1758C21.6834 13.0483 20.9304 12.4972 20.5408 11.7022L17.4797 5.45534Z"
    />
  </SvgIcon>
);
