import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react';

import { colors } from '../../../../theme/default/colors';

interface AgentInfoProps {
  text: string;
  text2: string;
}

const StyledButton = styled(Stack)(() => ({
  borderRadius: 100,
  backgroundColor: 'rgba(14, 51, 31, 1)',
  justifyContent: 'center',
  alignItems: 'center',
  height: 40,
  width: 170,
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  marginTop: -10,
}));

const AgentInfo: React.FC<AgentInfoProps> = ({ text, text2 }) => {
  return (
    <Box display="flex" justifyContent="center">
      <StyledButton gap={5}>
        <Typography variant="h8" color={colors.primary.green}>
          {text}
        </Typography>
        <Typography color={colors.neutrals.white}>{text2}</Typography>
      </StyledButton>
    </Box>
  );
};

export default AgentInfo;
