export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Card: ['KpiCardResult', 'MicroChartCardResult', 'SimpleCardResult'],
    ChartAxis: [
      'ChartAxisCategoryViewResult',
      'ChartAxisPercentViewResult',
      'ChartAxisValueViewResult',
    ],
    ColorUnion: ['ColorString', 'ThemeColor'],
    Component: [
      'ButtonComponent',
      'CardsComponent',
      'CashFlowKpiComponent',
      'ChartComponent',
      'ColumnLayoutComponent',
      'ComponentBase',
      'DemoHomeChartComponent',
      'DemoHomeMicroChartComponent',
      'DialsComponent',
      'FilterComponent',
      'FiltersComponent',
      'FinHomePageCardsComponent',
      'GraFacilitatedPathwaysTableComponent',
      'GraHomeCardsComponent',
      'GraHomeDialsComponent',
      'GraRecommendedPathwaysDetailsComponent',
      'GraRecommendedPathwaysTableComponent',
      'GridComponent',
      'GtmHomeCardsComponent',
      'GtmHomeDialComponent',
      'GtmImpactRecommendationComponent',
      'GtmImpactTableComponent',
      'GtmImpactTableHeaderComponent',
      'GtmPulseDetailsChartComponent',
      'GtmPulseDetailsChartHeaderComponent',
      'GtmPulseDetailsDriversComponent',
      'GtmPulseDialComponent',
      'GtmPulseLeaderboardListComponent',
      'GtmPulseLeaderboardPositionComponent',
      'GtmPulseRecommendedActionsComponent',
      'GtmResourceAllocationTableComponent',
      'HeaderRowComponent',
      'HeaderSimpleComponent',
      'KpiComponent',
      'ListComponent',
      'MarkdownComponent',
      'MicroChartComponent',
      'MnsDeviationsDetailsComponent',
      'MnsDeviationsDialComponent',
      'NavigationTabsComponent',
      'PlailistComponent',
      'PnlRebatesBreakdownChartComponent',
      'PnlRebatesBreakdownChartHeaderComponent',
      'PnlRebatesBreakdownTableComponent',
      'PnlRebatesInsightsCardsComponent',
      'PnlRebatesInsightsHeaderComponent',
      'PplHomeCardsComponent',
      'PplHomeCategoriesDialsComponent',
      'PplHomeDetailsChartComponent',
      'PplHomeDetailsChartHeaderComponent',
      'PplHomeDetailsDriversChartComponent',
      'PplHomeDetailsDriversChartHeaderComponent',
      'PplHomeDetailsDriversComponent',
      'PplHomeDetailsRecommendationsComponent',
      'PplHomeDialComponent',
      'ProAgentDetailsComponent',
      'ProDailyAgentComponent',
      'ProHomeFeedComponent',
      'RecommendationsActionsComponent',
      'RecommendationsBreakdownComponent',
      'RecommenderComponent',
      'RndHomeDialsComponent',
      'RndInsightsBusinessDevelopmentAndLicensingDriversComponent',
      'RndInsightsBusinessDevelopmentAndLicensingTableComponent',
      'RndInventoryRecommendationsBreakdownComponent',
      'RndInventoryRecommendationsComponent',
      'RndStudyDesignFormComponent',
      'RndStudyDesignsSiteDistributionTableComponent',
      'RndStudyDesignsTableComponent',
      'RndTrialsAiPosDriversComponent',
      'RndTrialsKpiCategoriesComponent',
      'RndTrialsKpiDetailTableComponent',
      'RndTrialsRndInvestmentsPnlTableComponent',
      'RndValueOverviewChartComponent',
      'RndValueOverviewChartHeaderComponent',
      'SpendGlocalPulseDialComponent',
      'SpendGlocalPulseDriversChartComponent',
      'SpendGlocalPulseDriversListComponent',
      'SpendGlocalPulseKeyDriversChartComponent',
      'SpendGlocalPulseKeyDriversListComponent',
      'SpendGlocalPulseLeaderboardListComponent',
      'SpendGlocalPulseLeaderboardPositionComponent',
      'SpendGlocalPulseRecommendedActionsComponent',
      'SpendHomeCardsComponent',
      'SpendHomeDialComponent',
      'SpendProcurementChartComponent',
      'SpendProcurementTableComponent',
      'SpendTrendingComponent',
      'StoryListComponent',
      'SummaryInfoComponent',
      'SupplyHomeCardsComponent',
      'SupplyHomeDialsComponent',
      'SupplyLowInventoryRiskRootCausesComponent',
      'SupplyPulseLeaderboardListComponent',
      'SupplyShortDatedRootCausesComponent',
      'TableComponent',
      'TableSwitcherComponent',
      'TimelineComponent',
      'UserAnalyticsComponent',
      'UserPrioritiesComponent',
      'VideoComponent',
    ],
    Filter: ['SelectFilter', 'SelectGroupFilter', 'TreeSelectFilter'],
    FilterValue: ['MultiSelectFilterValue', 'SelectFilterValue', 'SelectGroupFilterValue'],
    IDataView: [
      'ButtonDataView',
      'CardsDataView',
      'CashFlowKpiDataViewResult',
      'ChartDataViewResult',
      'DialsDataView',
      'FinHomePageCardsDataView',
      'GlocalPulseLeaderboardPositionDataView',
      'GlocalPulseRecommendedActionsDataView',
      'GraCardDataView',
      'GraFacilitatedPathwaysTableDataView',
      'GraHomeDialsDataView',
      'GraRecommendedPathwaysDetailsDataView',
      'GraRecommendedPathwaysTableDataView',
      'GridDataView',
      'GtmCardDataView',
      'GtmHomeDialDataView',
      'GtmImpactRecommendationDataView',
      'GtmImpactTableDataView',
      'GtmImpactTableHeaderDataView',
      'GtmPulseDetailsChartHeaderDataView',
      'GtmPulseDetailsDriversDataView',
      'GtmPulseDialDataView',
      'GtmPulseLeaderboardListDataView',
      'GtmPulseLeaderboardPositionDataView',
      'GtmPulseRecommendedActionsDataView',
      'GtmResourceAllocationTableDataView',
      'HeaderRow',
      'HeaderSimple',
      'KpiStatusOverview',
      'ListDataView',
      'MicroChartResult',
      'MnsDeviationsDetailsDataView',
      'MnsDeviationsDialDataView',
      'PnlRebatesBreakdownChartDataView',
      'PnlRebatesBreakdownChartHeaderDataView',
      'PnlRebatesBreakdownTableDataView',
      'PnlRebatesInsightsCardsDataView',
      'PnlRebatesInsightsHeaderDataView',
      'PplCardDataView',
      'PplHomeCategoriesDialsDataView',
      'PplHomeDetailsChartHeaderDataView',
      'PplHomeDetailsDriverChartHeaderDataView',
      'PplHomeDetailsDriversDataView',
      'PplHomeDetailsRecommendationsDataView',
      'PplHomeDialDataView',
      'PplMicroChartResult',
      'ProAgentDetailsDataView',
      'ProDailyAgentDataView',
      'ProHomeFeedDataView',
      'RecommendationsActionsDataView',
      'RecommendationsBreakdownDataView',
      'RecommenderDataView',
      'RndHomeDialsDataView',
      'RndInsightsBusinessDevelopmentAndLicensingDriversView',
      'RndInsightsBusinessDevelopmentAndLicensingTableDataView',
      'RndInventoryRecommendationsBreakdownDataView',
      'RndInventoryRecommendationsDataView',
      'RndStudyDesignerSiteDistributionTableDataView',
      'RndStudyDesignsTableDataView',
      'RndTimelineChartDataViewResult',
      'RndTrialsAiPosDriversDataView',
      'RndTrialsBreakdownAiPtrsDriversDataView',
      'RndTrialsKpiCategoriesDataView',
      'RndTrialsKpiDetailTableDataView',
      'RndTrialsRndInvestmentsPnlTableDataView',
      'RndTrialsValueOverviewChartDataView',
      'RndTrialsValueOverviewChartDataViewResult',
      'RndTrialsValueOverviewChartHeaderDataView',
      'SpendCardDataView',
      'SpendGlocalPulseDialDataView',
      'SpendGlocalPulseDriversChartDataView',
      'SpendGlocalPulseDriversListDataView',
      'SpendGlocalPulseLeaderboardListDataView',
      'SpendHomeDialDataView',
      'SpendProcurementChartDataView',
      'SpendProcurementTableDataView',
      'SpendTrendingResult',
      'SummaryInfoDataView',
      'SupplyHomeCardsDataView',
      'SupplyHomeDialsDataView',
      'SupplyLowInventoryRiskRootCausesDataView',
      'SupplyPulseDriverKpisDataView',
      'SupplyPulseLandingResult',
      'SupplyPulseLeaderboardListDataView',
      'SupplyShortDatedRootCausesDataView',
      'TableDataView',
    ],
    InsightType: ['Real', 'Story'],
    Media: ['ImageResult', 'VideoContent'],
    ScreenResultType: [
      'DailyAgentActionsScreenResult',
      'DailyAgentFireworksScreenResult',
      'DailyAgentProgressScreenResult',
    ],
    TableCellContent: [
      'BadgeResult',
      'BadgeResultWithLabel',
      'ColoredVarianceResult',
      'ColoredVarianceWithDeltaResult',
      'ComparatorTrendResult',
      'DrillDownResult',
      'GraphicElementResult',
      'IconLinkResult',
      'IconsLinkResult',
      'KpiStatus',
      'KpiStatusOverview',
      'LocationMarkerResult',
      'MicroChartResult',
      'ProgressBarResult',
      'RegionCountryResult',
      'RiskIndicatorResult',
      'StatusTextResult',
      'StudyDesignLinkResult',
      'TextExtendedResult',
      'TextLinkResult',
      'TextResult',
      'Timeline',
      'TimelineDataPoint',
      'TimelinePhases',
      'ValueWithVarianceResult',
      'VarianceResult',
    ],
  },
};
export default result;
