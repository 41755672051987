import * as T from '@aily/graphql-sdk/schema';
import { useCallback } from 'react';

import { FilterDisplayModeFunction } from './FilterToolbar';

export function useHandleFilterDisplayMode(filterComponents?: T.FilterComponent[] | null) {
  return useCallback<FilterDisplayModeFunction>(
    (filterId) =>
      filterComponents?.find(({ id }) => id === filterId)?.displayMode ?? T.DisplayMode.Default,
    [filterComponents],
  );
}
