import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, unionBy } from 'lodash-es';

import { RootState } from '@/store/rootReducer';

import { Modal, ModalsState } from './types';

const initialState: ModalsState = {
  modals: [],
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<Modal>) => {
      state.modals = unionBy([action.payload], state.modals, ({ key }) => key);
    },
    onModalOk: (state, _action: PayloadAction<Modal['key']>) => state,
    onModalCancel: (state, _action: PayloadAction<Modal['key']>) => state,
    onModalClose: (state, { payload: key }: PayloadAction<Modal['key']>) => {
      const foundIndex = findIndex(state.modals, { key });
      if (foundIndex !== -1) {
        // For now, hiding a modal will remove it
        state.modals.splice(foundIndex, 1);
      }
    },
  },
});

export const getModals = (state: RootState) => state.modals.modals;

export const { showModal, onModalOk, onModalCancel, onModalClose } = modalsSlice.actions;
export default modalsSlice.reducer;
