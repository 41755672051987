import { Box, BoxProps, styled } from '@mui/material';
import React, { CSSProperties, useCallback, useState } from 'react';

export interface CoverImageProps extends BoxProps {
  children: React.ReactElement<HTMLSourceElement> | React.ReactElement<HTMLSourceElement>[];
  lqipUrl?: string;
  onLoad?: () => void;
  loadedStyle?: (loaded: boolean) => CSSProperties;
}

const ImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'lqipUrl' && prop !== 'loaded',
})<{ lqipUrl?: string; loaded?: boolean }>(({ lqipUrl, loaded }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `url(${lqipUrl})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  transition: 'filter 0.2s linear',
  transform: 'translateZ(0)',
  userSelect: 'none',
  pointerEvents: 'none',
  filter: loaded ? 'blur(0px) brightness(0.2)' : 'blur(10px) brightness(0.2)',
}));

const StyledImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const CoverImage: React.FC<CoverImageProps> = ({
  children,
  lqipUrl,
  onLoad,
  loadedStyle,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = useCallback(() => {
    setLoaded(true);
    onLoad?.();
  }, [onLoad]);

  return (
    <ImageContainer lqipUrl={lqipUrl} loaded={loaded} style={loadedStyle?.(loaded)} {...rest}>
      <picture>
        {children}
        <StyledImage onLoad={handleLoad} />
      </picture>
    </ImageContainer>
  );
};

export default CoverImage;
