import { AdditionalFilterOptions, Story, UserAdditionalFilters } from '@aily/graphql-sdk/schema';
import React, { createContext, useContext, useMemo, useState } from 'react';

import { FilterRowButtonType } from '../hooks';

interface ProFiltersContextState {
  feedItems?: Story[];
  userAdditionalFilters?: UserAdditionalFilters[];
  selectedFilters: FilterRowButtonType[];
  feedFilterOptions?: AdditionalFilterOptions[];
  customsFilters: UserAdditionalFilters[];
  updateFeedItems: (feedItems: Story[]) => void;
  updateCustomsFilters: (customsFilters: UserAdditionalFilters[]) => void;
  updateFeedFilterOptions: (feedFilterOptions: AdditionalFilterOptions[]) => void;
  updateUserAdditionalFilters: (userAdditionalFilters: UserAdditionalFilters[]) => void;
  removeSelectedFilter: () => void;
  addSelectedFilter: (filter: FilterRowButtonType, option?: FilterRowButtonType) => void;
}

export const ProFiltersContext = createContext<ProFiltersContextState>({
  feedItems: [],
  feedFilterOptions: [],
  userAdditionalFilters: [],
  selectedFilters: [],
  customsFilters: [],
  updateFeedItems: () => {},
  updateCustomsFilters: () => {},
  updateFeedFilterOptions: () => {},
  updateUserAdditionalFilters: () => {},
  removeSelectedFilter: () => {},
  addSelectedFilter: () => {},
});

export const ProFiltersProvider = ({ children }: { children: React.ReactNode }) => {
  const [feedItems, setFeedItems] = useState<Story[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<FilterRowButtonType[]>([]);
  const [customsFilters, setCustomsFilters] = useState<UserAdditionalFilters[]>([]);
  const [userAdditionalFilters, setUserAdditionalFilters] = useState<UserAdditionalFilters[]>([]);
  const [feedFilterOptions, setFeedFilterOptions] = useState<AdditionalFilterOptions[]>([]);
  const updateFeedItems = (data: Story[]) => {
    setFeedItems(data);
  };

  const updateUserAdditionalFilters = (filters: UserAdditionalFilters[]) => {
    setUserAdditionalFilters(filters);
  };

  const updateFeedFilterOptions = (options: AdditionalFilterOptions[]) => {
    setFeedFilterOptions(options);
  };

  const addSelectedFilter = (
    filter: FilterRowButtonType,
    option?: FilterRowButtonType | undefined,
  ) => {
    const arrFilters = [filter];
    if (option) {
      arrFilters.push(option);
    }
    setSelectedFilters(arrFilters);
  };

  const updateCustomsFilters = (filters: UserAdditionalFilters[]) => {
    setCustomsFilters(filters);
  };

  const removeSelectedFilter = () => {
    setSelectedFilters([]);
  };

  const contextValue = useMemo(
    () => ({
      feedItems,
      feedFilterOptions,
      userAdditionalFilters,
      selectedFilters,
      customsFilters,
      updateFeedItems,
      updateCustomsFilters,
      updateFeedFilterOptions,
      updateUserAdditionalFilters,
      removeSelectedFilter,
      addSelectedFilter,
    }),
    [
      feedItems,
      feedFilterOptions,
      userAdditionalFilters,
      selectedFilters,
      customsFilters,
      updateFeedItems,
      updateCustomsFilters,
      updateFeedFilterOptions,
      updateUserAdditionalFilters,
      removeSelectedFilter,
      addSelectedFilter,
    ],
  );

  return <ProFiltersContext.Provider value={contextValue}>{children}</ProFiltersContext.Provider>;
};

export function useProFiltersContext(): ProFiltersContextState | undefined {
  return useContext(ProFiltersContext);
}
