import React, { createContext, ReactNode, useCallback, useContext, useRef } from 'react';

import { AilyAgent, AilyAgentOperation } from '../classes';
import { WispSvg } from '../components/Wisp';

interface AilyAgentContextType {
  startOperations: (operations: AilyAgentOperation[]) => void;
  exit: () => void;
}

const AilyAgentContext = createContext<AilyAgentContextType | undefined>(undefined);

export const AilyAgentProvider = ({ children }: { children: ReactNode }) => {
  const agentRef = useRef(AilyAgent.getInstance());

  // Callback ref to handle SVG element
  const handleSvgRef = useCallback((svgElement: SVGSVGElement | null) => {
    if (svgElement) {
      agentRef.current.setSvgElement(svgElement, '.body__glow ellipse');
    }
  }, []);

  const startOperations = (operations: AilyAgentOperation[]) => {
    agentRef.current.setOperations(operations);
    agentRef.current.start();
  };

  const exit = () => {
    agentRef.current.exit();
  };

  return (
    <AilyAgentContext.Provider value={{ startOperations, exit }}>
      <WispSvg ref={handleSvgRef} />
      {children}
    </AilyAgentContext.Provider>
  );
};

export const useAilyAgent = (): AilyAgentContextType => {
  const context = useContext(AilyAgentContext);

  if (!context) {
    throw new Error('useAilyAgent must be used within an AilyAgentProvider');
  }

  return context;
};
