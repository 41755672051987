import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableFooter,
  TableFooterProps as MuiTableFooterProps,
  TableHead,
  TableRow as MuiTableRow,
} from '@mui/material';
import { TableProps as MuiTableProps } from '@mui/material/Table/Table';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { TableRowProps } from '@mui/material/TableRow/TableRow';
import React, { PropsWithChildren } from 'react';

import TableRow from './TableRow';

export interface TableColumnProps {
  dataKey: string;
  label: React.ReactNode;
  width?: string | number;
  align?: TableCellProps['align'];
}

export interface TableProps<RowType> extends MuiTableProps {
  columns: TableColumnProps[];
  rows: RowType[];
  getChildrenRows?: (row: RowType) => RowType[];
  rowKey?: (row: RowType) => string | number;
  onRow?: (row: RowType, rowIndex: number) => TableRowProps;
  onCell?: (row: RowType, column: TableColumnProps, columnIndex: number) => TableCellProps;
  onCellRender?: (row: RowType, column: TableColumnProps, columnIndex: number) => React.ReactNode;
  onHeaderCell?: (column: TableColumnProps, columnIndex: number) => TableCellProps;
  onHeaderCellRender?: (column: TableColumnProps, columnIndex: number) => React.ReactNode;
  TableFooterProps?: MuiTableFooterProps;
  withoutHeader?: boolean;
  TableContainerProps?: TableContainerProps;
}

export const Table = React.memo(
  <RowType,>({
    columns,
    rows,
    onRow,
    getChildrenRows,
    rowKey,
    onCell,
    onCellRender,
    onHeaderCell,
    onHeaderCellRender,
    TableFooterProps,
    withoutHeader,
    TableContainerProps,
    ...tableProps
  }: PropsWithChildren<TableProps<RowType>>) => (
    <TableContainer {...TableContainerProps}>
      <MuiTable {...tableProps}>
        {!withoutHeader && (
          <TableHead>
            <MuiTableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  width={column.width}
                  align={column.align}
                  variant="head"
                  {...onHeaderCell?.(column, index)}
                >
                  {onHeaderCellRender?.(column, index) ?? column.label}
                </TableCell>
              ))}
            </MuiTableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={rowKey ? rowKey(row) : index}
              row={row}
              rowIndex={index}
              rowKey={rowKey}
              getChildrenRows={getChildrenRows}
              columns={columns}
              onRow={onRow}
              onCell={onCell}
              onCellRender={onCellRender}
            />
          ))}
        </TableBody>
        {!!TableFooterProps && <TableFooter {...TableFooterProps} />}
      </MuiTable>
    </TableContainer>
  ),
) as <RowType>(props: PropsWithChildren<TableProps<RowType>>) => React.ReactNode;
