import { Grid2 as Grid, Grid2Props as GridProps } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import React from 'react';

import StoryListItem, { Props as ListItemProps } from './StoryListItem';

// The following props either cause a type collision, or we don't want them to be set from outside
type OmitPropKeys = 'children' | 'component' | 'custom' | 'ref' | 'style';

// The `Grid` item component is overridden by the `Motion` component
type GridItemPropsOverride = Omit<GridProps, keyof MotionProps> & MotionProps;
type GridItemProps = Omit<GridItemPropsOverride, OmitPropKeys>;

interface Props extends GridProps {
  children: React.ReactElement<ListItemProps>[];
  GridItemProps?: GridItemProps;
}

const StoryList: React.FC<Props> & { Item: typeof StoryListItem } = ({
  children,
  GridItemProps,
  ...rest
}) => (
  <Grid
    container
    rowSpacing={{ xs: 2, md: 3 }}
    columnSpacing={{ md: 8 }}
    sx={{ py: 1.5 }}
    {...rest}
  >
    {React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return (
          <Grid
            key={index}
            size={{ xs: 12, md: 6 }}
            component={motion.div}
            custom={index}
            animate="visible"
            initial={{ opacity: 0 }}
            variants={{
              visible: (index: number) => ({
                opacity: 1,
                transition: { delay: index * 0.02, duration: 0.1 },
              }),
            }}
            layout
            {...GridItemProps}
          >
            {child}
          </Grid>
        );
      }

      return child;
    })}
  </Grid>
);

StoryList.Item = StoryListItem;

export default StoryList;
