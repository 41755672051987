import * as T from '@aily/graphql-sdk/schema';
import React, { Suspense } from 'react';

import { LoadingSkeleton } from '../LoadingSkeleton';
import Component from './Component';

export interface Props {
  renderBeforeContent?: (components: T.Component[]) => React.ReactNode;
  pageContent?: T.PageContentResult;
  onComponentRender?: (
    component: T.Component,
    componentIndex: number,
    components: T.Component[],
  ) => React.ReactNode;
  suspenseFallback?: React.ReactNode;
}

const PageContent: React.FC<Props> = ({
  renderBeforeContent,
  pageContent,
  onComponentRender,
  suspenseFallback = <LoadingSkeleton />,
}) => {
  const components = pageContent?.body ?? [];

  return (
    <>
      {renderBeforeContent?.(components)}
      <Suspense fallback={suspenseFallback}>
        {components.map((component, componentIndex) => (
          <Component
            key={component.id}
            component={component}
            componentIndex={componentIndex}
            components={components}
            onComponentRender={onComponentRender}
          />
        ))}
      </Suspense>
    </>
  );
};

export default PageContent;
