import { Module, PriorityInput, SelectFilter, TreeSelectFilter } from '@aily/graphql-sdk/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/rootReducer';

import { DimensionFilterState } from './types';

const initialState: DimensionFilterState = {
  dimensionFilterByModuleIdPriorityId: {},
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'dimensionFilter',
  initialState,
  reducers: {
    fetchDimensionFilterRequest: (
      state,
      _action: PayloadAction<{ moduleId: Module['id']; priorityId: PriorityInput['priorityId'] }>,
    ) => {
      state.loading = true;
      state.error = null;
    },
    fetchDimensionFilterFailure: (state, action: PayloadAction<Error>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchDimensionFilterSuccess: (
      state,
      action: PayloadAction<{
        moduleId: Module['id'];
        priorityId: PriorityInput['priorityId'];
        dimensionFilter: SelectFilter | TreeSelectFilter;
      }>,
    ) => {
      const { moduleId, priorityId, dimensionFilter } = action.payload;

      if (state.dimensionFilterByModuleIdPriorityId[moduleId] == null) {
        state.dimensionFilterByModuleIdPriorityId[moduleId] = {};
      }
      state.dimensionFilterByModuleIdPriorityId[moduleId][priorityId] = dimensionFilter;
      state.loading = false;
    },
    priorityFilterChanged: (
      _state,
      _action: PayloadAction<{ moduleId: Module['id']; priorityId: PriorityInput['priorityId'] }>,
    ) => {},
  },
});

export const getDimensionFilter =
  (
    moduleId: Module['id'] | undefined,
    priorityId: PriorityInput['priorityId'] | null | undefined,
  ) =>
  (state: RootState) => {
    if (!priorityId || !moduleId) {
      return undefined;
    }
    return state.dimensionFilter.dimensionFilterByModuleIdPriorityId[moduleId]?.[priorityId];
  };

export const getDimensionFilterLoading = (state: RootState) => state.dimensionFilter.loading;
export const getDimensionFilterError = (state: RootState) => state.dimensionFilter.error;

export const {
  fetchDimensionFilterRequest,
  fetchDimensionFilterFailure,
  fetchDimensionFilterSuccess,
  priorityFilterChanged,
} = slice.actions;

export default slice.reducer;
