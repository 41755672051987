import * as T from '@aily/graphql-sdk/schema';
import { Typography } from '@mui/material';
import React from 'react';

export interface TableCellCategoryProps {
  text: T.TextResult;
}

export const TableCellCategory: React.FC<TableCellCategoryProps> = ({ text }) => (
  <Typography variant="h9" color="text.secondary" data-testid="CategoryName">
    {text.value}
  </Typography>
);

export default TableCellCategory;
