import { Components } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';

import { CircularProgress } from './CircularProgress';
import { ModuleLogo } from './ModuleLogo';
// MUI Components
import { MuiAlert } from './MuiAlert';
import { MuiAvatar } from './MuiAvatar';
import { MuiButton } from './MuiButton';
import { MuiButtonBase } from './MuiButtonBase';
import { MuiCard } from './MuiCard';
import { MuiChip } from './MuiChip';
import { MuiContainer } from './MuiContainer';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiDialogContentText } from './MuiDialogContentText';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiDivider } from './MuiDivider';
import { MuiIconButton } from './MuiIconButton';
import { MuiMenu } from './MuiMenu';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiPaper } from './MuiPaper';
import { MuiPopover } from './MuiPopover';
import { MuiSelect } from './MuiSelect';
import { MuiSkeleton } from './MuiSkeleton';
import { MuiTab } from './MuiTab';
import { MuiTable } from './MuiTable';
import { MuiTableCell } from './MuiTableCell';
import { MuiTabs } from './MuiTabs';
import { MuiToolbar } from './MuiToolbar';
import { MuiTooltip } from './MuiTooltip';
import { RecommenderItem } from './RecommenderItem';

export const components: Components<Omit<Theme, 'components'>> = {
  CircularProgress,
  ModuleLogo,
  RecommenderItem,
  // MUI Components
  MuiAlert,
  MuiAvatar,
  MuiButton,
  MuiButtonBase,
  MuiCard,
  MuiChip,
  MuiContainer,
  MuiCssBaseline,
  MuiDialogContentText,
  MuiDialogTitle,
  MuiDivider,
  MuiIconButton,
  MuiMenu,
  MuiMenuItem,
  MuiPaper,
  MuiPopover,
  MuiSelect,
  MuiSkeleton,
  MuiTab,
  MuiTable,
  MuiTableCell,
  MuiTabs,
  MuiToolbar,
  MuiTooltip,
};
