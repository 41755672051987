import { Theme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import themes, { ThemeName } from '../theme';

type ThemeContextState = {
  theme: Theme;
  themeName: ThemeName;
  setThemeName: (newThemeName: ThemeName) => void;
  availableThemeNames: ThemeName[];
};

// Function to validate the theme name
function getValidThemeName(themeName: string): ThemeName {
  if (themeName in themes) {
    return themeName as ThemeName;
  }

  return 'default';
}

// Function to get the default theme name
export function getDefaultThemeName(): ThemeName {
  return 'default';
}

// Function to set the default theme name
function setDefaultThemeName(themeName: ThemeName) {
  localStorage.setItem('aily.defaultTheme', themeName);
}

// Updates the document element style
function updateDocumentElementStyle(themeName: ThemeName) {
  if (themes[themeName]?.palette?.background?.default) {
    document.documentElement.style.setProperty(
      '--background-color',
      themes[themeName].palette.background.default,
    );
  }
}

const availableThemeNames: ThemeName[] = Object.keys(themes) as ThemeName[];

const ThemeContext = createContext<ThemeContextState | undefined>(undefined);

export const ThemeProvider = ({
  children,
  themeName,
}: {
  children: React.ReactNode;
  themeName?: ThemeName;
}) => {
  const [localThemeName, setLocalThemeName] = useState<ThemeName>(getDefaultThemeName());
  const currentThemeName = themeName ?? localThemeName;
  const currentTheme = themes[currentThemeName];

  const handleSetThemeName = useCallback((newThemeName: ThemeName) => {
    const validThemeName = getValidThemeName(newThemeName);
    setLocalThemeName(validThemeName);
    setDefaultThemeName(validThemeName);
    updateDocumentElementStyle(validThemeName);
  }, []);

  const contextValue = useMemo(
    () => ({
      theme: currentTheme,
      themeName: currentThemeName,
      setThemeName: handleSetThemeName,
      availableThemeNames,
    }),
    [currentTheme, currentThemeName, handleSetThemeName, availableThemeNames],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};
