import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const HomeActive: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      d="M4.57141 26.0062V13.894C4.57141 12.3959 5.38015 11.0146 6.68651 10.2813L15.0242 5.60147C16.2836 4.89456 17.8203 4.89456 19.0797 5.60147L27.4173 10.2813C28.7237 11.0146 29.5324 12.3959 29.5324 13.894V26.0062C29.5324 28.2942 27.6776 30.149 25.3896 30.149H22.0814C21.5094 30.149 21.0457 29.6853 21.0457 29.1133V26.888C21.0457 24.8201 19.3694 23.1438 17.3015 23.1438C15.2337 23.1438 13.5574 24.8201 13.5574 26.888V29.1133C13.5574 29.6853 13.0937 30.149 12.5217 30.149H8.71427C6.42623 30.149 4.57141 28.2942 4.57141 26.0062Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2808 3.7079C15.9697 2.76403 18.0303 2.76403 19.7192 3.7079L28.6637 8.70679C30.4155 9.68583 31.5 11.5303 31.5 13.5305V26.4684C31.5 29.5234 29.0127 32 25.9445 32H22.3955C21.1683 32 20.1734 31.0094 20.1734 29.7874V27.4103C20.1734 25.8125 18.8725 24.5172 17.2678 24.5172C15.6631 24.5172 14.3622 25.8125 14.3622 27.4103V29.7874C14.3622 31.0094 13.3673 32 12.14 32H8.05549C4.98728 32 2.5 29.5234 2.5 26.4684V13.5305C2.5 11.5303 3.58451 9.68583 5.3363 8.70679L14.2808 3.7079ZM18.6315 5.63739C17.6182 5.07106 16.3818 5.07106 15.3685 5.63739L6.42398 10.6363C5.3729 11.2237 4.7222 12.3304 4.7222 13.5305V26.4684C4.7222 28.3014 6.21456 29.7874 8.05549 29.7874H12.14V27.4103C12.14 24.5905 14.4358 22.3046 17.2678 22.3046C20.0998 22.3046 22.3955 24.5905 22.3955 27.4103V29.7874H25.9445C27.7854 29.7874 29.2778 28.3014 29.2778 26.4684V13.5305C29.2778 12.3304 28.6271 11.2237 27.576 10.6363L18.6315 5.63739Z"
      fill="url(#paint0_linear_20329_154014)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_20329_154014"
        x1="2.5"
        y1="17.5"
        x2="31.5"
        y2="17.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31D4B6" />
        <stop offset="1" stopColor="#48FF9B" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
