import { StoryDecision } from '@aily/graphql-sdk/schema';
import { Box, Stack } from '@mui/material';
import { filter, isEqual, isNil } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';

import { Slideshow, SlideshowProvider, useSlideshow } from '../../../../components/Slideshow';
import { useDeepCompareMemoize } from '../../../../hooks';
import { AilyAgentOperation } from '../../classes';
import { useAilyAgent } from '../../providers';
import { AgentJsonData, Screen, ScreenType } from '../../types/agentJsonData';
import { getAgentAudioPath } from '../../utils/getAgentAudioPath';
import { AgentHeader } from '../AgentScreenItems/AgentHeader';
import { AgentSlideshow, AgentSlideshowProps } from './AgentSlideshow';
import { AgentSlideshowSlideSectionBuilder } from './AgentSlideshowSlideSectionBuilder';

export const AgentSlideshowSlideContent = ({
  screens,
  onAfterScreen,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
}: {
  screens: Screen[];
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}) => {
  const { startOperations, exit } = useAilyAgent();
  const refs = useRef(new Array(screens.length).fill(null));
  const [refsReady, setRefsReady] = useState(false);
  const memoizedScreens = useDeepCompareMemoize(screens);

  const { state } = useSlideshow();
  const { audioEnabled } = state;

  // Function to handle setting of refs
  const setRef = (node: HTMLElement | null, index: number) => {
    refs.current[index] = node;
    // Check if all refs are set
    if (refs.current.every((ref) => ref !== null) && !refsReady) {
      setRefsReady(true); // Set flag to prevent re-triggering
    }
  };

  useEffect(() => {
    if (!refsReady || !audioEnabled) {
      return;
    }

    const operations = refs.current.reduce((acc, ref, index) => {
      const screen = memoizedScreens[index];
      if (screen.audio_track) {
        acc.push({
          audioPath: getAgentAudioPath(baseAudioURL, screen.audio_track),
          elementRef: ref,
          delayAfterEnd: 1000,
          corner: 'top-right',
        });
      }

      return acc;
    }, [] as AilyAgentOperation[]);

    if (operations.length) {
      startOperations(operations);
    }

    return () => {
      exit();
    };
  }, [refsReady, memoizedScreens, audioEnabled, exit, startOperations, baseAudioURL]);

  return (
    <Stack direction="row" spacing={3} sx={{ width: '100%', maxHeight: '620px' }}>
      {memoizedScreens.map((screen, index) => (
        <AgentSlideshowSlideSectionBuilder
          key={index}
          screen={screen}
          titleRef={(node) => setRef(node, index)}
          onAfterScreen={onAfterScreen}
          StackProps={{ paddingLeft: index === 0 ? 7 : undefined }}
          baseAudioURL={baseAudioURL}
          agentJsonData={agentJsonData}
          decision={decision}
          onAgentClose={onAgentClose}
          onBackdropClose={onBackdropClose}
        />
      ))}
    </Stack>
  );
};

const extractVisibleScreens = (decision: StoryDecision | null, screens: Screen[]) => {
  // if the decision isn't taken, return only is_default screens, otherwise return screens that belong to the decision
  if (isNil(decision)) {
    return filter(screens, 'is_default');
  }

  return screens.reduce(
    (carry, { is_default, screen_id, screen_type, popup, optimisation_levers, ...screen }) => {
      const isScreenIncludedInScenario = decision.screenIds.includes(screen_id);
      const isScreenWithPopup = !!popup;
      const isLeversScreen = screen_type === ScreenType.Levers;

      return [
        ...carry,
        ...(isScreenIncludedInScenario ? [{ screen_id, screen_type, is_default, ...screen }] : []),
        ...(isScreenWithPopup
          ? popup.screens.reduce(
              (carry, { screen_id, screen_type, ...screen }) => [
                ...carry,
                ...(decision.screenIds.includes(screen_id)
                  ? [{ screen_id, screen_type, ...screen }]
                  : []),
              ],
              [] as Screen[],
            )
          : []),
        ...(isLeversScreen
          ? (optimisation_levers || []).reduce(
              (carry, { screen_dict }) => [
                ...carry,
                ...Object.keys(screen_dict).reduce(
                  (carry, key) => [
                    ...carry,
                    ...screen_dict[key].screens.reduce(
                      (carry, { screen_id, ...screen }) => [
                        ...carry,
                        ...(decision.screenIds.includes(screen_id)
                          ? [{ screen_id, ...screen }]
                          : []),
                      ],
                      [] as Screen[],
                    ),
                  ],
                  [] as Screen[],
                ),
              ],
              [] as Screen[],
            )
          : []),
      ];
    },
    [] as Screen[],
  );
};

export interface AgentSlideshowBuilderProps extends Omit<AgentSlideshowProps, 'children'> {
  agentJsonData: AgentJsonData;
  onAfterScreen?: (screen: Screen) => React.ReactNode;
  baseAudioURL: string;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
}

export const AgentSlideshowBuilder: React.FC<AgentSlideshowBuilderProps> = React.memo(
  ({
    agentJsonData,
    onAfterScreen,
    baseAudioURL,
    decision,
    onAgentClose,
    onBackdropClose,
    ...rest
  }) => {
    const screens = extractVisibleScreens(decision, agentJsonData.screens);
    const screensPerSlide = 3;
    const totalSlides = Math.ceil(screens.length / screensPerSlide);

    return (
      <SlideshowProvider>
        <Box position="relative">
          <AgentSlideshow {...rest}>
            <Slideshow.Screen
              index={0}
              header={
                <AgentHeader
                  title={agentJsonData.headline}
                  subTitle={agentJsonData.sub_headline ? String(agentJsonData.sub_headline) : ''}
                />
              }
            >
              <Slideshow.SlideGroup index={0}>
                {Array.from({ length: totalSlides }, (_, slideIndex) => {
                  const start = slideIndex * screensPerSlide;
                  const end = start + screensPerSlide;
                  const currentScreens = screens.slice(start, end);

                  return (
                    <Slideshow.Slide key={`slide-${slideIndex}`} index={slideIndex}>
                      <AgentSlideshowSlideContent
                        screens={currentScreens}
                        onAfterScreen={onAfterScreen}
                        baseAudioURL={baseAudioURL}
                        agentJsonData={agentJsonData}
                        decision={decision}
                        onAgentClose={onAgentClose}
                        onBackdropClose={onBackdropClose}
                      />
                    </Slideshow.Slide>
                  );
                })}
              </Slideshow.SlideGroup>
            </Slideshow.Screen>
          </AgentSlideshow>
          <Slideshow.PrevButton />
          <Slideshow.NextButton />
        </Box>
      </SlideshowProvider>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
