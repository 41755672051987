import { styled, Typography } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getModulesError } from '@/store/modules/slices';

import { Logo } from './Logo';

interface Props extends MotionProps {
  error?: Error;
}

const MainWrapper = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex['modal'],
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
}));

const LogoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // Shift it up a little for better visibility
  paddingBottom: 60,
}));

const LaunchScreen: React.FC<Props> = ({ error, ...rest }) => {
  const modulesError = useSelector(getModulesError);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Loading');

  useEffect(() => {
    if (error || modulesError) {
      setLoading(false);
      setMessage('Error: Could not load data');
    }
  }, [error, modulesError]);

  return (
    <MainWrapper {...rest}>
      <LogoWrapper>
        <Logo loading={loading} />
        <Typography variant="subtitle">{message}</Typography>
      </LogoWrapper>
    </MainWrapper>
  );
};

export default LaunchScreen;
