/**
 * Maps the selected value to a 2-value pair representing the positions of the slider handles.
 *
 * @param {number} value - The selected value.
 * @param {number} baseValue - The fixed base value of the slider.
 * @returns {[number, number]}
 */
export function mapValueToSliderValue(value: number, baseValue: number): [number, number] {
  if (value < baseValue) {
    return [value, baseValue];
  }

  if (value > baseValue) {
    return [baseValue, value];
  }

  return [baseValue, baseValue];
}
