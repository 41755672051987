import * as T from '@aily/graphql-sdk/schema';
import { isNil, unionBy } from 'lodash-es';
import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export interface SyncedFilterMapping {
  filterCode: string;
  componentCodes: string[];
  value?: number;
}

interface SyncedFiltersContextState {
  syncFilterComponents: (filterComponents: T.FilterComponent[]) => T.FilterComponent[];
  updateMappingValue: (value: number, filterCode: string, componentCode: string) => void;
  clearMappingValues: (filterCode?: string) => void;
}

interface SyncedFiltersProviderProps {
  children: ReactNode;
  mappings: SyncedFilterMapping[];
}

const SyncedFiltersContext = createContext<SyncedFiltersContextState | undefined>(undefined);

export const SyncedFiltersProvider: React.FC<SyncedFiltersProviderProps> = ({
  children,
  mappings: defaultMappings,
}) => {
  const [mappings, setMappings] = useState(defaultMappings);

  const syncFilterComponents = useCallback(
    (filterComponents: T.FilterComponent[]) => {
      return filterComponents.reduce(
        (acc: T.FilterComponent[], filterComponent: T.FilterComponent) => {
          // Find the mapping for this filter component
          const mapping = mappings.find(({ filterCode }) => filterCode === filterComponent.code);

          // If the mapping is found and the value is set, set it as the default for the filter component
          if (mapping && !isNil(mapping.value)) {
            acc.push({ ...filterComponent, defaultValue: mapping.value });
          } else {
            acc.push(filterComponent);
          }

          return acc;
        },
        [],
      );
    },
    [mappings],
  );

  const updateMappingValue = useCallback(
    (value: number, filterCode: string, componentCode: string) => {
      // Find the mapping for this filter and component code
      const mapping = mappings.find(
        (mapping) =>
          mapping.filterCode === filterCode && mapping.componentCodes.includes(componentCode),
      );

      // If the mapping is found update its value
      if (mapping) {
        setMappings((prevState) => unionBy([{ ...mapping, value }], prevState, 'filterCode'));
      }
    },
    [mappings],
  );

  const clearMappingValues = useCallback(
    (filterCode?: string) => {
      if (filterCode) {
        const mapping = mappings.find((mapping) => mapping.filterCode === filterCode);
        if (mapping) {
          setMappings((prevState) =>
            unionBy([{ ...mapping, value: undefined }], prevState, 'filterCode'),
          );
        }
      } else {
        setMappings((prevState) => prevState.map((mapping) => ({ ...mapping, value: undefined })));
      }
    },
    [mappings],
  );

  const value = useMemo(() => {
    return {
      syncFilterComponents,
      updateMappingValue,
      clearMappingValues,
    };
  }, [syncFilterComponents, updateMappingValue, clearMappingValues]);

  return <SyncedFiltersContext.Provider value={value}>{children}</SyncedFiltersContext.Provider>;
};

export function useSyncedFilters(): SyncedFiltersContextState | undefined {
  return useContext(SyncedFiltersContext);
}
