import * as T from '@aily/graphql-sdk/schema';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useRef } from 'react';

import { NavLink } from '../NavLink';
import { OptimallyWideMultilineText } from '../OptimallyWideMultilineText';

export interface TableCellTextLinkProps {
  textLink: T.TextLinkResult;
  onClick?: (link?: T.Link | null) => void;
  noWrap?: boolean;
}

export const TableCellTextLink: React.FC<TableCellTextLinkProps> = ({
  textLink,
  onClick,
  noWrap,
}) => {
  const { value, linkResult, linkDirection, subLabels } = textLink;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    onClick?.(linkResult);
  }, [onClick, linkResult]);

  switch (linkDirection) {
    case T.LinkDirection.Forward:
      return (
        <div ref={containerRef}>
          <NavLink forward onClick={handleClick} data-testid="TextLink">
            <Box display="flex" flexDirection="column">
              <OptimallyWideMultilineText containerRef={containerRef}>
                <Typography variant="inherit" component="span" noWrap={noWrap}>
                  {value}
                </Typography>
              </OptimallyWideMultilineText>
              {subLabels && (
                <Typography variant="small" component="span" noWrap={noWrap} color="text.secondary">
                  {subLabels[0]}
                </Typography>
              )}
            </Box>
          </NavLink>
        </div>
      );
    case T.LinkDirection.Back:
      return (
        <NavLink back onClick={handleClick} data-testid="TextLink">
          {value}
        </NavLink>
      );
  }

  return (
    <NavLink onClick={handleClick} data-testid="TextLink">
      {value}
    </NavLink>
  );
};
