import { useCallback, useEffect, useState } from 'react';

/**
 * Represents a collection of toggle items grouped by keys.
 */
export interface ToggleItems {
  [key: string]: ToggleItem[];
}

/**
 * Represents a single toggle item with a unique identifier and an optional default state.
 */
export interface ToggleItem {
  /**
   * A unique identifier for the toggle item.
   */
  id: string;

  /**
   * An optional field that, when true, marks the item as the default toggled item in its group.
   */
  isDefault?: boolean;
}

/**
 * Represents the indices of toggled items in each group.
 */
export interface ToggleIndices {
  [key: string]: number;
}

/**
 * Calculates default toggled item indices for each group.
 * @param {ToggleItems} items - The toggle items grouped.
 * @returns {ToggleIndices} - The default toggle indices for each group.
 */
export function getDefaultToggleIndices(items: ToggleItems): ToggleIndices {
  const indices: ToggleIndices = {};

  for (const groupId in items) {
    const defaultItemIndex = items[groupId].findIndex(({ isDefault }) => isDefault);
    indices[groupId] = defaultItemIndex !== -1 ? defaultItemIndex : 0;
  }

  return indices;
}

/**
 * Gets the index of the next item to be toggled in a group.
 * @param {number} currentIndex - The index of the current toggled item.
 * @param {ToggleItem[]} items - The items in a group.
 * @returns {number} - The index of the next item to toggle.
 */
export function getNextItemIndex(currentIndex: number, items: ToggleItem[]): number {
  return (currentIndex + 1) % items.length;
}

/**
 * A hook that manages the toggle state of items in various groups.
 */
export function useToggleState(items: ToggleItems) {
  const [toggleIndices, setToggleIndices] = useState<ToggleIndices>(getDefaultToggleIndices(items));

  useEffect(() => {
    setToggleIndices(getDefaultToggleIndices(items));
  }, [items]);

  /**
   * Toggles to the next item in a specified group.
   * @param {string} groupId - The id of the group to toggle items in.
   * @returns {string | undefined} - The id of the toggled item.
   */
  const toggleNextItem = useCallback(
    (groupId: string): string | undefined => {
      let toggledItemId: string | undefined;

      setToggleIndices((prevIndices) => {
        const groupItems = items[groupId];
        if (!groupItems) {
          return prevIndices;
        }

        const currentIndex = prevIndices[groupId] ?? 0;
        const nextIndex = getNextItemIndex(currentIndex, groupItems);

        toggledItemId = groupItems[nextIndex].id;

        return {
          ...prevIndices,
          [groupId]: nextIndex,
        };
      });

      return toggledItemId;
    },
    [items],
  );

  /**
   * Checks if a group has more than one item to toggle between.
   * @param {string} groupId - The id of the group to check.
   * @returns {boolean} - Whether the group has more than one toggleable item.
   */
  const isToggleable = useCallback(
    (groupId: string): boolean => {
      const groupItems = items[groupId];
      return groupItems && groupItems.length > 1;
    },
    [items],
  );

  /**
   * Checks if an item is currently toggled in a group.
   * @param {string} groupId - The id of the group.
   * @param {string} itemId - The id of the item to check.
   * @returns {boolean} - Whether the specified item is currently toggled in the group.
   */
  const isToggled = useCallback(
    (groupId: string, itemId: string): boolean => {
      const groupItems = items[groupId];
      if (!groupItems) {
        return false;
      }

      const currentIndex = toggleIndices[groupId];
      const currentItem = groupItems[currentIndex];
      const defaultItem = groupItems.find(({ isDefault }) => isDefault);

      if (!currentItem) {
        return defaultItem?.id === itemId;
      }

      return currentItem?.id === itemId;
    },
    [toggleIndices, items],
  );

  /**
   * Checks if an item should be visible based on its toggle state.
   * @param {string} groupId - The id of the group.
   * @param {string} itemId - The id of the item to check.
   * @returns {boolean} - Whether the item should be visible.
   */
  const isVisible = useCallback(
    (groupId: string, itemId: string): boolean =>
      !isToggleable(groupId) || isToggled(groupId, itemId),
    [isToggleable, isToggled],
  );

  return { toggleIndices, toggleNextItem, isToggleable, isToggled, isVisible } as const;
}
