import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Alert,
  Box,
  BoxProps,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { useFilterDeep } from '../../hooks';
import { lineClamp } from '../../theme/utils';
import { findDeep } from '../../utils';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:hover, &.Mui-selected': {
    backgroundColor: theme.palette.background.paper,
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
  '& .MuiListItemSecondaryAction-root': {
    position: 'relative',
    top: 'auto',
    right: 'auto',
    transform: 'none',
  },
  '& .MuiListItemButton-root': {
    '&:hover, &.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
  '& .MuiIconButton-root': {
    width: 70,
    height: 33,
    marginRight: 0,
    borderRadius: 0,
    color: 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
  '& mark': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: 0,
  },
  '& .MuiListItemText-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiTypography-root, & .MuiTypography-root + .MuiTypography-root': {
      ...lineClamp(1),
    },
    '& .MuiTypography-root:only-child': {
      ...lineClamp(2),
      lineHeight: 16 / 15,
    },
  },
}));

interface Props<T> extends Omit<BoxProps, 'onSelect'> {
  containerMaxHeight: number;
  itemHeight: number;
  items: T[];
  currentItem?: T;
  itemKey: (item: T) => string;
  itemLabel: (item: T) => string;
  itemSubLabel?: (item: T) => string;
  itemChildren: (item: T) => T[];
  selectable?: (item: T) => boolean;
  search?: string;
  onSelect?: (item: T) => void;
  secondaryAction?: (item: T) => React.ReactNode;
}

const NestedList = <T extends Record<string, unknown>>({
  containerMaxHeight,
  itemHeight,
  items,
  currentItem,
  itemKey,
  itemLabel,
  itemSubLabel,
  itemChildren,
  selectable,
  search,
  onSelect,
  secondaryAction,
  ...rest
}: PropsWithChildren<Props<T>>) => {
  const getParentItem = useCallback(
    (item: T) => {
      const foundItem = findDeep(items, (i: T) => itemKey(i) === itemKey(item));

      return !(foundItem?.parent as unknown as T[])?.length ? foundItem?.parent : undefined;
    },
    [itemKey, items],
  );

  const [parentItem, setParentItem] = useState<T | undefined>(
    currentItem ? getParentItem(currentItem) : undefined,
  );

  useEffect(() => {
    setParentItem(currentItem ? getParentItem(currentItem) : undefined);
  }, [currentItem, getParentItem]);

  const filterItem = useCallback(
    (s: string) => (item: T) =>
      itemLabel(item).toLowerCase().includes(s.toLowerCase()) && (!selectable || selectable(item)),
    [itemLabel, selectable],
  );

  const sortItems = useCallback(
    (a: T, b: T) => {
      return itemLabel(a).localeCompare(itemLabel(b));
    },
    [itemLabel],
  );

  const filterDeep = useFilterDeep({ nodes: items, getChildren: itemChildren });

  const currentItems = useMemo(() => {
    if (search) {
      const filteredItems = filterDeep(filterItem(search));
      return filteredItems?.map((searchItem) => searchItem?.node).sort(sortItems);
    }

    if (parentItem) {
      return [parentItem, ...itemChildren(parentItem)];
    }

    return items;
  }, [items, search, parentItem, filterDeep, filterItem, itemChildren, sortItems]);

  const handleDrillDown = useCallback(
    (item: T) => () => {
      setParentItem(item);
    },
    [],
  );

  const handleDrillUp = useCallback(
    (item: T) => () => {
      setParentItem(getParentItem(item));
    },
    [getParentItem],
  );

  const handleSelect = useCallback(
    (item: T) => {
      if (onSelect) {
        onSelect(item);
      }
    },
    [onSelect],
  );

  const handleListItemClick = useCallback(
    (item: T) => {
      if (item === parentItem) {
        handleDrillUp(item)();
      } else if (selectable?.(item)) {
        handleSelect(item);
      } else if (itemChildren(item).length) {
        handleDrillDown(item)();
      }
    },
    [selectable, handleSelect, parentItem, itemChildren, handleDrillDown, handleDrillDown],
  );

  const getListItemAction = useCallback(
    (item: T) => {
      if (item !== parentItem && itemChildren(item).length) {
        return (
          <IconButton edge="end" onClick={handleDrillDown(item)}>
            <ChevronRight />
          </IconButton>
        );
      }

      return null;
    },
    [parentItem, itemChildren, handleDrillDown],
  );

  const renderListItem = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      const item = currentItems[index];
      const subLabel = itemSubLabel ? itemSubLabel(item) : '';

      return (
        <StyledListItem
          style={style}
          key={itemKey(item)}
          disablePadding
          secondaryAction={secondaryAction ? secondaryAction(item) : getListItemAction(item)}
          data-testid="ListItem"
        >
          <ListItemButton
            selected={currentItem ? itemKey(item) === itemKey(currentItem) : false}
            onClick={() => handleListItemClick(item)}
            data-testid="ListItemButton"
          >
            {item === parentItem && <ChevronLeft />}
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body">
                  {search ? (
                    <Highlighter
                      searchWords={[search]}
                      textToHighlight={itemLabel(item)}
                      autoEscape
                    />
                  ) : (
                    itemLabel(item)
                  )}
                </Typography>
              }
              secondary={
                !!subLabel && (
                  <Typography variant="small" color="text.secondary">
                    {subLabel}
                  </Typography>
                )
              }
              data-testid="ListItemText"
            />
          </ListItemButton>
        </StyledListItem>
      );
    },
    [
      currentItems,
      currentItem,
      parentItem,
      search,
      handleListItemClick,
      secondaryAction,
      itemSubLabel,
      getListItemAction,
      itemKey,
      itemLabel,
    ],
  );

  const itemCount = useMemo(() => currentItems.length, [currentItems]);

  return search && !currentItems?.length ? (
    <Alert variant="outlined" severity="info">
      Nothing found
    </Alert>
  ) : (
    <Box data-testid="List" {...rest}>
      <FixedSizeList<T>
        height={Math.min(containerMaxHeight, itemCount * itemHeight)}
        width="100%"
        itemSize={itemHeight}
        itemCount={itemCount}
        overscanCount={10}
      >
        {renderListItem}
      </FixedSizeList>
    </Box>
  );
};

export default NestedList;
