import { Components, Theme } from '@mui/material';

import { colors } from '../colors';
import { typography } from '../typography';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: (theme) => ({
    html: {
      scrollBehavior: 'smooth',
    },
    h1: { ...typography.h1 },
    h2: { ...typography.h2 },
    h3: { ...typography.h3 },
    h4: { ...typography.h4 },
    h5: { ...typography.h5 },
    h6: { ...typography.h6 },
    body: {
      overflowY: 'scroll',
      ...typography.body,
    },
    p: { ...typography.body },
    small: { ...typography.small },
    a: { color: colors.primary.aqua },
    'ol, ul': { paddingLeft: 16 },
    '*': {
      scrollbarColor: `${colors.neutrals.grey4} ${theme.palette.divider}`,
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: 12,
      height: 12,
      backgroundColor: theme.palette.divider,
    },
    '*::-webkit-scrollbar-corner': {
      backgroundColor: colors.neutrals.grey4,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: colors.neutrals.grey4,
      borderRadius: 6,
      border: `2px solid ${theme.palette.divider}`,
    },
    // The OIDC client uses iframes for silent checking of the user session, which creates a scroll bar on the `body` element, this fixes it
    'iframe[width="0"][height="0"]': {
      display: 'none',
    },
    '.grabbing *': {
      cursor: 'grabbing',
    },
    '*::selection': {
      backgroundColor: colors.primary.aqua,
      color: colors.neutrals.white,
      WebkitTextFillColor: colors.neutrals.white,
    },
    mark: {
      backgroundColor: colors.primary.aqua,
      color: colors.neutrals.black,
    },
  }),
};
