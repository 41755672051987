import * as T from '@aily/graphql-sdk/schema';
import { Box, BoxProps, Link, LinkProps, Stack, StackProps, styled } from '@mui/material';
import React from 'react';

import { OpenInNew } from '../../icons';
import { ellipsis } from '../../theme/utils';

export interface StoryMetaBarProps extends StackProps {
  onReferenceClick?: (story: T.Story, reference: T.ExternalLink) => void;
}

export const Dot = () => (
  <svg width="3" height="3" viewBox="0 0 3 3">
    <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor" />
  </svg>
);

export const Divider = (props: BoxProps) => (
  <Box {...props}>
    <Dot />
  </Box>
);

export const OpenInNewLink = ({ children, ...rest }: LinkProps) => (
  <Link
    variant="small"
    color="inherit"
    underline="none"
    href="#"
    target="_blank"
    sx={{ '&:hover': { color: 'text.primary' } }}
    {...rest}
  >
    <OpenInNew sx={{ fontSize: 15, mr: 0.5, verticalAlign: 'text-bottom' }} />
    {children}
  </Link>
);

const StyledStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  userSelect: 'none',
  '& .MuiTypography-root': { ...ellipsis() },
}));

export const StoryMetaBar: React.FC<StoryMetaBarProps> = ({ children, ...rest }) => (
  <StyledStack
    direction="row"
    alignItems="center"
    divider={<Divider sx={{ display: 'inline-flex', px: 1 }} />}
    data-testid="StoryMetaBar"
    {...rest}
  >
    {children}
  </StyledStack>
);
