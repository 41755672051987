import { Components, Theme } from '@mui/material/styles';

export const RecommenderItem: Components<Theme>['RecommenderItem'] = {
  defaultProps: {
    titleTypography: { xs: 'bodyBoldTight', xl: 'h9', xxl: 'h7' },
  },
  styleOverrides: {
    root: {},
  },
};
