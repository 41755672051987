import * as T from '@aily/graphql-sdk/schema';
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  styled,
  SvgIcon,
  Toolbar,
  Typography,
} from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { useCallback, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

import { useOverflow } from '../../hooks';
import PlailistList from './PlailistList';
import { getPlailistGradient } from './utils';

interface Props extends BoxProps {
  plailistCategory: T.PlailistCategory;
  onItemClick?: (plailist: T.Plailist) => void;
}

export const ArrowRight: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="22" height="8" viewBox="0 0 22 8" {...props}>
    <path
      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

const SeeMoreButton = styled(Button)(({ theme }) => ({
  ...theme.typography.small,
  color: theme.palette.text.secondary,
  textTransform: 'none',
  '&:hover': {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
  },
}));

const PlailistCategory: React.FC<Props> = ({ plailistCategory, onItemClick, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { y: overflowY } = useOverflow(ref);
  const [expanded, setExpanded] = useState(false);
  const prevExpanded = usePrevious(expanded);

  const handleItemClick = useCallback(
    (plailist: T.Plailist) => () => {
      onItemClick?.(plailist);
    },
    [onItemClick],
  );

  return (
    <Box {...rest}>
      <Toolbar>
        <Typography variant="h9" sx={{ mr: 'auto' }}>
          {plailistCategory.name}
        </Typography>
        {(!!overflowY || expanded || prevExpanded) && (
          <SeeMoreButton variant="text" onClick={() => setExpanded(!expanded)} sx={{ mr: -1 }}>
            {expanded ? 'See less' : 'See all'}
          </SeeMoreButton>
        )}
      </Toolbar>
      {!!plailistCategory.plailists?.length && (
        <Collapse ref={ref} in={expanded} timeout="auto" collapsedSize={113}>
          <PlailistList>
            {((plailistCategory.plailists ?? []) as T.Plailist[])?.map((plailist) => (
              <PlailistList.Item
                key={plailist?.plailistId}
                title={plailist?.title ?? 'Plailist'}
                onClick={handleItemClick(plailist)}
                BackgroundBoxProps={{ sx: { background: getPlailistGradient(plailist) } }}
              />
            ))}
          </PlailistList>
        </Collapse>
      )}
    </Box>
  );
};

export default PlailistCategory;
