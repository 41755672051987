import { Box, BoxProps } from '@mui/material';
import React from 'react';

export interface InterceptActionsProps extends BoxProps {}

export const InterceptActions: React.FC<InterceptActionsProps> = ({ children, ...rest }) => (
  <Box data-testid="InterceptActions" {...rest}>
    {children}
  </Box>
);
