import * as T from '@aily/graphql-sdk/schema';
import { DisplayMode } from '@aily/graphql-sdk/schema';
import React from 'react';

import { SelectFilter, SelectFilterProps } from './SelectFilter';
import SelectFilterRadioGroup, { SelectFilterRadioGroupProps } from './SelectFilterRadioGroup';
import SliderSelectFilter, { SliderSelectFilterProps } from './SliderSelectFilter';

export interface Props {
  displayMode?: DisplayMode;
  SelectFilterProps?: SelectFilterProps;
  SelectFilterRadioGroupProps?: SelectFilterRadioGroupProps;
  SliderSelectFilterProps?: SliderSelectFilterProps;
}

export const SelectFilterDisplayMode: React.FC<Props> = ({
  displayMode,
  SelectFilterProps,
  SelectFilterRadioGroupProps,
  SliderSelectFilterProps,
}) => {
  switch (displayMode) {
    case T.DisplayMode.Inline:
      return <SelectFilterRadioGroup {...SelectFilterRadioGroupProps} />;

    case T.DisplayMode.Default:
      return <SelectFilter {...SelectFilterProps} />;

    case T.DisplayMode.Slider:
      return <SliderSelectFilter {...SliderSelectFilterProps} />;

    default:
      return null;
  }
};
