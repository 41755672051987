import { formatTestIdString } from '@aily/saas-core';
import { HStack } from '@aily-labs/ui';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { colors } from '../../../../theme/default/colors';

interface LogosListProps {
  logoList: string[];
}

const LogosList: React.FC<LogosListProps> = ({ logoList }) => {
  const renderLogoText = (logo: string) => {
    if (logo === 'supply') {
      return 'sup';
    }

    return logo;
  };

  return (
    <HStack justifyContent="center" alignContent="center" width="100%" style={{ marginTop: -5 }}>
      {logoList?.map((logo, index) => (
        <HStack
          testID={formatTestIdString(`test-decision-lever-logo-${logo}`)}
          flex={1}
          alignItems="center"
          justifyContent="center"
          key={index}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={40}
            height={40}
            sx={{
              backgroundColor: colors.primary.aqua,
              borderRadius: 2,
            }}
          >
            <Typography variant="moduleText" fontSize={18}>
              {renderLogoText(logo)}
            </Typography>
          </Box>
        </HStack>
      ))}
    </HStack>
  );
};

export default LogosList;
