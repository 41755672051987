import * as T from '@aily/graphql-sdk/schema';
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

interface LinkContextState {
  link: T.Link;
}

const LinkContext = createContext<LinkContextState | null>(null);

export const LinkContextProvider: React.FC<PropsWithChildren<LinkContextState>> = ({
  children,
  link,
}) => {
  const value = useMemo<LinkContextState>(() => ({ link }), [link]);
  return <LinkContext.Provider value={value}>{children}</LinkContext.Provider>;
};

export function useLinkContext() {
  return useContext(LinkContext);
}

export default LinkContext;
