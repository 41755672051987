import { Box, styled } from '@mui/material';
import {
  CarouselProvider as PureCarouselProvider,
  CarouselProviderProps,
  Slide,
  Slider,
} from 'pure-react-carousel';
import React, { useMemo } from 'react';

import CarouselButtonBack from './CarouselButtonBack';
import CarouselButtonNext from './CarouselButtonNext';
import CarouselDotNavigation from './CarouselDotNavigation';

export interface CarouselProps extends Partial<CarouselProviderProps> {}

const transition = 'all 400ms cubic-bezier(0.645, 0.045, 0.355, 1)';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: '0 auto',
  padding: theme.spacing(5),
}));

const StyledSlider = styled(Slider)(() => ({
  position: 'relative',
  outline: 'none',
  '& .carousel__slider-tray--horizontal': {
    transition,
    transitionProperty: 'transform',
    willChange: 'transform',
  },
}));

const StyledSlide = styled(Slide)(() => ({
  position: 'relative',
  float: 'left',
  opacity: 1,
  display: 'flex',
  justifyContent: 'center',
  transition,
  transitionProperty: 'opacity, transform',
  willChange: 'transform',
  '& .carousel__inner-slide': {
    transition,
    transitionProperty: 'left',
    willChange: 'left',
  },
  '&.carousel__slide--hidden': {
    opacity: 0,
  },
}));

const StyledButtonBack = styled(CarouselButtonBack)(() => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  height: '50%',
  transform: 'translateY(-50%)',
}));

const StyledButtonNext = styled(CarouselButtonNext)(() => ({
  position: 'absolute',
  top: '50%',
  right: 0,
  height: '50%',
  transform: 'translateY(-50%)',
}));

const StyledDotNavigation = styled(CarouselDotNavigation)(() => ({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
}));

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const transformedChildren = useMemo(
    () =>
      React.Children.map(children, (child, index) => (
        <StyledSlide key={index} index={index}>
          {child}
        </StyledSlide>
      )),
    [children],
  );

  return (
    <Container>
      <StyledSlider>{transformedChildren}</StyledSlider>
      <StyledDotNavigation />
      <StyledButtonBack />
      <StyledButtonNext />
    </Container>
  );
};

const CarouselProvider: React.FC<CarouselProps> = ({ children, ...rest }) => {
  return (
    <PureCarouselProvider
      totalSlides={0}
      naturalSlideWidth={0}
      naturalSlideHeight={0}
      isIntrinsicHeight
      dragEnabled={false}
      {...rest}
    >
      <Carousel>{children}</Carousel>
    </PureCarouselProvider>
  );
};

export default CarouselProvider;
