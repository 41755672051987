import { AnalyticsProviderProps } from '@aily/analytics-service';

import envConfig from '@/config/env';
import { useCurrentModule, useCurrentPage } from '@/hooks';

const AnalyticsEnvEnum = {
  dev: ['dev'],
  prod: ['prod'],
  uat: ['uat', 'pre-prod'],
  ai: ['ai'],
  qa: ['qa'],
};

const getEnvironment = (environment: string | undefined) => {
  if (!environment) return AnalyticsEnvEnum.dev.join('');

  for (const [key, values] of Object.entries(AnalyticsEnvEnum)) {
    if (values.includes(environment)) {
      return key;
    }
  }

  return AnalyticsEnvEnum.dev.join('');
};

export const analyticsConfig: AnalyticsProviderProps = {
  env: getEnvironment(envConfig.ENV),
  appVersion: envConfig.BUILD_VERSION,
  tenant: envConfig.TENANT,
  useCurrentModule: useCurrentModule,
  useCurrentPage: useCurrentPage,
  writeKey: envConfig.ANALYTICS_WRITE_KEY,
  cdnURL: envConfig.ANALYTICS_CDN_URL,
};
