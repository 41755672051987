import React from 'react';

import { AchievementBadgeBronze } from './AchievementBadgeBronze';
import { AchievementBadgeGold } from './AchievementBadgeGold';
import { AchievementBadgeSilver } from './AchievementBadgeSilver';

export enum AchievementBadgeType {
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Silver = 'SILVER',
}

export interface AchievementBadgeProps {
  width?: string | number;
  height?: string | number;
  backgroundColor?: string;
}

const componentMap: Record<AchievementBadgeType, React.ComponentType<AchievementBadgeProps>> = {
  [AchievementBadgeType.Bronze]: AchievementBadgeBronze,
  [AchievementBadgeType.Gold]: AchievementBadgeGold,
  [AchievementBadgeType.Silver]: AchievementBadgeSilver,
};

export const AchievementBadge: React.FC<AchievementBadgeProps & { type: AchievementBadgeType }> = ({
  type,
  ...rest
}) => {
  const Component = componentMap[type];
  return Component ? <Component {...rest} /> : null;
};
