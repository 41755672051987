import { signOut } from '@aily/auth-service';
import { call, put, takeLatest } from 'redux-saga/effects';

import { clearLocalUserSettings, updateLocalUserSettings } from '@/core/services/localUserSettings';
import { confirmModal } from '@/store/modals/sagas';

import config from './config';
import {
  verifyUserDisclaimerFailure,
  verifyUserDisclaimerRequest,
  verifyUserDisclaimerSuccess,
} from './slices';

// Watchers
function* onVerifyUserDisclaimerRequest() {
  yield takeLatest(verifyUserDisclaimerRequest, handleUserDisclaimerRequest);
}

function* onVerifyUserDisclaimerSuccess() {
  yield takeLatest(verifyUserDisclaimerSuccess, handleUserDisclaimerSuccess);
}

function* onVerifyUserDisclaimerFailure() {
  yield takeLatest(verifyUserDisclaimerFailure, handleUserDisclaimerFailure);
}

// Workers
function* handleUserDisclaimerRequest() {
  const { title, message } = config;
  const confirmed = (yield call(confirmModal, {
    key: 'userDisclaimer',
    title: title,
    content: message,
    okText: 'Agree',
    cancelText: 'Disagree',
  })) as ReturnType<typeof confirmModal>;

  if (confirmed) {
    yield put(verifyUserDisclaimerSuccess());
  } else {
    yield put(verifyUserDisclaimerFailure(new Error('User did not confirm the statement')));
    // Sign out the user if he did not confirm the statement
    yield call(signOut);
  }
}

function* handleUserDisclaimerSuccess() {
  yield call(updateLocalUserSettings, { userDisclaimerConfirmedOn: Date.now() });
}

function* handleUserDisclaimerFailure() {
  yield call(clearLocalUserSettings);
}

export default [
  onVerifyUserDisclaimerRequest,
  onVerifyUserDisclaimerSuccess,
  onVerifyUserDisclaimerFailure,
];
