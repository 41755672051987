import { useSelector } from 'react-redux';

import { getAppIsUpdateAvailable } from '@/store/app/slices';

/**
 * A custom hook that checks if a new version of the application is available.
 *
 * If a new version is detected, it throws an error with a specific message.
 * This error can be caught by an error boundary higher in the component tree,
 * allowing for a centralized handling of the update notification.
 */
export function useWatchForUpdates() {
  const isUpdateAvailable = useSelector(getAppIsUpdateAvailable);
  if (isUpdateAvailable) {
    throw new Error('Update available: Please refresh the page');
  }
}
