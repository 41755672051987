import * as T from '@aily/graphql-sdk/schema';
import { Typography } from '@mui/material';
import React from 'react';

import { mapSentimentToChipColor } from '../../utils';
import { Chip } from '../Chip';
import { FitText } from '../FitText';

export interface TableCellBadgeProps {
  badge: T.BadgeResult;
}

export const TableCellBadge: React.FC<TableCellBadgeProps> = ({ badge }) => {
  const { content, sentiment } = badge;

  if (!sentiment || sentiment === T.Sentiment.Neutral) {
    return (
      <Typography variant="bodyTight">
        <FitText>{content}</FitText>
      </Typography>
    );
  }

  return <Chip label={content} color={mapSentimentToChipColor(sentiment)} />;
};

export default TableCellBadge;
