import * as T from '@aily/graphql-sdk/schema';
// @ts-expect-error: IDeepEntry is not resolved
// eslint-disable-next-line import/no-unresolved
import { type IDeepEntry } from 'deepdash-es/IDeepEntry';
import { findDeep as _findDeep } from 'deepdash-es/standalone';

export function findDeep<T = unknown>(collection: readonly T[], predicate: (item: T) => boolean) {
  return _findDeep(collection, predicate, { childrenPath: ['children'] }) as
    | (Omit<IDeepEntry, 'value' | 'parent'> & { value: T; parent?: T })
    | undefined;
}

export function findPageDepthFirst(
  pages: T.Page[],
  pathname: string,
  parentPages: T.Page[] = [],
): { page?: T.Page; parentPages?: T.Page[] } {
  for (const page of pages) {
    if (page.absolutePath === pathname) {
      return { page, parentPages };
    } else if (page.children) {
      const found = findPageDepthFirst(page.children, pathname, [...parentPages, page]);
      if (found.page) {
        return found;
      }
    }
  }

  return { page: undefined, parentPages: undefined };
}

export function findPageInModules(
  modules: T.Module[],
  pathname: string,
): { page?: T.Page; parentPages?: T.Page[]; module?: T.Module } {
  for (const module of modules) {
    if (module.pages) {
      const found = findPageDepthFirst(module.pages, pathname);
      if (found.page) {
        return { ...found, module };
      }
    }
  }

  return { page: undefined, parentPages: undefined, module: undefined };
}
