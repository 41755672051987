import { Module } from '@aily/graphql-sdk/schema';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useLocalUserSettings } from '@/core/services/localUserSettings';
import { getModules } from '@/store/modules/slices';

/**
 * Custom hook to get the default module - last visited by the user or the first one that has some pages.
 */
export function useDefaultModule(): Module | undefined {
  const { settings } = useLocalUserSettings();
  const modules = useSelector(getModules);
  const defaultModuleId = settings?.defaultModuleId;
  const firstNonEmptyModule = useMemo(() => modules.find(({ pages }) => pages?.length), [modules]);

  return useMemo(
    () =>
      defaultModuleId
        ? (modules.find(({ id }) => id === defaultModuleId) ?? firstNonEmptyModule)
        : firstNonEmptyModule,
    [defaultModuleId, modules, firstNonEmptyModule],
  );
}
