import { Checkbox as MuiCheckbox, CheckboxProps, styled, SvgIcon } from '@mui/material';
import React from 'react';

const Icon = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="none" stroke="currentColor" />
  </SvgIcon>
);

const CheckedIcon = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20">
    <rect width="20" height="20" rx="10" fill="currentColor" />
    <path
      d="M13.5 7.5L8.6875 12.5L6.5 10.2273"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const IndeterminateIcon = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20">
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="9.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <line x1="5" y1="10" x2="15" y2="10" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </SvgIcon>
);

const StyledCheckbox = styled(MuiCheckbox)({
  '& > .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export const Checkbox: React.FC<CheckboxProps> = (props) => (
  <StyledCheckbox
    icon={<Icon />}
    checkedIcon={<CheckedIcon />}
    indeterminateIcon={<IndeterminateIcon />}
    {...props}
  />
);
