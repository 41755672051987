import * as T from '@aily/graphql-sdk/schema';
import { Box, Skeleton, Stack, Toolbar, Typography } from '@mui/material';
import { find } from 'lodash-es';
import React, { useCallback } from 'react';

import { useColumnToggling } from '../../hooks';
import { useModule } from '../../providers';
import { colors } from '../../theme/default/colors';
import {
  convertDateFormat,
  mapSentimentToChipColor,
  mapSentimentToColor,
  mapSentimentToTextGradient,
} from '../../utils';
import { Chip } from '../Chip';
import { KpiHeader } from '../KpiHeader';
import { LoadingBackdrop } from '../LoadingBackdrop';
import { MicroLineChart } from '../MicroLineChart';
import { useHeaderRowTransformedData } from './useHeaderRowTransformedData';

const checkFormat = (value?: T.Maybe<string>) => {
  const pattern = /^\d{4}\.\d{2}$/;
  return pattern.test(value ?? '');
};

export type AllHeaderRow = T.HeaderRow | T.GtmImpactTableHeaderDataView;

interface Props {
  dataView?: AllHeaderRow;
  variant?: T.DisplayVariant;
  loading?: boolean;
}

export const HeaderRow: React.FC<Props> = ({ dataView, variant, loading }) => {
  const [headerRow] = dataView?.rows ?? [];
  const isAlternativeVariant = variant === T.DisplayVariant.Alternative;
  const currentModuleCode = useModule()?.moduleCode ?? '';

  const { isColumnToggleable, isColumnVisible, toggleColumn } = useColumnToggling(
    dataView?.columns,
  );

  const transformedData = useHeaderRowTransformedData(dataView);

  const handleBodyCellRender = useCallback(
    (row: T.TableRowResult, column: T.TableColumnResult, columnIndex: number) => {
      const cell = row.cells?.find(({ columnDataKey }) => columnDataKey === column.dataKey);

      if (!cell?.cellContent) {
        return <React.Fragment key={columnIndex} />;
      }

      if (
        columnIndex === 0 &&
        T.isTextResult(cell.cellContent) &&
        checkFormat(cell.cellContent.value)
      ) {
        const { value } = cell.cellContent;
        return (
          <Typography variant="h7">
            {!!value && convertDateFormat(value, '/', { month: 'short', year: 'numeric' })}
          </Typography>
        );
      }

      if (T.isVarianceResult(cell.cellContent) && isColumnVisible(column)) {
        const { actual, sentiment } = cell.cellContent;
        return (
          <Stack
            onClick={isColumnToggleable(column) ? () => toggleColumn(column) : undefined}
            style={{ cursor: isColumnToggleable(column) ? 'cursor' : undefined }}
          >
            <Typography
              variant="h7"
              sx={
                sentiment
                  ? {
                      background: mapSentimentToTextGradient(sentiment, {
                        [T.Sentiment.Neutral]: colors.gradients.white,
                        [T.Sentiment.SlightlyNegative]: colors.gradients.pink,
                      }),
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }
                  : undefined
              }
            >
              {actual}
            </Typography>
          </Stack>
        );
      }

      return undefined;
    },
    [isColumnToggleable, isColumnVisible, toggleColumn],
  );

  if (loading && !dataView) {
    return (
      <Toolbar style={{ height: isAlternativeVariant ? 54 : 48 }}>
        <Skeleton variant="rectangular" style={{ width: '25%' }} />
      </Toolbar>
    );
  }

  if (isAlternativeVariant) {
    return (
      <KpiHeader
        columns={(transformedData?.columns ?? []) as T.TableColumnResult[]}
        rows={(transformedData?.rows ?? []) as T.TableRowResult[]}
        onBodyCellRender={handleBodyCellRender}
      />
    );
  }

  return (
    <Toolbar style={{ height: 48 }} data-testid="header-row">
      <Stack direction="row" alignItems="baseline" spacing={2}>
        {dataView?.columns?.map((column, index) => {
          const cell = find(headerRow?.cells, { columnDataKey: column.dataKey });

          if (!cell?.cellContent) {
            return <React.Fragment key={index} />;
          }

          const dataAttribs: Record<string, string> = {
            ['data-testid']: 'header-row-cell',
            ['data-x-column-data-key']: column.dataKey,
          };

          if (
            T.isTextResult(cell.cellContent) &&
            index == 0 &&
            currentModuleCode !== T.ModuleCode.Gtm
          ) {
            const { value } = cell.cellContent;
            return (
              <Typography key={index} variant="h9" color="text.primary" {...dataAttribs}>
                {value}
              </Typography>
            );
          }

          if (T.isTextResult(cell.cellContent)) {
            const { value } = cell.cellContent;
            return column.title ? (
              <Stack key={index} direction="row" alignItems="baseline" spacing={1} {...dataAttribs}>
                <Typography variant="h9">{value}</Typography>
                <Typography variant="small" color="text.secondary">
                  {column.title}
                </Typography>
              </Stack>
            ) : (
              <Typography key={index} variant="h9" {...dataAttribs}>
                {value}
              </Typography>
            );
          }

          if (T.isVarianceResult(cell.cellContent) && isColumnVisible(column)) {
            const { actual, sentiment } = cell.cellContent;
            return (
              <Chip
                key={index}
                label={actual}
                color={mapSentimentToChipColor(sentiment ?? T.Sentiment.Neutral)}
                annotation={column.title}
                onClick={isColumnToggleable(column) ? () => toggleColumn(column) : undefined}
                sx={{ fontWeight: 'bold' }}
                {...dataAttribs}
              />
            );
          }

          if (T.isMicroChartResult(cell.cellContent)) {
            const [width, height] = [70, 24];
            const { seriesData, trendlineData, sentiment } = cell.cellContent;

            if (!seriesData?.length) {
              return null;
            }

            return (
              <Box>
                <MicroLineChart
                  data={seriesData}
                  trendlineData={trendlineData}
                  color={mapSentimentToColor(sentiment ?? T.Sentiment.Neutral)}
                  width={width}
                  height={height}
                  animated
                />
              </Box>
            );
          }
        })}
      </Stack>
      {loading && <LoadingBackdrop CircularProgressProps={{ size: 20 }} />}
    </Toolbar>
  );
};
