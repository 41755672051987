import { styled } from '@mui/material';
import React from 'react';

import { colors } from '../../../theme/default/colors';

interface Props {
  value?: string;
}

const StyledSpan = styled('span')({
  color: colors.primary.orange,
});

/**
 * A custom Markdown component to handle rendering of an inline hashtag
 */
const Hashtag: React.FC<Props> = ({ value }) => {
  if (!value) {
    return null;
  }

  return (
    <StyledSpan className="hashtag" data-testid="hashtag">
      {value}
    </StyledSpan>
  );
};

export default Hashtag;
