import * as T from '@aily/graphql-sdk/schema';
import { Text } from '@aily-labs/ui';
import { styled, Typography } from '@mui/material';
import React, { useCallback } from 'react';

import { useColumnToggling } from '../../hooks';
import { backgroundColor, bodyTypography, color, gridColumnGap, gridRowGap } from './tokens';
import { getTextColorStyles, translateSentimentToColor } from './utils';

interface KpiHeaderProps {
  columns: T.TableColumnResult[];
  rows: T.TableRowResult[];
  onHeadCellRender?: (column: T.TableColumnResult, columnIndex: number) => React.ReactNode;
  onBodyCellRender?: (
    row: T.TableRowResult,
    column: T.TableColumnResult,
    columnIndex: number,
  ) => React.ReactNode;
}

const GridContainer = styled('div')<{
  columnCount: number;
}>(({ columnCount }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columnCount - 1}, 1fr) max-content`,
  columnGap: gridColumnGap,
  rowGap: gridRowGap,
  backgroundColor,
  color,
  userSelect: 'none',
}));

const HeadCell = styled('div')({});
const BodyCell = styled('div')({});

const KpiHeader: React.FC<KpiHeaderProps> = ({
  columns,
  rows,
  onHeadCellRender,
  onBodyCellRender,
}) => {
  const { isColumnToggleable, isColumnVisible, toggleColumn } = useColumnToggling(columns);
  const visibleColumns = columns.filter(isColumnVisible);

  const handleHeadCellRender = useCallback(
    (column: T.TableColumnResult, columnIndex: number) => {
      if (onHeadCellRender) {
        const element = onHeadCellRender(column, columnIndex);
        if (element !== undefined) {
          return element;
        }
      }

      return (
        <HeadCell key={`${column.dataKey}-${columnIndex}`}>
          <Text type="boldCondensed" color="$colorBrandAqua">
            {column.title}
          </Text>
        </HeadCell>
      );
    },
    [onHeadCellRender],
  );

  const handleBodyCellRender = useCallback(
    (row: T.TableRowResult, column: T.TableColumnResult, columnIndex: number) => {
      if (onBodyCellRender) {
        const element = onBodyCellRender(row, column, columnIndex);
        if (element !== undefined) {
          return element;
        }
      }

      const cell = row.cells?.find(({ columnDataKey }) => columnDataKey === column.dataKey);

      if (!cell?.cellContent) {
        return null;
      }

      if (T.isTextResult(cell.cellContent)) {
        return <Typography variant={bodyTypography}>{cell.cellContent.value}</Typography>;
      }

      if (T.isVarianceResult(cell.cellContent)) {
        const { sentiment, label } = cell.cellContent;
        const sentimentColor = sentiment ? translateSentimentToColor(sentiment) : undefined;
        const colorStyles = sentimentColor ? getTextColorStyles(sentimentColor) : undefined;

        return (
          <Typography variant={bodyTypography} sx={colorStyles}>
            {label}
          </Typography>
        );
      }

      return null;
    },
    [onBodyCellRender],
  );

  return (
    <GridContainer columnCount={visibleColumns.length} data-testid="kpi-header-row">
      {columns.map((column, columnIndex) => {
        const isVisible = isColumnVisible(column);
        const isToggleable = isColumnToggleable(column);
        return (
          isVisible && (
            <HeadCell
              key={`${column.dataKey}-${columnIndex}`}
              onClick={isToggleable ? () => toggleColumn(column) : undefined}
              style={{ cursor: isToggleable ? 'pointer' : undefined }}
              data-testid="kpi-head-cell"
            >
              {handleHeadCellRender?.(column, columnIndex)}
            </HeadCell>
          )
        );
      })}
      {rows.map((row, rowIndex) =>
        columns.map((column, columnIndex) => {
          const isVisible = isColumnVisible(column);
          const isToggleable = isColumnToggleable(column);
          return (
            isVisible && (
              <BodyCell
                key={`${column.dataKey}-${rowIndex}-${columnIndex}`}
                onClick={isToggleable ? () => toggleColumn(column) : undefined}
                style={{ cursor: isToggleable ? 'pointer' : undefined }}
                data-testid="kpi-body-cell"
              >
                {handleBodyCellRender?.(row, column, columnIndex)}
              </BodyCell>
            )
          );
        }),
      )}
    </GridContainer>
  );
};

export type { KpiHeaderProps };
export { KpiHeader };
