import { User } from 'oidc-client-ts';
import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

// Internal email domains (must be in lower case)
export const internalEmailDomains = ['ailylabs.com'];

export const getEmailDomain = (email: string) => email.split('@')[1]?.toLowerCase();

interface UseUserResult {
  /**
   * See [User](https://authts.github.io/oidc-client-ts/classes/User.html) for more details.
   */
  user?: User | null;
  userEmailDomain?: string;
  isInternalUser: boolean;
}

/**
 * Custom hook to get user and check if they belong to an internal domain
 */
export function useUser(): UseUserResult {
  const { user } = useAuth();

  const userEmailDomain = useMemo(
    () => (user?.profile?.email ? getEmailDomain(user.profile.email) : undefined),
    [user],
  );

  const isInternalUser = useMemo(
    () => (userEmailDomain ? internalEmailDomains.includes(userEmailDomain) : false),
    [userEmailDomain],
  );

  return useMemo(
    () => ({ user, userEmailDomain, isInternalUser }),
    [user, userEmailDomain, isInternalUser],
  );
}
