import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { useDefaultModule } from '@/hooks/useDefaultModule';

const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));

const DefaultModuleRedirect: React.FC = () => {
  const defaultModule = useDefaultModule();
  return defaultModule ? <Navigate to={`/${defaultModule.path}`} replace /> : <NotFoundPage />;
};

export default DefaultModuleRedirect;
