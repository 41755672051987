// decision levers due to their complexity and abstraction for now remain untyped
// once we know for sure the scope this component will have and it stops changing regularly, it will be efficient and a must to type it
import { StoryDecision } from '@aily/graphql-sdk/schema';
import { formatTestIdString } from '@aily/saas-core';
import { Button } from '@aily-labs/ui';
import { alpha, Box, MenuItem, Stack, styled, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DiscreteSlider } from '../../../../components/DiscreteSlider';
import { SegmentControl } from '../../../../components/SegmentControl';
import { AgentJsonData, ElementType, Screen, ScreenDictScreen } from '../../types/agentJsonData';
import { AgentContentRenderer } from '../AgentContentRenderer/AgentContentRenderer';
import { AgentDecisionLeversPopup } from '../AgentDecisionLeversPopup/AgentDecisionLeversPopup';
import { PopupProps } from '../Popup';
import AgentInfo from './AgentInfo';
import AgentTextElement from './AgentTextElement';
import { AilyRecommends } from './AilyRecommends';
import LogosList from './LogosList';

type SliderValueType = {
  [id: string]: {
    label: string;
    selectedValue: string;
  };
};

const testSliderPositions = (
  sliderCount: number,
  positionCount: number,
  fixedIndex: number,
  fixedValue: number,
  testFunction: (combinedValue: string) => string | undefined,
) => {
  // @ts-expect-error due to time constraints, this remains untyped, read comment on top
  const generate = (currentPosition, index) => {
    if (index === sliderCount) {
      if (testFunction([...currentPosition].join(','))) {
        return [...currentPosition];
      }
      return null;
    }

    if (index === fixedIndex) {
      currentPosition[index] = fixedValue;
      return generate(currentPosition, index + 1);
    } else {
      for (let i = 0; i < positionCount; i++) {
        currentPosition[index] = i;
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        const result = generate(currentPosition, index + 1);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  return generate(Array(sliderCount).fill(0), 0);
};

const PanelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  width: 300,
}));

const PanelContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  width: 241,
  height: '100%',
}));

const PanelControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  marginTop: -20,
}));

const SegmentControlPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1.25),
  borderRadius: 20,
  padding: theme.spacing(1.5),
  backgroundColor: alpha(theme.palette.grey[100], 0.5),
  marginTop: -10,
}));

type Props = {
  screen: Screen;
  baseAudioURL: string;
  agentJsonData: AgentJsonData;
  decision: StoryDecision | null;
  onAgentClose?: () => void;
  onBackdropClose?: (forceRefetch: boolean) => void;
};

export const DecisionLevers: React.FC<Props> = ({
  screen,
  baseAudioURL,
  agentJsonData,
  decision,
  onAgentClose,
  onBackdropClose,
}) => {
  const { optimisation_levers } = screen;

  const [optimizationScope, setOptimizationScope] = useState(0);
  const [switchValue, setSwitchValue] = useState({ value: 0 });
  const [sliderValues, setSliderValues] = useState<SliderValueType>({});
  const [recommendedSliderValues, setRecommendedSliderValues] = useState<{
    [id: string]: number;
  }>({});
  // @ts-expect-error due to time constraints, this remains untyped, read comment on top
  const [popup, setPopup] = useState<PopupProps & { screens: Screen[]; name: string }>({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const setInitialValues = useCallback(
    () =>
      !!optimisation_levers &&
      optimisation_levers?.[switchValue.value]?.content?.map(({ elements }) =>
        elements?.map((row) =>
          row.map((col) =>
            col.forEach((element) => {
              if (element.type === ElementType.Slider) {
                setRecommendedSliderValues((currentRecommendedSliderValues) => ({
                  ...currentRecommendedSliderValues,
                  [switchValue.value]: {
                    // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                    ...currentRecommendedSliderValues[switchValue.value],
                    [element.id]: element.default_value,
                  },
                }));

                setSliderValues((currentSliderValues) => ({
                  ...currentSliderValues,
                  [switchValue.value]: {
                    ...currentSliderValues[switchValue.value],
                    [element.id]: {
                      label: element.name,
                      selectedValue: element.default_value,
                      positions: element.variables.length,
                    },
                  },
                }));
              }
            }),
          ),
        ),
      ),
    [optimisation_levers, switchValue],
  );

  const optimizationScopeArr = useMemo(() => {
    return optimisation_levers?.[optimizationScope];
  }, [optimizationScope, optimisation_levers]);

  const combinedSliderValues = useMemo(() => {
    const activeSwitchSlidersIds = Object.keys(sliderValues?.[switchValue.value] || {});

    return activeSwitchSlidersIds?.reduce(
      (carry, id, index) =>
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        (carry += `${sliderValues?.[switchValue.value]?.[id]?.selectedValue}${index + 1 === activeSwitchSlidersIds.length ? '' : ','}`),
      '',
    );
  }, [sliderValues, switchValue]);

  const handleSlidingComplete = useCallback(
    (id: string, val: { value: number; label: string }) => {
      setSliderValues((prevValues) => {
        const newSliderValues = {
          ...prevValues,
          [switchValue.value]: {
            ...prevValues[switchValue.value],
            // @ts-expect-error due to time constraints, this remains untyped, read comment on top
            [id]: { ...prevValues[switchValue.value][id], ...val },
          },
        };

        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        const activeSwitchSliders = newSliderValues[switchValue.value];
        // @ts-expect-error due to time constraints, this remains untyped, read comment on top
        const activeSwitchSlidersIds = Object.keys(newSliderValues[switchValue.value]);

        const newCombinedSliderValues = activeSwitchSlidersIds?.reduce(
          (carry, id, index) =>
            // @ts-expect-error due to time constraints, this remains untyped, read comment on top
            (carry += `${newSliderValues?.[switchValue.value]?.[id]?.selectedValue}${index + 1 === activeSwitchSlidersIds.length ? '' : ','}`),
          '',
        );

        const validSliderValueCombinations = Object.keys(
          optimisation_levers?.[switchValue.value]?.screen_dict || {},
        );

        const checkIfValidValue = (combinedValue: string) =>
          validSliderValueCombinations.find((value) => combinedValue === value);

        // If combination is a screen_dict key, values are valid
        if (checkIfValidValue(newCombinedSliderValues)) {
          return newSliderValues;
        } else {
          // If combination is not a screen_dict key, test
          // every other slider value until it's a valid key

          const activeSliderValue = activeSwitchSliders[id].selectedValue;
          const activeSliderIndex = activeSwitchSlidersIds.findIndex((sliderId) => sliderId === id);

          const validSliderValuesCombination = testSliderPositions(
            activeSwitchSlidersIds.length,
            activeSwitchSliders[id].positions,
            activeSliderIndex,
            activeSliderValue,
            checkIfValidValue,
          );

          if (validSliderValuesCombination) {
            const sliderValues = validSliderValuesCombination.join('');

            activeSwitchSlidersIds.forEach(
              (sliderId, index) =>
                // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                (newSliderValues[switchValue.value][sliderId].selectedValue = Number(
                  sliderValues[index],
                )),
            );
          }

          return newSliderValues;
        }
      });
    },
    [optimisation_levers, switchValue],
  );

  const onChangeSwitch = useCallback(
    (value: number) => {
      setOptimizationScope(value);
      setSliderValues({});
      setSwitchValue({
        value,
      });
    },
    [setOptimizationScope, setSliderValues, setSwitchValue],
  );

  const onOpenScenarioButtonClick = useCallback(() => {
    const screenDict =
      optimisation_levers?.[switchValue.value]?.screen_dict?.[combinedSliderValues];

    if (screenDict) {
      const slides = {
        ...screenDict,
        screens: screenDict.screens.reduce(
          (carry, screen) => [
            ...carry,
            {
              ...screen,
              is_recommended: screenDict?.is_recommended,
            },
          ],
          [] as ScreenDictScreen[],
        ),
      };

      // @ts-expect-error due to time constraints, this remains untyped, read comment on top
      setPopup(slides);
      setIsPopupOpen(true);
    }
  }, [setPopup, setIsPopupOpen, optimisation_levers, switchValue, combinedSliderValues]);

  const shouldDisplayAilyRecommends = useCallback(
    (id: string) => {
      const activeSwitchSlidersIds = Object.keys(sliderValues?.[switchValue.value] || {});

      const lastSliderId = activeSwitchSlidersIds[activeSwitchSlidersIds.length - 1];

      const defaultSliderValues = activeSwitchSlidersIds
        .reduce(
          // @ts-expect-error due to time constraints, this remains untyped, read comment on top
          (carry, sliderId) => [...carry, recommendedSliderValues[switchValue.value][sliderId]],
          [] as string[],
        )
        .join(',');

      return combinedSliderValues === defaultSliderValues && lastSliderId === id;
    },
    [sliderValues, combinedSliderValues, recommendedSliderValues, switchValue.value],
  );

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues, switchValue]);

  return (
    <>
      <PanelContainer>
        <PanelContent>
          <PanelControls>
            <AgentContentRenderer content={screen.content}>
              {(elements) =>
                elements.map((element, elementKey) => (
                  <>
                    {element.type === ElementType.Text && (
                      <AgentTextElement key={elementKey} element={element} />
                    )}
                  </>
                ))
              }
            </AgentContentRenderer>
            <SegmentControlPanel>
              <Typography variant="bodyBold">Optimisation Scope</Typography>
              <SegmentControl
                data-testid={`test-id-segment-control-component`}
                value={optimizationScope}
                onChange={onChangeSwitch}
                sx={{ width: 'calc(100% + 18px)' }}
              >
                {(optimisation_levers ?? []).map(({ name }, index) => (
                  <MenuItem
                    data-testid={formatTestIdString(`test-id-segment-control-item-${name}`)}
                    key={index}
                    value={index}
                  >
                    {name}
                  </MenuItem>
                ))}
              </SegmentControl>
            </SegmentControlPanel>
            {optimizationScopeArr?.content?.map(({ elements }) =>
              elements?.map((row) =>
                row.map((col, colIndex) => (
                  <Stack key={colIndex}>
                    {col.map((element, elementIndex) => (
                      <Stack key={elementIndex}>
                        {/* @ts-expect-error due to time constraints, this remains untyped, read comment on top */}
                        {!isNil(sliderValues?.[switchValue.value]?.[element.id]?.selectedValue) &&
                          element.type === ElementType.Slider && (
                            <>
                              <DiscreteSlider
                                testID={formatTestIdString(`test-discrete-slider-${element.name}`)}
                                label={element.name}
                                values={element.variables}
                                isDisabled={element.variables.length <= 1}
                                selectedValue={
                                  // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                                  sliderValues?.[switchValue.value]?.[element.id]?.selectedValue
                                }
                                // @ts-expect-error due to time constraints, this remains untyped, read comment on top
                                onChange={(val) => handleSlidingComplete(element.id, val)}
                                BoxProps={{ sx: { px: 1.5 } }}
                              />
                              {shouldDisplayAilyRecommends(element.id) && <AilyRecommends />}
                            </>
                          )}

                        {element.type === ElementType.Info && (
                          <AgentInfo
                            text={
                              optimisation_levers?.[switchValue.value]?.screen_dict[
                                combinedSliderValues
                              ]?.button_value?.[0] || ''
                            }
                            text2={
                              optimisation_levers?.[switchValue.value]?.screen_dict[
                                combinedSliderValues
                              ]?.button_value?.[1] || ''
                            }
                          />
                        )}

                        {element.type === ElementType.Button && (
                          <Button
                            testID="test-button-open-scenario"
                            type="secondary"
                            title={element.content || ''}
                            width={234}
                            onPress={() => onOpenScenarioButtonClick()}
                          />
                        )}

                        {element.type === ElementType.Logos && (
                          <LogosList logoList={element.content} />
                        )}
                      </Stack>
                    ))}
                  </Stack>
                )),
              ),
            )}
          </PanelControls>
        </PanelContent>
      </PanelContainer>

      {popup && (
        <AgentDecisionLeversPopup
          data-testid={`desicion-levers-popup`}
          title={popup.name}
          screens={popup.screens}
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          baseAudioURL={baseAudioURL}
          agentJsonData={agentJsonData}
          decision={decision}
          textElementStyle={{ paddingBottom: 0 }}
          onAgentClose={onAgentClose}
          onBackdropClose={onBackdropClose}
        />
      )}
    </>
  );
};
