/**
 * A string or color-stop pair that represent CSS color value
 */
export type Color = string | [string, number];

/**
 * Returns a `linear-gradient` CSS string based on the provided colors array with optional color stops.
 * If the colors array is empty or falsy, returns undefined.
 * If the colors array has one element, that element will be used for both the start and end color of the gradient.
 * If the colors array has more than one element, the first two elements will be used for the start and end color of the gradient, respectively.
 * If any element of the colors array is an array, it is interpreted as a color-stop pair, where the first element is the color and the second is the stop position.
 *
 * @param colors An array of strings or color-stop pairs that represent CSS color values.
 * @returns A string that represents a `linear-gradient` CSS value, or undefined if the colors array is empty or falsy.
 */
export function getLinearGradient(colors?: Color[]): string | undefined {
  if (colors?.length) {
    const colorStops = colors.map((color) => {
      if (Array.isArray(color)) {
        const [colorValue, stop] = color;
        return `${colorValue} ${stop}%`;
      } else {
        return color;
      }
    });

    return `linear-gradient(180deg, ${colorStops.join(', ')})`;
  }

  return undefined;
}
