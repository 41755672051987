import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const Correlator: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="34" height="34" viewBox="0 0 34 34" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3214 10.1429C16.1558 10.1429 17.6429 8.6558 17.6429 6.82143C17.6429 4.98705 16.1558 3.5 14.3214 3.5C12.4871 3.5 11 4.98705 11 6.82143C11 8.6558 12.4871 10.1429 14.3214 10.1429ZM17.3498 10.5733C18.4435 9.68945 19.1429 8.33712 19.1429 6.82143C19.1429 4.15863 16.9842 2 14.3214 2C11.6586 2 9.5 4.15863 9.5 6.82143C9.5 8.52638 10.385 10.0246 11.7206 10.8819L8.95012 16.4229C8.30813 16.1065 7.58554 15.9287 6.82143 15.9287C4.15863 15.9287 2 18.0873 2 20.7501C2 23.4129 4.15863 25.5716 6.82143 25.5716C8.62386 25.5716 10.1953 24.5825 11.0226 23.1175L22.6651 26.822L23.1199 25.3926L11.5472 21.7104C11.6099 21.4001 11.6429 21.0789 11.6429 20.7501C11.6429 19.3976 11.0859 18.1751 10.1888 17.2995L13.0958 11.4857C13.4871 11.5882 13.8979 11.6429 14.3214 11.6429C14.9306 11.6429 15.5134 11.5299 16.05 11.3238L23.8638 23.826L25.1358 23.031L17.3498 10.5733ZM6.82143 24.0716C8.6558 24.0716 10.1429 22.5845 10.1429 20.7501C10.1429 18.9158 8.6558 17.4287 6.82143 17.4287C4.98705 17.4287 3.5 18.9158 3.5 20.7501C3.5 22.5845 4.98705 24.0716 6.82143 24.0716Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1784 30.5003C29.0127 30.5003 30.4998 29.0132 30.4998 27.1789C30.4998 25.3445 29.0127 23.8574 27.1784 23.8574C25.344 23.8574 23.8569 25.3445 23.8569 27.1789C23.8569 29.0132 25.344 30.5003 27.1784 30.5003ZM27.1784 32.0003C29.8412 32.0003 31.9998 29.8417 31.9998 27.1789C31.9998 24.516 29.8412 22.3574 27.1784 22.3574C24.5156 22.3574 22.3569 24.516 22.3569 27.1789C22.3569 29.8417 24.5156 32.0003 27.1784 32.0003Z"
      fill="#48FF9B"
    />
  </SvgIcon>
);
