import { List, ListProps, styled } from '@mui/material';
import React from 'react';

import { PageMenuItem, PageMenuItemProps } from './PageMenuItem';

export interface PageMenuProps extends ListProps {
  expanded?: boolean;
  children?: React.ReactElement<PageMenuItemProps> | React.ReactElement<PageMenuItemProps>[];
}

const StyledList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded?: boolean }>(({ theme, expanded }) => ({
  padding: theme.spacing(6, 0),
  '& .MuiListItemText-root': {
    opacity: expanded ? 1 : 0,
    transitionDelay: `${expanded ? 0 : 500}ms`,
  },
}));

export const PageMenu: React.FC<PageMenuProps> & { Item: typeof PageMenuItem } = ({
  expanded,
  children,
  ...rest
}) => (
  <StyledList expanded={expanded} data-testid="page-menu" {...rest}>
    {children}
  </StyledList>
);

PageMenu.Item = PageMenuItem;

export default PageMenu;
