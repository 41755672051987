import { styled, useThemeProps } from '@mui/material';
import React, { useMemo } from 'react';

import { useResponsiveValue } from '../../hooks';
import Carousel from '../Carousel/index';
import { CircularProgressProps } from '../CircularProgress';

export interface DialsResponsiveCarouselProps {
  children: React.ReactNode;
}

const StyledCarousel = styled(Carousel)(() => ({}));

const DialsResponsiveCarousel: React.FC<DialsResponsiveCarouselProps> = ({ children }) => {
  const circularProgressProps = useThemeProps({
    props: {} as CircularProgressProps,
    name: 'CircularProgress',
  });

  const slideSize = useResponsiveValue(circularProgressProps.size ?? 154, 154);
  const totalSlides = useMemo(() => React.Children.count(children), [children]);
  const visibleSlides = useResponsiveValue({ xs: 1, sm: 2, md: 3 }, 1);
  const gapSize = 40;

  const containerWidth = useMemo(
    () => visibleSlides * slideSize + (visibleSlides - 1) * gapSize + 80,
    [visibleSlides, slideSize, gapSize],
  );

  return (
    <StyledCarousel
      totalSlides={totalSlides}
      visibleSlides={visibleSlides}
      sx={{ width: containerWidth }}
    >
      {children}
    </StyledCarousel>
  );
};

export default DialsResponsiveCarousel;
