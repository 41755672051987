import * as T from '@aily/graphql-sdk/schema';
import React, { FC, memo } from 'react';

import { TriangleDown, TriangleUp } from '../../icons';

interface Props {
  sentiment: T.Sentiment;
}

export const RiskIndicatorIcon: FC<Props> = memo(({ sentiment }) => {
  return sentiment === T.Sentiment.Positive ? (
    <TriangleDown color="success" sx={{ fontSize: 16 }} />
  ) : (
    <TriangleUp color="error" sx={{ fontSize: 16 }} />
  );
});
