import * as T from '@aily/graphql-sdk/schema';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import { mapSentimentToColor } from '../../utils';
import { FitText } from '../FitText';

export interface TableCellVarianceProps {
  variance: T.VarianceResult;
}

export const TableCellVariance: React.FC<TableCellVarianceProps> = ({ variance }) => {
  const { actual, target, sentiment } = variance;
  const color = mapSentimentToColor(sentiment ?? T.Sentiment.Neutral);

  if (actual && target) {
    return (
      <Stack
        display="inline-flex"
        direction="row"
        alignItems="center"
        spacing={0.625}
        data-testid="VarianceCell"
      >
        <Typography variant="h9" sx={{ color }}>
          {actual}
        </Typography>
        <Typography variant="small" color="text.secondary">
          /
        </Typography>
        <Typography variant="small" color="text.secondary">
          {target}
        </Typography>
      </Stack>
    );
  }

  if (actual) {
    return (
      <Typography variant="body" fontWeight="inherit" sx={{ color }} data-testid="VarianceCell">
        <FitText>{actual}</FitText>
      </Typography>
    );
  }

  return null;
};

export default TableCellVariance;
